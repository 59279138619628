import React,{Component} from 'react';
import '../scss/profile.scss';

type StartProps = {
    stars:boolean[];
    setStar:any;
}
class StarCom extends Component<StartProps>{
    state = {
        stars: [false, false, false, false, false ],
    };
    constructor(props:StartProps) {
        super(props);
        this.state.stars = props.stars;
    }
    changeStatus = (e:any ) =>{
        let id = parseInt(e.currentTarget.dataset.id);
        let isPossible = true;
        const stars = [...this.state.stars];
        let isActiveStar:boolean = true; 
        if( stars[id] ){// if trying to deactivate
            for( let i = id; i < stars.length; i ++ ){
                stars[i] = false;
            }
        }else{
            for( let i = 0; i < id+1; i ++ ){
                stars[i] = true;
            }
        }
        this.setState(state => ({
            stars: stars
          }));
        const point = this.getStarPoint( stars );
        this.props.setStar(point);
}    
    getStarPoint = ( stars:any ) =>{
        let point:number = 0;
        for( let i = 0; i < stars.length; i ++ ){
            const star = stars[i];
            if( star ){
                point ++;
            }
        }
        return point;
    }
    public render(){
        return(
            <div className="star_container">
                <span className={this.state.stars[0] ? 'star active' : 'star' } data-id="0" onClick = {this.changeStatus}></span>
                <span className={this.state.stars[1] ? 'star active' : 'star' } data-id="1" onClick = {this.changeStatus}></span>
                <span className={this.state.stars[2] ? 'star active' : 'star' } data-id="2" onClick = {this.changeStatus}></span>
                <span className={this.state.stars[3] ? 'star active' : 'star' } data-id="3" onClick = {this.changeStatus}></span>
                <span className={this.state.stars[4] ? 'star active' : 'star' } data-id="4" onClick = {this.changeStatus}></span>
            </div>
        );
    }
}
export default StarCom;