import React, { Component } from 'react';
import '../scss/recommend.scss';
import { Link } from 'react-router-dom';
import * as Global from '../../modules/global';
import { Redirect } from 'react-router-dom';

class RecommendLinkCom extends Component<any>{
    state = {
        isRedirect: false,
    }
    anotherAccount = () => {
        this.setState({
            isRedirect: true,
        })
    }
    renderRedirect = () => {
        if (this.state.isRedirect) {
            let role = Global.getRole();
            if (role === '1') {
                role = '2';
                Global.setDefaultRole(role);
            } else if (role === '2') {
                role = '1';
                Global.setDefaultRole(role);
            }
            return <Redirect to={"/choose"} />
        } else {
            return <></>;
        }
    }

    render() {
        const emtId = Global.getEmtId();
        return (
            <div className="right-menu-container mt_70">
                {this.renderRedirect()}
                <ul>
                    <li>
                        <Link to="/search" className="fa">
                            <span>職⼈を探す、仕事を依頼する</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/jobview" className="fa"><span>仕事の登録・修正、依頼仕事履歴、</span><span>職⼈履歴</span></Link>
                    </li>
                    <li>
                        <Link to={"/messagelist"} className="fa"><span>メッセージ</span></Link>
                    </li>
                    <li>
                        <Link to={"/myprofile"} className="fa"><span>親⽅/会社プロフィールの登録・修正</span></Link>
                    </li>
                    <li>
                        <Link to={"/pointview"} className="fa"><span>所持ポイント表⽰、ポイント購⼊</span></Link>
                    </li>
                    {
                        emtId === '3' ?
                            <li>
                                <a className="fa"><div className="fa" onClick={this.anotherAccount}><span>職人でログイン</span></div></a>
                            </li>
                            : <></>
                    }
                </ul>
            </div>
        );
    }
}
export default RecommendLinkCom; 
