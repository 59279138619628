import React from 'react';
import '../scss/job.scss';
import Header from '../Include/Header'
import JobNavCom from "../Find/JobNavCom";
import LeaveFeedbackCom from './LeaveFeedbackCom';
import Footer from '../Include/Footer';
import { FeedbackType } from '../../modules/feedback/types';
import ProviderNavCom from '../Include/ProviderNavCom';

class LeaveFeedback extends React.Component<any>{
    public render() {
        const url = this.props.match.url;
        let id = this.props.match.params.id ? this.props.match.params.id : "";
        let ctrId = this.props.location.state.ctrId ? this.props.location.state.ctrId : "";
        let em_name = this.props.location.state.em_name ? this.props.location.state.em_name : "";
        let em_photo_s = this.props.location.state.em_photo_s ? this.props.location.state.em_photo_s : "";
        let is_boss = this.props.location.state.is_boss ? this.props.location.state.is_boss : "";
        let info: FeedbackType = {
            em_id: id,
            ctrId: ctrId,
            em_name: em_name,
            is_boss: is_boss,
            em_photo_s: em_photo_s,
        }
        const role = localStorage.getItem('role');
        return (
            <div>
                <Header />
                <div className="content-container" id="content">
                    <div className="wrap-content">
                        <div className="main-title">仕事管理</div>
                        <div className="job_container mt_40">
                            {role === "1" ?
                                <JobNavCom to={url} /> :
                                <ProviderNavCom to={url} />
                            }
                            <div className="job profile">
                                <LeaveFeedbackCom info={info} />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}
export default LeaveFeedback;