import { getYear } from 'date-fns';
import moment from 'moment';
export function convertDateToUTC(date: Date): Date {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
}
export function nl2br(str: string) {
    return str.replace(/(?:\ r\n|\r|\n)/g, '<br/>');
}
export function brnl2(str: string) {
    return str.replace(/<br\/>/g, "\n");
}
export function isIE() {
    const isEdge = window.navigator.userAgent.indexOf('Edge') !== -1;
    const isIE = window.navigator.userAgent.indexOf('Trident') !== -1 && !isEdge;
    return isIE;
}
export function removeItem(arr: any, value: any) {
    var index = arr.indexOf(value);
    if (index > -1) {
        arr.splice(index, 1);
    }
    return arr;
}
export function moneyFormat(value: string) {
    if (value === '') value = '0';
    var formatter = new Intl.NumberFormat('ja-JP', {
        style: 'decimal',
        currency: 'JPY',
    });
    return formatter.format(parseInt(value));
}
export function numberWithCommas(x: string) {
    return x.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}
export function moneyFormatToFloat(value: string) {
    value = value.replace(/[^\d\.\-]/g, ""); // You might also include + if you want them to be able to type it
    var num = parseFloat(value);
    return num;
}
export const PAGE_PER_COUNT: number = 3;

export const VALIDATION_MESSAGES = {
    'required': '入力してください',
    'accepted': '選択してください',
    'date': '形式が正しくありません',
    'time': '形式が正しくありません',
    'email': '形式が正しくありません',
    'select': '選択してください',
    'postcode': '形式が正しくありません',
    'phone': '形式が正しくありません',
    'furigana': '形式が正しくありません',
    'money': '形式が正しくありません',
    'numeric': '形式が正しくありません',
    'min': '形式が正しくありません',
    'unique': 'ユーザがすでに存在します',
    'compareWith': '予定金額の範囲が間違っています。',
    'unique_phone': '電話番号がすでに存在します',
    'minselect': ':attributeを:valueつ以上追加してください。',
    'maxselect': ':attributeは:valueつまで追加できます。',
    'default': 'Something not match',

}
export function convertFulltoHalf(value: string) {
    value = value.replace(
        /[\uff01-\uff5e]/g,
        function (ch) { return String.fromCharCode(ch.charCodeAt(0) - 0xfee0); }
    );
    return value;
}
export const VALIDATORS = {
    select: {
        rule: (val: any, params: any, validator: any) => {
            if (val === "" || parseInt(val) === 0)
                return false;
            return true;
        },
        required: true  // optional 
    },
    postcode: {
        rule: (value: any, params: any, validator: any) => {
            value = convertFulltoHalf(value);
            if (value.trim() === '') return true;
            var myregexp = /^[0-9]{3}-?[0-9]{4}$/;
            return myregexp.test(value);
        },
    },
    furigana: {
        rule: (value: any, params: any, validator: any) => {
            value = value.trim();
            if (value.trim() === '') return true;

            var myregexp = /^[ァ-ンー]+$/;
            var myregexp1 = /^[ｧ-ﾝﾞﾟー]+$/;
            if (myregexp.test(value) || myregexp1.test(value)) {
                return true;
            }
            return false;
        },
    },
    money: {
        rule: (value: any, params: any, validator: any) => {
            value = value.trim();
            if (value.trim() === '') return true;

            var myregexp = /^[1-9]\d*(((,\d{3}){0,5})?)$/;
            //var myregexp = /^[1-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/;
            if (myregexp.test(value)) {
                return true;
            }
            return false;
        },
    },
    phone: {
        rule: (value: any, params: any, validator: any) => {
            value = value.trim();
            value = convertFulltoHalf(value);
            if (value.trim() === '') return true;
            var myregexp = /^0[0-9]{1}-?[0-9]{4}-?[0-9]{4}$|^0[0-9]{2}-?[0-9]{3}-?[0-9]{4}$|^0[0-9]{3}-?[0-9]{2}-?[0-9]{4}$|^0[0-9]{4}-?[0-9]{1}-?[0-9]{4}$|^0[0-9]{5}-?[0-9]{4}$/;
            var myregexp2 = /^0[0-9]{2}-?[0-9]{4}-?[0-9]{4}$/;
            return myregexp.test(value) || myregexp2.test(value);
        },
        required: true  // optional         
    },
    unique: {
        rule: (value: any, params: any, validator: any) => {
            return !value;
        }
    },
    unique_phone: {
        rule: (value: any, params: any, validator: any) => {
            return !value;
        }
    },
    compareWith: {
        rule: (value: any, params: any, validator: any) => {
            return value;
        }
    },
    maxselect: {
        rule: (list: Array<any>, params: any, validation: any) => {
            if (list.length > params) {
                return false;
            }
            return true;
        },
        messageReplace: (message: string, params: any) => {
            message = message.replace("sk s[]", "業種");
            message = message.replace(":value", params);
            return message;
        },  // optional
    },
    minselect: {
        rule: (list: Array<any>, params: any, validation: any) => {
            if (list.length < params) {
                return false;
            }
            return true;
        },
        messageReplace: (message: string, params: any) => {
            message = message.replace("sk s[]", "業種");
            message = message.replace(":value", params);
            return message;
        },  // optional
    },
    date: {
        rule: (value: any, params: any, validator: any) => {
            var myregexp = /\d{4}\/\d{1,2}\/\d{1,2}/;
            return myregexp.test(value);
        },
        messageReplace: (message: string, params: any) => {
            return message;
        },
    },
    time: {
        rule: (value: any, params: any, validator: any) => {
            var myregexp = /[0-2]?[0-9]{1}:\d{1,2}$/;
            return myregexp.test(value);
        },
        messageReplace: (message: string, params: any) => {
            return message;
        },
    }
}
export function dateFormat(date: any, fstr: any, utc: any) {
    utc = utc ? 'getUTC' : 'get';

    return fstr.replace(/%[YmdHMS]/g, function (m: any) {
        switch (m) {
            case '%Y': return date[utc + 'FullYear'](); // no leading zeros required
            case '%m': m = 1 + date[utc + 'Month'](); break;
            case '%d': m = date[utc + 'Date'](); break;
            case '%H': m = date[utc + 'Hours'](); break;
            case '%M': m = date[utc + 'Minutes'](); break;
            case '%S': m = date[utc + 'Seconds'](); break;
            default: return m.slice(1); // unknown code, remove %
        }
        // add leading zero if required
        return ('0' + m).slice(-2);
    });
}

export function DateFormat(year: string, month: string, day: string) {
    if (parseInt(month) < 10) {
        month = "0" + month;
    }
    if (parseInt(day) < 10) {
        day = "0" + day;
    }
    return (month + "/" + day + "/" + year);
}
export function timeFormat(_time: string) {
    if (_time === '')
        return _time;
    let timeList = _time.split(":");
    for (let i = 0; i < timeList.length; i++) {
        timeList[i] = (timeList[i].indexOf('0') !== 0 && parseInt(timeList[i]) < 10) ? ("0" + timeList[i]) : timeList[i];
    }
    let time = timeList[0] + ":" + timeList[1];
    return time;
}
export function stringToDate(dateList: any) {
    const date = new Date(dateList.year, (dateList.month - 1), dateList.day);
    return date;
}
export function DateToString(date: string, splitter: string = "/", format = "m/d/y", removeZero: boolean = true) {
    let ret = {
        year: 0,
        month: 0,
        day: 0,
    }
    if (date != null && date !== undefined && date !== "") {
        let dateList = date.split(splitter);
        if (dateList.length === 3) {
            let month = dateList[0];

            if (format === "y/m/d") {
                month = dateList[1];
            }
            if (month.charAt(0) === '0' && removeZero) {
                month = month.slice(1);
            }
            let day = dateList[1];
            if (format === "y/m/d") {
                day = dateList[2];
            }
            if (day.charAt(0) === '0' && removeZero) {
                day = day.slice(1);
            }
            let year = dateList[2];
            if (format === "y/m/d") {
                year = dateList[0];
            }

            ret.year = year as any;
            ret.month = month as any;
            ret.day = day as any;
        }
    }
    return ret;
}
export function getAge(date: string) {
    const year = date != ''?moment(date, 'MM/DD/YYYY').format('YYYY'):'';

    if( year == '' ) {
        return '';
    }
    const year_num = parseInt( year );
    return new Date().getFullYear() - year_num;
}
export function convertDateToJapan(usDate: string, delimmer = "/", isRemoveZero = true) {
    if (!usDate || usDate === '') {
        return '';
    }
    let dateParts = usDate.split(/(\d{1,2})\/(\d{1,2})\/(\d{4})/);
    if (dateParts.length !== 5) {
        return '';
    }
    let year = dateParts[3];
    let month = dateParts[1];
    if (month.charAt(0) === '0' && isRemoveZero) {
        month = month.slice(1);
    }
    let day = dateParts[2];
    if (day.charAt(0) === '0' && isRemoveZero) {
        day = day.slice(1);
    }

    return year + delimmer + month + delimmer + day;
}

export function getSkills(skills: string) {
    if (skills === "") {
        return [];
    }
    return skills.split(",");
}
export function splitToArray(s: string) {
    if (s === "") {
        return [];
    }
    return s.split(",");
}
export function ArrayToString(list: any, delimmer: string = ",") {
    if (!list) {
        return '';
    }
    let ret = '';
    for (let i = 0; i < list.length; i++) {
        ret += list[i] + ",";
    }
    return ret;
}
function getWeekNumber(d: any): number {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart: any = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    // Return array of year and week number
    return weekNo;
}
export function getSpecialDate(dateStr: string, offset: number, isForward: boolean = false) {
    const dateList = DateToString(dateStr, "-", "y/m/d");
    let date = new Date(dateList.year, (dateList.month - 1), (dateList.day - offset));
    if (isForward) {
        let d = parseInt(dateList.day.toString()) + offset;
        date = new Date(dateList.year, (dateList.month - 1), d);
    }
    dateStr = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    return dateStr;
}
export function getWeekList(date: string) {
    const dateList = DateToString(date, "-", "y/m/d");
    let weekList = [];
    if (dateList.year !== 0 && dateList.month !== 0 && dateList.day !== 0) {
        const date = new Date(dateList.year, (dateList.month - 1), dateList.day);
        const weekNo = date.getDay();

        for (let i = 0; i < weekNo; i++) {
            let date = new Date(dateList.year, (dateList.month - 1), dateList.day - weekNo + i);
            let dateStrFormat: string = date.getFullYear() + "年" + (date.getMonth() + 1) + "月" + date.getDate() + "日" + "（" + WEEK[date.getDay()] + "）";
            let dateStr = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
            let dateStr1 = ('0' + (date.getMonth() + 1)).slice(-2) + '/'
                + ('0' + date.getDate()).slice(-2) + '/'
                + date.getFullYear();
            let dateObj = {
                idx: i,
                time: date.getTime(),
                date: dateStr,
                date1: dateStr1,
                formatDate: dateStrFormat,
            }
            weekList.push(dateObj);
        }
        for (let j = weekNo; j < 7; j++) {
            let date = new Date(dateList.year, (dateList.month - 1), dateList.day - weekNo + j);
            let dateStrFormat: string = date.getFullYear() + "年" + (date.getMonth() + 1) + "月" + date.getDate() + "日" + "（" + WEEK[date.getDay()] + "）";
            let dateStr = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
            let dateStr1 = ('0' + (date.getMonth() + 1)).slice(-2) + '/'
                + ('0' + date.getDate()).slice(-2) + '/'
                + date.getFullYear();

            let dateObj = {
                idx: j,
                date: dateStr,
                time: date.getTime(),
                date1: dateStr1,
                formatDate: dateStrFormat,
            }
            weekList.push(dateObj);
        }
    }
    return weekList;
}
export function getEmId() {
    return (localStorage.getItem("em_id") !== undefined) ? localStorage.getItem("em_id") : '';
}
export function getRole() {
    return (localStorage.getItem("role") !== undefined) ? localStorage.getItem("role") : '0';
}
export function getRoleName() {
    return (localStorage.getItem("em_role") !== undefined) ? localStorage.getItem("em_role") : '';
}
export function getEmName() {
    return (localStorage.getItem("em_name") !== undefined) ? localStorage.getItem("em_name") : '';
}
export function getEmCompany() {
    return (localStorage.getItem("em_company") !== undefined) ? localStorage.getItem("em_company") : '';
}
export function getStar() {
    return (localStorage.getItem("em_star") !== undefined) ? localStorage.getItem("em_star") : '0';
}
export function setStar(em_star: string) {
    localStorage.setItem('em_star', em_star);
}
export function getSkillString() {
    return (localStorage.getItem("sk_s") !== undefined) ? localStorage.getItem("sk_s") : '';
}
export function setSkillString(sk_s: string) {
    localStorage.setItem('sk_s', sk_s);
}

export function getPoint() {
    return (localStorage.getItem("em_point") !== undefined) ? parseInt(localStorage.getItem("em_point")!) : 0;
}
export function getMessageToEmId() {
    return (localStorage.getItem("msg_em_id") !== undefined) ? parseInt(localStorage.getItem("msg_em_id")!) : 0;
}
export function setMessageToEmId(em_id: string) {
    localStorage.setItem('msg_em_id', em_id);
}
export function setPoint(point: string) {
    localStorage.setItem('em_point', point);
}
export function setDefaultRole(role: string) {
    localStorage.setItem('em_default_role', role);
}
export function getDefaultRole() {
    return (localStorage.getItem("em_default_role") !== undefined) ? localStorage.getItem("em_default_role") : '';
}

export function setEmId(em_id: string) {
    localStorage.setItem('em_id', em_id);
}
export function setEmBossLoginDate( date: string ) {
    localStorage.setItem('em_boss_login_date', date );    
}
export function getEmBossLoginDate() {
    return (localStorage.getItem("em_boss_login_date") !== undefined) ? localStorage.getItem("em_boss_login_date") : '';
}
export function setEmWorkerLoginDate( date: string ) {
    localStorage.setItem('em_worker_login_date', date );    
}
export function getEmWorkerLoginDate() {
    return (localStorage.getItem("em_worker_login_date") !== undefined) ? localStorage.getItem("em_worker_login_date") : '';
}
export function setEmBossCompany(em_company: string) {
    localStorage.setItem('em_boss_company', em_company);
}
export function setEmWorkerName(em_name: string) {
    localStorage.setItem('em_worker_name', em_name);
}
export function setEmDepart(em_depart: string) {
    localStorage.setItem('em_depart', em_depart);
}
export function setEmWorkerCompany(em_company: string) {
    localStorage.setItem('em_worker_company', em_company);
}
export function setEmBigPhoto(bigPhoto: string) {
    localStorage.setItem('em_photo_b', bigPhoto);
}
export function setEmSmallPhoto(smallPhoto: string) {
    localStorage.setItem('em_photo_s', smallPhoto);
}
export function setTempEmtId(emtId: string) {
    localStorage.setItem('t_emt_id', emtId);
}
export function getTempEmtId() {
    return localStorage.getItem('t_emt_id') !== undefined ? localStorage.getItem('t_emt_id') : '';
}
export function getEmBossCompany() {
    return localStorage.getItem('em_boss_company') !== undefined ? localStorage.getItem('em_boss_company') : '';
}
export function getEmWorkerName() {
    return localStorage.getItem('em_worker_name') !== undefined ? localStorage.getItem('em_worker_name') : '';
}
export function getEmBigPhoto() {
    return localStorage.getItem('em_photo_b') !== undefined ? localStorage.getItem('em_photo_b') : '';
}
export function getEmSmallPhoto() {
    return localStorage.getItem('em_photo_s') !== undefined ? localStorage.getItem('em_photo_s') : '';
}
export function getEmDepart() {
    return localStorage.getItem('em_depart') !== undefined ? localStorage.getItem('em_depart') : '';
}
export function getEmWorkerCompany() {
    return localStorage.getItem('em_worker_company') !== undefined ? localStorage.getItem('em_worker_company') : '';
}
export function setEm_is_legal(em_is_legal: string) {
    localStorage.setItem('em_is_legal', em_is_legal);
}
export function setEmCompnay(em_company: string) {
    localStorage.setItem('em_company', em_company);
}
export function setEm_company_legal(em_company_legal: string) {
    localStorage.setItem('em_company_legal', em_company_legal);
}
export function setEm_is_individual(em_is_individual: string) {
    localStorage.setItem('em_is_individual', em_is_individual);
}
export function setEm_firstname(em_firstname: string) {
    localStorage.setItem('em_firstname', em_firstname);
}
export function setEm_lastname(em_lastname: string) {
    localStorage.setItem('em_lastname', em_lastname);
}
export function setEmt_is_boss(emt_is_boss: string) {
    localStorage.setItem('emt_is_boss', emt_is_boss);
}
export function setEmt_is_worker(emt_is_worker: string) {
    localStorage.setItem('emt_is_worker', emt_is_worker);
}
export function setEmtId(emt_is_boss: string, emt_is_worker: string) {
    if (emt_is_boss === '1' && emt_is_worker === '1') {
        localStorage.setItem('emt_id', '3');
    } else if (emt_is_boss === '1' && emt_is_worker === '0') {
        localStorage.setItem('emt_id', '1');
    } else if (emt_is_boss === '0' && emt_is_worker === '1') {
        localStorage.setItem('emt_id', '2');
    }
}
export function setEmtIdById(emt_id: string) {
    localStorage.setItem('emt_id', emt_id);
}
export function getEmtId() {
    return (localStorage.getItem("emt_id") !== undefined) ? localStorage.getItem("emt_id") : '0';
}


/*********************************************/
export function setProviderSearchClientParams(params: string) {
    localStorage.setItem('provider_search_client_params', params);
}
export function getProviderSearchClientParams() {
    return (localStorage.getItem("provider_search_client_params") !== undefined) ? localStorage.getItem("provider_search_client_params") : '';
}
/******************************************* */
export function renderSkill(skillStr: string) {
    if (!skillStr) {
        return [];
    }
    const skills = skillStr.split(',');
    let ret = [];
    for (let i = 0; i < skills.length; i++) {
        ret.push('<span className="tag-mg fs14" key = {i}><span className="tag-inner">{skills[i]}</span></span>')
    }
    return ret;
}
function range(start: any, end: any, step: number = 1) {
    var range = [];
    var typeofStart = typeof start;
    var typeofEnd = typeof end;

    if (step === 0) {
        throw TypeError("Step cannot be zero.");
    }

    if (typeofStart === "undefined" || typeofEnd === "undefined") {
        throw TypeError("Must pass start and end arguments.");
    } else if (typeofStart !== typeofEnd) {
        throw TypeError("Start and end arguments must be of same type.");
    }

    typeof step == "undefined" && (step = 1);

    if (end < start) {
        step = -step;
    }

    if (typeofStart === "number") {

        while (step > 0 ? end >= start : end <= start) {
            range.push(start);
            start += step;
        }

    } else if (typeofStart === "string") {

        if (start.length !== 1 || end.length !== 1) {
            throw TypeError("Only strings with one character are supported.");
        }

        start = start.charCodeAt(0);
        end = end.charCodeAt(0);

        while (step > 0 ? end >= start : end <= start) {
            range.push(String.fromCharCode(start));
            start += step;
        }

    } else {
        throw TypeError("Only string and number types are supported");
    }

    return range;
}

// export const BIRTH_YEAR = [
//                             '1980','1981','1982','1983','1984','1985','1986','1987','1988','1989','1990','1991','1992','1993','1994','1995','1996','1997','1998','1999'
//                             ,'2000','2001','2002','2003','2004','2005','2006','2007','2008','2009','2010'
//                             ];

export const BIRTH_YEAR = range(1945, getYear(new Date()), 1);

export const ANALYZE_YEAR = ['2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016'
    , '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024', '2025'
];
export const YEAR = ['2020', '2021', '2022', '2023', , '2024', , '2025'];
export const MONTH = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
export const DAY = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'
    , '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'
    , '25', '26', '27', '28', '29', '30', '31'];
const WEEK = ["日", "月", "火", "水", "木", "金", "土"];

export const DATE_DEVIDE_OPTIONS = [
    { grp_ft_id: 1, grp_ft_name: '終日', grp_ft_value: '3' },
    {
        grp_ft_id: 2,
        grp_ft_name: '終日+夜間',
        grp_ft_value: '1',
    },
    { grp_ft_id: 3, grp_ft_name: '午前', grp_ft_value: '4' },
    {
        grp_ft_id: 4,
        grp_ft_name: '午前+夜間',
        grp_ft_value: '2,4,6',
    },
    { grp_ft_id: 5, grp_ft_name: '午後', grp_ft_value: '5' },
    {
        grp_ft_id: 6,
        grp_ft_name: '午後+夜間',
        grp_ft_value: '2,5,6',
    },
    { grp_ft_id: 7, grp_ft_name: '夜間', grp_ft_value: '2,6' }
];
export const NEAR_DATE_OPTIONS = [
    {
        jb_date: moment().format('MM/DD/YYYY'),
        jb_date_name: '今⽇',
    },
    {
        jb_date: moment().add(1, 'days').format('MM/DD/YYYY'),
        jb_date_name: '明⽇',
    },
    {
        jb_date: moment().add(2, 'days').format('MM/DD/YYYY'),
        jb_date_name: '明後⽇',
    },
];
