import React,{ Component } from 'react';
import '../scss/job.scss';
import '../scss/business_type.scss';
import Header from '../Include/Header'
import JobTypeItemCom from './JobTypeItemCom';
import Footer from '../Include/Footer';
import * as Global from '../../modules/global';
import * as URLS from '../../modules/urls';
import Loading from '../Include/Loading';

export default class JobType extends Component<any, any>{
    state = {
        index:-1,
        loading:false,
        items:[],
    }
    setIndex = ( _index:number ) => {
        this.setState({
            index:_index,
        });
    }
    componentDidMount(){
        this.setState({
            loading:true,
        });
        const that = this;
        fetch( URLS.GET_SKILL + "&tim_id=35" , {
            method: 'get',
        }).then(async function(response) {
            let ret = await response.json();
            if( ret.total > 0 ){
                const items = ret.items;
                that.setState({
                    loading:false,
                    items:items,
                });
            }else{
                that.setState({
                    loading:false,
                    items:[],
                });
            }
        }).catch(error => {
            this.setState({ loading:false, items:[], errorMessage: error.toString() });
        });         
    }
    render(){
        const em_id = Global.getEmId();
        return(
            <div>
                {
                    (em_id && em_id !== "")?<Header/>:<></>
                }
                {this.state.loading?
                    <Loading/>
                    :<></>
                }
                <div className="content-container" id = "content">
                    <div className="wrap-content">
                        <div className="main-title">業種一覧</div>
                        <div className="job_container mt_40">
                            <div className ="job business_type">
                                <div className = "business_type_inner">
                                    {
                                        this.state.items.map( item => (
                                            <JobTypeItemCom key = {`btype_${item['sk_id']}`} no = {item['sk_id']} setIndex = { this.setIndex } index = {this.state.index} item = {item}/>
                                        ))
                                    }
                                </div>
                            </div>                            
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}