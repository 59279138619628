import { SearchImmediateJobActions, SearchImmediateJobState, actionTypes, SearchImmediateJob } from './types';
import moment from 'moment';
const initialState: SearchImmediateJobState = {
    loading: false,
    jb_date: moment().format('MM/DD/YYYY'),
    jb_time: '',
    errorMessage:'',
};
const SAVE = actionTypes.SAVE;
export function SearchImmediateJobReducer(state: SearchImmediateJobState = initialState, action: SearchImmediateJobActions): SearchImmediateJobState {
    switch (action.type) {
        case SAVE:
            const searchImmediateJob: SearchImmediateJob = action.payload.searchImmediateJob;
            return {
                ...state,
                jb_date: searchImmediateJob.jb_date,
                jb_time: searchImmediateJob.jb_time,
            };
        default:
            return state;
    }
}