import React, {Component} from 'react';
import '../scss/recommend.scss';
import {Link} from 'react-router-dom';
import PageNavigation from '../Include/PageNavigation';
import * as URLS from '../../modules/urls';
import Loading from '../Include/Loading';
import * as Global from '../../modules/global';
import { IRootState } from '../../modules';
import { FooterActions } from '../../modules/footer';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as footerAsyncactions from '../../modules/footer/async-actions';

const mapDispatcherToProps = (dispatch: Dispatch<FooterActions>) => {
    return {
        isRerender:( _isRerender: boolean ) => footerAsyncactions.isRerender( dispatch, _isRerender ),             
    }
}
type ReduxType = ReturnType<typeof mapDispatcherToProps>;

class RecommendCom extends Component<ReduxType>{
    state = {
        items:[],
        loading:false,
        totalCount:0,
        startNo:0,
        errorMessage:'',
    }
    componentDidMount(){
        const that = this;
        this.setState({
            loading:true,
        })
        let em_id:any = Global.getEmId();
        const sort=[{"property":"match_all","direction":"desc"}];
        const url = URLS.GET_RECOMMEND_PROVIDER_LIST + "&sort=" + JSON.stringify(sort) + "&em_id=" + em_id;
        fetch( url, {
            method: 'get',
        }).then(async function(response) {
            let ret = await response.json();
            if( ret.total > 0 ){
                const items = ret.items;
                that.setState({
                    loading:false,
                    items:items,
                }, async() => {
                    await that.props.isRerender( true );
                });
            }else{
                that.setState({
                    loading:false,
                    items:[],
                }, async() => {
                    await that.props.isRerender( true );
                });
            }
        }).catch(error => {
            this.setState({ loading:false, items:[], errorMessage: error.toString() });
        });           
    }
    renderSkill( skillStr:string ){
        if( !skillStr ){
            return [];
        }
        const skills = skillStr.split( ',' );
        let ret = [];
        for( let i=0; i < skills.length; i ++ ){
            ret.push(<span className="tag-mg fs14" key = {i}><span className="tag-inner">{skills[i]}</span></span>)
        }
        return ret;
    }

    render(){
        return(
            <div>
                <div className="job_inner recommend-inner">
                    {this.state.loading?
                        <Loading/>
                        :<></>
                    }
                    {
                        this.state.items.length == 0?<span className="no_content">条件に一致する職人がいません。</span>:<></>
                    }
                    {
                    this.state.items.map( item => (
                        <div className="element both_ways" key = {item['em_id']}>
                            <div className = "flex_start">
                                <div className="first">
                                    <img src = {URLS.IMAGE_URL+item['em_photo_s']}/>
                                </div>
                                <div className="second">
                                    <div className="fs14">{item["em_is_individual"]=='1'?'個人事業主':item['em_company']}</div>
                                    <div className="fs20">{item['em_lastname']+item['em_firstname']}</div>
                                </div>
                                <div className="third">
                                    <div className="fs14 map-icon">{item['st_label']}</div>
                                    <div>
                                        {this.renderSkill(item["sk_label_s"])}                                    
                                    </div>
                                </div>
                            </div>
                            <div className="btn-container">
                                <div className="grey-button v-small">
                                    <Link to = {"/provider/profile/" + item['em_id'] }>
                                        <div className="inner f-meiryo-bold">
                                            詳細		
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))                        
                    }                    
                </div>
                {/* <PageNavigation startNo={9} totalCount = {10} pageUrl = {"/findpeople/"} /> */}
            </div>
        );
    }
}
export default connect(null, mapDispatcherToProps)(RecommendCom);