import React, {Component} from 'react';
import '../scss/job.scss';
import '../scss/message.scss';
import MessageItemCom from './MessageItemCom';
import { IRootState } from '../../modules';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as asyncactions from '../../modules/chat/async-actions';
import { ChatActions, Message } from '../../modules/chat/types';
import Loader from 'react-loader-spinner';
import { List, ListRowProps, AutoSizer, CellMeasurer, CellMeasurerCache } from "react-virtualized";
import * as Global from '../../modules/global';
import * as FirebaseAPI from '../../firebase/api/message';

import * as footerAsyncactions from '../../modules/footer/async-actions';
import { FooterActions } from '../../modules/footer';

type MessageComProps = {
    to:number,
    img:string,
    docId:string,
    is_not_available?:number,
}
const mapStateToProps = ({ message }: IRootState, messageProps:MessageComProps ) => {
    const { messages, loading, lastObject, isDownloading, isAllDownloaded, isTypedNewText, messageListener, isRealTime } = message;
    const {to, img, docId, is_not_available } = messageProps;
    return { messageListener, messages, loading, lastObject, isAllDownloaded, isTypedNewText, to, img, isRealTime, isDownloading, docId, is_not_available };
}

const mapDispatcherToProps = (dispatch: Dispatch<ChatActions|FooterActions>) => {
    return {
        loadMessage:( from:number, to:number, lastObject:any, isAllDownloaded:boolean = false ) => asyncactions.loadMessageAsync( dispatch, from, to, lastObject, isAllDownloaded ),
        checkMessage:( from:number, to:number ) => asyncactions.listenerAsync( dispatch, from, to ),
        uncheckMessage:( from:number, to:number ) => asyncactions.listenerFree( dispatch, from, to ),        
        deleteTempMessage:() => asyncactions.deleteTemp( dispatch ),
//        alreadyRead:( from:number, to:number, docId:string ) => asyncactions.listenerFree( dispatch, from, to ),
        deleteNewMessageHistory:(from:number, to:number ) =>asyncactions.deleteNewMessageHistoryAsync( dispatch, from, to ),
        isRerender:( _isRerender: boolean ) => footerAsyncactions.isRerender( dispatch, _isRerender ),                     
    }
}
type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;

class MessageCom extends Component<ReduxType>{
    domHeight:number = 0;
    scrollTotalheight:number = 886;
    mouseScrollStart:boolean = false;
    scrollTop:number = 0;
    previousScrollTop:number = 0;
    isDownloading:boolean = false;
    isFirstLoading:boolean = false;
    isMouseDown:boolean = false;
    messagesEndRef = React.createRef<HTMLDivElement>()
    messageBlockRef = React.createRef<HTMLDivElement>()
    messageContainerRef = React.createRef<HTMLDivElement>()
    listRef = React.createRef<List>();
    from:any = -1;
    to:any = -1;
    cache:CellMeasurerCache = new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 100
    });
    constructor( props:ReduxType, context:any ){
        super( props );
//        detatchCountListener( 1, 2 );
    }
    componentDidMount(){
        this.isFirstLoading = true;        
        this.from = Global.getEmId();
        this.to = this.props.to?this.props.to:"";
        if( this.to.toString() !== '' ){
            Global.setMessageToEmId(this.to);
        }
        if( this.from.toString() !== "" && this.to.toString() !== "" ){
            this.props.deleteNewMessageHistory(parseInt(this.from), parseInt(this.to));
            if( this.props.docId && this.props.docId !== "" ){
                FirebaseAPI.setAlreadyViewed( this.from, this.to, this.props.docId );
            }
        }
        this.loadMessage();
        this.checkMessage();
    }
    componentWillUnmount(){
        Global.setMessageToEmId('');
        this.props.deleteTempMessage();
    }    
    componentDidUpdate( prevProps:ReduxType ){
        this.mouseScrollStart = true;        
        if( this.listRef.current ){
            //let height:number = this.listRef && this.listRef.current && this.listRef.current.Grid ?this.listRef.current.Grid.getTotalRowsHeight():0;
            let height:number = 0;
            if( this.props.messages.length > 0 ){
                if( this.isFirstLoading ){
                    this.listRef.current.scrollToRow(this.props.messages.length-1);
                }else{
                    if( this.props.messages.length ){
                        this.scrollTop = 570 * ( height - 570 + this.scrollTop )/height;
//console.log( "--- Scroll Top is " + this.scrollTop + "---" + this.domHeight + "---" + height );                            
                        this.listRef.current.scrollToPosition( this.scrollTop );
                        //this.mouseScrollStart = false;
                    }
                }
            }
            if( height < 570 ){
                this.isFirstLoading = true;
                this.prepareLoadMessage();
            }else{
                this.isFirstLoading = false;
            }
            this.domHeight = height;            
        }
        if( this.props.messages !== prevProps.messages ){
            this.cache.clearAll();
            
            if( this.listRef.current ){
                if( this.props.isRealTime ){
                    this.listRef.current.scrollToRow(this.props.messages.length-1);
                }else{
                    this.listRef.current.recomputeRowHeights();
                }
            }
            this.props.isRerender( true );
        }
    }
    prepareLoadMessage(){
        if( !this.props.isDownloading ){
//            this.isDownloading = true;
            this.loadMessage();
        }
    }
    loadMessage(){
        if( this.from !== "" && this.to !== "" ){
            this.props.loadMessage( this.from , this.to, this.props.lastObject, this.props.isAllDownloaded );            
        }
    }
    checkMessage(){
        if( this.from !== "" && this.to !== "" )
            this.props.checkMessage( parseInt(this.from), parseInt(this.to) );
    }
    render(){
//        this.isDownloading = false;
        if( this.props.isTypedNewText ){
            this.isFirstLoading = true;
        }
        return(
            this.props.loading === false?
                <div className= "layout_center max_800">
                    <Loader
                        type="TailSpin"
                        color="#333"
                        height={50}
                        width={50}
                    />
                </div>:            
                <div className = "message_block ">{
                    <div className = "message_block_inner">
                        <div className = "date_block">
                        <AutoSizer>
                        {
                            ({ width, height }) => (
                            <List
                                ref = {this.listRef}
                                className="virtual_list"
                                height={570}
                                overscanRowCount={3}
                                noRowsRenderer={this.noRowsRenderer}
                                rowCount={this.props.messages.length}
                                rowHeight={ this.cache.rowHeight }
                                onScroll = { this.onScroll }
                                rowRenderer={ props => this.renderRow(props) }
                                width={650}
                                deferredMeasurementCache={this.cache}
                                scrollToAlignment={"end"}
                                //scrollToIndex = { this.isFirstLoading && this.props.messages.length > 0?this.props.messages.length-1:0}
                            />
                            )
                        }
                        </AutoSizer>  
                        </div>                
                    </div>
                }
            </div>
        );
    }
    onScroll = ( props:any ) =>{
        if( this.scrollTop > props.scrollTop && props.scrollTop < 300 ){
            if( this.mouseScrollStart ){
                this.mouseScrollStart = false;
//                this.isFirstLoading = false;                
                this.prepareLoadMessage();
            }else{
                this.mouseScrollStart = true;
            }
        }
        this.scrollTop = props.scrollTop;
        this.previousScrollTop = this.scrollTop;
    }
    renderRow( props: ListRowProps ): React.ReactNode{
        let message:Message = this.props.messages[props.index] as Message;
        return (
          <CellMeasurer 
            key={props.key}
            cache={this.cache}
            parent={props.parent}
            columnIndex={0}
            rowIndex={props.index}>
            <div  style={props.style} className = "pd_r">
            {
                message.isFirstNewDate
                ?
                    <div className="date_txt">{message.date}</div>
                :
                    <></>
            }
            <MessageItemCom message={message} key={message.time} from = {this.from} img = {this.props.img} is_not_available = {this.props.is_not_available}/>
            </div>
          </CellMeasurer>
        );
    }
    isRowLoaded = ( index:any ) => {
        return false;
    };
    noRowsRenderer() {
        return <></>;
    }
}
export default connect(mapStateToProps,mapDispatcherToProps )(MessageCom);