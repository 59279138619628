import React, {Component} from 'react';
import '../../scss/job.scss';
import '../../scss/search.scss';
import Header from '../../Include/Header'
import SearchNavCom from '../../Find/SearchNavCom';
import Footer from '../../Include/Footer';
import PSearchAreaCom from './PSearchAreaCom';
import { withRouter } from 'react-router-dom';

class PSearchArea extends Component<any> {
    public render(){
        const url = this.props.match.url;        
        return(
            <div>
                <Header/>
                <div className="content-container" id = "content">
                    <div className="wrap-content">
                        <div className="main-title">仕事・親方/会社を探す</div>
                        <div className="job_container mt_40">
                            <SearchNavCom to = {url}/>                            
                            <div className="job search recommend">
                                <PSearchAreaCom history = {this.props.history}/>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>

        );
    }
}
export default withRouter(PSearchArea);