import React from 'react';
import '../scss/job.scss';
import '../scss/myprofile.scss';
import Header from '../Include/Header'
import MyProfileCom from './MyProfileCom';
import Footer from '../Include/Footer';

class MyProfile extends React.Component<any>{
    public render(){
        return(
            <div>
                <Header/>
                <div className="content-container" id = "content">
                    <div className="wrap-content">
                        <div className="main-title">プロフィール</div>
                        <div className="job_container mt_40">
                            <div className ="job myprofile">
                                <MyProfileCom/>
                            </div>                            
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>

        );
    }
}
export default MyProfile;