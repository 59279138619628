import { Dispatch } from 'redux';
import * as actions from './actions';
import { UserInfoActions, UserInfo } from './types';
import * as Global from '../global';
import { addRoleUserListener } from '../../firebase/api/users';

export async function logInAsync(dispatch: Dispatch<UserInfoActions>, userInfo:UserInfo ) {

    const response = fetch('https://api.npms.io/v2/search?q=react').then(async response => {    
        const data = await response.json();

        // check for error response
        if (!response.ok) {
            // get error message from body or default to response statusText
            const error = (data && data.message) || response.statusText;
            dispatch(actions.LogIn( false, {}));
        }else{
            dispatch(actions.LogIn( true, {}));
        }
    })
    .catch(error => {
        dispatch(actions.LogIn( false, {}));
        console.error('There was a login error!', error );
    });    
}
export async function removeRoleListenerAysnc( dispatch: Dispatch<UserInfoActions> ){
    dispatch( actions.removeRoleListener() );
}
export async function registerRoleListenerAysnc( dispatch: Dispatch<UserInfoActions> ){
    const listener = addRoleUserListener( dispatch );
    dispatch( actions.registerRoleListener(listener) );
}
export async function getProfileAsync( dispatch: Dispatch<UserInfoActions>, role:string, userInfo:UserInfo ){
    localStorage.setItem('role', role );
    localStorage.setItem('em_point', userInfo.point?userInfo.point.toString():'0' );
    localStorage.setItem('em_name', userInfo.em_name?userInfo.em_name.toString():'' );    
    localStorage.setItem('em_company', userInfo.em_company?userInfo.em_company.toString():'' );
    localStorage.setItem('em_star', userInfo.star?userInfo.star.toString():'0' );
    Global.setSkillString( userInfo.sk_s?userInfo.sk_s:'' );
    dispatch( actions.SetProfile(userInfo) );
}
export async function changePointAsync( dispatch: Dispatch<UserInfoActions>, point:string ){
    Global.setPoint(point);
    const newPoint = Global.getPoint();
    const em_id:number = parseInt( Global.getEmId()! );
    const role:string = Global.getRole()!;
    const role_name:string = Global.getRoleName()!;
    const em_company:string = Global.getEmCompany()!;
    const em_name:string = Global.getEmName()!;
    const star:string = (Global.getPoint()!).toString();
    const userInfo:UserInfo = {
        id:em_id,
        role:role,
        em_name:em_name,
        em_role:role_name,
        em_company:em_company,
        point:newPoint,
        star:star
    }
    dispatch( actions.SetProfile(userInfo) );
}
export async function changeAvartaAsync( dispatch: Dispatch<UserInfoActions>, image:any, isBigImage:boolean = false ){
    dispatch(actions.ChangeAvarta( image, isBigImage ) );    
}