import React, {Component} from 'react';
import '../scss/job.scss';
import '../scss/recommend.scss';
import Header from '../Include/Header'
import {Link} from 'react-router-dom';
import SearchNavCom from './SearchNavCom';
import RecommendCom from '../Include/RecomCom';
import { render } from '@testing-library/react';
import Footer from '../Include/Footer';
class People extends Component<any> {
    public render(){
        const url = this.props.match.url;
        return(
            <div>
                <Header/>
                <div className="content-container" id = "content">
                    <div className="wrap-content">
                        <div className="main-title">仕事・職人を探す</div>
                        <div className="job_container mt_40">
                            <SearchNavCom to = {url}/>                            
                            <div className="recommend job find">
                                <RecommendCom/>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>

        );
    }
}
export default People;