import React, { Component } from 'react';
import '../../scss/job.scss';
import '../../scss/search.scss';
import SimpleReactValidator from 'simple-react-validator';
import * as Global from '../../../modules/global';
import * as URLS from '../../../modules/urls';
import { BasicDropDown } from '../../Include/dropdown/BasicDropDown';
import BasicCheckBox from '../../Include/checkbox/BasicCheckBox';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { IRootState } from '../../../modules';
import { Dispatch } from 'redux';
import * as asyncactions from '../../../modules/searchproviderjob/async-actions';

import { SearchProviderJob, SearchProviderJobState, SearchProviderJobActions } from '../../../modules/searchproviderjob';
import { SearchArea } from '../../../modules/searchprovider';

const mapStateToProps = ({ searchProviderJob }: IRootState) => {
    return { searchProviderJob };
};
const mapDispatcherToProps = (dispatch: Dispatch<SearchProviderJobActions>) => {
    return {
        save: (searchProviderJob: SearchProviderJob) => asyncactions.save(dispatch, searchProviderJob),
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatcherToProps> & any

class PSearchAreaCom extends Component<ReduxType, SearchProviderJobState>{
    state = {
        loading: false,
        em_skill_keyword: '',
        em_company: '',
        sk_s: new Array<string>(),
        searchAreas: new Array<SearchArea>(),
    }
    validator: SimpleReactValidator;
    areaRef_0_1: any;
    areaRef_0_2: any;
    areaRef_0_3: any;

    areaRef_1_1: any;
    areaRef_1_2: any;
    areaRef_1_3: any;

    areaRef_2_1: any;
    areaRef_2_2: any;
    areaRef_2_3: any;

    constructor(props: any) {
        super(props);
        this.validator = new SimpleReactValidator({
            messages: Global.VALIDATION_MESSAGES,
            autoForceUpdate: this,
            className: 'error',
            validators: Global.VALIDATORS
        });
        this.areaRef_0_1 = React.createRef();
        this.areaRef_0_2 = React.createRef();
        this.areaRef_0_3 = React.createRef();

        this.areaRef_1_1 = React.createRef();
        this.areaRef_1_2 = React.createRef();
        this.areaRef_1_3 = React.createRef();

        this.areaRef_2_1 = React.createRef();
        this.areaRef_2_2 = React.createRef();
        this.areaRef_2_3 = React.createRef();
    }
    componentDidMount() {
        const searchProviderJob: SearchProviderJobState = this.props.searchProviderJob;
        this.setState({
            em_skill_keyword: searchProviderJob.em_skill_keyword,
            em_company: searchProviderJob.em_company,
            sk_s: searchProviderJob.sk_s,
            searchAreas: searchProviderJob.searchAreas,
        });
    }
    onSearch = (e: any) => {
        if (this.validator.allValid()) {
            this.props.save(this.state);
            this.goToResultPage();
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    goToResultPage = () => {
        this.props.history.push({
            pathname: '/provider/searcharearesult',
        })
    }
    onHandleChange = (e: any) => {
        console.log('Handel changed');
        let value: string = e.target.value;
        let name: string = e.target.name;
        if (name !== "st_id_0" && name !== "st_id_1" && name !== "st_id_2"
            && name !== 'ar_id_0_1' && name !== 'ar_id_0_2' && name !== 'ar_id_0_3'
            && name !== 'ar_id_1_1' && name !== 'ar_id_1_2' && name !== 'ar_id_1_3'
            && name !== 'ar_id_2_1' && name !== 'ar_id_2_2' && name !== 'ar_id_2_3') {
            // this.setState({ [name]: value });
            this.setState(prevState => ({
                ...prevState,
                [name]: value // No error here, but can't ensure that key is in StateKeys
              }));            
        }
        if (name === 'ar_id_0_1' || name === 'ar_id_0_2' || name === 'ar_id_0_3') {
            const idx = parseInt(name.split('ar_id_0_')[1]);
            const searchAreas: Array<SearchArea> = this.state.searchAreas;
            searchAreas[0] = {
                st_id: searchAreas[0].st_id,
                ar_id_1: idx === 1 ? value : searchAreas[0].ar_id_1,
                ar_id_2: idx === 2 ? value : searchAreas[0].ar_id_2,
                ar_id_3: idx === 3 ? value : searchAreas[0].ar_id_3,
            }
            this.setState({
                searchAreas: searchAreas.concat([]),
            });
        }
        if (name === 'ar_id_1_1' || name === 'ar_id_1_2' || name === 'ar_id_1_3') {
            const idx = parseInt(name.split('ar_id_1_')[1]);
            const searchAreas: Array<SearchArea> = this.state.searchAreas;
            searchAreas[1] = {
                st_id: searchAreas[1].st_id,
                ar_id_1: idx === 1 ? value : searchAreas[1].ar_id_1,
                ar_id_2: idx === 2 ? value : searchAreas[1].ar_id_2,
                ar_id_3: idx === 3 ? value : searchAreas[1].ar_id_3,
            }
            this.setState({
                searchAreas: searchAreas.concat([]),
            });
        }
        if (name === 'ar_id_2_1' || name === 'ar_id_2_2' || name === 'ar_id_2_3') {
            const idx = parseInt(name.split('ar_id_2_')[1]);
            const searchAreas: Array<SearchArea> = this.state.searchAreas;
            searchAreas[2] = {
                st_id: searchAreas[2].st_id,
                ar_id_1: idx === 1 ? value : searchAreas[2].ar_id_1,
                ar_id_2: idx === 2 ? value : searchAreas[2].ar_id_2,
                ar_id_3: idx === 3 ? value : searchAreas[2].ar_id_3,
            }
            this.setState({
                searchAreas: searchAreas.concat([]),
            });
        }
        if (name === "st_id_0" || name === "st_id_1" || name === "st_id_2") {
            const idx = parseInt(name.split('st_id_')[1]);
            const searchAreas: Array<SearchArea> = this.state.searchAreas;
            searchAreas[idx] = {
                st_id: value,
                ar_id_1: '',
                ar_id_2: '',
                ar_id_3: '',
            }
            this.setState({
                searchAreas: searchAreas.concat([]),
            });
            if (idx === 0) {
                if (this.areaRef_0_1) {
                    this.areaRef_0_1.current.fetch(URLS.GET_AREA_URL + "&st_id=" + value);
                }
                if (this.areaRef_0_2) {
                    this.areaRef_0_2.current.fetch(URLS.GET_AREA_URL + "&st_id=" + value);
                }
                if (this.areaRef_0_3) {
                    this.areaRef_0_3.current.fetch(URLS.GET_AREA_URL + "&st_id=" + value);
                }
            } else if (idx === 1) {
                if (this.areaRef_1_1) {
                    this.areaRef_1_1.current.fetch(URLS.GET_AREA_URL + "&st_id=" + value);
                }
                if (this.areaRef_1_2) {
                    this.areaRef_1_2.current.fetch(URLS.GET_AREA_URL + "&st_id=" + value);
                }
                if (this.areaRef_1_3) {
                    this.areaRef_1_3.current.fetch(URLS.GET_AREA_URL + "&st_id=" + value);
                }
            } else if (idx === 2) {
                if (this.areaRef_2_1) {
                    this.areaRef_2_1.current.fetch(URLS.GET_AREA_URL + "&st_id=" + value);
                }
                if (this.areaRef_2_2) {
                    this.areaRef_2_2.current.fetch(URLS.GET_AREA_URL + "&st_id=" + value);
                }
                if (this.areaRef_2_3) {
                    this.areaRef_2_3.current.fetch(URLS.GET_AREA_URL + "&st_id=" + value);
                }
            }
        } else if (name === "sk_s[]") {
            const target = e.target;
            const checked = target.type === "checkbox" ? target.checked : '';
            if (!checked) {
                const sk_s = this.state.sk_s;
                const index = sk_s.indexOf(value);
                if (index > -1) {
                    let business: Array<any> = sk_s.slice(0, index);
                    business = business.concat(sk_s.slice(index + 1, sk_s.length));
                    this.setState({
                        sk_s: business,
                    });
                }
            } else {
                const val = [value];
                const sk_s = this.state.sk_s;
                const business = val.concat(sk_s);
                this.setState({
                    sk_s: business,
                });
            }
            this.validator.showMessageFor(name);
        }
    }
    onHandleBlur = (e: any) => {
        let name: string = e.target.name;
        this.validator.showMessageFor(name);
    }
    renderSearchAreaElement = () => {
        const ret = [];
        for (let i = 0; i < this.state.searchAreas.length; i++) {
            const area = this.state.searchAreas[i];
            let ref_1 = null;
            let ref_2 = null;
            let ref_3 = null;
            if (i === 0) {
                ref_1 = this.areaRef_0_1;
                ref_2 = this.areaRef_0_2;
                ref_3 = this.areaRef_0_3;
            } else if (i === 1) {
                ref_1 = this.areaRef_1_1;
                ref_2 = this.areaRef_1_2;
                ref_3 = this.areaRef_1_3;
            } else if (i === 2) {
                ref_1 = this.areaRef_2_1;
                ref_2 = this.areaRef_2_2;
                ref_3 = this.areaRef_2_3;
            }
            const key = `searcharea_` + i;
            ret.push(
                <div key={key} className={i !== 0 ? `mt_5` : ''}>
                    <div className="wd_100 inline_block mr_5">
                        <BasicDropDown
                            className="full_wide" name={"st_id_" + i} idKey={"st_id"} valueKey={"st_label"} url={URLS.GET_PREFECTURE_URL} checkedValue={area.st_id} defaultName="都道府県"
                            onHandleChange={this.onHandleChange} onHandleBlur={this.onHandleBlur} noPrefix={true} />
                    </div>
                    <div className="wd_100 inline_block mr_5">
                        <BasicDropDown ref={ref_1}
                            className="full_wide" name={`ar_id_${i}_1`} idKey={`ar_id`} valueKey={"ar_label"} url={URLS.GET_AREA_URL + "&st_id=" + area.st_id} checkedValue={area.ar_id_1} defaultName="エリア"
                            onHandleChange={this.onHandleChange} onHandleBlur={this.onHandleBlur} noPrefix={true} />
                    </div>
                    <div className="wd_100 inline_block mr_5">
                        <BasicDropDown ref={ref_2}
                            className="full_wide" name={`ar_id_${i}_2`} idKey={`ar_id`} valueKey={"ar_label"} url={URLS.GET_AREA_URL + "&st_id=" + area.st_id} checkedValue={area.ar_id_2} defaultName="エリア"
                            onHandleChange={this.onHandleChange} onHandleBlur={this.onHandleBlur} noPrefix={true} />
                    </div>
                    <div className="wd_100 inline_block mr_5">
                        <BasicDropDown ref={ref_3}
                            className="full_wide" name={`ar_id_${i}_3`} idKey={`ar_id`} valueKey={"ar_label"} url={URLS.GET_AREA_URL + "&st_id=" + area.st_id} checkedValue={area.ar_id_3} defaultName="エリア"
                            onHandleChange={this.onHandleChange} onHandleBlur={this.onHandleBlur} noPrefix={true} />
                    </div>
                </div>
            )
        }
        return ret;
    }
    render() {
        return (
            <div>
                <div className="search_input fa wd_650 mb_20">
                    <input type="text" placeholder="フリーワードを入力" name="em_skill_keyword" value={this.state.em_skill_keyword} onChange={this.onHandleChange} onBlur={this.onHandleBlur} />
                </div>
                <div className="s_element">
                    <div className="mr_5"><span className="tag-mg fs14"><span className="tag-inner">会社名</span></span></div>
                    <div className="second_column">
                        <div className="wd_250 inline_block"><input type="text" name="em_company" className="ht_25" placeholder="名前を入力" value={this.state.em_company} onChange={this.onHandleChange} onBlur={this.onHandleBlur} /></div>
                    </div>
                </div>
                <div className="s_element both_ways">
                    <div className="wd_65"><span className="tag-mg fs14"><span className="tag-inner">業種</span></span></div>
                    <div>
                        <Link to="/job_type">
                            <img alt='' src={URLS.BASE_ABSOLUTE_URL + "/images/icon_help.jpg"} />
                            <span className="fs14 text-right mr_5 inline_block">業種説明</span>
                        </Link>
                    </div>
                </div>
                <div className="s_element">
                    <div className="ml_10">
                        <BasicCheckBox
                            className="full_wide" name="sk_s[]" idKey={"sk_id"} valueKey={"sk_label"} url={URLS.GET_SKILL} checkedValues={this.state.sk_s}
                            onHandleChange={this.onHandleChange} onHandleBlur={this.onHandleBlur} noPrefix={true} />
                    </div>
                </div>
                <div className="s_element">
                    <div className="mr_5"><span className="tag-mg fs14"><span className="tag-inner">地域</span></span></div>
                    <div className="second_column">
                        <div>
                            {this.renderSearchAreaElement()}
                        </div>
                    </div>
                </div>
                <button className="grey-button yellow v-large rb mt_20 mb_20">
                    <div className="inner" onClick={this.onSearch}>
                        <i className="fa fa-search"></i>
                            この条件で親方を探す
                        </div>
                </button>
            </div>
        )
    }
}
// export default compose(
//     withRouter,
//     connect(mapStateToProps, mapDispatcherToProps)
// )(PSearchAreaCom);

export default connect(mapStateToProps, mapDispatcherToProps)(PSearchAreaCom);
