import React from 'react';
import '../scss/point.scss';
import PointBuyCom from './PointBuyCom';
import Header from '../Include/Header'
import Footer from '../Include/Footer';

function PointBuy(){
    return (
        <div>
            <Header/>
            <div className="content-container" id = "content">
                <div className="wrap-content">
                    <div className="main-title">ポイント購入</div>
                    <PointBuyCom/>
                </div>    
            </div>
            <Footer/>
        </div>
    );
}
export default PointBuy;
