import { SearchProviderActions, SearchProviderState, actionTypes, SearchProvider } from './types';

const initialState: SearchProviderState = {
    loading: false,
    em_skill_keyword: '',
    is_date_search: '',
    ef_date_from: null,
    ef_date_to: null,
    ef_datetime_between_date: null,
    jb_time: '',
    ef_datetime_between_to_time: '',
    sk_s: [],
    searchAreas:[{st_id:'', ar_id_1:'', ar_id_2:'', ar_id_3:''},{st_id:'', ar_id_1:'', ar_id_2:'', ar_id_3:''},{st_id:'', ar_id_1:'', ar_id_2:'', ar_id_3:''}],
    nationality_s: [],
    em_daily_price_low: '',
    em_daily_price_hi: '',    
};
const SAVE = actionTypes.SAVE;
export function SearchProviderReducer(state: SearchProviderState = initialState, action: SearchProviderActions): SearchProviderState {
    switch (action.type) {
        case SAVE:
            const searchProvider: SearchProvider = action.payload.searchProvider;
            return {
                ...state,
                em_skill_keyword: searchProvider.em_skill_keyword,
                is_date_search: searchProvider.is_date_search,
                ef_date_from: searchProvider.ef_date_from,
                ef_date_to: searchProvider.ef_date_to,
                ef_datetime_between_date: searchProvider.ef_datetime_between_date,
                jb_time: searchProvider.jb_time,
                ef_datetime_between_to_time: searchProvider.ef_datetime_between_to_time,
                sk_s: searchProvider.sk_s,
                searchAreas: searchProvider.searchAreas,
                nationality_s: searchProvider.nationality_s,
                em_daily_price_low: searchProvider.em_daily_price_low,
                em_daily_price_hi: searchProvider.em_daily_price_hi,
            };
        default:
            return state;
    }
}