import React from 'react';
import '../scss/job.scss';
import '../scss/message_template.scss';
import Header from '../Include/Header'
import MessageTemplateCom from './MessageTemplateCom';
import { Link } from 'react-router-dom';
import Footer from '../Include/Footer';

class MessageTemplate extends React.Component<any>{
    public render(){
        let to = this.props.location&&this.props.location.state&&this.props.location.state.to?this.props.location.state.to:'';
        if( to == '' ){
            to =  this.props.match && this.props.match.params? this.props.match.params.to:'';
        }
console.log( "MessageTemplate--" + to );        
        return(
            <div>
                <Header/>
                <div className="content-container" id = "content">
                    <div className="wrap-content">
                        <div className="main-title">テンプレートを編集・削除</div>
                        <div className="job message_template mt_40">
                            <MessageTemplateCom to = {to}/>
                        </div>
                        <div className="grey-button yellow large_300 rb mt_30 mb_20">
                            <Link to = {{pathname:"/templateedit", state:{to:to} }}>
                                <div className="inner message_icon">
                                テンプレートを追加
                                </div>
                            </Link>
                        </div>
                        {
                            to != ''?
                            <div className="grey-button yellow large_300 rb mt_30 mb_20">
                                <Link to = {"/message/" + to }>
                                    <div className="inner message_icon">
                                        メッセージ画面に戻る
                                    </div>
                                </Link>
                            </div>							
                            :<></>
                        }			
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}
export default MessageTemplate;