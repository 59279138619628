import { SearchClientActions, SearchClientState, actionTypes, SearchClient } from './types';

const initialState: SearchClientState = {
    loading: false,
    jb_keyword: '',
    is_date_search: "0",
    jb_open_date: null,
    jb_close_date: null,
    jb_date: null,
    jb_time: '0',
    jb_total: '',
    sk_s: [],
    searchAreas:[{st_id:'', ar_id_1:'', ar_id_2:'', ar_id_3:''},{st_id:'', ar_id_1:'', ar_id_2:'', ar_id_3:''},{st_id:'', ar_id_1:'', ar_id_2:'', ar_id_3:''}],    
    em_is_foreign: "0",
    em_daily_price_low: '',
    em_daily_price_hi: '',
};
const SAVE = actionTypes.SAVE;
export function SearchClientReducer(state: SearchClientState = initialState, action: SearchClientActions): SearchClientState {
    switch (action.type) {
        case SAVE:
            const searchClient: SearchClient = action.payload.searchClient;
            return {
                ...state,
                jb_keyword: searchClient.jb_keyword,
                is_date_search: searchClient.is_date_search,
                jb_open_date: searchClient.jb_open_date,
                jb_close_date: searchClient.jb_close_date,
                jb_date: searchClient.jb_date,
                jb_time: searchClient.jb_time,
                jb_total: searchClient.jb_total,
                sk_s: searchClient.sk_s,
                searchAreas: searchClient.searchAreas,
                em_is_foreign: searchClient.em_is_foreign,
                em_daily_price_low: searchClient.em_daily_price_low,
                em_daily_price_hi: searchClient.em_daily_price_hi,                
            };
        default:
            return state;
    }
}