import React, {Component} from 'react';
import '../scss/job.scss';
import '../scss/search.scss';
import {Link} from 'react-router-dom';

export type SearchNavProps = {
    to?: string;
};
export default class ClientSearchNavCom extends Component<SearchNavProps>{
    public render(){
        let className = '';
        let url = this.props.to;
        let which:number = 1;
        if( url != undefined ){
            if( url == "/search" ){
                which = 1;
            }else if( url == "/people" ){
                which = 2;
            }else if( url == "/freepeople" ){
                which = 3;
            }
        }        
        return(
            <div className="job_sidebar">
                <div className="job_sidebar_inner">
                    <ul>
                        <li>
                            <Link to = "/search" className = {which== 1?"n_active":""}> 
                                <span className="fa search">条件を指定して検索する</span>
                            </Link>
                        </li>
                        {/* <li>
                            <Link to = "/people" className = {which== 2?"n_active":""}> 
                                <span className="fa search">検索結果を一覧で表示する</span>
                            </Link>
                        </li>								 */}
                        <li>
                            <Link to = "/freepeople" className = {which== 3?"n_active":""}> 
                                <span className="fa search">すぐに働ける職人を探す</span>
                            </Link>
                        </li>								

                    </ul>
                    <div className="clear"></div>								
                </div>
            </div>
        );
    }
}
