import React, {Component} from 'react';
import '../scss/job.scss';
import '../scss/editschedule.scss';
import Header from '../Include/Header'
import ProviderNavCom from '../Include/ProviderNavCom';
import MyScheduleEditCom from './MyScheduleEditCom';
import Footer from '../Include/Footer';

class MyScheduleEdit extends Component<any> {
    public render(){
        const url = this.props.match.url;
        let date = this.props.location.state.date?this.props.location.state.date:"";
        return(
            <div>
                <Header/>
                <div className="content-container" id = "content">
                    <div className="wrap-content">
                        <div className="main-title">空き時間管理・応募仕事管理</div>
                        <div className="job_container mt_40">
                            <ProviderNavCom to = {url}/>                             
                            <div className="job schedule_edit">
                                <MyScheduleEditCom date = {date}/>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}
export default MyScheduleEdit;