import { CreateInfoActions, actionTypes, CreateState } from './types';

const initialState:CreateState = {
    loading:false,
    page:-1,
    errorMessage:'',
    isUserExist:false,
    createInfo:{
        emt_id:1,
        em_name:'',
        em_passwd:'',
        st_id:"",
        ar_id:"",
        em_phone:'',
        sk_s:[],        
    },
};
const CREATE = actionTypes.CREATE;
const BACK = actionTypes.BACK;
export function CreateInfoReducer(state: CreateState = initialState, action: CreateInfoActions ): CreateState{
    switch (action.type ) {
        case CREATE:
            return {...state, loading:action.payload.loading, page:1, createInfo:action.payload.createInfo };
        case BACK:
            return {...state, page:action.payload.page };            
        default:
            return state;            
    }
}