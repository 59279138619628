import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import DialogContentText from '@material-ui/core/DialogContentText';
import {Redirect} from 'react-router-dom';
import '../scss/alert.scss';

function PaperComponent(props:any ) {
    return (
      <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
}
type AlertDialogProps = {
    returnURL?:string,
}
export class AlertDialog extends Component<AlertDialogProps> {
    state = {
        open:false,
        title:'',
        errorMessage:'',
        isRedirect:false,
        close:false,
    }
	handleClose = () => {
        this.setState({
            open:false,
            close:true,
        })
    };
    open( title:string, errorMessage:string, isRedirect = false ){
        this.setState({
            open:true,
            close:false,
            title:title,
            isRedirect:isRedirect,
            errorMessage:errorMessage,
        })
    }
	renderRedirect = () => {
		if( this.state.close && this.props.returnURL !== undefined && this.state.isRedirect ){
			let url = this.props.returnURL?this.props.returnURL:'';
			return <Redirect to = {url}/>
		}
		return <></>
	}      
    render(){
        return(
            <>
			{this.renderRedirect()}    
            <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                fullWidth = {true}
            >
                <Toolbar style={{ cursor: 'move' }} id="draggable-dialog-title">
                    <DialogTitle>
                        {this.state.title}
                    </DialogTitle>
                    <IconButton edge="end" color="inherit" onClick={this.handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <DialogContentText>
                        {this.state.errorMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>            
            </>
        )
    }
}
