import React from 'react';
import '../scss/job.scss';
import Header from '../Include/Header'
import JobNavCom from "./JobNavCom";
import JobCom from './JobCom'
import Footer from '../Include/Footer';
import ProviderNavCom from "../Include/ProviderNavCom";
import {Redirect} from 'react-router-dom';
//function JobDetail(){
class Job extends React.Component<any>{
    renderRedirect = () => {
        let id = this.props.match.params.id?this.props.match.params.id:"";
        if( id === '' ){
            return <Redirect to = {"/"}/>            
        }else{
            return <></>            
        }
    }

    public render(){
        const url = this.props.match.url;
        let id = this.props.match.params.id?this.props.match.params.id:"";
        let em_name = this.props.location.state.em_name?this.props.location.state.em_name:"";
        const role = localStorage.getItem( 'role' );        
        return (
            <div>
                <Header/>
                <div className="content-container" id = "content">
                {this.renderRedirect()}                    
                    <div className="wrap-content">
                        <div className="main-title">{role === '1'?em_name +'さん':em_name}との仕事</div>
                        <div className="job_container mt_40">
                            { role === "1"?
                             <JobNavCom to = {url} />:
                             <ProviderNavCom to = {url}/>
                            }
                            <div className="job">
                                <JobCom em_id = {id}/>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    } 

}
export default Job; 