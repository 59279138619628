import React, {Component} from 'react';
import {DropDownProps, DropDownState} from "../../../modules/dropdown/types";
import {filter} from 'lodash';

export class BasicDropDown extends Component<DropDownProps, DropDownState>{
    mount = false;
    state = {
        total:0,
        items:[],
    }
    onHandleChange =(e:any) =>{
        this.props.onHandleChange(e);
    }
    onHandleBlur = (e:any) =>{
        this.props.onHandleBlur(e);
    }
    shouldComponentUpdate(){
        return true;
    }
    componentWillUnmount() {
        this.mount = false;
    }
    componentDidMount() {
        this.mount = true;
        if( this.props.url && this.props.url !== '' )
            this.fetch( this.props.url );
        else{
            if( this.props.data ){
                this.setState({
                    items:this.props.data,
                })    
            }
        }
    } 
    fetch = ( url:string = "" ) =>{
        fetch( url ).then(async response => {
            if( !this.mount ) {
                return;
            }
            const data = await response.json();
            // check for error response
            this.setState({ total: data.total, items:data.items });
        }).catch(error => {
//            this.setState({ errorMessage: error.toString() });
            console.log( error );
            console.error('There was an error!');
        });
    }

    render(){
        return(
            <select className = {this.props.className} name = {this.props.name} onChange={this.onHandleChange} onBlur = {this.onHandleBlur}  value={this.props.checkedValue}>
                {
                    this.props.notNeedDefaultOption === undefined || !this.props.notNeedDefaultOption?
                    <option value = "0">{this.props.defaultName}</option>:
                    <></>
                }
                
                {
                    filter(this.state.items, (item) => !(this.props.excludeValues || [] ).includes(item[this.props.idKey]) ).map( item => (
                        <option value ={this.props.noPrefix?item[this.props.idKey]:(item[this.props.idKey] + "_" + item[this.props.valueKey]) } key = {item[this.props.idKey] + "_" + item[this.props.valueKey]}>
                            {item[this.props.valueKey]}
                        </option>
                    ))
                }                
            </select>
        );
    }
}