import { ActionType } from 'typesafe-actions';
import * as actions from './actions';

export type CreateInfo = {
    emt_id:number,
    em_name:string,
    em_passwd:string,
    st_id:string,
    ar_id:string,
    em_phone:string,
    ema_id?:string,
    ema_code?:string,
    sk_s:Array<any>,
}
export type CreateState = {
    loading:boolean,
    page:number,
    isUserExist:boolean,
    errorMessage:string,
    createInfo:CreateInfo;
};
export enum actionTypes {
    CREATE = "createinfo/create",
    BACK =  "createinfo/back",
}
export type CreateInfoActions = ActionType<typeof actions>;