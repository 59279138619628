import React from 'react';
import '../scss/job.scss';
import '../scss/profile.scss';
import '../scss/change_type.scss';
import * as Global from '../../modules/global';
import {Redirect} from 'react-router-dom';

class ChangeAccountTypeCom extends React.Component<any>{
    state = {
        is_redirect:false,
        emt_id:'0',
    }
    onHandleChange = ( e:any ) =>{
        let value:string = e.target.value;
        let name:string = e.target.name;
        this.setState( { [name]:value} );
    }
    onChoose = async (e:any) =>{
        await Global.setTempEmtId( this.state.emt_id);
        this.setState({
            is_redirect:true,
        })
    }
    renderRedirect = () => {
        let em_id:any = Global.getEmId();
        if( em_id !== "" && this.state.is_redirect ){
            let url = '/change_account_type_confirm';
            return <Redirect to = {url}/>
        }
        return <></>
    }
    componentDidMount(){
        Global.setTempEmtId('');
    }

    public render(){
        const emtId = Global.getEmtId();
        const tempEmtId = this.state.emt_id;
        let emtName = '';
        let otherEmtName = '';
        let status = "";
        if( emtId === '1' ){
            emtName = '親方/会社';
            status = '親方/会社';
        }else if( emtId === '2' ){
            emtName = '職人';
            status = '職人';                        
        }else if( emtId === '3' ){
            emtName = '親方/会社・職人';
            status = '親方/会社・職人';                        
        }
        if( tempEmtId === '1' ){
            otherEmtName = '親方/会社';
            emtName = '職人';            
        }else if( tempEmtId === '2' ){
            otherEmtName = '職人';
            emtName = '親方/会社';            
        }else{
            otherEmtName = '親方/会社・職人';
            emtName = '';
        }
        return(
        <div className="change_type">
            {this.renderRedirect()}
            <div className="change_type_inner">
                <div style = {{width:'725px'}}>
                    <div className="fs16 f-meiryo-bold">現在の登録形態：{status}</div>
                    <div className="fs16 mt_30" style = {(this.state.emt_id !== '0' && this.state.emt_id !== '3') ?{display:'block'}:{display:'none'}}>「{otherEmtName}」を選択すると、「{emtName}」のプロフィール、{this.state.emt_id == '1'?`応募仕事履歴`:this.state.emt_id == '2'?`仕事`:``}など登録内容がすべて削除されます。</div>
                    <div className="ml_20 mt_40">
                        {
                            emtId !== '1'?
                            <label className="radio-item mr_20">
                                <span className="wd_30 m_wd_20"><input type="radio" name="emt_id" value="1" checked = {this.state.emt_id==="1"?true:false} onChange={this.onHandleChange}/>
                                <span className="radiobtn"></span></span>
                                <span className="txt">親方/会社</span>
                            </label>                        
                            :<></>
                        }
                        {
                            emtId !== '2'?
                            <label className="radio-item mr_20">
                                <span className="wd_30 m_wd_20"><input type="radio" name="emt_id" value="2" checked = {this.state.emt_id==="2"?true:false} onChange={this.onHandleChange}/>
                                <span className="radiobtn"></span></span>
                                <span className="txt">職人</span>
                            </label>
                            :<></>
                        }
                        {
                            emtId !== '3'?
                            <label className="radio-item">
                                <span className="wd_30 m_wd_20"><input type="radio" name="emt_id" value="3" checked = {this.state.emt_id==="3"?true:false} onChange={this.onHandleChange}/>
                                <span className="radiobtn"></span></span>
                                <span className="txt">親方/会社・職人</span>
                            </label>										
                            :<></>
                        }
                    </div>
                </div>
            </div>
            <div className="button-container">
                <button className="grey-button large mt_70" disabled = { tempEmtId === '0'?true:false } onClick = {this.onChoose}>
                    <div className="inner">
                    登録形態を変更する
                    <i className="fa fa-angle-right"></i>
                    </div>
                </button>										
            </div>								
        </div>
        );
    }
}
export default ChangeAccountTypeCom;