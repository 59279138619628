import React from "react";
import "../../scss/job.scss";
import "../../scss/profile.scss";
import { Link } from "react-router-dom";
import * as URLS from "../../../modules/urls";
import * as Global from "../../../modules/global";
import Loading from "../../Include/Loading";
import { AlertDialog } from "../../Modal/Alert";
import * as FirebaseAPI from "../../../firebase/api/message";

class ClientProfileDetailCom extends React.Component<any> {
  alertRef: any;
  state = {
    loading: false,
    em_photo: "",
    em_photo_s: "",
    em_id: "-1",
    em_is_legal: "-1",
    em_company: "",
    em_company_legal: "",
    em_person_legal: "",
    em_founding: "",
    year: "0",
    month: "0",
    day: "0",
    em_legal_number: "",
    em_total: "",
    em_depart: "",
    em_post: "",
    st_id: "",
    st_label: "",
    em_street_address: "",
    ar_id: "",
    ar_label: "",
    em_contact_phone: "",
    sk_s: [],
    sk_label_s: "",
    business1: "",
    business2: "",
    business3: "",
    em_skill_keyword: "",
    em_inputer_depart: "",
    em_inputer_pos: "",
    em_inputer_lastname: "",
    em_inputer_firstname: "",
    em_inputer_lastname_kana: "",
    em_inputer_firstname_kana: "",
    em_precontact: "",
    em_overview: "",
    ctr_id_s: null,
    jba_id_s: null,
    cnt_ctr: "0",
    cnt_ctrc_b: "0",
    ctrc_value_b: "0",
    has_relation: "0",
  };
  constructor(props: any) {
    super(props);
    this.alertRef = React.createRef();
  }
  onDirectAccept = () => {
    const that = this;
    let em_id: any = Global.getEmId();
    let jbaId = this.state.jba_id_s ? this.state.jba_id_s : "";
    if (jbaId === "") {
      that.alertRef.current.open(
        "エラー",
        "依頼に同意することができませんでした。"
      );
      return;
    }
    let data = {
      jba_id: jbaId,
    };

    if (em_id === "" || jbaId === "") {
      return;
    }
    this.setState({
      loading: true,
    });
    let em_worker_name: any =
      localStorage.getItem("em_worker_name") !== undefined
        ? localStorage.getItem("em_worker_name")?.toString()
        : "ニックネーム";
    if (em_worker_name === "") {
      em_worker_name = "ニックネーム";
    }
    fetch(URLS.CONTRACT_JOB, {
      method: "post",
      body: JSON.stringify(data),
    })
      .then(async function (response) {
        let ret = await response.json();
        if (ret.success) {
          that.alertRef.current.open("成功", "依頼に同意しました。", true);
        } else {
          that.alertRef.current.open(
            "エラー",
            "依頼に同意することができませんでした。"
          );
        }
        that.setState({
          loading: false,
        });
      })
      .catch((error) => {
        this.alertRef.current.open(
          "エラー",
          "依頼に同意することができませんでした。"
        );
        this.setState({ loading: false, errorMessage: error.toString() });
      });
  };
  componentDidMount() {
    const me_em_id = Global.getEmId();
    if (!me_em_id) {
      return;
    }
    if (!this.props.jobInfo || !this.props.jobInfo.em_id) {
      return;
    }
    let em_id: any = this.props.jobInfo.em_id;
    if (me_em_id === "" || em_id === "") {
      this.setState({
        isOKURL: false,
      });
      return;
    }
    if (this.props.message) {
      let docId =
        this.props.message && this.props.message.docId
          ? this.props.message.docId
          : "";
      let from =
        this.props.message && this.props.message.from
          ? this.props.message.from
          : "";
      let to =
        this.props.message && this.props.message.to
          ? this.props.message.to
          : "";
      if (docId !== "" && from !== "" && to !== "") {
        FirebaseAPI.setAlreadyViewed(me_em_id, to, docId);
        //FirebaseAPI.deleteDocument( from, to, docId );
      }
    }
    let keyword = "boss";
    let url =
      URLS.GET_PROFILE +
      "?em_id=" +
      em_id +
      "&keyword=" +
      keyword +
      "&inc_cnt=1" +
      "&sta_em_id=" +
      me_em_id +
      "&inc_em_stuff=1";
    let jb_id = this.props.jobInfo.jobId ? this.props.jobInfo.jobId : "";
    if (jb_id !== "") {
      url += "&sta_jb_id=" + jb_id;
    }
    const that = this;
    this.setState({
      loading: true,
    });
    fetch(url, {
      method: "get",
    })
      .then(async function (response) {
        let ret = await response.json();
        let skills = Global.getSkills(ret.emp.sk_label_s);
        that.setState({
          em_id: ret.emp.em_id,
          em_is_legal: ret.emp.em_is_legal ? ret.emp.em_is_legal : 0,
          em_company: ret.emp.em_company ? ret.emp.em_company : "",
          em_company_legal: ret.emp.em_company_legal
            ? ret.emp.em_company_legal
            : "",
          em_person_legal: ret.emp.em_person_legal
            ? ret.emp.em_person_legal
            : "",
          em_founding: ret.emp.em_founding ? ret.emp.em_founding : "",
          em_legal_number: ret.emp.em_legal_number
            ? ret.emp.em_legal_number
            : "",
          em_total: ret.emp.em_total ? ret.emp.em_total : "",
          em_depart: ret.emp.em_depart ? ret.emp.em_depart : "",
          em_post: ret.emp.em_post ? ret.emp.em_post : "",
          st_id: ret.emp.st_id,
          st_label: ret.emp.st_label ? ret.emp.st_label : "",
          em_street_address: ret.emp.em_street_address
            ? ret.emp.em_street_address
            : "",
          ar_id: ret.emp.ar_id,
          ar_label: ret.emp.ar_label ? ret.emp.ar_label : "",
          sk_s: [],
          sk_label_s: ret.emp.sk_label_s ? ret.emp.sk_label_s : "",
          em_contact_phone: ret.emp.em_contact_phone
            ? ret.emp.em_contact_phone
            : "",
          business1: skills.length >= 1 ? skills[0] : "",
          business2: skills.length >= 2 ? skills[1] : "",
          business3: skills.length >= 3 ? skills[2] : "",
          em_skill_keyword: ret.emp.em_skill_keyword
            ? ret.emp.em_skill_keyword
            : "",
          em_inputer_depart: ret.emp.em_inputer_depart
            ? ret.emp.em_inputer_depart
            : "",
          em_inputer_pos: ret.emp.em_inputer_pos ? ret.emp.em_inputer_pos : "",
          em_inputer_lastname: ret.emp.em_inputer_lastname
            ? ret.emp.em_inputer_lastname
            : "",
          em_inputer_firstname: ret.emp.em_inputer_firstname
            ? ret.emp.em_inputer_firstname
            : "",
          em_inputer_lastname_kana: ret.emp.em_inputer_lastname_kana
            ? ret.emp.em_inputer_lastname_kana
            : "",
          em_inputer_firstname_kana: ret.emp.em_inputer_firstname_kana
            ? ret.emp.em_inputer_firstname_kana
            : "",
          em_precontact: ret.emp.em_precontact ? ret.emp.em_precontact : "",
          em_overview: ret.emp.em_overview ? ret.emp.em_overview : "",
          ctrc_value_b: ret.emp.ctrc_value_b ? ret.emp.ctrc_value_b : "0",
          cnt_ctr: ret.emp.cnt_ctr ? ret.emp.cnt_ctr : "0",
          cnt_ctrc_b: ret.emp.cnt_ctrc_b ? ret.emp.cnt_ctrc_b : "0",
          jba_id_s: ret.emp.jba_id_s ? ret.emp.jba_id_s : null,
          ctr_id_s: ret.emp.ctr_id_s ? ret.emp.ctr_id_s : null,
          em_photo: ret.emp.em_photo,
          em_photo_s: ret.emp.em_photo_s,
          has_relation: ret.emp.has_relation ? ret.emp.has_relation : "0",
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false, errorMessage: error.toString() });
      });
  }
  renderSkill(skillStr: string) {
    const skills = skillStr.split(",");
    let ret = [];
    for (let i = 0; i < skills.length; i++) {
      ret.push(
        <span className="tag-mg fs14" key={i}>
          <span className="tag-inner">{skills[i]}</span>
        </span>
      );
    }
    return ret;
  }
  public render() {
    const role = Global.getRole();
    console.log(this.state);
    let jobId = this.props.jobInfo.jobId ? this.props.jobInfo.jobId : "";
    let jbaId = this.props.jobInfo.jbaId ? this.props.jobInfo.jbaId : "";
    let ctrId = this.props.jobInfo.ctrId ? this.props.jobInfo.ctrId : "";

    const jobStatus = this.props.message?this.props.message.jobStatus:'';
    const jba_is_direct_msg = this.props.message?this.props.message.jba_is_direct:'';

    let contract_count_for_job = this.state.cnt_ctr;
    let contract_comment_count_for_job = this.state.cnt_ctrc_b;
    let ctrc_value_b = this.state.ctrc_value_b;
    let em_name =
      this.state.em_is_legal === "0"
        ? this.state.em_company
        : this.state.em_company_legal;
    let em_company = this.state.em_depart;
    const em_star =
      this.state.ctrc_value_b !== "" ? parseFloat(this.state.ctrc_value_b) : 0;
    let jba_is_direct = this.props.jobInfo.jba_is_direct
      ? this.props.jobInfo.jba_is_direct
      : "";
    return (
      <div className="job_inner">
        {this.state.loading ? <Loading /> : <></>}
        <AlertDialog ref={this.alertRef} returnURL="/provider/myjob" />
        <div className="element both_ways">
          <div className="first">
            <Link
              to={{
                pathname: "/feedback/" + this.state.em_id,
                state: {
                  em_photo_s: this.state.em_photo_s,
                  em_name: em_name,
                  em_company: em_company,
                  ctrc_value_b: ctrc_value_b,
                },
              }}
            >
              <img alt="" src={URLS.IMAGE_URL + this.state.em_photo_s} />
            </Link>
            <Link
              to={{
                pathname: "/feedback/" + this.state.em_id,
                state: {
                  em_photo_s: this.state.em_photo_s,
                  em_name: em_name,
                  em_company: em_company,
                  ctrc_value_b: ctrc_value_b,
                },
              }}
            >
              <div className="star_container mt_5">
                <span className={em_star > 0 ? "star active" : "star"}></span>
                <span className={em_star > 1 ? "star active" : "star"}></span>
                <span className={em_star > 2 ? "star active" : "star"}></span>
                <span className={em_star > 3 ? "star active" : "star"}></span>
                <span className={em_star > 4 ? "star active" : "star"}></span>
              </div>
            </Link>
          </div>
          <div
            className="second"
            style={
              this.props.mode && this.props.mode === "wide"
                ? { width: "49em" }
                : {}
            }
          >
            <div className="company_info">
              <div className="company_name">
                <div className="mr_30">
                  <div className="both_ways">
                    <div className="fs20">{em_name}</div>
                    <div className="fs14">{em_company}</div>
                  </div>
                  <div>
                    <div className="fs14 map-icon">{this.state.st_label}</div>
                  </div>
                </div>
                <div>
                  <div>{this.renderSkill(this.state.sk_label_s)}</div>
                  <div className="line">{this.state.em_overview}</div>
                </div>
              </div>
              <div>
                <div className="btn-container">
                  <div className="grey-button v-small">
                    <Link to={"/message/" + this.state.em_id}>
                      <div className="inner font_14 message_send">
                        メッセージを送る
                      </div>
                    </Link>
                  </div>
                </div>
                {jobStatus == "jobapply" && (jba_is_direct_msg != undefined && jba_is_direct_msg == '1') ? (
                  <div className="btn-container">
                    <div className="grey-button no_background_green v-small">
                      <Link to={"/provider/myjob"}>
                        <div className="inner fs25 message_send">
                          直接依頼の仕事
                        </div>
                      </Link>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="profile_detail">
              {jobId !== "" &&
              jbaId !== "" &&
              contract_count_for_job === "1" &&
              contract_comment_count_for_job === "0" ? (
                <div className="grey-button yellow large_300 rb mt_10">
                  <Link
                    to={{
                      pathname: "/leave_feedback/" + this.state.em_id,
                      state: {
                        em_photo_s: this.state.em_photo_s,
                        ctrId: ctrId,
                        is_boss: 0,
                      },
                    }}
                  >
                    <div className="inner">
                      <i className="fa fa-pencil"></i>
                      評価をつける
                    </div>
                  </Link>
                </div>
              ) : (
                <></>
              )}
              <div className="grey-button yellow large_300 rb mt_10">
                <Link
                  to={{
                    pathname: "/job/" + this.state.em_id,
                    state: {
                      em_photo_s: this.state.em_photo_s,
                      em_name: em_name,
                      is_boss: 1,
                    },
                  }}
                >
                  <div className="inner">
                    <i className="fa fa-pencil"></i>
                    {em_name + "との仕事"}
                  </div>
                </Link>
              </div>
              <div className="grey-button yellow large_300 rb mt_10">
                <Link
                  to={{
                    pathname: "/feedback/" + this.state.em_id,
                    state: {
                      em_photo_s: this.state.em_photo_s,
                      em_name: em_name,
                      em_company: em_company,
                      ctrc_value_b: ctrc_value_b,
                    },
                  }}
                >
                  <div className="inner">
                    <i className="fa fa-pencil"></i>
                    評価を見る
                  </div>
                </Link>
              </div>
              <div className="d_element pd_b">
                <span className="desc_title">代表者</span>
                <span className="desc">{this.state.em_person_legal}</span>
              </div>
              <div className="d_element pd_b">
                <span className="desc_title">創業/設立年月日</span>
                <span className="desc">
                  {Global.convertDateToJapan(this.state.em_founding)}
                </span>
              </div>
              <div className="d_element pd_b">
                <span className="desc_title">従業員数</span>
                <span className="desc">{this.state.em_total}</span>
              </div>
              <div className="d_element pd_b">
                <span className="desc_title">法人番号</span>
                <span className="desc">{this.state.em_legal_number}</span>
              </div>
              <div className="d_element pd_b mt_10">
                <span className="desc_title">所在地</span>
                <span className="desc">
                  {this.state.has_relation == "1" ? (
                    `〒${this.state.em_post} `
                  ) : (
                    <></>
                  )}
                  {this.state.st_label + this.state.ar_label}
                  {this.state.has_relation == "1" ? (
                    `${this.state.em_street_address} `
                  ) : (
                    <></>
                  )}
                </span>
              </div>
              {/* {
                                this.state.has_relation === '1' ?
                                    <div className="d_element pd_b">
                                        <span className="desc_title">電話番号</span>
                                        <span className="desc">{this.state.em_contact_phone}</span>
                                    </div>
                                    : <></>
                            }                             */}
              {this.state.business1 !== "" ? (
                <div className="d_element pd_b">
                  <span className="desc_title">業種１</span>
                  <span className="desc">{this.state.business1}</span>
                </div>
              ) : (
                <></>
              )}
              {this.state.business2 !== "" ? (
                <div className="d_element pd_b">
                  <span className="desc_title">業種２</span>
                  <span className="desc">{this.state.business2}</span>
                </div>
              ) : (
                <></>
              )}
              {this.state.business3 !== "" ? (
                <div className="d_element pd_b">
                  <span className="desc_title">業種３</span>
                  <span className="desc">{this.state.business3}</span>
                </div>
              ) : (
                <></>
              )}

              <div className="d_element pd_b">
                <span className="desc_title">検索用業種フリーワード</span>
                <span className="desc">{this.state.em_skill_keyword}</span>
              </div>
              {this.state.has_relation === "1" ? (
                <div className="d_element pd_b">
                  <span className="desc_title">入力担当者</span>
                  <span className="desc">
                    {this.state.em_inputer_depart +
                      this.state.em_inputer_pos +
                      "　" +
                      this.state.em_inputer_lastname +
                      this.state.em_inputer_firstname}
                  </span>
                </div>
              ) : (
                <></>
              )}
              {this.state.has_relation === "1" ? (
                <div className="d_element pd_b">
                  <span className="desc_title">事前連絡事項</span>
                  <span className="desc">{this.state.em_precontact}</span>
                </div>
              ) : (
                <></>
              )}

              {/* <div className="d_element pd_b">
                                <span className="desc_title">依頼中の仕事</span>
                                <span className="desc">{this.state.em_contact_phone}</span>
                            </div> */}
            </div>
          </div>
        </div>
        {}
        <div className="button-container">
          {role == "2" &&
          jba_is_direct === "1" &&
          this.state.jba_id_s != null &&
          this.state.ctr_id_s == null ? (
            <div className="grey-button large mt_70">
              <div className="inner" onClick={this.onDirectAccept}>
                依頼に同意する
                <i className="fa fa-angle-right"></i>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="grey-button large mt_20">
            <Link to={"/message/" + this.state.em_id}>
              <div className="inner">
                メッセージを送る
                <i className="fa fa-angle-right"></i>
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
export default ClientProfileDetailCom;
