import React, {Component} from 'react';
import '../scss/job.scss';
import '../scss/search.scss';
import Header from '../Include/Header'
import SearchNavCom from './SearchNavCom';
import SearchCom from './SearchCom';
import Footer from '../Include/Footer';
class Search extends Component<any> {
    public render(){
        const url = this.props.match.url;
        return(
            <div>
                <Header/>
                <div className="content-container" id = "content">
                    <div className="wrap-content">
                        <div className="main-title">職人を探す</div>
                        <div className="job_container mt_40">
                            <SearchNavCom to = {url}/>                            
                            <div className="job recommend search">
                                <SearchCom history = {this.props.history}/>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>

        );
    }
}
export default Search;