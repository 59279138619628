import { db, firebaseTimestamp } from "../firebase";
import { Dispatch } from "redux";
import {
  Message,
  FireBaseMessage,
  FireBaseSendContact,
  FireBaseResponseContact,
} from "../../modules/chat/types";
import { ChatActions, PAGE_PER_COUNT } from "../../modules/chat/types";
import {
  downloadSucessMessage,
  downloadFailureMessage,
  uploadSucessMessage,
  countMessage,
  updatePoint,
  registerCountListener,
  registerMessageListener,
} from "../../modules/chat/actions";
import * as URLS from "../../modules/urls";
import * as Global from "../../modules/global";
import * as moment from "moment-timezone";

export function addMessage(
  dispatch: Dispatch<ChatActions>,
  msg: Message,
  from: number,
  to: number,
  emt_id: number
) {
  const date = new Date();
  const utc = firebaseTimestamp.fromDate(date);
  msg.time = utc;
  const fmsg: FireBaseMessage = {
    id: utc.nanoseconds.toString(),
    to: msg.to,
    isViewed: false,
    from: msg.from,
    time: utc,
    text: msg.text,
    emt_id: emt_id,
    img: msg.img,
  };
  const roomNumber: string = getRoom(from, to);
  // updateContacts( from, to, to.toString(), msg.text, "", emt_id, 0 );
  // updateContacts( from, to, from.toString(), msg.text, "", emt_id, 0, false );
  //db.collection( "messages" ).doc( roomNumber ).collection("message").doc( docName ).set( fmsg ).then( function(){
  db.collection("messages")
    .doc(roomNumber)
    .collection("message")
    .doc()
    .set(fmsg)
    .then(function () {
      updateContacts(from, to, to.toString(), msg.text, "", emt_id, 0);
      updateContacts(from, to, from.toString(), msg.text, "", emt_id, 0, false);
      dispatch(uploadSucessMessage(true, msg));
      deliminatePoint(dispatch, from, to);
    })
    .catch(function (error) {
      dispatch(uploadSucessMessage(false, msg));
    });
}
export function addSystemMessage(
  dispatch: Dispatch<ChatActions>,
  from: number,
  to: number,
  docId: string,
  cmsg: string,
  title: string = "",
  emt_id: number,
  isKuraha: number = 0,
  isFromMe: boolean = true,
  msg = ""
) {
  const date = new Date();
  const utc = firebaseTimestamp.fromDate(date);
  const fmsg: FireBaseMessage = {
    id: utc.nanoseconds.toString(),
    to: to,
    isViewed: false,
    from: from,
    emt_id: emt_id,
    time: utc,
    text: msg,
  };
  const roomNumber: string = getRoom(from, to);
  //	const docName:string = msg.id.toString();
  db.collection("messages")
    .doc(roomNumber)
    .collection("message")
    .doc()
    .set(fmsg)
    .then(function () {
      updateContacts(from, to, docId, cmsg, title, emt_id, isKuraha, isFromMe);
      //updateContacts(from, to, from.toString(), msg, title, emt_id, isKuraha, isFromMe, msg);
      deliminatePoint(dispatch, from, to);
    })
    .catch(function (error) {});
}

function deliminatePoint(
  dispatch: Dispatch<ChatActions>,
  em_id: number,
  t_em_id: number
) {
  const role = Global.getRole();
  let keyword = "boss";
  if (role === "2") {
    keyword = "worker";
  }
  const data = {
    em_id_1: em_id,
    em_id_2: t_em_id,
    keyword: keyword,
  };
  fetch(URLS.RELATION_REGISTER, {
    method: "post",
    body: JSON.stringify(data),
  })
    .then(async function (response) {
      let ret = await response.json();
      const currentPoint = parseInt(ret.em.em_point ? ret.em.em_point : 0);
      Global.setPoint(currentPoint.toString());
      dispatch(updatePoint(true, currentPoint));
      //return response.json();
    })
    .catch((error) => {
      const currentPoint = Global.getPoint()! - 50;
      Global.setPoint(currentPoint.toString());
      dispatch(updatePoint(true, currentPoint));
    });
}

export function detatchCountListener(
  dispatch: Dispatch<ChatActions>,
  from: number,
  to: number
) {
  dispatch(countMessage(0, undefined, true));
  const contactNumber: string = "contact_" + from;
  let unsub = db
    .collection("contacts")
    .doc(contactNumber)
    .collection("contact")
    .onSnapshot((querySnapshot) => {});
  unsub();
}
export function deleteMultipleDocument(from: string, docIds: string[]) {
  const writeBatch: any = db.batch();
  const contactNumber: string = "contact_" + parseInt(from);
  for (let i = 0; i < docIds.length; i++) {
    const docId = docIds[i];
    const documentReference = db
      .collection("contacts")
      .doc(contactNumber)
      .collection("contact")
      .doc(docId.toString());
    writeBatch.delete(documentReference);
  }
  writeBatch
    .commit()
    .then(function () {
      console.log("Successfully deleted");
    })
    .catch(function () {
      console.log("multiple delete error");
    });
}
export function deleteDocument(from: string, docId: string) {
  const contactNumber: string = "contact_" + parseInt(from);
  db.collection("contacts")
    .doc(contactNumber)
    .collection("contact")
    .doc(docId.toString())
    .delete()
    .then(function () {
      console.log("Document successfully deleted!");
    })
    .catch(function (error) {
      console.error("Error removing document: ", error);
    });
}
export function deleteMyContactDocument(from: string) {
  const contactNumber: string = "contact_" + parseInt(from);
  db.collection("contacts")
    .doc(contactNumber)
    .delete()
    .then(function () {
      console.log("Document successfully deleted!");
    })
    .catch(function (error) {
      console.error("Error removing document: ", error);
    });
}
export function deleteMyMessages(from: string, tos: any) {
  for (let i = 0; i < tos.length; i++) {
    const roomNumber: string = getRoom(parseInt(from), tos[i]);
    db.collection("messages")
      .doc(roomNumber)
      .delete()
      .then(function () {
        console.log("Document successfully deleted!");
      })
      .catch(function (error) {
        console.error("Error removing document: ", error);
      });
  }
}
export function setAlreadyViewed(docId: string, to: string, id: string) {
  const contactNumber: string = "contact_" + parseInt(docId);
  const document = db
    .collection("contacts")
    .doc(contactNumber)
    .collection("contact")
    .doc(id.toString());
  document
    .get()
    .then(function (doc) {
      if (doc.exists) {
        let role = parseInt(Global.getRole()!);
        if (role === 1) {
          document.update({
            numUnViewed_boss: 0,
            isViewed_boss: true,
          });
        } else {
          document.update({
            numUnViewed_worker: 0,
            isViewed_worker: true,
          });
        }
      }
      // else{
      // 	contact.set( fmsg ).then( function(){
      // 		db.collection( "contacts" ).doc( fromNumber ).set({
      // 			count:1,
      // 		})
      // 	}).catch( function( error ){
      // 	});
      // }
    })
    .catch(function (error) {});
}
export function messageCountListener(
  dispatch: Dispatch<ChatActions>,
  from: number
) {
  let role = parseInt(Global.getRole()!);
  let em_id = parseInt(Global.getEmId()!);
  const contactNumber: string = "contact_" + em_id;
  let condition = "";
  if (role === 1) {
    condition = "isViewed_boss";
  } else if (role === 2) {
    condition = "isViewed_worker";
  }
  const unsub = db
    .collection("contacts")
    .doc(contactNumber)
    .collection("contact")
    .where(condition, "==", false)
    .onSnapshot(
      (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          if (
            change.type === "modified" ||
            change.type === "added" ||
            change.type === "removed"
          ) {
            const data = change.doc.data();
            
            const newContact: FireBaseResponseContact =
              data as FireBaseResponseContact;
            if (change.type === "removed") {
              if (role === 1) {
                newContact.isViewed_boss = true;
                newContact.numUnViewed_boss = 0;
              } else {
                newContact.isViewed_worker = true;
                newContact.numUnViewed_worker = 0;
              }
            }

            /*********************Trash codes, don't use this one again.******************/
            const doc = db
              .collection("contacts")
              .doc(contactNumber)
              .collection("contact")
              .doc(change.doc.id.toString());
            doc.update({
              ...newContact,
            });
            // if( newMessage.emt_id != role ){
            // 	return;
            // }
            // if( ( em_id != newMessage.from && newMessage.emt_id == role ) || ( em_id == newMessage.from && newMessage.emt_id != role ) ){
            // 	return;
            // }
            //if( newMessage.from !== from && newMessage.from !== to ){
            const newMessage: Message | null = convertContactToMessage(
              role,
              data,
              change.doc.id
            );
            if (!newMessage) {
              return;
            }
            if (newMessage.from !== em_id) {
              dispatch(countMessage(1, newMessage, true));
            }
          }
        });
      },
      (error) => {
        dispatch(countMessage(0, undefined, false, false));
      }
    );
  dispatch(registerCountListener(unsub));
  //unsub();
}
export function detatchListener(from: number, to: number) {
  // [START detach_listener]
  //console.log( "All the time?" );
  const roomNumber: string = getRoom(from, to);
  let role = parseInt(Global.getRole()!);
  if (role === 1) {
    role = 2;
  }
  let unsub = db
    .collection("messages")
    .doc(roomNumber)
    .collection("message")
    .where("from", "==", to)
    .where("isViewed", "==", false)
    .where("emt_id", "==", role)
    .onSnapshot(() => {});
  // Stop listening for changes
  unsub();
  // [END detach_listener]
}

export function messageListener(
  dispatch: Dispatch<ChatActions>,
  from: number,
  to: number
) {
  const roomNumber: string = getRoom(from, to);
  let role = parseInt(Global.getRole()!);
  if (role === 1) {
    role = 2;
  } else if (role === 2) {
    role = 1;
  }
  const unsub = db
    .collection("messages")
    .doc(roomNumber)
    .collection("message")
    .where("from", "==", to)
    .where("isViewed", "==", false)
    .where("emt_id", "==", role)
    .onSnapshot(
      (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            const list = [];
            const doc = db
              .collection("messages")
              .doc(roomNumber)
              .collection("message")
              .doc(change.doc.id.toString());
            doc.update({
              isViewed: true,
            });
            list.push(change.doc.data());
            list.map(function (message) {
              message = setMessage(message);
              return message;
            });
            const messages: Message[] = list as Message[];
            dispatch(downloadSucessMessage(messages, null, true));
          }
        });
      },
      (error) => {
        //...
        console.log("Snapshot Error! ");
      }
    );
  dispatch(registerMessageListener(unsub));
}
function getRoom(from: number, to: number) {
  const startNo = Math.min(from, to);
  const endNo = Math.max(from, to);
  return "room_" + startNo + "_" + endNo;
}
function setMessage(message: any): any {
  message.isWrited = true;
  message.isViewed = true;
  message = setTime(message);
  message.img = "../images/" + message.img;
  return message;
}
function setTime(contact: any, docId: string = ""): any {
  const time: Date = contact.time.toDate();
  contact.docId = docId;
  contact.year = time!.getFullYear();
  contact.date = time!.getMonth() + 1 + "月" + time!.getDate() + "日";
  contact.fulldate =
    time!.getFullYear() + "/" + (time!.getMonth() + 1) + "/" + time!.getDate();
  contact.str_time = time!.getHours() + ":" + time!.getMinutes();
  return contact;
}
function setTimeForContact(
  role: string,
  contact: any,
  docId: string = ""
): any {
  let time: Date | null = null;
  if (role === "1") {
    time = contact.time_boss.toDate();
  } else {
    time = contact.time_worker.toDate();
  }
  contact.docId = docId;
  contact.year = time!.getFullYear();
  contact.date = time!.getMonth() + 1 + "月" + time!.getDate() + "日";
  contact.fulldate =
    time!.getFullYear() + "/" + (time!.getMonth() + 1) + "/" + time!.getDate();
  contact.str_time = time!.getHours() + ":" + time!.getMinutes();
  return contact;
}
export function updateContacts(
  from: number,
  to: number,
  docId: string,
  cmsg: string,
  title: string = "",
  emt_id: number,
  isKuraha: number = 0,
  isFromMe: boolean = true
) {
  const date = new Date();
  const utc = firebaseTimestamp.fromDate(date);
  let fromNumber: string = "contact_" + from;
  //let id = to;
  if (!isFromMe) {
    fromNumber = "contact_" + to;
    //id = from;
  }

  let docName: string = docId;
  let fmsg: FireBaseSendContact;
  let role = Global.getRole();
  if (role !== "1" && role !== "2") {
    return;
  }

  const contact = db
    .collection("contacts")
    .doc(fromNumber)
    .collection("contact")
    .doc(docName);
  contact
    .get()
    .then(function (doc) {
      const item = doc.data();
      if (role === "1") {
        if (isFromMe)
          // owner's update.
          fmsg = {
            from_boss: from,
            to_boss: to,
            title_boss: title,
            text_boss: cmsg,
            time_boss: utc,
            emt_is_boss: 1,

            isKuraha_boss: isKuraha,
            isViewed_boss: true,
            numUnViewed_boss:
              item && item.numUnViewed_boss
                ? parseInt(item.numUnViewed_boss)
                : 0,
          };
        // recepient's update
        else
          fmsg = {
            from_worker: from,
            to_worker: to,
            title_worker: title,
            text_worker: cmsg,
            time_worker: utc,
            emt_is_worker: 1,
            isKuraha_worker: isKuraha,
            isViewed_worker: false,
            numUnViewed_worker:
              item && item.numUnViewed_worker
                ? parseInt(item.numUnViewed_worker) + 1
                : 1,
          };
      } else {
        if (isFromMe)
          // owner's update.
          fmsg = {
            from_worker: from,
            to_worker: to,
            title_worker: title,
            text_worker: cmsg,
            time_worker: utc,
            emt_is_worker: 1,
            isKuraha_worker: isKuraha,
            isViewed_worker: true,
            numUnViewed_worker:
              item && item.numUnViewed_worker
                ? parseInt(item.numUnViewed_worker)
                : 0,
          };
        // recepient's update
        else
          fmsg = {
            from_boss: from,
            to_boss: to,
            title_boss: title,
            text_boss: cmsg,
            time_boss: utc,
            emt_is_boss: 1,
            isKuraha_boss: isKuraha,
            isViewed_boss: false,
            numUnViewed_boss:
              item && item.numUnViewed_boss
                ? parseInt(item.numUnViewed_boss) + 1
                : 1,
          };
      }

      if (doc.exists) {
        contact
          .update(fmsg!)
          .then(function () {})
          .catch(function (error) {});
      } else {
        contact
          .set(fmsg!)
          .then(function () {})
          .catch(function (error) {});
      }
    })
    .catch(function (error) {});
}
export function updateMySystemContacts(
  myDocName: string,
  otherDocName: string,
  from: number,
  to: number,
  title: string = "",
  contactMessage: string,
  role_id: number,
  isFromMe: boolean = true
) {
  const date = new Date();
  const utc = firebaseTimestamp.fromDate(date);

  let fmsg: FireBaseSendContact;
  let role = Global.getRole();
  if (role !== "1" && role !== "2") {
    return;
  }

  const contact = db
    .collection("contacts")
    .doc(myDocName)
    .collection("contact")
    .doc(otherDocName);
  contact
    .get()
    .then(function (doc) {
      const item = doc.data();
      if (role === "1") {
        if (isFromMe)
          // owner's update.
          fmsg = {
            from_boss: from,
            to_boss: to,
            title_boss: title,
            text_boss: contactMessage,
            time_boss: utc,
            emt_is_boss: 1,
            isKuraha_boss: 1,
            isViewed_boss: false,
            numUnViewed_boss:
              item && item.numUnViewed_boss
                ? parseInt(item.numUnViewed_boss) + 1
                : 1,
          };
        // recepient's update
        else
          fmsg = {
            from_worker: from,
            to_worker: to,
            title_worker: title,
            text_worker: contactMessage,
            time_worker: utc,
            emt_is_worker: 1,
            isKuraha_worker: 1,
            isViewed_worker: false,
            numUnViewed_worker:
              item && item.numUnViewed_worker
                ? parseInt(item.numUnViewed_worker) + 1
                : 1,
          };
      } else {
        if (isFromMe)
          // owner's update.
          fmsg = {
            from_worker: from,
            to_worker: to,
            title_worker: title,
            text_worker: contactMessage,
            time_worker: utc,
            emt_is_worker: 1,
            isKuraha_worker: 1,
            isViewed_worker: false,
            numUnViewed_worker:
              item && item.numUnViewed_worker
                ? parseInt(item.numUnViewed_worker) + 1
                : 1,
          };
        // recepient's update
        else
          fmsg = {
            from_boss: from,
            to_boss: to,
            title_boss: title,
            text_boss: contactMessage,
            time_boss: utc,
            emt_is_boss: 1,
            isKuraha_boss: 1,
            isViewed_boss: false,
            numUnViewed_boss:
              item && item.numUnViewed_boss
                ? parseInt(item.numUnViewed_boss) + 1
                : 1,
          };
      }
      if (doc.exists) {
        contact
          .update(fmsg!)
          .then(function () {})
          .catch(function (error) {});
      } else {
        contact
          .set(fmsg!)
          .then(function () {})
          .catch(function (error) {});
      }
    })
    .catch(function (error) {});
}
export function doGetContacts(from: number): any {
  let role: number = parseInt(Global.getRole()!);
  if (role !== 1 && role !== 2) {
    return;
  }
  let orderBy: string = "";
  let where: string = "";
  if (role === 1) {
    orderBy = "time_boss";
    where = `from_boss`;
  } else {
    orderBy = "time_worker";
    where = `from_worker`;
  }
  const em_id = Global.getEmId()!;
  let fromDateStr: string | null = "";

  if (role === 1) {
    fromDateStr = Global.getEmBossLoginDate();
  } else if (role === 2) {
    fromDateStr = Global.getEmWorkerLoginDate();
  }
  if (fromDateStr === null) {
    return;
  }
  //const fromDate: Date = moment(fromDateStr, 'YYYY-MM-DD HH:mm:ss').toDate();
  const fromDate: Date = moment
    .tz(fromDateStr, "YYYY-MM-DD HH:mm:ss", "Asia/Tokyo")
    .toDate();
  const time = firebaseTimestamp.fromDate(fromDate);
  const contact = db
    .collection("contacts")
    .doc("contact_" + from)
    .collection("contact")
    .where(orderBy, ">=", time)
    .orderBy(orderBy, "desc");
  return contact
    .get()
    .then((doc) => {
      const list = doc.docs.map((item) => ({
        id: item.id,
        message: item.data(),
      }));
      list.map(function (item) {
        item.message = setTimeForContact(
          role.toString(),
          item.message,
          item.id
        );
        return item;
      });
      // const ret = list.map(message => message.message);
      const ret = list;
      const messages: Message[] = [];
      for (let i: number = 0; i < ret.length; i++) {
        const contactElement = ret[i].message;
        if (role === 1) {
          if (contactElement.emt_is_boss && contactElement.emt_is_boss === 1) {
            //this is the message as a boss.
            const message: Message = {
              docId: contactElement.docId,
              from:
                parseInt(contactElement.from_boss) === parseInt(em_id)
                  ? contactElement.to_boss
                  : contactElement.from_boss,
              to: contactElement.to_boss,
              title: contactElement.title_boss,
              text: contactElement.text_boss,
              isKuraha: contactElement.isKuraha_boss,
              //isKuraha: ret[i].id === '1'?1:0,
              time: contactElement.time_boss,
              isViewed: contactElement.isViewed_boss,
              numUnviewed: contactElement.numUnViewed_boss,
              date: contactElement.date,
              isWrited: true,
              emt_is_boss: 1,
              emt_is_worker: 0,
              emt_id: 1,
              str_time: "",
              year: "",
              isFirstNewDate: true,
            };
            messages.push(message);
          }
        } else {
          if (
            contactElement.emt_is_worker &&
            contactElement.emt_is_worker === 1
          ) {
            //this is the message as a worker.
            const message: Message = {
              docId: contactElement.docId,
              from:
                parseInt(contactElement.from_worker) === parseInt(em_id)
                  ? contactElement.to_worker
                  : contactElement.from_worker,
              title: contactElement.title_worker,
              to: contactElement.to_worker,
              text: contactElement.text_worker,
              isKuraha: contactElement.isKuraha_worker,
              //isKuraha: ret[i].id === '1'?1:0,
              time: contactElement.time_worker,
              isViewed: contactElement.isViewed_worker,
              numUnviewed: contactElement.numUnViewed_worker,
              date: contactElement.date,
              isWrited: true,
              emt_is_boss: 0,
              emt_is_worker: 1,
              emt_id: 2,
              str_time: "",
              year: "",
              isFirstNewDate: true,
            };
            messages.push(message);
          }
        }
      }
      return messages;
    })
    .catch(function (error) {
      console.log("error here ");
      return [];
    });
}
function convertContactToMessage(
  role: number,
  contact: firebase.firestore.DocumentData,
  docId: string,
): Message | null {
  let message: Message | null = null;
  if (role === 1) {
    if (contact.emt_is_boss && contact.emt_is_boss === 1) {
      //this is the message as a boss.
      message = {
        docId: docId,
        from: contact.from_boss,
        to: contact.to_boss,
        text: contact.text_boss,
        isKuraha: contact.isKuraha_boss,
        time: contact.time_boss,
        isViewed: contact.isViewed_boss,
        numUnviewed: contact.numUnViewed_boss,
        date: contact.date,
        isWrited: true,
        emt_is_boss: 1,
        emt_is_worker: 0,
        emt_id: 1,
        str_time: "",
        year: "",
        isFirstNewDate: true,
      };
    }
  } else {
    if (contact.emt_is_worker && contact.emt_is_worker === 1) {
      //this is the message as a worker.
      message = {
        docId: docId,
        from: contact.from_worker,
        to: contact.to_worker,
        text: contact.text_worker,
        isKuraha: contact.isKuraha_worker,
        time: contact.time_worker,
        isViewed: contact.isViewed_worker,
        numUnviewed: contact.numUnViewed_worker,
        date: contact.date,
        isWrited: true,
        emt_is_boss: 0,
        emt_is_worker: 1,
        emt_id: 2,
        str_time: "",
        year: "",
        isFirstNewDate: true,
      };
    }
  }
  return message;
}
export function doGetMessage(
  dispatch: Dispatch<ChatActions>,
  from: number,
  to: number,
  lastObject: any,
  isAllDownloaded: boolean = false
) {
  const roomNumber: string = getRoom(from, to);
  if (isAllDownloaded) return;
  let role = parseInt(Global.getRole()!);
  let em_id = parseInt(Global.getEmId()!);
  let fromDateStr: string | null = "";

  if (role === 1) {
    fromDateStr = Global.getEmBossLoginDate();
  } else if (role === 2) {
    fromDateStr = Global.getEmWorkerLoginDate();
  }
  if (fromDateStr === null) {
    return;
  }
  //const fromDate: Date = moment(fromDateStr, 'YYYY-MM-DD HH:mm:ss').toDate();
  const fromDate: Date = moment
    .tz(fromDateStr, "YYYY-MM-DD HH:mm:ss", "Asia/Tokyo")
    .toDate();

  const room = db.collection("messages").doc(roomNumber);
  const time = firebaseTimestamp.fromDate(fromDate);
  room
    .get()
    .then(function (doc) {
      if (!lastObject) {
        const roomDoc = room
          .collection("message")
          .where("time", ">=", time)
          .orderBy("time", "desc")
          .limit(PAGE_PER_COUNT);
        roomDoc
          .get()
          .then(async function (doc) {
            var lastVisible = doc.docs[doc.docs.length - 1];
            //room.collection( "message" ).orderBy("time", "desc").startAfter(lastVisible).limit(10);
            //const list = doc.docs.map(message => message.data());
            const list = [];
            for (let k = 0; k < doc.docs.length; k++) {
              const message = await doc.docs[k].data();
              list.push(message);
            }
            list.map(function (message) {
              message = setMessage(message);
              return message;
            });

            let idx = 0;
            while (idx < list.length) {
              if (
                (parseInt(em_id.toString()) !==
                  parseInt(list[idx].from.toString()) &&
                  parseInt(list[idx].emt_id.toString()) ===
                    parseInt(role.toString())) ||
                (parseInt(em_id.toString()) ===
                  parseInt(list[idx].from.toString()) &&
                  parseInt(list[idx].emt_id.toString()) !==
                    parseInt(role.toString()))
              ) {
                list.splice(idx, 1);
              } else {
                idx++;
              }
            }
            const messages: Message[] = list as Message[];
            dispatch(downloadSucessMessage(messages, lastVisible));
          })
          .catch(function (error) {
            return null;
          });
      } else {
        const roomDoc = room
          .collection("message")
          .where("time", ">", time)
          .orderBy("time", "desc")
          .startAfter(lastObject)
          .limit(PAGE_PER_COUNT);
        roomDoc
          .get()
          .then(async function (doc) {
            var lastVisible = doc.docs[doc.docs.length - 1];
            const list = doc.docs.map((message) => message.data());

            list.map(function (message) {
              const time: Date = message.time.toDate();
              message.year = time.getFullYear();
              message.date = time.getMonth() + 1 + "月" + time.getDate() + "日";
              message.str_time = time.getHours() + ":" + time.getMinutes();
              message.isWrited = true;
              message.img = "../images/" + message.img;
              return message;
            });

            let idx = 0;
            while (idx < list.length) {
              const em_id_num = parseInt(em_id.toString());
              const from_num = parseInt(list[idx].from.toString());
              const emt_id_num = parseInt(list[idx].emt_id.toString());
              const role_num = parseInt(role.toString());

              if (
                (parseInt(em_id.toString()) !==
                  parseInt(list[idx].from.toString()) &&
                  parseInt(list[idx].emt_id.toString()) ===
                    parseInt(role.toString())) ||
                (parseInt(em_id.toString()) ===
                  parseInt(list[idx].from.toString()) &&
                  parseInt(list[idx].emt_id.toString()) !==
                    parseInt(role.toString()))
              ) {
                list.splice(idx, 1);
              } else {
                idx++;
              }
            }
            const messages: Message[] = list as Message[];
            dispatch(downloadSucessMessage(messages, lastVisible));
          })
          .catch(function (error) {
            return null;
          });
      }
      // }else{
      // 	return dispatch( downloadSucessMessage( [], null, false ) );
      // }
      // const roomDoc = room.collection( "message" ).get();
      // const messages = (await roomDoc ).docs.map( message => message.data() );
      // const xxx:number = 1;
    })
    .catch(function (error) {
      return dispatch(downloadFailureMessage([]));
      //		doGetMessage( dispatch, from, to, lastObject, isAllDownloaded );
    });
}
