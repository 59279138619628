import React from 'react';
import '../../scss/job.scss';
import '../../scss/editprofile.scss';
import Header from '../../Include/Header'
import PJobDetailCom from './PJobDetailCom';
import Footer from '../../Include/Footer';

class PEditProfile extends React.Component<any>{
    public render(){
        const id = this.props.match.params.id?this.props.match.params.id:this.props.location.state && this.props.location.state.jb_id?this.props.location.state.jb_id:"";
        const name = this.props.match.params.company?this.props.match.params.company:this.props.location.state && this.props.location.state.em_company?this.props.location.state.em_company:"";
        const depart = this.props.match.params.depart?this.props.match.params.depart:this.props.location.state && this.props.location.state.em_depart?this.props.location.state.em_depart:" ";
        const basepath = this.props.match.params.basepath?this.props.match.params.basepath:'';
        const photo = this.props.match.params.photo?this.props.match.params.photo:'';
        const client_em_id = this.props.match.params.em_id?this.props.match.params.em_id:this.props.location.state && this.props.location.state.em_id?this.props.location.state.em_id:"";                        
        let em_photo_s = '';
        if( basepath !== '' ) {
            em_photo_s = basepath + '/' + photo;
        }else{
            em_photo_s = this.props.location.state && this.props.location.state.em_photo_s?this.props.location.state.em_photo_s:"";         
        }
        return (
            <div>
                <Header/>
                <div className="content-container" id = "content">
                    <div className="wrap-content">
                        <div className="main-title">仕事詳細</div>
                        <div className="job_container mt_40">
                            <div className ="job myprofile">
                                <PJobDetailCom jobId = {id} company = {name} depart = {depart} photo = {em_photo_s} em_id = {client_em_id}/>
                            </div>                            
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}
export default PEditProfile;