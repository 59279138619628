import React from 'react';
import '../scss/job.scss';
import '../scss/jobedit.scss';
import Header from '../Include/Header'
import JobNavCom from "./JobNavCom";
import {Link} from 'react-router-dom';
import { render } from '@testing-library/react';
import {JobNavComProps} from './JobNavCom';
import JobEditCom from './JobEditCom';
import Footer from '../Include/Footer';

class JobEdit extends React.Component<any>{
    public render(){
        const url = this.props.match.url;
        let id = this.props.match.params.id?this.props.match.params.id:-1;
        return (
            <div>
                <Header/>
                <div className="content-container" id = "content">
                    <div className="wrap-content">
                        <div className="main-title">仕事管理</div>
                        <div className="job_container mt_40">
                            <JobNavCom to = {url} />
                            <div className="job edit_project">
                                <JobEditCom jobId = {id}/>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    } 

}
export default JobEdit; 