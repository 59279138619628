import React, { ChangeEvent, Component } from 'react';
import '../scss/choose.scss';
import { Link } from 'react-router-dom';
import Footer from '../Include/Footer';
import { IRootState } from '../../modules';
import { Dispatch } from 'redux';
import { UserInfoActions } from '../../modules/userinfo';
import { ChatActions } from '../../modules/chat';
import * as messageAsyncactions from '../../modules/chat/async-actions';
import * as userAsyncactions from '../../modules/userinfo/async-actions';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import { VALIDATION_MESSAGES } from '../../modules/global';
import Loading from '../Include/Loading';
import { AlertDialog } from '../Modal/Alert';
import * as Global from '../../modules/global';
import * as URLS from '../../modules/urls';


type LogInState = {
  height?: number,
  isLogIn: boolean,
  em_name: string,
  em_passwd: string,
  loading: boolean,
  errorMessage: string,
}
const mapStateToProps = ({ message, user }: IRootState) => {
  const { removeCountListener } = message;
  const { isLogIn, userInfo } = user;
  return { isLogIn, userInfo, removeCountListener };
}
const mapDispatcherToProps = (dispatch: Dispatch<ChatActions | UserInfoActions>) => {
  return {
    //logIn:( userInfo:UserInfo ) => asyncactions.logInAsync( dispatch, userInfo ),
    //initialize: () => messageAsyncactions.initializeAsync(dispatch),
    //removeListener: () => userAsyncactions.removeRoleListenerAysnc(dispatch),
  }
}
type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;

class LogIn extends Component<ReduxType, LogInState>{
  state = {
    loading: false,
    isLogIn: false,
    em_name: "",
    em_passwd: "",
    height: 0,
    errorMessage: '',
  }
  myRef: any;
  alertRef: any;
  validator: SimpleReactValidator;
  constructor(props: any) {
    super(props);
    this.myRef = React.createRef();

    this.validator = new SimpleReactValidator({
      messages: VALIDATION_MESSAGES,
      autoForceUpdate: this,
      className: 'error',
    });
    this.alertRef = React.createRef();
  }
  componentDidMount() {
    this.onSize();
  }
  onSize() {
    let clientHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const currentHeight = this.myRef.current.clientHeight;
    console.log( 'OnSize=' + currentHeight );        
    if (clientHeight - currentHeight > 36) {// 36 is footer height
      this.setState({
        height: (clientHeight - 36),
      });
    }else{
      this.setState({
        height: 0,
      });
    }
  }
  get = (username: string, password: string) => {
    const that = this;
    const URL: string = URLS.LOGIN + "?em_name_exact=" + username + "&em_passwd=" + password + "&em_leave_no=0";
    this.setState({
      loading: true,
    });
    fetch(URL, {
      method: 'GET',
    }).then(async function (response) {
      const ret = await response.json();
      if (ret.items.length) {
        if (ret.items[0].emt_is_boss === undefined || ret.items[0].emt_is_worker === undefined || (ret.items[0].emt_is_boss === 0 && ret.items[0].emt_is_worker === 0)) {
          that.alertRef.current.open("エラー", "ユーザー名かパスワードが違います。");
          that.setState({
            loading: false,
          });
          return;
        }
        localStorage.setItem('em_id', ret.items[0].em_id);
        localStorage.setItem('em_worker_name', ret.items[0].em_lastname + ret.items[0].em_firstname);
        localStorage.setItem('em_boss_company', ret.items[0].em_is_legal ? ret.items[0].em_company : ret.items[0].em_company_legal);

        Global.setEmtId(ret.items[0].emt_is_boss, ret.items[0].emt_is_worker);
        Global.setEmBossCompany(ret.items[0].em_is_legal == '0' ? ret.items[0].em_company : ret.items[0].em_company_legal);
        Global.setEmBigPhoto(ret.items[0].em_photo ? ret.items[0].em_photo : '');
        Global.setEmSmallPhoto(ret.items[0].em_photo_s ? ret.items[0].em_photo_s : '');
        Global.setEmDepart(ret.items[0].em_depart ? ret.items[0].em_depart : '');
        Global.setEmWorkerCompany(ret.items[0].em_company_w ? ret.items[0].em_company_w : '');
        Global.setEmWorkerName(ret.items[0].em_lastname + ret.items[0].em_firstname);
        Global.setEmCompnay(ret.items[0].em_company ? ret.items[0].em_company : '');
        Global.setEm_company_legal(ret.items[0].em_company_legal ? ret.items[0].em_company_legal : '');
        Global.setEm_firstname(ret.items[0].em_firstname ? ret.items[0].em_firstname : '');
        Global.setEm_lastname(ret.items[0].em_lastname ? ret.items[0].em_lastname : '');
        Global.setEm_is_individual(ret.items[0].em_is_individual ? ret.items[0].em_is_individual : '0');
        Global.setEm_is_legal(ret.items[0].em_is_legal);
        Global.setEmt_is_boss(ret.items[0].emt_is_boss);
        Global.setEmt_is_worker(ret.items[0].emt_is_worker);

        that.setState({
          isLogIn: true,
          loading: false,
        });
      } else {
        that.alertRef.current.open("エラー", "ユーザー名かパスワードが違います。");
        that.setState({ loading: false });
      }
    }).catch(error => {
      this.alertRef.current.open("エラー", "エラーが発生しました。");
      this.setState({ loading: false, errorMessage: error.toString() });
    });
  }
  onLogIn = (e: any) => {
    this.logIn();
  }
  logIn = () => {
    if (this.validator.allValid()) {
      this.get(this.state.em_name, this.state.em_passwd);
      // const userInfo:UserInfo = {
      //     em_name:this.state.em_name,
      //     em_passwd:this.state.em_passwd,
      // }
      // this.props.logIn( userInfo );
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
      this.onSize();
    }
  }
  onHandleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value: string = e.target.value;
    let name: string = e.target.name;
    //this.setState({ [name]: value });
    this.setState(prevState => ({
      ...prevState,
      [name]: value // No error here, but can't ensure that key is in StateKeys
    }));
  }
  onHandleBlur = (e: any) => {
    //let name:string = e.target.name;
    let name: string = e.target.name;
    this.validator.showMessageFor(name);
    this.onSize();
  }
  renderRedirect = () => {
    let em_id: string | null = Global.getEmId();
    if ((em_id && em_id !== "") || this.state.isLogIn) {
      // if (this.props.countListener) {
      //   this.props.countListener();
      // }
      let url = '/choose';
      return <Redirect to={url} />
    }
    return <></>
  }
  onKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      this.logIn();
    }
  }
  render() {
    const isFixed:boolean = this.state.height != 0?true: false;
    return (
      <>
        {this.renderRedirect()}
        <div className="splash_container">
          {this.state.loading ?
            <Loading color="white" />
            : <></>
          }
          <div className="choose-inner" ref={this.myRef} style={{ height: this.state.height != 0 ? this.state.height : 'auto' }}>
            <AlertDialog ref={this.alertRef} />
            <img alt='' className="b-logo" src={URLS.BASE_ABSOLUTE_URL + '/images/logo.png'} />
            <p className="font-medium text-center f_white">クラフトマンハウスは、職人と親方を結ぶマッチングアプリです。</p>
            <div className="button-container mt_30">
              <input type="text" name="em_name" className="input-txt l-center large  mt_20" value={this.state.em_name} onChange={this.onHandleChange} onBlur={this.onHandleBlur} onKeyUp={this.onKeyPress} />
              {this.validator.message('em_name', this.state.em_name, 'required')}
              <input type="password" name="em_passwd" className="input-txt l-center large  mt_20" value={this.state.em_passwd} onChange={this.onHandleChange} onBlur={this.onHandleBlur} onKeyUp={this.onKeyPress} />
              {this.validator.message('password', this.state.em_passwd, 'required')}
              <div className="fs14 layout_center mt_20 f_white">
                パスワードをお忘れですか？<Link to={"/resetpassword"}><span className="pointer f-meiryo-bold f_white underline">パスワードの再設定</span></Link>
              </div>
              <div className="fs14 layout_center mt_20 f_white">
                IDをお忘れですか？<Link to={"/resetuserid"}><span className="pointer f-meiryo-bold f_white underline">IDを送る</span></Link>
              </div>
              <button className="grey-button large mt_20" onClick={this.onLogIn}>
                <div className="inner">
                  ログイン
                  <i className="fa fa-angle-right"></i>
                </div>
              </button>
              <div className="grey-button yellow large mt_20">
                <Link to="/create">
                  <div className="inner">
                    登録する
                    <i className="fa fa-angle-right"></i>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <Footer isFixed = {isFixed}/>
        </div>
      </>
    );
  }
}
export default connect(mapStateToProps, mapDispatcherToProps)(LogIn);