import React, { Component } from 'react';
import '../../scss/recommend.scss';
import Header from '../../Include/Header'
import PRecomCom from './PRecommendCom';
import PRecommendLinkCom from '../../Include/PRecommendLinkCom'
import {
    Link,
 } from 'react-router-dom';
import Footer from '../../Include/Footer';
import * as URLS from '../../../modules/urls';
import { IRootState } from '../../../modules';
import { connect } from 'react-redux';
import Advertise from '../../Include/Advertise';
import { FooterActions } from '../../../modules/footer';
import { Dispatch } from 'redux';
import * as footerAsyncactions from '../../../modules/footer/async-actions';


const mapStateToProps = ({ message }: IRootState ) => {
    const { count } = message;
    return { count };
}
const mapDispatcherToProps = (dispatch: Dispatch<FooterActions>) => {
    return {
        isRerender:( _isRerender: boolean ) => footerAsyncactions.isRerender( dispatch, _isRerender ),             
    }
}
type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;


class PRecommend extends Component<ReduxType>{
    // componentDidUpdate = (prevProps:ReduxType) => {
    //     if( this.props.count > 0 ) {
    //         this.props.isRerender( true );
    //     }
    // }
    render(){
console.log( 'PRecommend===');        
if( this.props.count > 0 ) {
    this.props.isRerender( true );
}

        return(
            <div>
                <Header/>
                <div className="content-container" id = "content">
                    <div className="wrap-content">
                        {
                                    this.props.count > 0? 
                                    <div className="message-notify">
                                        <div className="message-notify-inner">
                                            新しい<Link to = {"/messagelist" } className="underline dark_red">メッセージ</Link>が届いています
                                        </div>
                                    </div>
                                    :<></>
                        }                        
                        <div className="recommend">
                            <PRecomCom/>
                            <div className="grey-button yellow v-large rb mt_40 mb_30">
                                <Link to= "/provider/search">
                                    <div className="inner">
                                        <i className="fa fa-search"></i>								
                                        仕事・親方/会社を探す
                                    </div>
                                </Link>
                            </div>
                        </div>    
                        <PRecommendLinkCom/>
                        <Advertise/>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}
export default connect(mapStateToProps, mapDispatcherToProps)(PRecommend);