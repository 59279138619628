import React from 'react';
import '../scss/job.scss';
import '../scss/change_type.scss';
import Header from '../Include/Header'
import AccountDeliveryCom from './AccountDeliveryCom';
import Footer from '../Include/Footer';

class AccountDelivery extends React.Component<any>{
    public render(){
        const url = this.props.match.url;
        return (
            <div>
                <Header/>
                <div className="content-container" id = "content">
                    <div className="wrap-content">
                        <div className="main-title">アカウントデータ引継ぎ</div>					
                        <div className="job_container mt_40">
                            <AccountDeliveryCom/>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}
export default AccountDelivery;
