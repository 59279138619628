import React, { Component } from "react";
import "../../scss/job.scss";
import "../../scss/search.scss";
import { Link, RouteComponentProps } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import * as Global from "../../../modules/global";
import * as URLS from "../../../modules/urls";
import Loading from "../../Include/Loading";
import "react-datepicker/dist/react-datepicker.css";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { IRootState } from "../../../modules";
import { compose } from "redux";
import { SearchClient, SearchClientState } from "../../../modules/searchclient";
import { SearchArea } from "../../../modules/searchprovider";
import moment from "moment";

const mapStateToProps = ({ searchClient }: IRootState) => {
  return { searchClient };
};

type ReduxType = ReturnType<typeof mapStateToProps> & RouteComponentProps;

class PSearchResultCom extends Component<ReduxType, any> {
  mounted: boolean = false;
  validator: SimpleReactValidator;
  state = {
    loading: false,
    items: [],
  };
  constructor(props: any) {
    super(props);
    this.validator = new SimpleReactValidator({
      messages: Global.VALIDATION_MESSAGES,
      autoForceUpdate: this,
      className: "error",
      validators: Global.VALIDATORS,
    });
  }
  componentDidMount() {
    this.mounted = true;
    let em_id: any = Global.getEmId();
    if (em_id === "") {
      return;
    }
    const states: SearchClientState = this.props.searchClient;
    // const params: string = this.props.params?this.props.params:'';
    if (states) {
      const params: string = this.getParams(em_id, states);
      this.search(params);
    }
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  getParams = (em_id: string, states: SearchClient) => {
    let params = "&jb_finished=0";
    if (states.jb_keyword !== "") {
      params += "&jb_keyword=" + states.jb_keyword;
    }
    // if (states.st_id !== "0") {
    //     params += "&st_id=" + states.st_id;
    // }
    // let ar_id_s: Array<string> = [];
    // if (states.ar_id_1 !== '' && states.ar_id_1 !== "0") {
    //     ar_id_s.push(states.ar_id_1);
    // }
    // if (states.ar_id_2 !== '' && states.ar_id_2 !== "0") {
    //     ar_id_s.push(states.ar_id_2);
    // }
    // if (states.ar_id_3 !== '' && states.ar_id_3 !== "0") {
    //     ar_id_s.push(states.ar_id_3);
    // }
    // if (ar_id_s.length > 0) {
    //     params += "&ar_id_s=" + ar_id_s.join(",");
    // }
    let regions = [];
    for (let i = 0; i < states.searchAreas.length; i++) {
      const searchArea: SearchArea = states.searchAreas[i];
      regions.push({
        st_id: searchArea.st_id === "" ? null : searchArea.st_id,
        ar_ids: [
          searchArea.ar_id_1 === "" ? null : searchArea.ar_id_1,
          searchArea.ar_id_2 === "" ? null : searchArea.ar_id_2,
          searchArea.ar_id_3 === "" ? null : searchArea.ar_id_3,
        ],
      });
    }
    params += "&regions=" + JSON.stringify(regions);
    if (states.sk_s.length) {
      params += "&sk_s=" + Global.ArrayToString(states.sk_s);
    }
    if (states.em_daily_price_low !== "") {
      params += "&jb_daily_price_low=" + states.em_daily_price_low;
    }
    if (states.em_daily_price_hi !== "") {
      params += "&jb_daily_price_hi=" + states.em_daily_price_hi;
    }
    if (parseInt(states.jb_total) > 0) {
      params += "&jb_total=" + states.jb_total;
    }
    let sta_em_id: any = Global.getEmId();
    params += "&sta_em_id=" + sta_em_id;
    params += "&em_id_not=" + em_id;
    params +=
      '&sort=[{"property":"jb_created","direction":"desc"},{"property":"jb_id","direction":"desc"}]';
    if (states.is_date_search === "1") {
      if (states.jb_date != null) {
        const dateObj = Global.dateFormat(states.jb_date, "%m/%d/%Y", false);
        const jb_date = dateObj;
        params += "&jb_date=" + jb_date;
        if (states.jb_time !== "0") {
          const value = parseInt(states.jb_time);
          const free_element = Global.DATE_DEVIDE_OPTIONS.filter(
            (item) => item.grp_ft_id === value
          );
          if (free_element && free_element.length > 0) {
            params += "&ft_id_s=" + free_element[0].grp_ft_value;
          }
        }
      }
    } else {
      if (states.jb_open_date != null) {
        const dateObj = Global.dateFormat(
          states.jb_open_date,
          "%m/%d/%Y",
          false
        );
        const jb_open_date = dateObj;
        params += "&jb_open_date=" + jb_open_date;
      }
      if (states.jb_close_date != null) {
        const dateObj = Global.dateFormat(
          states.jb_close_date,
          "%m/%d/%Y",
          false
        );
        const jb_close_date = dateObj;
        params += "&jb_close_date=" + jb_close_date;
      }
    }
    return params;
  };
  search = (params: string) => {
    let em_id: any = Global.getEmId();
    if (em_id === "") {
      return;
    }
    const that = this;
    this.setState({
      loading: true,
    });
    let url = URLS.SEARCH_JOB_PERIOD;
    url = url + params;
    fetch(url, {
      method: "get",
    })
      .then(async function (response) {
        if (!that.mounted) {
          return;
        }
        let ret = await response.json();
        if (ret.total > 0) {
          const items = ret.items;
          that.setState({
            loading: false,
            is_search_result: true,
            items: items,
          });
        } else {
          that.setState({
            loading: false,
            is_search_result: true,
            items: [],
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          items: [],
          errorMessage: error.toString(),
        });
      });
  };
  goBack = () => {
    this.props.history.goBack();
  };
  public render() {
    return (
      <div>
        {this.state.loading ? (
          <Loading />
        ) : (
          <>
            <div className='underline' onClick={this.goBack}>
              <span className='fs14' style={{ cursor: "pointer" }}>
                &lt;検索条件に戻る
              </span>
            </div>
            <div className='job_inner recommend-inner'>
              {this.state.items.length === 0 ? (
                <span className='no_content mt_40'>
                  条件に一致する仕事がありません。
                </span>
              ) : (
                <></>
              )}
              {this.state.items.map((item) => (
                <div className='element flex' key={item["jb_id"]}>
                  <Link
                    to={
                      "/jobdetail/" +
                      item["jb_id"] +
                      (item["cnt_apply"] == "1" ? "/1" : "/0")
                    }
                  >
                    <span className={"job_title"}>
                      <span>{item["jb_comp_name"]}</span>
                    </span>
                    <div className='fs14'>
                      {item["em_is_legal"] == "0"
                        ? item["em_company"]
                        : item["em_company_legal"]}
                    </div>
                    <div className='fs14 grey_color'>募集日時</div>
                    <div className='fs14 '>
                      {(item["jb_open_date"]
                        ? moment(item["jb_open_date"], "MM/DD/YYYY").format(
                            "YYYY/MM/DD"
                          )
                        : "") +
                      `～` +
                      (item["jb_close_date"]
                        ? moment(item["jb_close_date"], "MM/DD/YYYY").format(
                            "YYYY/MM/DD"
                          )
                        : "") +
                      ` ` +
                      (item["jb_time_from"] ? item["jb_time_from"] : "") +
                      ((item["jb_time_from"] || item["jb_time_to"])
                        ? `～` 
                        : `` ) + (item["jb_time_to"] ? item["jb_time_to"] : "")}
                    </div>
                  </Link>
                  <div className='btn_area'>
                    <div className='grey-button vv-small mr_10'>
                      <Link
                        to={
                          "/pjobdetail/" +
                          item["jb_id"] +
                          "/" +
                          (item["em_is_legal"] === "0"
                            ? item["em_company"]
                            : item["em_company_legal"]) +
                          "/" +
                          (item["em_depart"] != "" ? item["em_depart"] : " ") +
                          "/" +
                          item["em_photo_s"]
                        }
                      >
                        <button className='grey-button v-small'>
                          <div className='inner  f-meiryo-bold'>詳細</div>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    );
  }
}
export default compose(withRouter, connect(mapStateToProps))(PSearchResultCom);

//export default withRouter(PSearchResultCom);
