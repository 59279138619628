import React from 'react';
import '../scss/job.scss';
//import '../scss/profile.scss';
import '../scss/change_type.scss';
import {Link} from 'react-router-dom';

class AccountDeliveryCom extends React.Component<any>{
    public render(){
        return(
        <div className="change_type space">
            <div className="change_type_inner">
                <div>
                    <div className="fs16">
                        メールアドレスとパスワードを設定して、テキストテキストテキステキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                    </div>
                </div>
            </div>
            <div className="button-container">
                <input type = "input" className="input-txt border l-center large  mt_50" />
                <input type = "input" className="input-txt border l-center large  mt_20" />					
                <button className="grey-button large mt_30">
                    <div className="inner">
                    データ引継ぎ設定
                    <i className="fa fa-angle-right"></i>
                    </div>
                </button>										
            </div>								
        </div>
        );
    }
}
export default AccountDeliveryCom;