import React, { FormEvent, Component } from 'react';
import '../scss/choose.scss';
import { Redirect } from 'react-router-dom';
import Footer from '../Include/Footer';
import { IRootState } from '../../modules';
import { Dispatch } from 'redux';
import { UserInfo, UserInfoActions } from '../../modules/userinfo';
import * as asyncactions from '../../modules/userinfo/async-actions';
import * as URLS from '../../modules/urls';
import Loading from '../Include/Loading';
import { connect } from 'react-redux';
import * as Global from '../../modules/global';
import * as messageAsyncactions from '../../modules/chat/async-actions';
import * as userAsyncactions from '../../modules/userinfo/async-actions';
import { ChatActions } from '../../modules/chat';

interface ScheduleState {
    isProfileCreated?: boolean,
    redirect?: boolean,
    loading: boolean,
    height?: number,
}
const mapStateToProps = ({ user }: IRootState) => {
    const { userInfo } = user;
    return { userInfo };
}
const mapDispatcherToProps = (dispatch: Dispatch<UserInfoActions|ChatActions>) => {
    return {
        getProfile: (role: string, userInfo: UserInfo) => asyncactions.getProfileAsync(dispatch, role, userInfo),
        initialize: () => messageAsyncactions.initializeAsync(dispatch),
        removeListener: () => userAsyncactions.removeRoleListenerAysnc(dispatch),
    }
}
type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;

class Choose extends Component<ReduxType, ScheduleState> {
    myRef: any;
    state = {
        redirect: false,
        isProfileCreated: false,
        loading: false,
        height: 0,
    }
    constructor(props: any) {
        super(props);
        this.myRef = React.createRef();
    }
    componentDidMount() {
        this.props.initialize();
        this.props.removeListener();
        let clientHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        const currentHeight = this.myRef.current.clientHeight;
        if (clientHeight - currentHeight > 36) {// 36 is footer height
            this.setState({
                height: (clientHeight - 36),
            });
        }
        const emtId = Global.getEmtId();
        if (emtId === '3') {
            return;
        }
        const defaultRole = Global.getDefaultRole();
        if (defaultRole !== '' && (defaultRole === '1' || defaultRole === '2')) {
            if (defaultRole === '1')
                this.getProfile("1", "boss");
            else
                this.getProfile("2", "worker");
        } else {
            if (emtId === '2') {
                this.getProfile("2", "worker");
            }
            else {
                this.getProfile("1", "boss");
            }
        }
    }
    onSubmit = (e: FormEvent) => {
        e.preventDefault();
    };
    getProfile = (role: string, keyword: string) => {
        let em_id: string | null = Global.getEmId();

        if (!em_id || em_id === "") {
            return;
        }
        this.setState({
            loading: true,
        })
        const URL: string = URLS.GET_PROFILE + "?em_id=" + em_id + "&keyword=" + keyword;
        const that = this;
        fetch(URL, {
            method: 'GET',
        }).then(async function (response) {
            const ret = await response.json();
            let em_name = "";
            let em_role = "";
            let star = '0';
            let em_company = "";
            if (keyword === "worker") {
                em_name = (ret.emp.em_lastname ? ret.emp.em_lastname : '') + (ret.emp.em_firstname ? ret.emp.em_firstname : '');
                em_role = "職人";
                star = ret.emp.ctrc_value_w ? ret.emp.ctrc_value_w : '0';
                em_company = (ret.emp.em_is_individual != null) ? (ret.emp.em_is_individual ==='1' ? "個人事業主" : ret.emp.em_company) : '';
            } else {
                em_name = ret.emp.em_is_legal !== undefined ? (ret.emp.em_is_legal === '0' ? ret.emp.em_company : ret.emp.em_company_legal) : '';
                em_role = "親方/会社";
                star = ret.emp.ctrc_value_b ? ret.emp.ctrc_value_b : '0';
                em_company = ret.emp.em_depart ? ret.emp.em_depart : '';
            }
            if( role === '1' ) {
                Global.setEmBossLoginDate(ret.emp.em_ever_boss_date);
            }else{
                Global.setEmWorkerLoginDate(ret.emp.em_ever_worker_date);
            }
            Global.setDefaultRole(role);
            const userInfo: UserInfo = {
                id: ret.emp.em_id,
                role: role,
                em_name: em_name,
                em_role: em_role,
                em_company: em_company,
                point: ret.emp.em_point,
                star: star,
                em_worker_name: (ret.emp.em_lastname ? ret.emp.em_lastname : '') + (ret.emp.em_firstname ? ret.emp.em_firstname : ''),
                em_worker_company: (ret.emp.em_is_individual != null) ? (ret.emp.em_is_individual === 1 ? "個人事業主" : ret.emp.em_company) : '',
                em_boss_company: ret.emp.em_is_legal === 0 ? ret.emp.em_company : ret.emp.em_company_legal,
                em_depart: ret.emp.em_depart,
                em_system: ret.em_system,
                sk_s: ret.emp.sk_s,
            }
            that.props.getProfile(role, userInfo);
            let isProfileCreated = false;
            // if( em_name != "" && em_company != ""){
            if (em_name !== "") {
                isProfileCreated = true;
            }
            that.setState({
                isProfileCreated: isProfileCreated,
                redirect: true,
                loading: false,
            });

        }).catch(error => {
            that.setState({
                loading: false,
            })
        });
    }
    onFreelancer = () => {
        this.getProfile("2", "worker");
    };
    onClient = () => {
        this.getProfile("1", "boss");
    }
    renderRedirect = () => {
        if (this.state.redirect) {
            let role = this.props.userInfo.role ? this.props.userInfo.role : '';
            let url = '';
            if (role === '1') {
                if (!this.state.isProfileCreated) {
                    url = '/editprofile';
                } else {
                    url = '/findpeople';
                }
                return <Redirect to={url} />
            } else if (role === '2') {
                if (!this.state.isProfileCreated) {
                    url = '/provider/editprofile';
                } else {
                    url = '/findjob';
                }
                return <Redirect to={url} />
            } else {
                url = "/";
                return <Redirect to={url} />
            }
        } else {
            let em_id = Global.getEmId();
            if (!em_id || em_id === '') {
                let url = "/";
                return <Redirect to={url} />
            }
            return <></>
        }
    }
    public render() {
        const emtId = Global.getEmtId();
        return (
            <div className="splash_container">
                {this.renderRedirect()}
                {   this.state.loading ?
                    <Loading color="white"/>
                    : <></>
                }
                <form onSubmit={this.onSubmit}>
                    <div className="choose-inner" ref={this.myRef} style={{ height: this.state.height !== 0 ? this.state.height : 'auto' }}>
                        <img alt='' className="b-logo" src={URLS.BASE_ABSOLUTE_URL + '/images/logo.png'} />
                        <div className="button-container">
                            {
                                emtId === '1' || emtId === '3' ?
                                    <button type="button" className="grey-button large mt_70" onClick={this.onClient}>
                                        <div className="inner">
                                            親方/会社でログイン
                                    <i className="fa fa-angle-right"></i>
                                        </div>
                                    </button>
                                    : <></>
                            }
                            {
                                emtId === '2' || emtId === '3' ?
                                    <button type="button" className="grey-button large mt_20" onClick={this.onFreelancer}>
                                        <div className="inner">
                                            職人でログイン
                                    <i className="fa fa-angle-right"></i>
                                        </div>
                                    </button>
                                    : <></>
                            }
                        </div>
                    </div>
                </form>
                <Footer />
            </div>
        );
    }
}
export default connect(mapStateToProps, mapDispatcherToProps)(Choose);