import React, {Component} from 'react';
import '../scss/job.scss';
import '../scss/editprofile.scss';
import { VALIDATORS, VALIDATION_MESSAGES } from '../../modules/global';
import SimpleReactValidator from 'simple-react-validator';
import * as URLS from '../../modules/urls';
import {Redirect} from 'react-router-dom';
import {AlertDialog} from '../Modal/Alert';
import Footer from '../Include/Footer';
import Loading from '../Include/Loading';

export default class SetPassword extends React.Component<any>{
    validator:SimpleReactValidator;
    alertRef:any;    
    state = {
        loading:false,
        errorMessage:'',
        em_passwd:'',
        confirm_em_passwd:'',
        is_passwordChanged:false,
    }
    constructor(props:any){
        super( props );
        this.validator = new SimpleReactValidator({
            messages: VALIDATION_MESSAGES,
            autoForceUpdate: this,
            className:'error error_center',
            validators:VALIDATORS
        });
        this.alertRef = React.createRef();
    }
    onHandleChange = ( e:any ) =>{
        let value:any = e.target.value;
        let name:string = e.target.name;
        this.setState( { [name]:value} );
    }
    onHandleBlur = ( e:any ) =>{
        let name:string = e.target.name;
        this.validator.showMessageFor( name );
    }
    onClickHandler = ( e:any ) => {
        if (this.validator.allValid()){
            if( this.state.em_passwd != this.state.confirm_em_passwd ){
                this.alertRef.current.open( "エラー", "パスワードと確認パスワードが違います。" );     
            }else{
                this.setPassword();
            }
        }else{
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    }
    setPassword = () => {
        this.setState({
            loading:true,
        });
        let em_auth = this.props.location.state && this.props.location.state.em_auth?this.props.location.state.em_auth:"";                
        let em_phone = this.props.location.state && this.props.location.state.em_phone?this.props.location.state.em_phone:"";
        if( em_auth == '' || em_phone == '' ){
            return;
        }        
        const data = {
            em_passwd:this.state.em_passwd,
            em_phone:em_phone,
            em_auth:em_auth,
        }
        const that = this;

        fetch( URLS.SET_PASSWORD, {
            method: 'post',
            body: JSON.stringify( data ),
        }).then(async function(response){
            let ret = await response.json();
            if( ret.success ){
                that.alertRef.current.open( "変更完了", "変更が完了いたしました。ログインのうえご利用ください。", true );
                that.setState({
                    loading:false,
                    is_passwordChanged:true,
                })
            }else{
                that.alertRef.current.open( 'エラー', '変更が完了していません。');
                that.setState({
                    loading:false,
                });
            }
            //return response.json();
        }).catch(error => {
            that.alertRef.current.open( 'エラー', '変更が完了していません。');
            that.setState({
                loading:false,
            });
        });
    }
    renderRedirect = () => {
        let em_auth = this.props.location.state && this.props.location.state.em_auth?this.props.location.state.em_auth:"";                
        let em_phone = this.props.location.state && this.props.location.state.em_phone?this.props.location.state.em_phone:"";
        if( em_auth == '' || em_phone == '' ){
            let url = '/';
            return <Redirect to = {url}/>
        }
        return <></>
    }
    public render(){
        return(
            <div>
                {
                    this.state.loading?
                    <Loading/>
                    :<></>
                }
                <div className="content-container" id = "content">
                    <div className="wrap-content">
                        <div className="main-title">パスワードの再設定</div>
                        <div className="job_container mt_40">
                            <div className ="job edit_profile create_profile">
                                <AlertDialog ref = {this.alertRef} returnURL = "/"/>            
                                {this.renderRedirect()}
                                <div className="edit_profile_inner">
                                    <div className="element">
                                        <div className="second_column">
                                            <div className="wd_100 mt_10 mr_40 text_right"><span className="tag-mg fs12"><span className="tag-inner">パスワード</span></span></div>
                                            <div className="wd_450 mr_5 inline_block">
                                                <input className = "input-txt border full_wide" type = "password" name = "em_passwd" value = {this.state.em_passwd} placeholder="パスワードを入力してください"  onChange={this.onHandleChange} onBlur={this.onHandleBlur} />
                                                <span className = "fs12">パスワードは8文字以上必要です。</span>
                                                {this.validator.message('password', this.state.em_passwd, 'required|min:8,string' )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="element">
                                        <div className="second_column">
                                            <div className="wd_100 mt_10 mr_40 text_right"><span className="tag-mg fs12"><span className="tag-inner">パスワードを再入力</span></span></div>
                                            <div className="wd_450 mr_5 inline_block">
                                                <input className = "input-txt border full_wide" type = "password" name = "confirm_em_passwd" value = {this.state.confirm_em_passwd} placeholder="パスワードを再入力してください"  onChange={this.onHandleChange} onBlur={this.onHandleBlur} />
                                                <span className = "fs12">パスワードは8文字以上必要です。</span>
                                                {this.validator.message('password', this.state.confirm_em_passwd, 'required|min:8,string' )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="button-container">
                                            <div className="grey-button large mt_50">
                                                <div className="inner" onClick = {this.onClickHandler}>
                                                変更する
                                                <i className="fa fa-angle-right"></i>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>                            
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );            
    }
}