import React, { Component } from "react";
import "../scss/job.scss";
import "../scss/recommend.scss";
import { Link } from "react-router-dom";
import * as URLS from "../../modules/urls";
import Loading from "../Include/Loading";
import * as footerAsyncactions from "../../modules/footer/async-actions";
import { ChatActions } from "../../modules/chat/types";
import { IRootState } from "../../modules";
import { FooterActions } from "../../modules/footer";
import { Dispatch } from "redux";
import { connect } from "react-redux";

type JobApplicantComProps = {
  jobId: number;
  jobName: string;
};

const mapDispatcherToProps = (dispatch: Dispatch<FooterActions>) => {
  return {
    isRerender: (_isRerender: boolean) =>
      footerAsyncactions.isRerender(dispatch, _isRerender),
  };
};
type ReduxType = ReturnType<typeof mapDispatcherToProps> & JobApplicantComProps;

class JobApplicantCom extends Component<ReduxType> {
  state = {
    loading: false,
    items: [],
  };
  componentDidMount() {
    const that = this;
    const jb_id = this.props.jobId;
    if (!jb_id) {
      return;
    }
    this.setState({
      loading: true,
    });
    fetch(URLS.GET_APPLICANT_LIST + "&jb_id=" + jb_id, {
      method: "get",
    })
      .then(async function (response) {
        let ret = await response.json();
        if (ret.total > 0) {
          const items = ret.items;
          that.setState(
            {
              loading: false,
              items: items,
            },
            async () => {
              await that.props.isRerender(true);
            }
          );
        } else {
          that.setState(
            {
              loading: false,
              items: [],
            },
            async () => {
              await that.props.isRerender(true);
            }
          );
        }
      })
      .catch((error) => {
        this.setState(
          { loading: false, items: [], errorMessage: error.toString() },
          async () => {
            await that.props.isRerender(true);
          }
        );
      });
  }
  renderSkill(skillStr: string) {
    const skills = skillStr.split(",");
    let ret = [];
    for (let i = 0; i < skills.length; i++) {
      ret.push(
        <span className="tag-mg fs14" key={i}>
          <span className="tag-inner">{skills[i]}</span>
        </span>
      );
    }
    return ret;
  }
  render() {
    return (
      <div className="recommend-inner">
        {this.state.loading ? <Loading /> : <></>}
        {this.state.items.length == 0 ? (
          <span className="no_content">応募者がありません。</span>
        ) : (
          <div className="title">
            <span className="mark_choosed_job_notify green right_show inline_block ml_20">直接依頼</span>  
            <span className="mark_choosed_job_notify right_show">
              職人決定済み
            </span>
          </div>
        )}
        {this.state.items.map((item) => (
          <div className="element flex" key={item["em_id"]}>
            <div className="first">
              <img src={URLS.IMAGE_URL + item["em_photo_s"]} />
            </div>
            <div className="second">
              <div className="flex-start">
                <span className="fs14">
                  {item["em_is_individual"] == "1"
                    ? "個人事業主"
                    : item["em_company"]}
                </span>
                {item["ctr_id"] != null ? (
                  <span className="relative mark_choosed_job ml_10"></span>
                ) : (
                  <></>
                )}
                {parseInt(item["jba_is_direct"]) > 0 ? (
                  <span className="relative direct_job ml_10"></span>
                ) : (
                  <></>
                )}
              </div>
              <div className="fs20">
                {item["em_lastname"] + item["em_firstname"]}
              </div>
            </div>
            <div className="third">
              <div className="fs14 map-icon">{item["st_label"]}</div>
              <div>{this.renderSkill(item["sk_label_s"])}</div>
            </div>
            <div className="btn-container">
              <div className="grey-button v-small">
                {/* <Link to = {"/provider/profile/" + item['em_id'] +"/" + this.props.jobId + "/" + item['jba_id']}> */}
                <Link
                  to={{
                    pathname: "/provider/profile/" + item["em_id"],
                    state: {
                      jobName: item["jb_comp_name"],
                      jobId: item["jb_id"],
                      jbaId: item["jba_id"],
                      ctrId: item["ctr_id"],
                    },
                  }}
                >
                  <div className="inner f-meiryo-bold">詳細</div>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
export default connect(null, mapDispatcherToProps)(JobApplicantCom);
