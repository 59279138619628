import { ActionType } from "typesafe-actions";
import * as actions from './actions';

export type SearchProvider = {
    em_skill_keyword: string,
    is_date_search: string,
    ef_date_from: Date|null,
    ef_date_to: Date|null,
    ef_datetime_between_date: Date|null,
    jb_time: string,
    ef_datetime_between_to_time: string,
    sk_s: Array<string>,
    searchAreas:Array<SearchArea>,
    nationality_s: Array<string>,
    em_daily_price_low: string,
    em_daily_price_hi: string,
}
export type SearchArea = {
    st_id: string,
    ar_id_1: string,
    ar_id_2: string,
    ar_id_3: string,
}
export type SearchProviderState = SearchProvider & {
    loading: boolean,
}
export enum actionTypes {
    SAVE = "searchprovider/save",
}
export type SearchProviderActions = ActionType<typeof actions>;