import React, { Component } from 'react';
import '../scss/point.scss';
import * as Global from '../../modules/global';
// import { IRootState } from '../../modules';
// import { Dispatch } from 'redux';
// import {UserInfoActions } from '../../modules/userinfo';
// import * as asyncactions from '../../modules/userinfo/async-actions';
// import * as URLS from '../../modules/urls';
// import Loading from '../Include/Loading';
// import { connect } from 'react-redux';

// const mapStateToProps = ({ user }: IRootState ) => {
//     const { userInfo } = user;
//     return { userInfo };
// }
// const mapDispatcherToProps = (dispatch: Dispatch<UserInfoActions>) => {
//     return {
//         changePoint:( point:string ) => asyncactions.changePointAsync( dispatch, point ),
//     }
// }
// type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;

class PointHistoryCom extends Component<any>{
    render(){
        return(
            <div className="point-history mt_100">
                {/* {this.state.loading?
                    <Loading/>
                    :<></>
                } */}
                <div className="point-history-inner">
                    <span className="title">ポイント履歴</span>
                    {
                    this.props.items.length == 0?<span className="no_content">購入履歴がありません。</span>:<></>
                    }
                    {
                        this.props.items.map( item =>(
                            <div className="element" key = {item['ep_id']}>
                                <span>{Global.convertDateToJapan(item['ep_created'])}</span>
                                <span>{item['ep_note']}</span>
                            </div>
                        ))
                    }
                </div>
            </div>
        );        
    }
}
//export default connect(mapStateToProps,mapDispatcherToProps )(PointHistoryCom);
export default PointHistoryCom;