import React, { Component } from "react";
import "../../scss/job.scss";
import "../../scss/editprofile.scss";
import {
  VALIDATORS,
  VALIDATION_MESSAGES,
  YEAR,
  MONTH,
  DAY,
  DateToString,
  getSkills,
} from "../../../modules/global";
import SimpleReactValidator from "simple-react-validator";
import * as URLS from "../../../modules/urls";
import { BasicDropDown } from "../../Include/dropdown/BasicDropDown";
import BasicCheckBox from "../../Include/checkbox/BasicCheckBox";
import { BasicRadioBox } from "../../Include/radiobox/BasicRadioBox";
import { AlertDialog } from "../../Modal/Alert";
import * as Global from "../../../modules/global";
import { Redirect } from "react-router-dom";
import { IRootState } from "../../../modules";
import { Dispatch } from "redux";
import {
  UserInfo,
  UserInfoActions,
  FireBaseUserInfo,
} from "../../../modules/userinfo";
import * as asyncactions from "../../../modules/userinfo/async-actions";
import { connect } from "react-redux";
import DraggableDialog from "../../Modal/Dialog";
import Loading from "../../Include/Loading";
import { Link } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ja from "date-fns/locale/ja";
import * as FireBaseUser from "../../../firebase/api/users";
import moment from "moment";

const mapStateToProps = ({ user }: IRootState) => {
  const { userInfo } = user;
  return { userInfo };
};
const mapDispatcherToProps = (dispatch: Dispatch<UserInfoActions>) => {
  return {
    getProfile: (role: string, userInfo: UserInfo) =>
      asyncactions.getProfileAsync(dispatch, role, userInfo),
  };
};
type ReduxType = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatcherToProps>;
type EmployeeType = {
  emst_firstname: string;
  emst_lastname: string;
  emst_firstname_kana: string;
  emst_lastname_kana: string;
  emst_birthday: string;
  rj_em_birthday: Date;
  sex_id: string;
  sk_s: any;
  sk_years_s: any;
  sp_s: string;
  hi_id: string;
  em_hi_digit: string;
  pi_id: string;
  ei_id: string;
  em_ei_digit: string;
  em_family_name: string;
  em_family_relation: string;
  em_family_phone: string;
  em_recent_health: string;
  em_blood_presure_hi: string;
  em_blood_presure_low: string;
  blt_id: string;
  em_special_health: string;
  sh_s: string;
  em_sh_note: string;
  em_qualify_country: string;
  em_qualify_lecture: string;
  em_qualify_lecture_special: string;
};
type ProfileType = {
  open: boolean;
  isMore: boolean;
  is_success: number;
  em_photo: string;
  em_photo_s: string;
  em_id: string;
  em_firstname: string;
  em_lastname: string;
  em_firstname_kana: string;
  em_lastname_kana: string;
  em_birthday: string;
  year: string;
  month: string;
  rj_em_birthday: Date;
  sex_id: string;
  em_is_individual: string;
  em_company: string;
  sk_s: Array<string>;
  business1: string;
  business2: string;
  business3: string;
  sk_years_s: Array<string>;
  sk_years_1: string;
  sk_years_2: string;
  sk_years_3: string;
  em_skill_keyword: string;
  em_daily_price: string;
  em_post: string;
  st_id: string;
  ar_id: string;
  em_street_address: string;
  em_is_foreign: string;
  em_overview: string;
  keyword: string;

  sp_s: string;
  hi_id: string;
  em_hi_digit: string;
  pi_id: string;
  ei_id: string;
  em_ei_digit: string;
  em_family_name: string;
  em_family_relation: string;
  em_family_phone: string;
  // em_recent_health_year: string,
  // em_recent_health_month: string,
  rj_em_recent_health: Date | null;
  em_recent_health: string;
  em_blood_presure_hi: string;
  em_blood_presure_low: string;
  blt_id: string;
  // em_special_health_year: string,
  // em_special_health_month: string,

  rj_em_special_health: Date | null;
  em_special_health: string;
  sh_s: string;
  em_sh_note: string;
  em_qualify_country: string;
  em_qualify_lecture: string;
  em_qualify_lecture_special: string;

  em_stuff: Array<EmployeeType>;
  employeeCount: number;
  loading: boolean;
  errorMessage: string;
  isOpenAddEmployee: boolean;
};
class PEditProfileCom extends Component<ReduxType, ProfileType> {
  validator: SimpleReactValidator;
  alertRef: any;
  areaRef: any;
  state = {
    open: false,
    isMore: false,
    isOpenAddEmployee: false,
    is_success: -1,
    em_photo: "",
    em_photo_s: "",
    em_id: "-1",
    em_firstname: "",
    em_lastname: "",
    em_firstname_kana: "",
    em_lastname_kana: "",
    em_birthday: "",
    year: "",
    month: "",
    rj_em_birthday: new Date(1980, 1, 1, 0, 0, 0, 0),
    sex_id: "1",
    em_is_individual: "0",
    em_company: "",
    sk_s: [],
    business1: "",
    business2: "",
    business3: "",
    sk_years_s: [],
    sk_years_1: "",
    sk_years_2: "",
    sk_years_3: "",
    em_skill_keyword: "",
    em_daily_price: "",
    em_post: "",
    st_id: "0",
    ar_id: "0",
    em_street_address: "",
    em_is_foreign: "0",
    em_overview: "",
    keyword: "",
    em_stuff: [],
    employeeCount: 0,
    loading: false,
    errorMessage: "",

    sp_s: "",
    hi_id: "",
    em_hi_digit: "",
    pi_id: "",
    ei_id: "",
    em_ei_digit: "",
    em_family_name: "",
    em_family_relation: "",
    em_family_phone: "",
    em_recent_health: "",
    rj_em_recent_health: new Date(),
    //em_recent_health_month: '',
    em_blood_presure_hi: "",
    em_blood_presure_low: "",
    blt_id: "",
    em_special_health: "",
    rj_em_special_health: new Date(),
    // em_special_health_year: '',
    // em_special_health_month: '',
    sh_s: "",
    em_sh_note: "",
    em_qualify_country: "",
    em_qualify_lecture: "",
    em_qualify_lecture_special: "",
  };
  constructor(props: any) {
    super(props);
    registerLocale("ja", ja);
    this.validator = new SimpleReactValidator({
      messages: VALIDATION_MESSAGES,
      autoForceUpdate: this,
      className: "error",
      validators: VALIDATORS,
    });
    this.alertRef = React.createRef();
    this.areaRef = React.createRef();
  }
  onArrayHandleChange = (e: any) => {
    let value: string = e.target.value;
    let name: string = e.target.name;
    let nameList: String[] = name.split("_");
    if (nameList.length > 0) {
      const lastChr: string = nameList[nameList.length - 1].toString();
      if (Number.isInteger(parseInt(lastChr))) {
        let em_stuff = this.state.em_stuff;
        nameList.splice(nameList.length - 1, 1);
        name = nameList.join("_");
        let employee: EmployeeType = em_stuff[parseInt(lastChr)];
        if (name.includes("sk_years")) {
          employee["sk_years_s"][name] = value;
        } else if (name.includes("sk")) {
          employee["sk_s"][name] = value;
          if (value === "0") {
            const index: string = name.split("_")[1];
            const year_name = "sk_years_" + index;
            employee["sk_years_s"][year_name] = "";
          }
        } else if (name === "sp_s" || name === "sh_s") {
          const target = e.target;
          const checked = target.type === "checkbox" ? target.checked : "";
          const sp_s: string = em_stuff[parseInt(lastChr)][name];
          let sp_list = sp_s.split(",");
          if (!checked) {
            const index = sp_list.indexOf(value);
            if (index !== -1) {
              sp_list.splice(index, 1);
            }
          } else {
            sp_list.splice(sp_list.length, 0, value);
          }
          if (sp_list[0].trim() === "") {
            sp_list.splice(0, 1);
          }
          employee[name] = sp_list.join();
        } else {
          employee[name] = value;
        }
        this.setState({
          em_stuff: [].concat(em_stuff),
        });
        //                employees[parseInt(lastChr)]
      }
    }
  };
  handleBirthDayArrayChange = (data: Date | null, index: number) => {
    if (!data) {
      return;
    }
    let em_stuff: any = this.state.em_stuff;
    const employee: EmployeeType = em_stuff[index];
    employee.rj_em_birthday = data!;
    this.setState({
      em_stuff: [].concat(em_stuff),
    });
  };
  onHandleChange = (e: any) => {
    let value: string = e.target.value;
    let name: string = e.target.name;
    //this.setState({ [name]: value });
    this.setState((prevState) => ({
      ...prevState,
      [name]: value, // No error here, but can't ensure that key is in StateKeys
    }));
    if (name === "st_id") {
      this.setState({
        ar_id: "",
      });
      if (this.areaRef) {
        this.areaRef.current.fetch(URLS.GET_AREA_URL + "&st_id=" + value);
      }
    } else if (name === "sp_s") {
      const target = e.target;
      const checked = target.type === "checkbox" ? target.checked : "";
      let sp_list = this.state.sp_s.split(",");
      if (!checked) {
        const index = sp_list.indexOf(value);
        if (index !== -1) {
          sp_list.splice(index, 1);
        }
      } else {
        sp_list.splice(sp_list.length, 0, value);
      }
      if (sp_list && sp_list.length && sp_list[0].trim() === "") {
        sp_list.splice(0, 1);
      }
      this.setState({
        sp_s: sp_list.join(),
      });
    } else if (name === "sh_s") {
      const target = e.target;
      const checked = target.type === "checkbox" ? target.checked : "";
      let sh_list = this.state.sh_s.split(",");

      if (!checked) {
        const index = sh_list.indexOf(value);
        if (index > -1) {
          sh_list.splice(index, 1);
        }
      } else {
        sh_list.splice(sh_list.length, 0, value);
      }
      if (sh_list && sh_list.length && sh_list[0].trim() === "") {
        sh_list.splice(0, 1);
      }
      this.setState({
        sh_s: sh_list.join(),
      });
    } else if (name === "business1") {
      if (value === "0") {
        this.setState({
          sk_years_1: "",
        });
      }
    } else if (name === "business2") {
      if (value === "0") {
        this.setState({
          sk_years_2: "",
        });
      }
    } else if (name === "business3") {
      if (value === "0") {
        this.setState({
          sk_years_3: "",
        });
      }
    }
  };
  onHandleBlur = (e: any) => {
    let name: string = e.target.name;
    this.validator.showMessageFor(name);
  };
  showMore = () => {
    this.setState({
      isMore: !this.state.isMore,
    });
  };
  handleBirthDayChange = (data: Date) => {
    this.setState({
      rj_em_birthday: data,
    });
  };
  handleRecentHealthChange = (data: Date) => {
    this.setState({
      rj_em_recent_health: data,
    });
  };
  handleSpecialHealthChange = (data: Date) => {
    this.setState({
      rj_em_special_health: data,
    });
  };
  componentDidMount() {
    let role = Global.getRole();
    if (role !== "2") {
      return;
    }
    let em_id: any = Global.getEmId();
    let keyword = "worker";
    let url =
      URLS.GET_PROFILE +
      "?em_id=" +
      em_id +
      "&keyword=" +
      keyword +
      "&inc_em_stuff=1";
    const that = this;
    this.setState({
      loading: true,
    });
    fetch(url, {
      method: "get",
    })
      .then(async function (response) {
        let ret = await response.json();
        let skills = getSkills(ret.emp.sk_s);
        let sk_years_s = getSkills(ret.emp.sk_years_s);
        const today = new Date();
        const currentMonth = today.getMonth();
        const currentDate = today.getDate();
        that.setState({
          em_photo: ret.emp.em_photo ? ret.emp.em_photo : "",
          em_photo_s: ret.emp.em_photo_s ? ret.emp.em_photo_s : "",
          em_firstname: ret.emp.em_firstname,
          em_lastname: ret.emp.em_lastname,
          em_firstname_kana: ret.emp.em_firstname_kana,
          em_lastname_kana: ret.emp.em_lastname_kana,
          em_birthday: ret.emp.em_birthday,
          rj_em_birthday: ret.emp.em_birthday
            ? Global.stringToDate(
                Global.DateToString(ret.emp.em_birthday, "/", "m/d/y", false)
              )
            : new Date(1980, currentMonth, currentDate, 0, 0, 0, 0),
          sex_id: ret.emp.sex_id,
          em_is_individual: ret.emp.em_is_individual,
          em_company: ret.emp.em_company,
          sk_s: [],
          business1: skills.length >= 1 ? skills[0] : "",
          business2: skills.length >= 2 ? skills[1] : "",
          business3: skills.length >= 3 ? skills[2] : "",
          sk_years_s: [],
          sk_years_1:
            sk_years_s.length >= 1
              ? sk_years_s[0] === "0"
                ? ""
                : sk_years_s[0]
              : "",
          sk_years_2:
            sk_years_s.length >= 2
              ? sk_years_s[1] === "0"
                ? ""
                : sk_years_s[1]
              : "",
          sk_years_3:
            sk_years_s.length >= 3
              ? sk_years_s[2] === "0"
                ? ""
                : sk_years_s[2]
              : "",
          em_skill_keyword: ret.emp.em_skill_keyword,
          em_daily_price: ret.emp.em_daily_price
            ? Global.numberWithCommas(
                parseInt(ret.emp.em_daily_price).toString()
              )
            : "",
          em_post: ret.emp.em_post,
          st_id: ret.emp.st_id == null ? "0" : ret.emp.st_id,
          ar_id: ret.emp.ar_id == null ? "0" : ret.emp.ar_id,
          em_street_address: ret.emp.em_street_address,
          em_is_foreign:
            ret.emp.em_is_foreign == null ? "0" : ret.emp.em_is_foreign,
          em_overview: ret.emp.em_overview,

          sp_s: ret.emp.sp_s,
          hi_id: ret.emp.hi_id == null ? "0" : ret.emp.hi_id,
          em_hi_digit: ret.emp.em_hi_digit,
          pi_id: ret.emp.pi_id == null ? "0" : ret.emp.pi_id,
          ei_id: ret.emp.ei_id == null ? "0" : ret.emp.ei_id,
          em_ei_digit: ret.emp.em_ei_digit,
          em_family_name: ret.emp.em_family_name,
          em_family_relation: ret.emp.em_family_relation,
          em_family_phone: ret.emp.em_family_phone,
          em_recent_health: ret.emp.em_recent_health,
          rj_em_recent_health: ret.emp.em_recent_health && ret.emp.em_recent_health != '00/00/0000'
            ? Global.stringToDate(
                Global.DateToString(
                  ret.emp.em_recent_health,
                  "/",
                  "m/d/y",
                  false
                )
              )
            : null,
          em_blood_presure_hi: ret.emp.em_blood_presure_hi,
          em_blood_presure_low: ret.emp.em_blood_presure_low,
          blt_id: ret.emp.blt_id == null ? "0" : ret.emp.blt_id,
          em_special_health: ret.emp.em_special_health,
          rj_em_special_health: ret.emp.em_recent_health &&  ret.emp.em_recent_health != '00/00/0000'
            ? Global.stringToDate(
                Global.DateToString(
                  ret.emp.em_special_health,
                  "/",
                  "m/d/y",
                  false
                )
              )
            : null,
          sh_s: ret.emp.sh_s,
          em_sh_note: ret.emp.em_sh_note,
          em_qualify_country: ret.emp.em_qualify_country,
          em_qualify_lecture: ret.emp.em_qualify_lecture,
          em_qualify_lecture_special: ret.emp.em_qualify_lecture_special,

          em_stuff: ret.emp.em_stuff ? ret.emp.em_stuff : [],
          employeeCount: ret.emp.em_stuff ? ret.emp.em_stuff.length : 0,
          loading: false,
        });
        that.areaRef.current.fetch(
          URLS.GET_AREA_URL + "&st_id=" + ret.emp.st_id
        );
      })
      .catch((error) => {
        this.setState({ loading: false, errorMessage: error.toString() });
      });
  }
  onUpdate = (e: any) => {
    if (this.validator.allValid()) {
      //        if (true){
      this.setState({
        is_success: -1,
        loading: true,
      });
      const that = this;

      const stateData = eval("(" + JSON.stringify(this.state) + ")");
      let em_id: any = Global.getEmId();
      if (stateData.blt_id === "0") {
        stateData.blt_id = null;
      }
      if (stateData.pi_id == "0") {
        stateData.pi_id = null;
      }
      if (stateData.ei_id == "0") {
        stateData.ei_id = null;
      }
      if (stateData.hi_id == "0") {
        stateData.hi_id = null;
      }
      if (stateData.em_blood_presure_hi === "") {
        stateData.em_blood_presure_hi = "0";
      }
      if (stateData.em_blood_presure_low === "") {
        stateData.em_blood_presure_low = "0";
      }
      stateData.em_birthday = this.state.rj_em_birthday
        ? moment(this.state.rj_em_birthday).format("MM/DD/YYYY")
        : "";
      stateData.em_recent_health = this.state.rj_em_recent_health
        ? moment(this.state.rj_em_recent_health).format("MM/DD/YYYY")
        : "";
      stateData.em_special_health = this.state.rj_em_special_health
        ? moment(this.state.rj_em_special_health).format("MM/DD/YYYY")
        : "";
      stateData.em_id = em_id;
      stateData.sk_s = [];
      stateData.em_daily_price = Global.moneyFormatToFloat(
        this.state.em_daily_price
      );
      let sk_s = "";
      if (this.state.business1 !== "" && this.state.business1 != "0") {
        stateData.sk_s.push(this.state.business1);
        sk_s = this.state.business1;
      }
      if (this.state.business2 !== "" && this.state.business2 != "0") {
        stateData.sk_s.push(this.state.business2);
        if (sk_s !== "") {
          sk_s = sk_s + "," + this.state.business2;
        }
      }
      if (this.state.business3 !== "" && this.state.business3 != "0") {
        stateData.sk_s.push(this.state.business3);
        if (sk_s !== "") {
          sk_s = sk_s + "," + this.state.business3;
        }
      }

      if (this.state.sk_years_1 !== "" && this.state.sk_years_1 !== "0") {
        stateData.sk_years_s.push(this.state.sk_years_1);
      }

      if (this.state.sk_years_2 !== "" && this.state.sk_years_2 !== "0") {
        stateData.sk_years_s.push(this.state.sk_years_2);
      }
      if (this.state.sk_years_3 !== "" && this.state.sk_years_3 !== "0") {
        stateData.sk_years_s.push(this.state.sk_years_3);
      }

      for (let i = 0; i < stateData.em_stuff.length; i++) {
        let employee = stateData.em_stuff[i];
        let temp_sk_s = [];
        if (employee.sk_s.sk_1 !== "" && employee.sk_s.sk_1 !== "0") {
          temp_sk_s.push(employee.sk_s.sk_1);
        }
        if (employee.sk_s.sk_2 !== "" && employee.sk_s.sk_2 !== "0") {
          temp_sk_s.push(employee.sk_s.sk_2);
        }
        if (employee.sk_s.sk_3 !== "" && employee.sk_s.sk_3 !== "0") {
          temp_sk_s.push(employee.sk_s.sk_3);
        }
        employee.sk_s = temp_sk_s.join(",");

        let temp_sk_years_s = [];

        if (
          employee.sk_years_s.sk_years_1 &&
          employee.sk_years_s.sk_years_1 != "" &&
          employee.sk_years_s.sk_years_1 != "0"
        ) {
          temp_sk_years_s.push(employee.sk_years_s.sk_years_1);
        }
        if (
          employee.sk_years_s.sk_years_2 &&
          employee.sk_years_s.sk_years_2 != "" &&
          employee.sk_years_s.sk_years_1 != "0"
        ) {
          temp_sk_years_s.push(employee.sk_years_s.sk_years_2);
        }
        if (
          employee.sk_years_s.sk_years_3 &&
          employee.sk_years_s.sk_years_3 != "" &&
          employee.sk_years_s.sk_years_1 != "0"
        ) {
          temp_sk_years_s.push(employee.sk_years_s.sk_years_3);
        }
        employee.sk_years_s = temp_sk_years_s.join(",");
        employee.emst_birthday = moment(employee.rj_em_birthday).format(
          "MM/DD/YYYY"
        );
      }
      stateData.keyword = "worker";

      const data = {
        update: [stateData],
      };
      fetch(URLS.SAVE_PROFILE, {
        method: "post",
        body: JSON.stringify(data),
      })
        .then(async function (response) {
          let ret = await response.json();
          if (ret.success) {
            //that.alertRef.current.open( "成功", "サーバに保存されました。" );
            const em_name = that.state.em_lastname + that.state.em_firstname;
            const em_role = "職人";
            const em_company =
              that.state.em_is_individual == "1"
                ? "個人事業主"
                : that.state.em_company;
            const point = Global.getPoint();
            const userInfo: UserInfo = {
              role: Global.getRole()!,
              em_name: em_name,
              em_role: em_role,
              em_system: "0",
              em_company: em_company,
              point: point,
              sk_s: sk_s,
            };
            that.registerToFirebase();
            that.props.getProfile(Global.getRole()!, userInfo);
          } else {
            //that.alertRef.current.open( "エラー", "サーバへ保存できませんでした。" );
            that.setState({
              is_success: 0,
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            is_success: 0,
            errorMessage: error.toString(),
          });
          if (this.alertRef.current)
            this.alertRef.current.open(
              "エラー",
              "サーバへ保存できませんでした。"
            );
        });
    } else {
      this.validator.showMessages();
      this.setState({
        is_success: -2,
        loading: false,
      });

      this.forceUpdate();
    }
  };
  registerToFirebase = () => {
    let em_id: any = Global.getEmId();
    const fireBaseUserInfo: FireBaseUserInfo = {
      em_firstname: this.state.em_firstname,
      em_lastname: this.state.em_lastname,
      em_is_individual: this.state.em_is_individual,
      em_company_w: this.state.em_company,
      emt_is_worker: "1",
    };
    FireBaseUser.addUserInfo(em_id, fireBaseUserInfo)
      .then((result: any) => {
        this.setState({
          is_success: 1,
          loading: false,
        });
      })
      .catch((error: any) => {
        this.alertRef.current.open("エラー", "サーバへ保存できませんでした。");
      });
  };
  setOpen = (_open: boolean) => {
    this.setState({
      open: _open,
    });
  };
  openDialog = () => {
    this.setOpen(true);
  };
  showResultMessage = () => {
    let ret = <></>;
    if (this.state.is_success == 1)
      return (
        <span className="notifiy layout_center mt_10">
          登録に成功しました。
        </span>
      );
    else if (this.state.is_success == 0)
      return (
        <span className="notifiy layout_center mt_10">
          登録できませんでした。
        </span>
      );
    else if (this.state.is_success == -2)
      return (
        <span className="notifiy layout_center">
          必須項目を入力してください。
        </span>
      );
    return ret;
  };
  renderRedirect = () => {
    const em_id = Global.getEmId();
    if (em_id === "") {
      let url: string = "/";
      return <Redirect to={url} />;
    } else {
      let role = Global.getRole();
      if (role !== "2") {
        let url: string = "/choose";
        return <Redirect to={url} />;
      } else {
        return <></>;
      }
    }
  };
  onAddEmployee = (e: any) => {
    let employee: any = {
      emst_firstname: "",
      emst_lastname: "",
      emst_firstname_kana: "",
      emst_lastname_kana: "",
      emst_birthday: "",
      sex_id: "",
      rj_em_birthday: new Date(1980, 0, 1, 0, 0, 0, 0),
      sk_s: {
        sk_1: "",
        sk_2: "",
        sk_3: "",
      },
      sk_years_s: {
        sk_years_1: "",
        sk_years_2: "",
        sk_years_3: "",
      },
      sp_s: "",
      hi_id: "",
      em_hi_digit: "",
      pi_id: "",
      ei_id: "",
      em_ei_digit: "",
      em_family_name: "",
      em_family_relation: "",
      em_family_phone: "",
      em_recent_health_year: "",
      em_recent_health_month: "",
      em_blood_presure_hi: "",
      em_blood_presure_low: "",
      blt_id: "0",
      em_special_health_year: "",
      em_special_health_month: "",
      sh_s: "",
      em_sh_note: "",
      em_qualify_country: "",
      em_qualify_lecture: "",
      em_qualify_lecture_special: "",
    };

    this.setState({
      em_stuff: this.state.em_stuff.concat(employee),
      employeeCount: this.state.employeeCount + 1,
      isOpenAddEmployee: true,
    });
  };
  onDeleteEmployee = (e: any) => {
    const i: number = parseInt(e.currentTarget.dataset.id);
    const em_stuff = this.state.em_stuff;
    em_stuff.splice(i, 1);
    this.setState({
      em_stuff: em_stuff,
      employeeCount: em_stuff.length,
      isMore: true,
    });
  };
  // addEmployee = () => {
  //   let employeesPattern: any = [];
  //   for (let i = 0; i < this.state.employeeCount; i++) {
  //     employeesPattern.push(this.renderEmployee(i));
  //   }
  //   return employeesPattern;
  // };
  // renderEmployee = (i: number) => {
  //     const employee: EmployeeType = this.state.em_stuff[i];
  //     if (typeof (employee.sk_s) == 'string') {
  //         const sk_s = Global.splitToArray(employee.sk_s);

  //         employee.sk_s = {};
  //         employee.sk_s['sk_1'] = sk_s.length >= 1 ? sk_s[0] : '';
  //         employee.sk_s['sk_2'] = sk_s.length >= 2 ? sk_s[1] : '';
  //         employee.sk_s['sk_3'] = sk_s.length >= 3 ? sk_s[2] : '';
  //     }
  //     if (typeof (employee.sk_years_s) == 'string') {
  //         const sk_years_s = Global.splitToArray(employee.sk_years_s);
  //         employee.sk_years_s = {};
  //         employee.sk_years_s.sk_years_1 = sk_years_s.length >= 1 ? sk_years_s[0] : '';
  //         employee.sk_years_s.sk_years_2 = sk_years_s.length >= 1 ? sk_years_s[1] : '';
  //         employee.sk_years_s.sk_years_3 = sk_years_s.length >= 1 ? sk_years_s[2] : '';
  //     }

  //     const today = new Date();
  //     const currentMonth = today.getMonth();
  //     const currentDate = today.getDate();
  //     const rj_em_birthday = employee.emst_birthday ? Global.stringToDate(Global.DateToString(employee.emst_birthday, "/", "m/d/y", false)) : new Date(1980, currentMonth - 1, currentDate, 0, 0, 0, 0);
  //     if (!employee.rj_em_birthday)
  //         employee.rj_em_birthday = rj_em_birthday;

  //     const sp_s = employee.sp_s.split(',');
  //     const sh_s = employee.sh_s.split(',');
  //     if (!employee.blt_id) {
  //         employee.blt_id = '0';
  //     }
  //     return (
  //         <div key={"employee_" + i}>
  //             <div className="element">
  //                 <div className="second_column">
  //                     <div className="wd_150"><span className="fs20">従業員 {i + 1}</span></div>
  //                     <div className="btn_area mt_5" data-id={i} onClick={this.onDeleteEmployee}>
  //                         <div className="grey-button vv-small mr_10">
  //                             <div className="inner">削除</div>
  //                         </div>
  //                     </div>
  //                 </div>
  //             </div>
  //             <div className="element">
  //                 <div className="second_column">
  //                     <div className="wd_150"><span className="tag-mg fs14"><span className="tag-inner">氏名</span></span></div>
  //                     <div className="wd_160">
  //                         <input type="text" className="full_wide" placeholder="姓" name={"emst_lastname_" + i} value={employee.emst_lastname} onChange={this.onArrayHandleChange} onBlur={this.onHandleBlur} />
  //                     </div>
  //                     <div className="wd_160">
  //                         <input type="text" className="full_wide ml_20" placeholder="名" name={"emst_firstname_" + i} value={employee.emst_firstname} onChange={this.onArrayHandleChange} onBlur={this.onHandleBlur} />
  //                     </div>
  //                 </div>
  //                 <span className="error_show notify_show">任意</span>
  //             </div>
  //             <div className="element">
  //                 <div className="second_column">
  //                     <div className="wd_150"><span className="tag-mg fs14"><span className="tag-inner">カナ</span></span></div>
  //                     <div className="wd_160">
  //                         <input type="text" className="full_wide" placeholder="セイ" name={"emst_lastname_kana_" + i} value={employee.emst_lastname_kana} onChange={this.onArrayHandleChange} onBlur={this.onHandleBlur} />
  //                         {this.validator.message(('emst_lastname_kana_' + i), employee.emst_lastname_kana, 'furigana')}
  //                     </div>
  //                     <div className="wd_160">
  //                         <input type="text" className="full_wide ml_20" placeholder="メイ" name={"emst_firstname_kana_" + i} value={employee.emst_firstname_kana} onChange={this.onArrayHandleChange} onBlur={this.onHandleBlur} />
  //                         {this.validator.message(("emst_firstname_kana_" + i), employee.emst_firstname_kana, 'furigana')}
  //                     </div>
  //                 </div>
  //                 <span className="error_show notify_show">任意</span>
  //             </div>
  //             <div className="element">
  //                 <div className="second_column">
  //                     <div className="wd_150"><span className="tag-mg fs14"><span className="tag-inner">生年月日</span></span></div>
  //                     <div>
  //                         <DatePicker
  //                             renderCustomHeader={({
  //                                 date,
  //                                 changeYear,
  //                                 changeMonth,
  //                                 decreaseMonth,
  //                                 increaseMonth,
  //                                 prevMonthButtonDisabled,
  //                                 nextMonthButtonDisabled
  //                             }) => (
  //                                 <div
  //                                     style={{
  //                                         margin: 10,
  //                                         display: "flex",
  //                                         justifyContent: "center"
  //                                     }}
  //                                 >
  //                                     <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
  //                                         {"<"}
  //                                     </button>
  //                                     <select
  //                                         value={date.getFullYear()}
  //                                         onChange={({ target: { value } }) => { changeYear(parseInt(value)) }}
  //                                     >
  //                                         {Global.BIRTH_YEAR.map(option => (
  //                                             <option key={option} value={option}>
  //                                                 {option}
  //                                             </option>
  //                                         ))}
  //                                     </select>
  //                                     <select
  //                                         value={date.getMonth()}
  //                                         onChange={({ target: { value } }) =>
  //                                             changeMonth(parseInt(value))
  //                                         }
  //                                     >
  //                                         {Global.MONTH.map(option => (
  //                                             <option key={option} value={(parseInt(option) - 1)}>
  //                                                 {option}
  //                                             </option>
  //                                         ))}
  //                                     </select>

  //                                     <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
  //                                         {">"}
  //                                     </button>
  //                                 </div>
  //                             )}
  //                             dateFormat="yyyy/MM/dd"
  //                             selected={employee.rj_em_birthday}
  //                             onChange={date => this.handleBirthDayArrayChange(date, i)}
  //                             locale='ja'
  //                         />
  //                     </div>
  //                 </div>
  //                 <span className="error_show notify_show">任意</span>
  //             </div>
  //             <div className="element">
  //                 <div className="second_column">
  //                     <div className="wd_150"><span className="tag-mg fs14"><span className="tag-inner">性別</span></span></div>
  //                     <div className="mr_20">
  //                         <label className="radio-item">
  //                             <span className="wd_30 m_wd_20"><input type="radio" name={"sex_id_" + i} value="1" checked={employee.sex_id == "1" ? true : false} onChange={this.onArrayHandleChange} /><span className="radiobtn"></span></span>
  //                             <span className="txt">男性</span>
  //                         </label>
  //                     </div>
  //                     <div className="mr_20">
  //                         <label className="radio-item">
  //                             <span className="wd_30 m_wd_20"><input type="radio" name={"sex_id_" + i} value="2" checked={employee.sex_id == "2" ? true : false} onChange={this.onArrayHandleChange} /><span className="radiobtn"></span></span>
  //                             <span className="txt">女性</span>
  //                         </label>
  //                     </div>
  //                 </div>
  //                 <span className="error_show notify_show">任意</span>
  //             </div>
  //             <div className="element">
  //                 <div className="second_column">
  //                     <div className="wd_150"><span className="tag-mg fs14"><span className="tag-inner">業種</span></span></div>
  //                     <div>
  //                         <div className="mt_10">
  //                             <span className="fs14 text-right mr_20 inline_block">業種は3つまで追加できます</span>
  //                             <Link to="/job_type" target="_blank"><img alt='' className="mt_8" src={URLS.BASE_ABSOLUTE_URL + "/images/icon_help.jpg"} />
  //                                 <span className="fs14 text-right mr_5 inline_block">業種説明</span></Link>
  //                         </div>
  //                         <div className="mt_10">
  //                             <div className="inline_block top">
  //                                 <span className="round-title bussiness_round mr_20 inline_block">業種1</span>
  //                                 <div className="wd_200 mr_20 inline_block">
  //                                     <BasicDropDown
  //                                         className="full_wide" name={"sk_1_" + i} idKey={"sk_id"} valueKey={"sk_label"} url={URLS.GET_WORKER_SKILL} checkedValue={employee.sk_s.sk_1} defaultName="選択してください"
  //                                         onHandleChange={this.onArrayHandleChange} onHandleBlur={this.onHandleBlur} noPrefix={true} />
  //                                 </div>
  //                             </div>
  //                             <div className="inline_block top">
  //                                 <span className="round-title bussiness_round mr_20 inline_block">経験年数</span>
  //                                 <div className="inline_block">
  //                                     <div className="wd_50 inline_block mr_10">
  //                                         <input type="text" className="full_wide" name={"sk_years_1_" + i} value={employee.sk_years_s.sk_years_1} disabled={employee.sk_s.sk_1 === '0' || employee.sk_s.sk_1 === '' ? true : false} onChange={this.onArrayHandleChange} onBlur={this.onHandleBlur} />
  //                                     </div>
  //                                     <span>年</span>
  //                                     {this.state.business1 != "0" && this.state.business1 != "" ?
  //                                         this.validator.message('sk_years_1', this.state.sk_years_1, 'required|numeric|min:1,num') :
  //                                         this.validator.message(('sk_years_1_' + i), employee.sk_years_s.sk_years_1, 'numeric|min:1,num')}

  //                                 </div>
  //                             </div>
  //                         </div>
  //                         <div className="mt_10">
  //                             <div className="inline_block top">
  //                                 <span className="round-title bussiness_round mr_20 inline_block">業種2</span>
  //                                 <div className="wd_200 mr_20 inline_block top">
  //                                     <BasicDropDown
  //                                         className="full_wide" name={"sk_2_" + i} idKey={"sk_id"} valueKey={"sk_label"} url={URLS.GET_WORKER_SKILL} checkedValue={employee.sk_s.sk_2} defaultName="選択してください"
  //                                         onHandleChange={this.onArrayHandleChange} onHandleBlur={this.onHandleBlur} noPrefix={true} />
  //                                 </div>
  //                             </div>
  //                             <div className="inline_block top">
  //                                 <span className="round-title bussiness_round mr_20 inline_block">経験年数</span>
  //                                 <div className="inline_block top">
  //                                     <div className="wd_50 inline_block mr_10">
  //                                         <input type="text" className="full_wide" name={"sk_years_2_" + i} value={employee.sk_years_s.sk_years_2} disabled={employee.sk_s.sk_2 === '0' || employee.sk_s.sk_2 === '' ? true : false} onChange={this.onArrayHandleChange} onBlur={this.onHandleBlur} />
  //                                     </div>
  //                                     <span>年</span>
  //                                     {this.state.business2 != "0" && this.state.business2 != "" ?
  //                                         this.validator.message('sk_years_2', this.state.sk_years_2, 'required|numeric|min:1,num') :
  //                                         this.validator.message(('sk_years_2_' + i), employee.sk_years_s.sk_years_2, 'numeric|min:1,num')}
  //                                 </div>
  //                             </div>
  //                         </div>
  //                         <div className="mt_10">
  //                             <div className="inline_block top">
  //                                 <span className="round-title bussiness_round mr_20 inline_block">業種3</span>
  //                                 <div className="wd_200 mr_20 inline_block">
  //                                     <BasicDropDown
  //                                         className="full_wide" name={"sk_3_" + i} idKey={"sk_id"} valueKey={"sk_label"} url={URLS.GET_WORKER_SKILL} checkedValue={employee.sk_s.sk_3} defaultName="選択してください"
  //                                         onHandleChange={this.onArrayHandleChange} onHandleBlur={this.onHandleBlur} noPrefix={true} />
  //                                 </div>
  //                             </div>
  //                             <div className="inline_block top">
  //                                 <span className="round-title bussiness_round mr_20 inline_block">経験年数</span>
  //                                 <div className="inline_block top">
  //                                     <div className="wd_50 inline_block mr_10">
  //                                         <input type="text" className="full_wide" name={"sk_years_3_" + i} value={employee.sk_years_s.sk_years_3} disabled={employee.sk_s.sk_3 === '0' || employee.sk_s.sk_3 === '' ? true : false} onChange={this.onArrayHandleChange} onBlur={this.onHandleBlur} />
  //                                     </div>
  //                                     <span>年</span>
  //                                     {this.state.business3 != "0" && this.state.business3 != "" ?
  //                                         this.validator.message('sk_years_3', this.state.sk_years_3, 'required|numeric|min:1,num') :
  //                                         this.validator.message(('sk_years_3_' + i), employee.sk_years_s.sk_years_3, 'numeric|min:1,num')}

  //                                 </div>
  //                             </div>
  //                         </div>
  //                     </div>
  //                 </div>
  //                 <span className="error_show notify_show">任意</span>
  //             </div>
  //             <div className="element">
  //                 <div className="second_column">
  //                     <div className="wd_150"><span className="tag-mg fs14"><span className="tag-inner">特記事項</span></span></div>
  //                     <div className="wd_590">
  //                         <BasicCheckBox
  //                             className="full_wide" name={"sp_s_" + i} idKey={"sp_id"} valueKey={"sp_label"} url={URLS.GET_SPECIAL_NOTE} checkedValues={sp_s}
  //                             onHandleChange={this.onArrayHandleChange} onHandleBlur={this.onHandleBlur} noPrefix={true} />
  //                     </div>
  //                 </div>
  //                 <span className="error_show notify_show">任意</span>
  //             </div>
  //             <div className="element">
  //                 <div className="second_column">
  //                     <div className="wd_150"><span className="tag-mg fs14"><span className="tag-inner">健康保険加入状況</span></span></div>
  //                     <div className="mr_20">
  //                         <BasicRadioBox
  //                             className="full_wide" key={"hi_id_" + i} name={"hi_id_" + i} idKey={"hi_id"} valueKey={"hi_label"} url={URLS.GET_HEALTH_CARE} checkedValue={employee.hi_id}
  //                             onHandleChange={this.onArrayHandleChange} onHandleBlur={this.onHandleBlur} noPrefix={true} />
  //                     </div>
  //                 </div>
  //                 <span className="error_show notify_show">任意</span>
  //             </div>
  //             <div className="element">
  //                 <div className="second_column">
  //                     <div className="wd_150"><span className="tag-mg fs14"><span className="tag-inner">健康保険番号下４桁</span></span></div>
  //                     <div className="wd_100 mr_20">
  //                         <input type="text" className="full_wide" name={"em_hi_digit_" + i} value={employee.em_hi_digit} onChange={this.onArrayHandleChange} onBlur={this.onHandleBlur} />
  //                     </div>
  //                 </div>
  //                 <span className="error_show notify_show">任意</span>
  //             </div>
  //             <div className="element">
  //                 <div className="second_column">
  //                     <div className="wd_150"><span className="tag-mg fs14"><span className="tag-inner">年金保険加入状況</span></span></div>
  //                     <div className="mr_20">
  //                         <BasicRadioBox
  //                             className="full_wide" key={"pi_id_" + i} name={"pi_id_" + i} idKey={"pi_id"} valueKey={"pi_label"} url={URLS.GET_YEAR_CARE} checkedValue={employee.pi_id}
  //                             onHandleChange={this.onArrayHandleChange} onHandleBlur={this.onHandleBlur} noPrefix={true} />
  //                     </div>
  //                 </div>
  //                 <span className="error_show notify_show">任意</span>
  //             </div>
  //             <div className="element">
  //                 <div className="second_column">
  //                     <div className="wd_150"><span className="tag-mg fs14"><span className="tag-inner">雇用保険加入状況</span></span></div>
  //                     <div className="mr_20">
  //                         <BasicRadioBox
  //                             className="full_wide" key={"ei_id_" + i} name={"ei_id_" + i} idKey={"ei_id"} valueKey={"ei_label"} url={URLS.GET_EMPLOYEE_CARE} checkedValue={employee.ei_id}
  //                             onHandleChange={this.onArrayHandleChange} onHandleBlur={this.onHandleBlur} noPrefix={true} />
  //                     </div>
  //                 </div>
  //                 <span className="error_show notify_show">任意</span>
  //             </div>
  //             <div className="element">
  //                 <div className="second_column">
  //                     <div className="wd_150"><span className="tag-mg fs14"><span className="tag-inner">雇用保険番号下４桁</span></span></div>
  //                     <div className="wd_100 mr_20">
  //                         <input type="text" className="full_wide" name={"em_ei_digit_" + i} value={employee.em_ei_digit} onChange={this.onArrayHandleChange} onBlur={this.onHandleBlur} />
  //                     </div>
  //                 </div>
  //                 <span className="error_show notify_show">任意</span>
  //             </div>
  //             <div className="element">
  //                 <div className="second_column">
  //                     <div className="wd_150"><span className="tag-mg fs14"><span className="tag-inner">家族連絡先</span></span></div>
  //                     <div>
  //                         <div className="mt_10">
  //                             <span className="wd_65 fs14 text-left mr_5 inline_block">氏名</span>
  //                             <div className="wd_200 inline_block mr_5">
  //                                 <input type="text" className="full_wide" name={"em_family_name_" + i} value={employee.em_family_name} onChange={this.onArrayHandleChange} />
  //                             </div>
  //                         </div>
  //                         <div className="mt_10">
  //                             <span className="wd_65 fs14 text-left mr_5 inline_block">続柄</span>
  //                             <div className="wd_250 inline_block mr_5">
  //                                 <input type="text" className="full_wide" name={"em_family_relation_" + i} value={employee.em_family_relation} onChange={this.onArrayHandleChange} onBlur={this.onHandleBlur} />
  //                             </div>
  //                         </div>
  //                         <div className="mt_10">
  //                             <span className="wd_65 fs14 text-left mr_5 inline_block">電話番号</span>
  //                             <div className="wd_150 inline_block mr_5">
  //                                 <input type="text" className="full_wide" name={"em_family_phone_" + i} value={employee.em_family_phone} onChange={this.onArrayHandleChange} onBlur={this.onHandleBlur} />
  //                                 {this.validator.message(('em_family_phone_' + i), employee.em_family_phone, 'phone')}
  //                             </div>
  //                         </div>
  //                     </div>
  //                 </div>
  //                 <span className="error_show notify_show">任意</span>
  //             </div>
  //             <div className="element">
  //                 <div className="second_column">
  //                     <div className="wd_150"><span className="tag-mg fs14"><span className="tag-inner">最近の健康診断日</span></span></div>
  //                     <div>
  //                         <div className="wd_100 mr_5 inline_block">
  //                             <select className="full_wide" name={'em_recent_health_year_' + i} value={employee.em_recent_health_year ? employee.em_recent_health_year : '0'} onChange={this.onArrayHandleChange} onBlur={this.onHandleBlur}>
  //                                 <option value="0">年</option>
  //                                 {
  //                                     Global.ANALYZE_YEAR.map(y => (
  //                                         <option value={y} key={y}>{y}</option>
  //                                     ))
  //                                 }
  //                             </select>
  //                         </div>
  //                         <span className="inline_block fs14 mr_20">年</span>
  //                         <div className="wd_50 mr_5 inline_block">
  //                             <select className="full_wide" name={"em_recent_health_month_" + i} value={employee.em_recent_health_month ? employee.em_recent_health_month : '0'} onChange={this.onArrayHandleChange} onBlur={this.onHandleBlur}>
  //                                 <option value="0">月</option>
  //                                 {
  //                                     MONTH.map(m => (
  //                                         <option value={m} key={m}>{m}</option>
  //                                     ))
  //                                 }
  //                             </select>
  //                         </div>
  //                         <span className="inline_block fs14">月</span>
  //                     </div>
  //                 </div>
  //                 <span className="error_show notify_show">任意</span>
  //             </div>
  //             <div className="element">
  //                 <div className="second_column">
  //                     <div className="wd_150"><span className="tag-mg fs14"><span className="tag-inner">血圧</span></span></div>
  //                     <div>
  //                         <span className="inline_block fs14 mr_10">最高</span>
  //                         <div className="wd_150 mr_5 inline_block top mr_20">
  //                             <input type="text" className="full_wide" name={"em_blood_presure_hi_" + i} value={employee.em_blood_presure_hi ? employee.em_blood_presure_hi : ''} onChange={this.onArrayHandleChange} onBlur={this.onHandleBlur} />
  //                             {this.validator.message(('em_blood_presure_hi_' + i), employee.em_blood_presure_hi, 'numeric')}
  //                         </div>
  //                         <span className="inline_block fs14 mr_10">最低</span>
  //                         <div className="wd_150 mr_5 inline_block top mr_20">
  //                             <input type="text" className="full_wide" name={"em_blood_presure_low_" + i} value={employee.em_blood_presure_low ? employee.em_blood_presure_low : ''} onChange={this.onArrayHandleChange} onBlur={this.onHandleBlur} />
  //                             {this.validator.message(('em_blood_presure_low_' + i), employee.em_blood_presure_low, 'numeric')}
  //                         </div>
  //                     </div>
  //                 </div>
  //                 <span className="error_show notify_show">任意</span>
  //             </div>
  //             <div className="element">
  //                 <div className="second_column">
  //                     <div className="wd_150"><span className="tag-mg fs14"><span className="tag-inner">血液型</span></span></div>
  //                     <div className="wd_100 mr_5 inline_block">
  //                         <BasicDropDown
  //                             className="full_wide" name={"blt_id_" + i} idKey={"blt_id"} valueKey={"blt_name"} url={URLS.GET_BLOOD_TYPE} checkedValue={employee.blt_id} defaultName="血液型"
  //                             onHandleChange={this.onArrayHandleChange} onHandleBlur={this.onHandleBlur} noPrefix={true} />
  //                     </div>
  //                 </div>
  //                 <span className="error_show notify_show">任意</span>
  //             </div>
  //             <div className="element">
  //                 <div className="second_column">
  //                     <div className="wd_150"><span className="tag-mg fs14"><span className="tag-inner">特殊健康診断</span></span></div>
  //                     <div>
  //                         <div className="wd_100 mr_5 inline_block">
  //                             <select className="full_wide" name={'em_special_health_year_' + i} value={employee.em_special_health_year ? employee.em_special_health_year : ''} onChange={this.onArrayHandleChange} onBlur={this.onHandleBlur}>
  //                                 <option value="0">年</option>
  //                                 {
  //                                     Global.ANALYZE_YEAR.map(y => (
  //                                         <option value={y} key={y}>{y}</option>
  //                                     ))
  //                                 }
  //                             </select>
  //                         </div>
  //                         <span className="inline_block fs14 mr_20">年</span>
  //                         <div className="wd_50 mr_5 inline_block">
  //                             <select className="full_wide" name={"em_special_health_month_" + i} value={employee.em_special_health_month ? employee.em_special_health_month : ''} onChange={this.onArrayHandleChange} onBlur={this.onHandleBlur}>
  //                                 <option value="0">月</option>
  //                                 {
  //                                     MONTH.map(m => (
  //                                         <option value={m} key={m}>{m}</option>
  //                                     ))
  //                                 }
  //                             </select>
  //                         </div>
  //                         <span className="inline_block fs14">月</span>
  //                     </div>
  //                 </div>
  //                 <span className="error_show notify_show">任意</span>
  //             </div>
  //             <div className="element">
  //                 <div className="second_column">
  //                     <div className="wd_150"><span className="tag-mg fs14"><span className="tag-inner">種類</span></span></div>
  //                     <div className="wd_590">
  //                         <BasicCheckBox
  //                             className="full_wide" name={"sh_s_" + i} idKey={"sh_id"} valueKey={"sh_label"} url={URLS.GET_SPECIAL_KIND} checkedValues={sh_s}
  //                             onHandleChange={this.onArrayHandleChange} onHandleBlur={this.onHandleBlur} noPrefix={true} />
  //                         <div className="wd_180 inline_block">
  //                             <input type="text" className="full_wide" name={"em_sh_note_" + i} value={employee.em_sh_note} onChange={this.onArrayHandleChange} />
  //                         </div>
  //                     </div>
  //                 </div>
  //                 <span className="error_show notify_show">任意</span>
  //             </div>
  //             <div className="element">
  //                 <div className="second_column">
  //                     <div className="wd_150"><span className="tag-mg fs14"><span className="tag-inner">保有資格</span></span></div>
  //                     <div>
  //                         <div className="mt_10">
  //                             <span className="wd_100 fs14 text-left mr_5 inline_block">国家資格・免許</span>
  //                             <div className="wd_200 inline_block mr_5">
  //                                 <input type="text" className="full_wide" name={"em_qualify_country_" + i} value={employee.em_qualify_country} onChange={this.onArrayHandleChange} />
  //                             </div>
  //                         </div>
  //                         <div className="mt_10">
  //                             <span className="wd_100 fs14 text-left mr_5 inline_block">技能講習</span>
  //                             <div className="wd_200 inline_block mr_5">
  //                                 <input type="text" className="full_wide" name={"em_qualify_lecture_" + i} value={employee.em_qualify_lecture} onChange={this.onArrayHandleChange} />
  //                             </div>
  //                         </div>
  //                         <div className="mt_10">
  //                             <span className="wd_100 fs14 text-left mr_5 inline_block">特別教育</span>
  //                             <div className="wd_200 inline_block mr_5">
  //                                 <input type="text" className="full_wide" name={"em_qualify_lecture_special_" + i} value={employee.em_qualify_lecture_special} onChange={this.onArrayHandleChange} />
  //                             </div>
  //                         </div>
  //                     </div>
  //                 </div>
  //                 <span className="error_show notify_show">任意</span>
  //             </div>
  //         </div>);
  // }
  public render() {
    this.validator.purgeFields();
    const sp_s = this.state.sp_s.split(",");
    const sh_s = this.state.sh_s.split(",");
    return (
      <div>
        {this.renderRedirect()}
        {this.state.loading ? <Loading /> : <></>}
        <div className="edit_profile_inner">
          <DraggableDialog
            open={this.state.open}
            setOpen={this.setOpen}
            clsName={"photopanel"}
          />
          <AlertDialog ref={this.alertRef} />
          <div className="flex_middle mb_50">
            <img
              className="profile_img mr_20"
              src={URLS.IMAGE_URL + Global.getEmSmallPhoto()}
            />
            {!Global.isIE() ? (
              <div className="pointer" onClick={this.openDialog}>
                <span className="fs14 underline">
                  {Global.getEmSmallPhoto() == "uphoto/dummy_s.jpg"
                    ? "写真を登録"
                    : "写真を変更"}
                </span>
              </div>
            ) : (
              <div>IEでは画像更新をサポートいたしません。</div>
            )}
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_150">
                <span className="tag-mg fs14">
                  <span className="tag-inner">氏名</span>
                </span>
              </div>
              <div className="wd_160">
                <input
                  type="text"
                  className="full_wide"
                  placeholder="姓"
                  name="em_lastname"
                  value={this.state.em_lastname}
                  onChange={this.onHandleChange}
                  onBlur={this.onHandleBlur}
                />
                {this.validator.message(
                  "em_lastname",
                  this.state.em_lastname,
                  "required"
                )}
              </div>
              <div className="wd_160">
                <input
                  type="text"
                  className="full_wide ml_20"
                  placeholder="名"
                  name="em_firstname"
                  value={this.state.em_firstname}
                  onChange={this.onHandleChange}
                  onBlur={this.onHandleBlur}
                />
                {this.validator.message(
                  "em_firstname",
                  this.state.em_firstname,
                  "required"
                )}
              </div>
            </div>
            <span className="error_show">必須</span>
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_150">
                <span className="tag-mg fs14">
                  <span className="tag-inner">カナ</span>
                </span>
              </div>
              <div className="wd_160">
                <input
                  type="text"
                  className="full_wide"
                  placeholder="セイ"
                  name="em_lastname_kana"
                  value={this.state.em_lastname_kana}
                  onChange={this.onHandleChange}
                  onBlur={this.onHandleBlur}
                />
                {this.validator.message(
                  "em_lastname_kana",
                  this.state.em_lastname_kana,
                  "required|furigana"
                )}
              </div>
              <div className="wd_160">
                <input
                  type="text"
                  className="full_wide ml_20"
                  placeholder="メイ"
                  name="em_firstname_kana"
                  value={this.state.em_firstname_kana}
                  onChange={this.onHandleChange}
                  onBlur={this.onHandleBlur}
                />
                {this.validator.message(
                  "em_firstname_kana",
                  this.state.em_firstname_kana,
                  "required|furigana"
                )}
              </div>
            </div>
            <span className="error_show">必須</span>
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_150">
                <span className="tag-mg fs14">
                  <span className="tag-inner">生年月日</span>
                </span>
              </div>
              <div>
                <DatePicker
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => (
                    <div
                      style={{
                        margin: 10,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}
                      >
                        {"<"}
                      </button>
                      <select
                        value={date.getFullYear()}
                        onChange={({ target: { value } }) => {
                          changeYear(parseInt(value));
                        }}
                      >
                        {Global.BIRTH_YEAR.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <select
                        value={date.getMonth()}
                        onChange={({ target: { value } }) =>
                          changeMonth(parseInt(value))
                        }
                      >
                        {Global.MONTH.map((option) => (
                          <option key={option} value={parseInt(option) - 1}>
                            {option}
                          </option>
                        ))}
                      </select>

                      <button
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                      >
                        {">"}
                      </button>
                    </div>
                  )}
                  dateFormat="yyyy/MM/dd"
                  selected={this.state.rj_em_birthday}
                  onChange={(date) => this.handleBirthDayChange(date!)}
                  locale="ja"
                />
                {this.validator.message(
                  "js_em_birthday",
                  this.state.rj_em_birthday,
                  "required"
                )}
              </div>
            </div>
            <span className="error_show">必須</span>
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_150">
                <span className="tag-mg fs14">
                  <span className="tag-inner">性別</span>
                </span>
              </div>
              <div className="mr_20">
                <label className="radio-item">
                  <span className="wd_30 m_wd_20">
                    <input
                      type="radio"
                      name="sex_id"
                      value="1"
                      checked={this.state.sex_id == "1" ? true : false}
                      onChange={this.onHandleChange}
                    />
                    <span className="radiobtn"></span>
                  </span>
                  <span className="txt">男性</span>
                </label>
              </div>
              <div className="mr_20">
                <label className="radio-item">
                  <span className="wd_30 m_wd_20">
                    <input
                      type="radio"
                      name="sex_id"
                      value="2"
                      checked={this.state.sex_id == "2" ? true : false}
                      onChange={this.onHandleChange}
                    />
                    <span className="radiobtn"></span>
                  </span>
                  <span className="txt">女性</span>
                </label>
              </div>
            </div>
            <span className="error_show">必須</span>
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_150">
                <span className="tag-mg fs14">
                  <span className="tag-inner">所属会社名</span>
                </span>
              </div>
              <div>
                <div>
                  <label className="radio-item">
                    <span className="wd_30 m_wd_20">
                      <input
                        type="radio"
                        name="em_is_individual"
                        value="1"
                        checked={
                          this.state.em_is_individual == "1" ? true : false
                        }
                        onChange={this.onHandleChange}
                      />
                      <span className="radiobtn"></span>
                    </span>
                    <span className="txt">一人親方（個人事業主）</span>
                  </label>
                </div>
                <div>
                  <label className="radio-item">
                    <span className="wd_30 m_wd_20">
                      <input
                        type="radio"
                        name="em_is_individual"
                        value="0"
                        checked={
                          this.state.em_is_individual == "0" ? true : false
                        }
                        onChange={this.onHandleChange}
                      />
                      <span className="radiobtn"></span>
                    </span>
                    <span className="txt">会社名</span>
                  </label>
                  <div className="wd_200 inline_block top">
                    <input
                      type="text"
                      className="full_wide ml_20"
                      placeholder="株式会社　クラハ"
                      disabled={
                        this.state.em_is_individual == "1" ? true : false
                      }
                      name="em_company"
                      value={this.state.em_company}
                      onChange={this.onHandleChange}
                      onBlur={this.onHandleBlur}
                    />
                    {this.state.em_is_individual == "0" ? (
                      this.validator.message(
                        "em_company",
                        this.state.em_company,
                        "required"
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <span className="error_show">必須</span>
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_150">
                <span className="tag-mg fs14">
                  <span className="tag-inner">業種</span>
                </span>
              </div>
              <div>
                <div className="mt_10">
                  <span className="fs14 text-right mr_20 inline_block">
                    業種は3つまで追加できます
                  </span>
                  <Link to="/job_type" target="_blank">
                    <img
                      alt=""
                      className="mt_8"
                      src={URLS.BASE_ABSOLUTE_URL + "/images/icon_help.jpg"}
                    />
                    <span className="fs14 text-right mr_5 inline_block">
                      業種説明
                    </span>
                  </Link>
                </div>
                <div className="mt_10">
                  <div className="inline_block top">
                    <span className="round-title bussiness_round mr_20 inline_block">
                      業種1
                    </span>
                    <div className="wd_200 mr_20 inline_block">
                      <BasicDropDown
                        className="full_wide"
                        name="business1"
                        idKey={"sk_id"}
                        valueKey={"sk_label"}
                        url={URLS.GET_WORKER_SKILL}
                        checkedValue={
                          this.state.business1 != undefined
                            ? this.state.business1
                            : "0"
                        }
                        defaultName="選択してください"
                        excludeValues={[this.state.business2, this.state.business3]}
                        onHandleChange={this.onHandleChange}
                        onHandleBlur={this.onHandleBlur}
                        noPrefix={true}
                      />

                      {(this.state.business1 === "" &&
                        this.state.business2 === "" &&
                        this.state.business3 === "") ||
                      (this.state.business1 === "0" &&
                        this.state.business2 === "0" &&
                        this.state.business3 === "0") ? (
                        this.validator.message(
                          "business1",
                          this.state.business1,
                          "select"
                        )
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="inline_block top">
                    <span className="round-title bussiness_round mr_20 inline_block">
                      経験年数
                    </span>
                    <div className="inline_block">
                      <div className="wd_50 inline_block mr_10">
                        <input
                          type="text"
                          className="full_wide"
                          name="sk_years_1"
                          value={this.state.sk_years_1}
                          onChange={this.onHandleChange}
                          onBlur={this.onHandleBlur}
                          disabled={
                            this.state.business1 != undefined && this.state.business1 != '' &&
                            this.state.business1 !== "0"
                              ? false
                              : true
                          }
                        />
                      </div>
                      <span>年</span>
                      {this.state.business1 != "0" && this.state.business1 != ""
                        ? this.validator.message(
                            "sk_years_1",
                            this.state.sk_years_1,
                            "required|numeric|min:1,num"
                          )
                        : this.validator.message(
                            "sk_years_1",
                            this.state.sk_years_1,
                            "numeric|min:1,num"
                          )}
                    </div>
                  </div>
                </div>
                <div className="mt_10">
                  <div className="inline_block top">
                    <span className="round-title bussiness_round mr_20 inline_block">
                      業種2
                    </span>
                    <div className="wd_200 mr_20 inline_block top">
                      <BasicDropDown
                        className="full_wide"
                        name="business2"
                        idKey={"sk_id"}
                        valueKey={"sk_label"}
                        url={URLS.GET_WORKER_SKILL}
                        checkedValue={
                          this.state.business2 != undefined
                            ? this.state.business2
                            : "0"
                        }
                        excludeValues={[this.state.business1, this.state.business3]}
                        defaultName="選択してください"
                        onHandleChange={this.onHandleChange}
                        onHandleBlur={this.onHandleBlur}
                        noPrefix={true}
                      />
                    </div>
                  </div>
                  <div className="inline_block top">
                    <span className="round-title bussiness_round mr_20 inline_block">
                      経験年数
                    </span>
                    <div className="inline_block top">
                      <div className="wd_50 inline_block mr_10">
                        <input
                          type="text"
                          className="full_wide"
                          name="sk_years_2"
                          value={this.state.sk_years_2}
                          onChange={this.onHandleChange}
                          onBlur={this.onHandleBlur}
                          disabled={
                            this.state.business2 != undefined && this.state.business2 != '' &&
                            this.state.business2 !== "0"
                              ? false
                              : true
                          }
                        />
                      </div>
                      <span>年</span>
                      {this.state.business2 != "0" && this.state.business2 != ""
                        ? this.validator.message(
                            "sk_years_2",
                            this.state.sk_years_2,
                            "required|numeric|min:1,num"
                          )
                        : this.validator.message(
                            "sk_years_2",
                            this.state.sk_years_2,
                            "numeric|min:1,num"
                          )}
                    </div>
                  </div>
                </div>
                <div className="mt_10">
                  <div className="inline_block top">
                    <span className="round-title bussiness_round mr_20 inline_block">
                      業種3
                    </span>
                    <div className="wd_200 mr_20 inline_block">
                      <BasicDropDown
                        className="full_wide"
                        name="business3"
                        idKey={"sk_id"}
                        valueKey={"sk_label"}
                        url={URLS.GET_WORKER_SKILL}
                        checkedValue={
                          this.state.business3 != undefined
                            ? this.state.business3
                            : "0"
                        }
                        excludeValues={[this.state.business1, this.state.business2]}
                        defaultName="選択してください"
                        onHandleChange={this.onHandleChange}
                        onHandleBlur={this.onHandleBlur}
                        noPrefix={true}
                      />
                    </div>
                  </div>
                  <div className="inline_block top">
                    <span className="round-title bussiness_round mr_20 inline_block">
                      経験年数
                    </span>
                    <div className="inline_block top">
                      <div className="wd_50 inline_block mr_10">
                        <input
                          type="text"
                          className="full_wide"
                          name="sk_years_3"
                          value={this.state.sk_years_3}
                          onChange={this.onHandleChange}
                          onBlur={this.onHandleBlur}
                          disabled={
                            this.state.business3 != undefined && this.state.business3 != '' &&
                            this.state.business3 !== "0"
                              ? false
                              : true
                          }
                        />
                      </div>
                      <span>年</span>
                      {this.state.business3 != "0" && this.state.business3 != ""
                        ? this.validator.message(
                            "sk_years_3",
                            this.state.sk_years_3,
                            "required|numeric|min:1,num"
                          )
                        : this.validator.message(
                            "sk_years_3",
                            this.state.sk_years_3,
                            "numeric|min:1,num"
                          )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span className="error_show">必須</span>
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_200">
                <span className="tag-mg fs14">
                  <span className="tag-inner">検索⽤業種フリーワード</span>
                </span>
              </div>
              <div className="wd_590">
                <textarea
                  className="full_wide ht_60"
                  placeholder="ガラス、鏡、シーリング、発電設備工事、送配電線工事、クレーン"
                  name="em_skill_keyword"
                  value={this.state.em_skill_keyword}
                  onChange={this.onHandleChange}
                >
                  {this.state.em_skill_keyword}
                </textarea>
              </div>
            </div>
            <span className="error_show notify_show">任意</span>
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_150">
                <span className="tag-mg fs14">
                  <span className="tag-inner">最低賃金</span>
                </span>
              </div>
              <div className="block">
                <div className="wd_100 mr_20 inline_block">
                  <input
                    type="text"
                    className="full_wide"
                    placeholder="16,000"
                    name="em_daily_price"
                    value={this.state.em_daily_price}
                    onChange={this.onHandleChange}
                    onBlur={this.onHandleBlur}
                  />
                </div>
                <span className="inline_block">円/人工</span>
                {this.validator.message(
                  "em_daily_price",
                  this.state.em_daily_price,
                  "required|money"
                )}
              </div>
            </div>
            <span className="error_show">必須</span>
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_150">
                <span className="tag-mg fs14">
                  <span className="tag-inner">現住所</span>
                </span>
              </div>
              <div>
                <div className="mt_10">
                  <span className="wd_65 fs14 text-right mr_5 inline_block">
                    郵便番号
                  </span>
                  <div className="wd_150 inline_block mr_5">
                    <input
                      type="text"
                      className="full_wide"
                      name="em_post"
                      value={this.state.em_post}
                      onChange={this.onHandleChange}
                      onBlur={this.onHandleBlur}
                    />
                    {this.validator.message(
                      "em_post",
                      this.state.em_post,
                      "postcode"
                    )}
                  </div>
                  <span className="error_show notify_show">任意</span>
                </div>
                <div className="mt_10">
                  <span className="wd_65 fs14 text-right mr_5 inline_block">
                    住所
                  </span>
                  <div className="wd_150 inline_block">
                    <BasicDropDown
                      className="full_wide"
                      name="st_id"
                      idKey={"st_id"}
                      valueKey={"st_label"}
                      url={URLS.GET_PREFECTURE_URL}
                      checkedValue={this.state.st_id}
                      defaultName="都道府県"
                      onHandleChange={this.onHandleChange}
                      onHandleBlur={this.onHandleBlur}
                      noPrefix={true}
                    />
                    {this.validator.message(
                      "st_id",
                      this.state.st_id,
                      "select"
                    )}
                  </div>
                </div>
                <div className="mt_10">
                  <span className="wd_65 fs14 text-right mr_5 inline_block"></span>
                  <div className="wd_450 inline_block">
                    <BasicDropDown
                      ref={this.areaRef}
                      className="full_wide"
                      name="ar_id"
                      idKey={"ar_id"}
                      valueKey={"ar_label"}
                      url={URLS.GET_AREA_URL + "&st_id=" + this.state.st_id}
                      checkedValue={this.state.ar_id}
                      defaultName="エリア"
                      onHandleChange={this.onHandleChange}
                      onHandleBlur={this.onHandleBlur}
                      noPrefix={true}
                    />
                    {this.validator.message(
                      "ar_id",
                      this.state.ar_id,
                      "select"
                    )}
                  </div>
                </div>
                <div className="mt_10">
                  <span className="wd_65 fs14 text-right mr_5 inline_block"></span>
                  <div className="wd_450 inline_block mr_5">
                    <input
                      type="text"
                      className="full_wide"
                      placeholder="番地"
                      name="em_street_address"
                      value={this.state.em_street_address}
                      onChange={this.onHandleChange}
                      onBlur={this.onHandleBlur}
                    />
                  </div>
                  <span className="error_show notify_show">任意</span>
                </div>
              </div>
            </div>
            <span className="error_show">必須</span>
          </div>
          {/* <div className="element">
                    <div className="second_column">
                        <div className="wd_150"><span className="tag-mg fs14"><span className="tag-inner">電話番号</span></span></div>
                        <div className="wd_280 inline_block mr_5">
                            <span className="fs14 inline_block mr_5">09000000000</span>
                            <img className = "inline_block mr_5" src = "../images/icon_verify.jpg"/>
                            <span className="fs14 green inline_block">認証済</span>
                        </div>

                    </div>
                    <span className="error_show">必須</span>									
                </div> */}
          <div className="element">
            <div className="second_column">
              <div className="wd_150">
                <span className="tag-mg fs14">
                  <span className="tag-inner">国籍</span>
                </span>
              </div>
              <div className="mr_20">
                <label className="radio-item">
                  <span className="wd_30 m_wd_20">
                    <input
                      type="radio"
                      name="em_is_foreign"
                      value="0"
                      checked={this.state.em_is_foreign == "0" ? true : false}
                      onChange={this.onHandleChange}
                    />
                    <span className="radiobtn"></span>
                  </span>
                  <span className="txt">日本</span>
                </label>
              </div>
              <div className="mr_20">
                <label className="radio-item">
                  <span className="wd_30 m_wd_20">
                    <input
                      type="radio"
                      name="em_is_foreign"
                      value="1"
                      checked={this.state.em_is_foreign == "1" ? true : false}
                      onChange={this.onHandleChange}
                    />
                    <span className="radiobtn"></span>
                  </span>
                  <span className="txt">外国</span>
                </label>
              </div>
            </div>
            <span className="error_show">必須</span>
          </div>
          <div className="element no_border">
            <div className="second_column">
              <div className="wd_150">
                <span className="tag-mg fs14">
                  <span className="tag-inner">紹介文</span>
                </span>
              </div>
              <div className="wd_590">
                <textarea
                  className="full_wide ht_160"
                  name="em_overview"
                  value={this.state.em_overview}
                  onChange={this.onHandleChange}
                >
                  {this.state.em_overview}
                </textarea>
              </div>
            </div>
            <span className="error_show notify_show">任意</span>
          </div>
          <div className="element no_border top_border">
            <div className="fs16 layout_center">
              以下は必要になった際に後で入力できます。
            </div>
          </div>
          <div className="element no_border">
            <button className="grey-button yellow large_300 rb">
              <div className="inner" onClick={this.showMore}>
                <i className="fa fa-pencil"></i>
                {this.state.isMore ? "追加情報を閉じる" : "追加情報を入力する"}
              </div>
            </button>
          </div>
        </div>
        <div
          className="edit_profile_inner"
          style={{ display: this.state.isMore ? "block" : "none" }}
        >
          <div className="element">
            <div className="second_column">
              <div className="wd_150">
                <span className="tag-mg fs14">
                  <span className="tag-inner">特記事項</span>
                </span>
              </div>
              <div className="wd_590">
                <BasicCheckBox
                  className="full_wide"
                  name="sp_s"
                  idKey={"sp_id"}
                  valueKey={"sp_label"}
                  url={URLS.GET_SPECIAL_NOTE}
                  checkedValues={sp_s}
                  onHandleChange={this.onHandleChange}
                  onHandleBlur={this.onHandleBlur}
                  noPrefix={true}
                />
              </div>
            </div>
            <span className="error_show notify_show">任意</span>
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_150">
                <span className="tag-mg fs14">
                  <span className="tag-inner">健康保険加入状況</span>
                </span>
              </div>
              <div className="mr_20">
                <BasicRadioBox
                  className="full_wide"
                  name="hi_id"
                  idKey={"hi_id"}
                  valueKey={"hi_label"}
                  url={URLS.GET_HEALTH_CARE}
                  checkedValue={this.state.hi_id}
                  onHandleChange={this.onHandleChange}
                  onHandleBlur={this.onHandleBlur}
                  noPrefix={true}
                />
              </div>
            </div>
            <span className="error_show notify_show">任意</span>
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_150">
                <span className="tag-mg fs14">
                  <span className="tag-inner">健康保険番号下４桁</span>
                </span>
              </div>
              <div className="wd_100 mr_20">
                <input
                  type="text"
                  className="full_wide"
                  name="em_hi_digit"
                  value={this.state.em_hi_digit}
                  onChange={this.onHandleChange}
                  onBlur={this.onHandleBlur}
                />
              </div>
            </div>
            <span className="error_show notify_show">任意</span>
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_150">
                <span className="tag-mg fs14">
                  <span className="tag-inner">年金保険加入状況</span>
                </span>
              </div>
              <div className="mr_20">
                <BasicRadioBox
                  className="full_wide"
                  name="pi_id"
                  idKey={"pi_id"}
                  valueKey={"pi_label"}
                  url={URLS.GET_YEAR_CARE}
                  checkedValue={this.state.pi_id}
                  onHandleChange={this.onHandleChange}
                  onHandleBlur={this.onHandleBlur}
                  noPrefix={true}
                />
              </div>
            </div>
            <span className="error_show notify_show">任意</span>
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_150">
                <span className="tag-mg fs14">
                  <span className="tag-inner">雇用保険加入状況</span>
                </span>
              </div>
              <div className="mr_20">
                <BasicRadioBox
                  className="full_wide"
                  name="ei_id"
                  idKey={"ei_id"}
                  valueKey={"ei_label"}
                  url={URLS.GET_EMPLOYEE_CARE}
                  checkedValue={this.state.ei_id}
                  onHandleChange={this.onHandleChange}
                  onHandleBlur={this.onHandleBlur}
                  noPrefix={true}
                />
              </div>
            </div>
            <span className="error_show notify_show">任意</span>
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_150">
                <span className="tag-mg fs14">
                  <span className="tag-inner">雇用保険番号下４桁</span>
                </span>
              </div>
              <div className="wd_100 mr_20">
                <input
                  type="text"
                  className="full_wide"
                  name="em_ei_digit"
                  value={this.state.em_ei_digit}
                  onChange={this.onHandleChange}
                  onBlur={this.onHandleBlur}
                />
              </div>
            </div>
            <span className="error_show notify_show">任意</span>
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_150">
                <span className="tag-mg fs14">
                  <span className="tag-inner">家族連絡先</span>
                </span>
              </div>
              <div>
                <div className="mt_10">
                  <span className="wd_65 fs14 text-left mr_5 inline_block">
                    氏名
                  </span>
                  <div className="wd_200 inline_block mr_5">
                    <input
                      type="text"
                      className="full_wide"
                      name="em_family_name"
                      value={this.state.em_family_name}
                      onChange={this.onHandleChange}
                    />
                  </div>
                </div>
                <div className="mt_10">
                  <span className="wd_65 fs14 text-left mr_5 inline_block">
                    続柄
                  </span>
                  <div className="wd_250 inline_block mr_5">
                    <input
                      type="text"
                      className="full_wide"
                      name="em_family_relation"
                      value={this.state.em_family_relation}
                      onChange={this.onHandleChange}
                    />
                  </div>
                </div>
                <div className="mt_10">
                  <span className="wd_65 fs14 text-left mr_5 inline_block">
                    電話番号
                  </span>
                  <div className="wd_150 inline_block mr_5">
                    <input
                      type="text"
                      className="full_wide"
                      name="em_family_phone"
                      value={this.state.em_family_phone}
                      onChange={this.onHandleChange}
                      onBlur={this.onHandleBlur}
                    />
                    {this.validator.message(
                      "em_family_phone",
                      this.state.em_family_phone,
                      "phone"
                    )}
                  </div>
                </div>
              </div>
            </div>
            <span className="error_show notify_show">任意</span>
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_150">
                <span className="tag-mg fs14">
                  <span className="tag-inner">最近の健康診断日</span>
                </span>
              </div>
              <div>
                {/* <div className="wd_100 mr_5 inline_block">
                                    <select className="full_wide" name='em_recent_health_year' value={this.state.em_recent_health_year ? this.state.em_recent_health_year : '0'} onChange={this.onHandleChange} onBlur={this.onHandleBlur}>
                                        <option value="0">年</option>
                                        {
                                            Global.ANALYZE_YEAR.map(y => (
                                                <option value={y} key={y}>{y}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <span className="inline_block fs14 mr_20">年</span>
                                <div className="wd_50 mr_5 inline_block">
                                    <select className="full_wide" name="em_recent_health_month" value={this.state.em_recent_health_month ? this.state.em_recent_health_month : '0'} onChange={this.onHandleChange} onBlur={this.onHandleBlur}>
                                        <option value="0">月</option>
                                        {
                                            MONTH.map(m => (
                                                <option value={m} key={m}>{m}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <span className="inline_block fs14">月</span> */}
                <DatePicker
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => (
                    <div
                      style={{
                        margin: 10,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}
                      >
                        {"<"}
                      </button>
                      <select
                        value={date.getFullYear()}
                        onChange={({ target: { value } }) => {
                          changeYear(parseInt(value));
                        }}
                      >
                        {Global.ANALYZE_YEAR.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <select
                        value={date.getMonth()}
                        onChange={({ target: { value } }) =>
                          changeMonth(parseInt(value))
                        }
                      >
                        {Global.MONTH.map((option) => (
                          <option key={option} value={parseInt(option) - 1}>
                            {option}
                          </option>
                        ))}
                      </select>

                      <button
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                      >
                        {">"}
                      </button>
                    </div>
                  )}
                  dateFormat="yyyy/MM/dd"
                  selected={this.state.rj_em_recent_health}
                  onChange={(date) => this.handleRecentHealthChange(date!)}
                  locale="ja"
                />
              </div>
            </div>
            <span className="error_show notify_show">任意</span>
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_150">
                <span className="tag-mg fs14">
                  <span className="tag-inner">血圧</span>
                </span>
              </div>
              <div>
                <span className="inline_block fs14 mr_10">最高</span>
                <div className="wd_150 mr_5 inline_block top mr_20">
                  <input
                    type="text"
                    className="full_wide"
                    name="em_blood_presure_hi"
                    value={
                      this.state.em_blood_presure_hi
                        ? this.state.em_blood_presure_hi
                        : ""
                    }
                    onChange={this.onHandleChange}
                    onBlur={this.onHandleBlur}
                  />
                  {this.validator.message(
                    "em_blood_presure_hi",
                    this.state.em_blood_presure_hi,
                    "numeric"
                  )}
                </div>
                <span className="inline_block fs14 mr_10">最低</span>
                <div className="wd_150 mr_5 inline_block top mr_20">
                  <input
                    type="text"
                    className="full_wide"
                    name="em_blood_presure_low"
                    value={
                      this.state.em_blood_presure_low
                        ? this.state.em_blood_presure_low
                        : ""
                    }
                    onChange={this.onHandleChange}
                    onBlur={this.onHandleBlur}
                  />
                  {this.validator.message(
                    "em_blood_presure_low",
                    this.state.em_blood_presure_low,
                    "numeric"
                  )}
                </div>
              </div>
            </div>
            <span className="error_show notify_show">任意</span>
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_150">
                <span className="tag-mg fs14">
                  <span className="tag-inner">血液型</span>
                </span>
              </div>
              <div className="wd_100 mr_5 inline_block">
                <BasicDropDown
                  className="full_wide"
                  name="blt_id"
                  idKey={"blt_id"}
                  valueKey={"blt_name"}
                  url={URLS.GET_BLOOD_TYPE}
                  checkedValue={this.state.blt_id}
                  defaultName="血液型"
                  onHandleChange={this.onHandleChange}
                  onHandleBlur={this.onHandleBlur}
                  noPrefix={true}
                />
              </div>
            </div>
            <span className="error_show notify_show">任意</span>
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_150">
                <span className="tag-mg fs14">
                  <span className="tag-inner">特殊健康診断</span>
                </span>
              </div>
              <div>
                <DatePicker
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => (
                    <div
                      style={{
                        margin: 10,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}
                      >
                        {"<"}
                      </button>
                      <select
                        value={date.getFullYear()}
                        onChange={({ target: { value } }) => {
                          changeYear(parseInt(value));
                        }}
                      >
                        {Global.ANALYZE_YEAR.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <select
                        value={date.getMonth()}
                        onChange={({ target: { value } }) =>
                          changeMonth(parseInt(value))
                        }
                      >
                        {Global.MONTH.map((option) => (
                          <option key={option} value={parseInt(option) - 1}>
                            {option}
                          </option>
                        ))}
                      </select>

                      <button
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                      >
                        {">"}
                      </button>
                    </div>
                  )}
                  dateFormat="yyyy/MM/dd"
                  selected={this.state.rj_em_special_health}
                  onChange={(date) => this.handleSpecialHealthChange(date!)}
                  locale="ja"
                />
              </div>
            </div>
            <span className="error_show notify_show">任意</span>
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_150">
                <span className="tag-mg fs14">
                  <span className="tag-inner">種類</span>
                </span>
              </div>
              <div className="wd_590">
                <BasicCheckBox
                  className="full_wide"
                  name="sh_s"
                  idKey={"sh_id"}
                  valueKey={"sh_label"}
                  url={URLS.GET_SPECIAL_KIND}
                  checkedValues={sh_s}
                  onHandleChange={this.onHandleChange}
                  onHandleBlur={this.onHandleBlur}
                  noPrefix={true}
                />
                <div className="wd_180 inline_block">
                  <input
                    type="text"
                    className="full_wide"
                    name="em_sh_note"
                    value={this.state.em_sh_note}
                    onChange={this.onHandleChange}
                  />
                </div>
              </div>
            </div>
            <span className="error_show notify_show">任意</span>
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_150">
                <span className="tag-mg fs14">
                  <span className="tag-inner">保有資格</span>
                </span>
              </div>
              <div>
                <div className="mt_10">
                  <span className="wd_100 fs14 text-left mr_5 inline_block">
                    国家資格・免許
                  </span>
                  <div className="wd_200 inline_block mr_5">
                    <input
                      type="text"
                      className="full_wide"
                      name="em_qualify_country"
                      value={this.state.em_qualify_country}
                      onChange={this.onHandleChange}
                    />
                  </div>
                </div>
                <div className="mt_10">
                  <span className="wd_100 fs14 text-left mr_5 inline_block">
                    技能講習
                  </span>
                  <div className="wd_200 inline_block mr_5">
                    <input
                      type="text"
                      className="full_wide"
                      name="em_qualify_lecture"
                      value={this.state.em_qualify_lecture}
                      onChange={this.onHandleChange}
                    />
                  </div>
                </div>
                <div className="mt_10">
                  <span className="wd_100 fs14 text-left mr_5 inline_block">
                    特別教育
                  </span>
                  <div className="wd_200 inline_block mr_5">
                    <input
                      type="text"
                      className="full_wide"
                      name="em_qualify_lecture_special"
                      value={this.state.em_qualify_lecture_special}
                      onChange={this.onHandleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <span className="error_show notify_show">任意</span>
          </div>
        </div>
        {this.showResultMessage()}
        <div className="button-container">
          <button className="grey-button large mt_50">
            <div className="inner" onClick={this.onUpdate}>
              登録する
              <i className="fa fa-angle-right"></i>
            </div>
          </button>
        </div>
        {/* <div className="edit_profile_inner mt_40">
                    {
                        this.addEmployee()
                    }
                    <div className="grey-button yellow large_300 mt_20 rb mb_30">
                        <div className="inner" onClick={this.onAddEmployee}>
                            <i className="fa fa-pencil"></i>
                        従業員を追加する
                    </div>
                    </div>
                    {
                        this.showResultMessage()
                    }
                    <div className="button-container">
                        <button className="grey-button large mt_50">
                            <div className="inner" onClick={this.onUpdate} >
                                登録する
                        <i className="fa fa-angle-right"></i>
                            </div>
                        </button>
                    </div>
                </div> */}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatcherToProps)(PEditProfileCom);
