import { db } from '../firebase';

import { FireBaseUserInfo, UserInfoActions } from '../../modules/userinfo/types';
import * as Global from '../../modules/global';
import { Dispatch } from 'redux';
import { LogOut } from '../../modules/userinfo/actions';

export function addUserInfo( em_id:number, firebaseUserInfo:FireBaseUserInfo  ):any{
	const userNumber:string = em_id.toString();	
	const document = db.collection( "users" ).doc( userNumber );
	return document.get().then( function(doc){
		if( doc.exists ){
			document.update( firebaseUserInfo );
		}
		else{
			document.set( firebaseUserInfo ).then( function(){
			}).catch( function( error ){
			});
		}
	}).catch(function( error ){
	});
}
export function addRoleUserListener(dispatch:Dispatch<UserInfoActions>):any{
	let em_id:string|null = Global.getEmId();
	let role:string|null = Global.getRole();
	if( !em_id || em_id == '' || parseInt(em_id) == NaN ){
		return null;
	}
	if( !role || role == '' || parseInt(role) == NaN || parseInt(role) < 1 || parseInt(role) > 2 ){
		return null;
	}
	let unsub = db.collection( "users" ).doc( em_id.toString() ).onSnapshot( async(querySnapshot) => {
		const headerData:any = querySnapshot.data();
		if( !headerData ){
			return;
		}
		const {emt_is_worker, emt_is_boss} = headerData;

		if( ( parseInt(role!) == 1 && emt_is_boss <= 0 ) || ( parseInt(role!) == 2 && emt_is_worker <= 0 ) ){
			Global.setEmId( '' );
			Global.setPoint('0');
			Global.setDefaultRole('');
			dispatch( LogOut(false, {}) )
		}
	});
	return unsub;

}