import React, { Component } from "react";
import "../scss/job.scss";
import { Link } from "react-router-dom";
import * as URLS from "../../modules/urls";
import Loading from "../Include/Loading";
import * as Global from "../../modules/global";
import * as footerAsyncactions from "../../modules/footer/async-actions";
import { FooterActions } from "../../modules/footer";
import { Dispatch } from "redux";
import { connect } from "react-redux";

const mapDispatcherToProps = (dispatch: Dispatch<FooterActions>) => {
  return {
    isRerender: (_isRerender: boolean) =>
      footerAsyncactions.isRerender(dispatch, _isRerender),
  };
};
type ReduxType = ReturnType<typeof mapDispatcherToProps>;
class JobCloseCom extends Component<ReduxType> {
  state = {
    loading: false,
    items: [],
  };
  componentDidMount() {
    const that = this;
    this.setState({
      loading: true,
    });
    let em_id: any = Global.getEmId();
    const url: string =
      URLS.READ_CLOSE_JOB +
      "&em_id=" +
      em_id +
      '&sort=[{"property":"jb_created","direction":"desc"},{"property":"jb_id","direction":"desc"}]';
    fetch(url, {
      method: "get",
    })
      .then(async function (response) {
        let ret = await response.json();
        if (ret.total > 0) {
          const items = ret.items;
          that.setState(
            {
              loading: false,
              items: items,
            },
            async () => {
              await that.props.isRerender(true);
            }
          );
        } else {
          that.setState(
            {
              loading: false,
              items: [],
            },
            async () => {
              await that.props.isRerender(true);
            }
          );
        }
      })
      .catch((error) => {
        this.setState(
          { loading: false, items: [], errorMessage: error.toString() },
          async () => {
            await that.props.isRerender(true);
          }
        );
      });
  }
  render() {
    return (
      <div className="job_inner mt_50">
        {this.state.loading ? <Loading /> : <></>}
        {this.state.items.length != 0 ? (
          <div className="title">
            募集終了した仕事
            <span className="mark_choosed_job_notify green right_show inline_block ml_20">
              直接依頼
            </span>
            <span className="mark_choosed_job_notify right_show">
              職人決定済み
            </span>
          </div>
        ) : (
          <div className="title">募集終了した仕事</div>
        )}
        {this.state.items.length == 0 ? (
          <span className="no_content">募集終了した仕事がありません。</span>
        ) : (
          <></>
        )}
        {this.state.items.map((item) => (
          <div className="element flex" key={item["jb_id"]}>
            <Link to={"/jobdetail/" + item["jb_id"]}>
              <div className="job_title">
                <span>{item["jb_comp_name"]}</span>
                {parseInt(item["cnt_ctr"]) > 0 ? (
                  <span className="mark_choosed_job ml_10"></span>
                ) : (
                  <></>
                )}
                {parseInt(item["cnt_apply"]) -
                  parseInt(item["cnt_apply_direct"]) >
                0 ? (
                  <span className="mark_apply_job ml_10"></span>
                ) : (
                  <></>
                )}
                {item["cnt_apply_direct"] > 0 ? (
                  <span className="direct_job ml_10"></span>
                ) : (
                  <></>
                )}
              </div>
            </Link>
            <div className="btn_area">
              <div className="grey-button vv-small mr_10">
                <Link to={"/jobdetail/" + item["jb_id"]}>
                  <div className="inner">詳細</div>
                </Link>
              </div>
              <div className="grey-button vv-small">
                <Link
                  to={
                    "/applicant_list/" +
                    item["jb_id"] +
                    "/" +
                    item["jb_comp_name"]
                  }
                >
                  <div className="inner">応募者・直接依頼した職人を見る</div>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
export default connect(null, mapDispatcherToProps)(JobCloseCom);
