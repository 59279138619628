import React, { Component } from 'react';
import '../scss/job.scss';
import '../scss/search.scss';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import * as Global from '../../modules/global';
import * as URLS from '../../modules/urls';
import Loading from '../Include/Loading';
import "react-datepicker/dist/react-datepicker.css";
import { connect } from 'react-redux';
import { IRootState } from '../../modules';
import { SearchArea, SearchProviderState } from '../../modules/searchprovider';
import * as footerAsyncactions from '../../modules/footer/async-actions';
import { FooterActions } from '../../modules/footer';
import { Dispatch } from 'redux';

const mapStateToProps = ({ searchProvider  }: IRootState) => {
    return { searchProvider };
};
const mapDispatcherToProps = (dispatch: Dispatch<FooterActions>) => {
    return {
        isRerender:( _isRerender: boolean ) => footerAsyncactions.isRerender( dispatch, _isRerender ),             
    }
}
type ReduxType = ReturnType<typeof mapStateToProps>  & ReturnType<typeof mapDispatcherToProps> & RouteComponentProps & any;
class SearchResultCom extends Component<ReduxType>{
    mounted: boolean = false;
    state = {
        loading: false,
        items:[],
    }
    componentDidMount =() => {
        this.mounted = true;
        this.search();    
    }
    componentWillUnmount() {
        this.mounted = false;
    }
    search = () => {
        let em_id: any = Global.getEmId();
        if (em_id === '') {
            return;
        }
        const that = this;
        this.setState({
            loading: true,
        })
        let url = URLS.SEARCH_PROVIDER_PERIOD;
        const searchProviderFilter: SearchProviderState = this.props.searchProvider;
//console.log( searchProviderFilter ); return;        
        let params = "&em_id_not=" + em_id;
        // if ( searchProviderFilter.st_id !== '' && searchProviderFilter.st_id !== "0") {
        //     params += "&st_id_w=" + searchProviderFilter.st_id;
        // }
        // let ar_id_s_w: Array<string> = [];
        // if (searchProviderFilter.ar_id_1 !== '' && searchProviderFilter.ar_id_1 !== "0") {
        //     ar_id_s_w.push(searchProviderFilter.ar_id_1);
        // }
        // if (searchProviderFilter.ar_id_2 !== '' && searchProviderFilter.ar_id_2 !== "0") {
        //     ar_id_s_w.push(searchProviderFilter.ar_id_2);
        // }
        // if (searchProviderFilter.ar_id_3 !== '' && searchProviderFilter.ar_id_3 !== "0") {
        //     ar_id_s_w.push(searchProviderFilter.ar_id_3);
        // }
        // if (ar_id_s_w.length > 0) {
        //     params += "&ar_id_s_w=" + ar_id_s_w.join(",");
        // }

        let regions = [];
        for( let i = 0; i < this.props.searchProvider.searchAreas.length; i ++ ) {
            const searchArea:SearchArea = this.props.searchProvider.searchAreas[i];
            regions.push({
                st_id:searchArea.st_id ===''?null:searchArea.st_id,
                ar_ids:[
                        searchArea.ar_id_1 === ''?null:searchArea.ar_id_1,
                        searchArea.ar_id_2 === ''?null:searchArea.ar_id_2,
                        searchArea.ar_id_3 === ''?null:searchArea.ar_id_3
                        ],
            })                
        }
        params += '&regions_w=' + JSON.stringify(regions);
        if (searchProviderFilter.sk_s.length) {
            params += "&sk_s_w=" + Global.ArrayToString(searchProviderFilter.sk_s);
        }
        if (searchProviderFilter.em_skill_keyword !== '') {
            params += "&em_skill_keyword_w=" + searchProviderFilter.em_skill_keyword;
        }
        if (searchProviderFilter.nationality_s && searchProviderFilter.nationality_s.length === 1 ) {
            params += "&em_is_foreign=" + searchProviderFilter.nationality_s[0].toString();
        }
        if (searchProviderFilter.em_daily_price_low !== '') {
            params += "&em_daily_price_low=" + searchProviderFilter.em_daily_price_low;
        }
        if (searchProviderFilter.em_daily_price_hi !== '') {
            params += "&em_daily_price_hi=" + searchProviderFilter.em_daily_price_hi;
        }
        if (searchProviderFilter.is_date_search === "1") {
            if (searchProviderFilter.ef_datetime_between_date != null) {
                const dateObj = Global.dateFormat(searchProviderFilter.ef_datetime_between_date, "%m/%d/%Y", false);
                const ef_datetime_between_date = dateObj;
                params += "&ef_date_from=" + ef_datetime_between_date;
                params += "&ef_date_to=" + ef_datetime_between_date;
            }
            if (searchProviderFilter.jb_time !== "0") {
                const value = parseInt(searchProviderFilter.jb_time);
                const free_element = Global.DATE_DEVIDE_OPTIONS.filter(item => item.grp_ft_id === value);
                if (free_element && free_element.length > 0) {
                    params += "&ft_id_s=" + free_element[0].grp_ft_value;
                }
            }
        } else {
            if (searchProviderFilter.ef_date_from != null) {
                const dateObj = Global.dateFormat(searchProviderFilter.ef_date_from, "%m/%d/%Y", false);
                const ef_date_from = dateObj;
                params += "&ef_date_from=" + ef_date_from;
            }
            if (searchProviderFilter.ef_date_to != null) {
                const dateObj = Global.dateFormat(searchProviderFilter.ef_date_to, "%m/%d/%Y", false);
                const ef_date_to = dateObj;
                params += "&ef_date_to=" + ef_date_to;
            }
        }
        params += '&em_system=0';
        params += '&sort=[{"property":"em_id","direction":"desc"}]';
        url = url + params;
        fetch(url, {
            method: 'get',
        }).then(async function (response) {
            if( !that.mounted ) {
                return;
            }
            let ret = await response.json();
            if (ret.total > 0) {
                const items = ret.items;
                that.setState({
                    loading: false,
                    is_search_result: true,
                    items: items,
                }, async() => {
                    await that.props.isRerender( true );
                });
            } else {
                that.setState({
                    loading: false,
                    is_search_result: true,
                    items: [],
                }, async() => {
                    await that.props.isRerender( true );
                });
            }
        }).catch(error => {
            this.setState({ loading: false, items: [], errorMessage: error.toString() }, async() => {
                await that.props.isRerender( true );
            });
        });
    }
    goBack = () => {
        this.props.history.goBack();
    }
    renderSkill(skillStr: string) {
        if (!skillStr) {
            return [];
        }
        const skills = skillStr.split(',');
        let ret = [];
        for (let i = 0; i < skills.length; i++) {
            ret.push(<span className="tag-mg fs14" key={i}><span className="tag-inner">{skills[i]}</span></span>)
        }
        return ret;
    }    
    public render() {
        return (
            <div>
                {this.state.loading ?
                    <Loading />
                    : <></>
                }
                <div className="underline" onClick={this.goBack}><span className="fs14" style={{ cursor: "pointer" }}>&lt;検索条件に戻る</span></div>
                <div className="job_inner recommend-inner">
                    {
                        this.state.items.length === 0 ? <span className="no_content">条件に一致する職人がいません。</span> : <></>
                    }
                    {
                        this.state.items.map(item => (
                            <div className="element flex" key={item['em_id']}>
                                <div className="flex_start">
                                    <div className="first">
                                        <img alt='' src={URLS.IMAGE_URL + item['em_photo_s']} />
                                    </div>
                                    <div className="second">
                                        <div className="fs14">{item["em_is_individual"] === '1' ? '個人事業主' : item['em_company']}</div>
                                        <div className="fs20">{(item['em_lastname'] ? item['em_lastname'] : '') + (item['em_firstname'] ? item['em_firstname'] : '')}</div>
                                    </div>
                                    <div className="third">
                                        <div className="fs14 map-icon">{item['st_label']}</div>
                                        <div>
                                            {this.renderSkill(item["sk_label_s"])}
                                        </div>
                                    </div>
                                </div>
                                <div className="btn-container">
                                    <div className="grey-button v-small">
                                        <Link to={{ pathname: "/provider/profile/" + item['em_id'], state: { isRightMenuShow: '0' } }}>
                                            <div className="inner f-meiryo-bold">
                                                詳細
                                        </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }
}

// export default compose(
//     withRouter,
//     connect(mapStateToProps)
// )(SearchResultCom);
export default connect(mapStateToProps, mapDispatcherToProps)(SearchResultCom);