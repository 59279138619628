import React from 'react';
import '../../scss/job.scss';
import Header from '../../Include/Header'
import JobOpenCom from '../../Find/JobOpenCom'
import JobCloseCom from '../../Find/JobCloseCom'
import Footer from '../../Include/Footer';
import SearchNavCom from '../../Find/SearchNavCom';
class PJobView extends React.Component<any>{
    public render(){
        const url = this.props.match.url;    
        return (
            <div>
                <Header/>
                <div className="content-container" id = "content">
                    <div className="wrap-content">
                        <div className="main-title">仕事管理</div>
                        <div className="job_container mt_40">
                            <SearchNavCom to = {url}/>
                            <div className="job">
                                <JobOpenCom/>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}
export default PJobView; 