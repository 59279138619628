import { ActionType } from "typesafe-actions";
import * as actions from './actions';

export type SearchImmediateJob = {
  jb_date: string,
  jb_time: string,
}
export type SearchImmediateJobState = SearchImmediateJob & {
  loading: boolean,
  items?: Array<any>,
  errorMessage: string,
}
export enum actionTypes {
  SAVE = "searchimmdiatejob/save",
}
export type SearchImmediateJobActions = ActionType<typeof actions>;