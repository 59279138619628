import { UserInfoActions, actionTypes, UserState, UserInfo } from './types';

const initialState: UserState = {
    isLogIn:false,
    userInfo:{},
    roleCheckListener:null,
};
const LOGIN = actionTypes.LOGIN;
const LOGOUT = actionTypes.LOGOUT;
const PROFILE = actionTypes.PROFILE;
const CHANGE_AVARTA = actionTypes.CHANGE_AVARTA;
const ADD_ROLE_LISTENER = actionTypes.ADD_ROLE_LISTENER;
const REMOVE_ROLE_LISTENER = actionTypes.REMOVE_ROLE_LISTENER;
export function UserReducer(state: UserState = initialState, action: UserInfoActions ): UserState{
    switch (action.type ) {
        case LOGIN:
            return {...state, isLogIn:action.payload.isLogIn, userInfo:action.payload.userInfo };
        case PROFILE:
            return {...state, userInfo:action.payload.userInfo };                        
        case CHANGE_AVARTA:
            let userInfo:UserInfo = {...state.userInfo };
            if( action.payload.isBigImage ){
                userInfo.bigImage = action.payload.image
            }else{
                userInfo.image = action.payload.image;
            }
            return {...state, userInfo:userInfo };
        case LOGOUT:
            return {...state, isLogIn:false, userInfo:{}, roleCheckListener:null, };
        case ADD_ROLE_LISTENER:
            return {...state, roleCheckListener:action.payload.listener }; 
        case REMOVE_ROLE_LISTENER:
            return {...state, roleCheckListener:null }; 
        default:
            return state;            
    }
}