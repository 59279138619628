import React, { Component, ChangeEvent, FormEvent } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as asyncactions from '../../modules/chat/async-actions';
import { ChatActions } from '../../modules/chat/types';
import { IRootState } from '../../modules';
import '../scss/job.scss';
import '../scss/message.scss';
import * as Global from '../../modules/global';
import {AlertDialog} from '../Modal/Alert';
import {ConfirmDialog} from '../Modal/Confirm';

type MessageComProps = {
  to:number,
}
const mapStateToProps = ({ message }: IRootState, messageProps:MessageComProps ) => {
  const {text, messages } = message;
  const {to} = messageProps;  
  return { text, messages, to };
}

const mapDispatcherToProps = (dispatch: Dispatch<ChatActions>) => {
  return {
    writeMessage:(text:string ) => asyncactions.writeMessageAsync( dispatch, text ),
    sendMessage: ( from:number, to:number, id:number, text: string ) => asyncactions.sendMessageAsync(dispatch, from, to, id, text )
  }
}
type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps> ;

class MessagesendCom extends Component<ReduxType>{
  from:any = "";
  to:any = "";
  alertRef:any;
  confirmRef:any;

  constructor(props:ReduxType) {
    super(props);
    this.from = Global.getEmId();
    this.to = this.props.to.toString();
    this.alertRef = React.createRef();
    this.confirmRef = React.createRef();
  }

  render(){
    return (
      <div>
        <AlertDialog ref = {this.alertRef} returnURL = "/myprovider"/>   
        <ConfirmDialog ref = {this.confirmRef} func = { this.confirmSendMessage }/>                                     
        <form onSubmit={this.onSubmit}>
          <div className="message_input">
              <textarea value={this.props.text} onChange={this.onChangeAction} onKeyUp = {this.onKeyPress}>{ this.props.text }</textarea>
              <button type = "submit" className="send_btn">
                  <div className="send_btn_inner"></div>
              </button>
          </div>
      </form>
      </div>
    );
  }
  onKeyPress = ( e:any ) => {
    if (e.key ==='Enter' && e.ctrlKey){
      this.send();
    }
  }
  onChangeAction = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.props.writeMessage( e.target.value );
  };
  onSubmit = (e: FormEvent) => {
    e.preventDefault();
    this.send();
  };
  confirmSendMessage = () => {
    let id:number = 0;
    this.props.sendMessage( parseInt(this.from), parseInt(this.to), id, this.props.text );    
  }  
  send = () =>{
    if( this.props.text.trim() === '' ){
      return;
    }
    if( this.props.messages.length ){
      // let messages:Message[] = this.props.messages;
      // id = Math.max( ...messages.map( message => message.id ) );
    }
    if( this.from === "" || this.to === "" ){
      return;
    }
    const point = Global.getPoint();
    if( point < 50 ){
      this.alertRef.current.open( "お知らせ", "ポイントが不足しています。" );
    }else{
       //this.props.sendMessage( parseInt(this.from), parseInt(this.to), id, nl2br( this.props.text) );
      this.confirmRef.current.open( "お知らせ", "50ポイント消費します。よろしいですか。" );   
    }
  }
}
export default connect(mapStateToProps, mapDispatcherToProps)(MessagesendCom);