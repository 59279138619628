import React, { Component } from "react";
import "../scss/job.scss";
import "../scss/editprofile.scss";
import DraggableDialog from "../Modal/Dialog";
import { connect } from "react-redux";
import { IRootState } from "../../modules";
import { Dispatch } from "redux";
import {
  UserInfo,
  UserInfoActions,
  FireBaseUserInfo,
} from "../../modules/userinfo";
import * as asyncactions from "../../modules/userinfo/async-actions";
import SimpleReactValidator from "simple-react-validator";
import {
  VALIDATORS,
  VALIDATION_MESSAGES,
  YEAR,
  MONTH,
  DAY,
  DateToString,
  getSkills,
} from "../../modules/global";
import { Link } from "react-router-dom";
import { BasicDropDown } from "../Include/dropdown/BasicDropDown";
import * as URLS from "../../modules/urls";
import Loading from "../Include/Loading";
import { AlertDialog } from "../Modal/Alert";
import * as Global from "../../modules/global";
import { Redirect } from "react-router-dom";
import * as FireBaseUser from "../../firebase/api/users";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ja from "date-fns/locale/ja";

const mapStateToProps = ({ user }: IRootState) => {
  const { userInfo } = user;
  return { userInfo };
};
const mapDispatcherToProps = (dispatch: Dispatch<UserInfoActions>) => {
  return {
    getProfile: (role: string, userInfo: UserInfo) =>
      asyncactions.getProfileAsync(dispatch, role, userInfo),
  };
};
type ReduxType = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatcherToProps>;

class EditProfileCom extends Component<ReduxType> {
  mounted: boolean = false;
  validator: SimpleReactValidator;
  alertRef: any;
  areaRef: any;
  constructor(props: any) {
    super(props);
    registerLocale("ja", ja);
    this.validator = new SimpleReactValidator({
      messages: VALIDATION_MESSAGES,
      autoForceUpdate: this,
      className: "error",
      validators: VALIDATORS,
    });
    this.alertRef = React.createRef();
    this.areaRef = React.createRef();
  }
  state = {
    open: false,
    is_success: -1,
    loading: false,
    errorMessage: "",
    keyword: "",
    em_id: "-1",
    em_photo: "",
    em_photo_s: "",
    em_is_legal: "0",
    em_company: "",
    em_phone: "",
    em_company_legal: "",
    em_person_legal: "",
    em_founding: "",
    rj_em_founding: null,
    // year:"0",
    // month:"0",
    // day:"0",

    em_legal_number: "",
    em_total: "",
    em_depart: "",
    em_post: "",
    st_id: "",
    em_street_address: "",
    ar_id: "",
    em_contact_phone: "",
    sk_s: [],
    business1: "",
    business2: "",
    business3: "",
    em_skill_keyword: "",
    em_inputer_depart: "",
    em_inputer_pos: "",
    em_inputer_lastname: "",
    em_inputer_firstname: "",
    em_inputer_lastname_kana: "",
    em_inputer_firstname_kana: "",
    em_precontact: "",
    em_overview: "",
  };
  setOpen = (_open: boolean) => {
    this.setState({
      open: _open,
    });
  };
  openDialog = () => {
    this.setOpen(true);
  };
  shouldComponentUpdate() {
    return true;
  }
  handleFoundingDateChange = (data: Date) => {
    this.setState({
      rj_em_founding: data,
    });
  };
  onHandleChange = (e: any) => {
    let value: string = e.target.value;
    let name: string = e.target.name;
    this.setState({ [name]: value });
    if (name == "st_id") {
      this.setState({
        ar_id: "",
      });
      if (this.areaRef) {
        this.areaRef.current.fetch(URLS.GET_AREA_URL + "&st_id=" + value);
      }
    }
  };
  onHandleBlur = (e: any) => {
    let name: string = e.target.name;
    this.validator.showMessageFor(name);
  };
  componentDidMount() {
    this.mounted = true;
    let role = Global.getRole();
    if (role != "1") {
      return;
    }
    let em_id: any = Global.getEmId();
    let keyword = "boss";
    let url = URLS.GET_PROFILE + "?em_id=" + em_id + "&keyword=" + keyword;
    const that = this;
    this.setState({
      loading: true,
    });
    fetch(url, {
      method: "get",
    })
      .then(async function (response) {
        if (!that.mounted) {
          return;
        }
        let ret = await response.json();
        let dateList = DateToString(ret.emp.em_founding);
        let skills = getSkills(ret.emp.sk_s);
        Global.setEmBigPhoto(ret.emp.em_photo ? ret.emp.em_photo : "");
        Global.setEmSmallPhoto(ret.emp.em_photo_s ? ret.emp.em_photo_s : "");
        const today = new Date();
        const currentMonth = today.getMonth();
        const currentDate = today.getDate();
        that.setState({
          em_photo: ret.emp.em_photo ? ret.emp.em_photo : "",
          em_photo_s: ret.emp.em_photo_s ? ret.emp.em_photo_s : "",
          em_is_legal: ret.emp.em_is_legal ? ret.emp.em_is_legal : "0",
          em_phone: ret.emp.em_phone ? ret.emp.em_phone : "",
          em_company: ret.emp.em_company ? ret.emp.em_company : "",
          em_company_legal: ret.emp.em_company_legal
            ? ret.emp.em_company_legal
            : "",
          em_person_legal: ret.emp.em_person_legal
            ? ret.emp.em_person_legal
            : "",
          em_founding: ret.emp.em_founding ? ret.emp.em_founding : "",
          rj_em_founding: ret.emp.em_founding
            ? Global.stringToDate(
                Global.DateToString(ret.emp.em_founding, "/", "m/d/y", false)
              )
            : new Date(1980, currentMonth, currentDate, 0, 0, 0, 0),
          em_legal_number: ret.emp.em_legal_number
            ? ret.emp.em_legal_number
            : "",
          em_total: ret.emp.em_total ? ret.emp.em_total : "",
          em_depart: ret.emp.em_depart ? ret.emp.em_depart : "",
          em_post: ret.emp.em_post ? ret.emp.em_post : "",
          st_id: ret.emp.st_id ? ret.emp.st_id : "0",
          em_street_address: ret.emp.em_street_address
            ? ret.emp.em_street_address
            : "",
          ar_id: ret.emp.ar_id ? ret.emp.ar_id : "0",
          sk_s: [],
          em_contact_phone: ret.emp.em_contact_phone
            ? ret.emp.em_contact_phone
            : "",
          business1: skills.length >= 1 ? skills[0] : "",
          business2: skills.length >= 2 ? skills[1] : "",
          business3: skills.length >= 3 ? skills[2] : "",
          em_skill_keyword: ret.emp.em_skill_keyword
            ? ret.emp.em_skill_keyword
            : "",
          em_inputer_depart: ret.emp.em_inputer_depart
            ? ret.emp.em_inputer_depart
            : "",
          em_inputer_pos: ret.emp.em_inputer_pos ? ret.emp.em_inputer_pos : "",
          em_inputer_lastname: ret.emp.em_inputer_lastname
            ? ret.emp.em_inputer_lastname
            : "",
          em_inputer_firstname: ret.emp.em_inputer_firstname
            ? ret.emp.em_inputer_firstname
            : "",
          em_inputer_lastname_kana: ret.emp.em_inputer_lastname_kana
            ? ret.emp.em_inputer_lastname_kana
            : "",
          em_inputer_firstname_kana: ret.emp.em_inputer_firstname_kana
            ? ret.emp.em_inputer_firstname_kana
            : "",
          em_precontact: ret.emp.em_precontact ? ret.emp.em_precontact : "",
          em_overview: ret.emp.em_overview ? ret.emp.em_overview : "",
          loading: false,
        });
        that.areaRef.current.fetch(
          URLS.GET_AREA_URL + "&st_id=" + ret.emp.st_id
        );
      })
      .catch((error) => {
        this.setState({ loading: false, errorMessage: error.toString() });
      });
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  onUpdate = (e: any) => {
    if (this.validator.allValid()) {
      //        if (true){
      this.setState({
        is_success: -1,
        loading: true,
      });
      const that = this;
      const stateData = this.state;
      let em_id: any = Global.getEmId();
      stateData.em_id = em_id;
      stateData.sk_s = [];
      let sk_s = "";

      if (this.state.business1 !== "") {
        stateData.sk_s.push(this.state.business1);
        sk_s = this.state.business1;
      }
      if (this.state.business2 !== "") {
        stateData.sk_s.push(this.state.business2);
        if (sk_s !== "") {
          sk_s = sk_s + "," + this.state.business2;
        }
      }
      if (this.state.business3 !== "") {
        stateData.sk_s.push(this.state.business3);
        if (sk_s !== "") {
          sk_s = sk_s + "," + this.state.business3;
        }
      }

      stateData.keyword = "boss";
      stateData.em_founding = Global.dateFormat(
        this.state.rj_em_founding,
        "%m/%d/%Y",
        false
      );

      const data = {
        update: [stateData],
      };
      fetch(URLS.SAVE_PROFILE, {
        method: "post",
        body: JSON.stringify(data),
      })
        .then(async function (response) {
          let ret = await response.json();
          if (ret.success) {
            //that.alertRef.current.open( "成功", "サーバに保存されました。" );
            const em_name =
              that.state.em_is_legal === "0"
                ? that.state.em_company
                : that.state.em_company_legal;
            const em_role = "親方/会社";
            const point = Global.getPoint();
            const userInfo: UserInfo = {
              role: Global.getRole()!,
              em_name: em_name,
              em_role: em_role,
              point: point,
              sk_s: sk_s,
            };
            that.registerToFirebase();
            that.props.getProfile(Global.getRole()!, userInfo);
          } else {
            if (!that.mounted) {
              return;
            }
            that.setState({
              is_success: 0,
              loading: false,
            });
          }
          //return response.json();
        })
        .catch((error) => {
          if (!that.mounted) {
            return;
          }
          this.setState({
            loading: false,
            is_success: 0,
            errorMessage: error.toString(),
          });
          if (this.alertRef.current)
            this.alertRef.current.open(
              "エラー",
              "サーバへ保存できませんでした。"
            );
        });
    } else {
      this.validator.showMessages();
      this.setState({
        is_success: -2,
        loading: false,
      });

      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  };
  registerToFirebase = () => {
    let em_id: any = Global.getEmId();
    const fireBaseUserInfo: FireBaseUserInfo = {
      em_company: this.state.em_company,
      em_company_legal: this.state.em_company_legal,
      em_is_legal: this.state.em_is_legal,
      em_depart: this.state.em_depart,
      emt_is_boss: "1",
    };
    FireBaseUser.addUserInfo(em_id, fireBaseUserInfo)
      .then((result: any) => {
        if (!this.mounted) {
          return;
        }
        this.setState({
          is_success: 1,
          loading: false,
        });
      })
      .catch((error: any) => {
        this.alertRef.current.open("エラー", "サーバへ保存できませんでした。");
      });
  };
  renderRedirect = () => {
    const em_id = Global.getEmId();
    if (em_id === "") {
      let url: string = "/";
      return <Redirect to={url} />;
    } else {
      let role = Global.getRole();
      if (role !== "1") {
        let url: string = "/choose";
        return <Redirect to={url} />;
      } else {
        return <></>;
      }
    }
  };
  showResultMessage() {
    let ret = <></>;
    if (this.state.is_success === 1)
      return (
        <span className="notifiy layout_center">登録に成功しました。</span>
      );
    else if (this.state.is_success === 0)
      return (
        <span className="notifiy layout_center">登録できませんでした。</span>
      );
    else if (this.state.is_success === -2)
      return (
        <span className="notifiy layout_center">
          必須項目を入力してください。
        </span>
      );

    return ret;
  }
  public render() {
    this.validator.purgeFields();
    return (
      <div>
        {this.renderRedirect()}
        {this.state.loading ? <Loading /> : <></>}
        <div className="edit_profile_inner">
          <AlertDialog ref={this.alertRef} />
          <DraggableDialog
            open={this.state.open}
            setOpen={this.setOpen}
            clsName={"photopanel"}
          />
          <div className="flex_middle mb_50">
            <img
              alt=""
              className="profile_img mr_20"
              src={URLS.IMAGE_URL + Global.getEmSmallPhoto()}
            />
            {!Global.isIE() ? (
              <div className="pointer" onClick={this.openDialog}>
                <span className="fs14 underline">
                  {Global.getEmSmallPhoto() == "uphoto/dummy_s.jpg"
                    ? "写真を登録"
                    : "写真を変更"}
                </span>
              </div>
            ) : (
              <div>IEでは画像更新をサポートいたしません。</div>
            )}
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_150">
                <span className="tag-mg fs14">
                  <span className="tag-inner">会社名</span>
                </span>
              </div>
              <div className="mr_20">
                <label className="radio-item" onChange={this.onHandleChange}>
                  <span className="wd_30 m_wd_20">
                    <input
                      type="radio"
                      name="em_is_legal"
                      value="0"
                      checked={this.state.em_is_legal == "0" ? true : false}
                      onChange={this.onHandleChange}
                    />
                    <span className="radiobtn"></span>
                  </span>
                  <span className="txt">個人</span>
                </label>
              </div>
              <div className="wd_250">
                <input
                  type="text"
                  disabled={this.state.em_is_legal == "0" ? false : true}
                  name="em_company"
                  placeholder="屋号または氏名"
                  value={this.state.em_company}
                  onChange={this.onHandleChange}
                  onBlur={this.onHandleBlur}
                />
                {this.state.em_is_legal == "0" ? (
                  this.validator.message(
                    "em_company",
                    this.state.em_company,
                    "required"
                  )
                ) : (
                  <></>
                )}
              </div>
              <div className="mr_20">
                <label className="radio-item" onChange={this.onHandleChange}>
                  <span className="wd_30 m_wd_20">
                    <input
                      type="radio"
                      name="em_is_legal"
                      value="1"
                      checked={this.state.em_is_legal == "1" ? true : false}
                      onChange={this.onHandleChange}
                    />
                    <span className="radiobtn"></span>
                  </span>
                  <span className="txt">法人</span>
                </label>
              </div>
              <div className="wd_250">
                <input
                  type="text"
                  name="em_company_legal"
                  disabled={this.state.em_is_legal == "1" ? false : true}
                  value={this.state.em_company_legal}
                  placeholder="会社名"
                  onChange={this.onHandleChange}
                  onBlur={this.onHandleBlur}
                />
                {this.state.em_is_legal == "1" ? (
                  this.validator.message(
                    "em_company_legal",
                    this.state.em_company_legal,
                    "required"
                  )
                ) : (
                  <></>
                )}
              </div>
            </div>
            <span className="error_show">必須</span>
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_150">
                <span className="tag-mg fs14">
                  <span className="tag-inner">代表者</span>
                </span>
              </div>
              <div className="wd_310">
                <input
                  type="text"
                  name="em_person_legal"
                  value={this.state.em_person_legal}
                  placeholder="代表取締役社長　田中 ○○"
                  onChange={this.onHandleChange}
                  onBlur={this.onHandleBlur}
                />
                {this.validator.message(
                  "em_person_legal",
                  this.state.em_person_legal,
                  "required"
                )}
              </div>
            </div>
            <span className="error_show">必須</span>
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_150">
                <span className="tag-mg fs14">
                  <span className="tag-inner">創業/設立年月日</span>
                </span>
              </div>
              <div>
                <DatePicker
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => (
                    <div
                      style={{
                        margin: 10,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}
                      >
                        {"<"}
                      </button>
                      <select
                        value={date.getFullYear()}
                        onChange={({ target: { value } }) => {
                          changeYear(parseInt(value));
                        }}
                      >
                        {Global.BIRTH_YEAR.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <select
                        value={date.getMonth()}
                        onChange={({ target: { value } }) =>
                          changeMonth(parseInt(value))
                        }
                      >
                        {Global.MONTH.map((option) => (
                          <option key={option} value={parseInt(option) - 1}>
                            {option}
                          </option>
                        ))}
                      </select>

                      <button
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                      >
                        {">"}
                      </button>
                    </div>
                  )}
                  dateFormat="yyyy/MM/dd"
                  selected={this.state.rj_em_founding}
                  onChange={(date) => this.handleFoundingDateChange(date)}
                  locale="ja"
                />
                {this.validator.message(
                  "rj_em_founding",
                  this.state.rj_em_founding,
                  "required"
                )}
              </div>
            </div>
            <span className="error_show">必須</span>
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_150">
                <span className="tag-mg fs14">
                  <span className="tag-inner">法人番号</span>
                </span>
              </div>
              <div className="wd_250">
                <input
                  type="text"
                  name="em_legal_number"
                  value={this.state.em_legal_number}
                  onChange={this.onHandleChange}
                />
              </div>
            </div>
            <span className="error_show notify_show">任意</span>
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_150">
                <span className="tag-mg fs14">
                  <span className="tag-inner">従業員数</span>
                </span>
              </div>
              <div className="wd_120">
                <input
                  type="text"
                  name="em_total"
                  value={this.state.em_total}
                  onChange={this.onHandleChange}
                  onBlur={this.onHandleBlur}
                />
                {this.validator.message(
                  "em_total",
                  this.state.em_total,
                  "required|numeric|min:1,num"
                )}
              </div>
            </div>
            <span className="error_show">必須</span>
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_150">
                <span className="tag-mg fs14">
                  <span className="tag-inner">支店名/部署名</span>
                </span>
              </div>
              <div className="wd_200">
                <input
                  type="text"
                  name="em_depart"
                  value={this.state.em_depart}
                  className="full_wide"
                  onChange={this.onHandleChange}
                  onBlur={this.onHandleBlur}
                />
              </div>
            </div>
            <span className="error_show notify_show">任意</span>
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_150">
                <span className="tag-mg fs14">
                  <span className="tag-inner">所在地</span>
                </span>
              </div>
              <div>
                <div className="mt_10">
                  <span className="wd_65 fs14 text-right mr_5 inline_block">
                    郵便番号
                  </span>
                  <div className="wd_150 inline_block mr_5">
                    <input
                      type="text"
                      name="em_post"
                      className="full_wide"
                      value={this.state.em_post}
                      onChange={this.onHandleChange}
                      onBlur={this.onHandleBlur}
                    />
                    {this.validator.message(
                      "em_post",
                      this.state.em_post,
                      "postcode"
                    )}
                  </div>
                  <span className="error_show notify_show">任意</span>
                </div>
                <div className="mt_10">
                  <span className="wd_65 fs14 text-right mr_5 inline_block">
                    住所
                  </span>
                  <div className="wd_125 inline_block">
                    <BasicDropDown
                      className="full_wide"
                      name="st_id"
                      idKey={"st_id"}
                      valueKey={"st_label"}
                      url={URLS.GET_PREFECTURE_URL}
                      checkedValue={this.state.st_id}
                      defaultName="都道府県"
                      onHandleChange={this.onHandleChange}
                      onHandleBlur={this.onHandleBlur}
                      noPrefix={true}
                    />
                    {this.validator.message(
                      "st_id",
                      this.state.st_id,
                      "select"
                    )}
                  </div>
                </div>
                <div className="mt_10">
                  <span className="wd_65 fs14 text-right mr_5 inline_block"></span>
                  <div className="wd_125 inline_block">
                    <BasicDropDown
                      ref={this.areaRef}
                      className="full_wide"
                      name="ar_id"
                      idKey={"ar_id"}
                      valueKey={"ar_label"}
                      url={URLS.GET_AREA_URL + "&st_id=" + this.state.st_id}
                      checkedValue={this.state.ar_id}
                      defaultName="エリア"
                      onHandleChange={this.onHandleChange}
                      onHandleBlur={this.onHandleBlur}
                      noPrefix={true}
                    />
                    {this.validator.message(
                      "ar_id",
                      this.state.ar_id,
                      "select"
                    )}
                  </div>
                </div>
                <div className="mt_10">
                  <span className="wd_65 fs14 text-right mr_5 inline_block"></span>
                  <div className="wd_450 inline_block mr_5">
                    <input
                      type="text"
                      className="full_wide"
                      name="em_street_address"
                      value={this.state.em_street_address}
                      onChange={this.onHandleChange}
                      onBlur={this.onHandleBlur}
                    />
                  </div>
                  <span className="error_show notify_show">任意</span>
                </div>
              </div>
            </div>
            <span className="error_show">必須</span>
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_150">
                <span className="tag-mg fs14">
                  <span className="tag-inner">電話番号</span>
                </span>
              </div>
              <div className="wd_200">
                <input
                  type="text"
                  className="full_wide"
                  name="em_contact_phone"
                  value={this.state.em_contact_phone}
                  onChange={this.onHandleChange}
                  onBlur={this.onHandleBlur}
                />
                {this.validator.message(
                  "em_contact_phone",
                  this.state.em_contact_phone,
                  "required|phone"
                )}
              </div>
            </div>
            <span className="error_show">必須</span>
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_150">
                <span className="tag-mg fs14">
                  <span className="tag-inner">業種</span>
                </span>
              </div>
              <div>
                <div className="mt_10">
                  <span className="fs14 text-right mr_20 inline_block">
                    業種は3つまで追加できます
                  </span>
                  <Link to="/job_type" target="_blank">
                    <img
                      src={URLS.BASE_ABSOLUTE_URL + "/images/icon_help.jpg"}
                      className="mt_8"
                    />
                    <span className="fs14 text-right mr_5 inline_block">
                      業種説明
                    </span>
                  </Link>
                </div>
                <div className="mt_10">
                  <span className="round-title bussiness_round mr_20 inline_block">
                    業種1
                  </span>
                  <div className="wd_250 inline_block">
                    <BasicDropDown
                      className="full_wide"
                      name="business1"
                      idKey={"sk_id"}
                      valueKey={"sk_label"}
                      url={URLS.GET_BOSS_SKILL}
                      checkedValue={this.state.business1}
                      excludeValues={[this.state.business2, this.state.business3]}
                      defaultName="選択してください"
                      onHandleChange={this.onHandleChange}
                      onHandleBlur={this.onHandleBlur}
                      noPrefix={true}
                    />
                    {/* {this.validator.message('business1', this.state.business1, 'select' ) }*/}
                  </div>
                </div>
                <div className="mt_10">
                  <span className="round-title bussiness_round mr_20 inline_block">
                    業種2
                  </span>
                  <div className="wd_250 inline_block">
                    <BasicDropDown
                      className="full_wide"
                      name="business2"
                      idKey={"sk_id"}
                      valueKey={"sk_label"}
                      url={URLS.GET_BOSS_SKILL}
                      checkedValue={this.state.business2}
                      excludeValues={[this.state.business1, this.state.business3]}
                      defaultName="選択してください"
                      onHandleChange={this.onHandleChange}
                      onHandleBlur={this.onHandleBlur}
                      noPrefix={true}
                    />
                  </div>
                </div>
                <div className="mt_10">
                  <span className="round-title bussiness_round mr_20 inline_block">
                    業種3
                  </span>
                  <div className="wd_250 inline_block">
                    <BasicDropDown
                      className="full_wide"
                      name="business3"
                      idKey={"sk_id"}
                      valueKey={"sk_label"}
                      url={URLS.GET_BOSS_SKILL}
                      checkedValue={this.state.business3}
                      excludeValues={[this.state.business1, this.state.business2]}
                      defaultName="選択してください"
                      onHandleChange={this.onHandleChange}
                      onHandleBlur={this.onHandleBlur}
                      noPrefix={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <span className="error_show notify_show">任意</span>
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_200">
                <span className="tag-mg fs14">
                  <span className="tag-inner">検索⽤業種フリーワード</span>
                </span>
              </div>
              <div className="wd_590">
                <textarea
                  className="full_wide ht_60"
                  placeholder="ガラス、鏡、シーリング、発電設備工事、送配電線工事、クレーン"
                  name="em_skill_keyword"
                  value={this.state.em_skill_keyword}
                  onChange={this.onHandleChange}
                >
                  {this.state.em_skill_keyword}
                </textarea>
              </div>
            </div>
            <span className="error_show notify_show">任意</span>
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_150">
                <span className="tag-mg fs14">
                  <span className="tag-inner">入力担当者</span>
                </span>
              </div>
              <div>
                <div className="mt_10">
                  <span className="wd_65 fs14 text-right mr_5 inline_block">
                    部署名
                  </span>
                  <div className="wd_200 inline_block mr_5">
                    <input
                      type="text"
                      className="full_wide"
                      name="em_inputer_depart"
                      value={this.state.em_inputer_depart}
                      onChange={this.onHandleChange}
                    />
                  </div>
                  <span className="error_show notify_show">任意</span>
                </div>
                <div className="mt_10">
                  <span className="wd_65 fs14 text-right mr_5 inline_block">
                    役職
                  </span>
                  <div className="wd_250 inline_block mr_5">
                    <input
                      type="text"
                      className="full_wide"
                      name="em_inputer_pos"
                      value={this.state.em_inputer_pos}
                      onChange={this.onHandleChange}
                    />
                  </div>
                  <span className="error_show notify_show">任意</span>
                </div>
                <div className="mt_10">
                  <span className="wd_65 fs14 text-right mr_5 inline_block">
                    氏名
                  </span>
                  <div className="wd_140 inline_block mr_5 top">
                    <input
                      type="text"
                      className="full_wide"
                      name="em_inputer_lastname"
                      value={this.state.em_inputer_lastname}
                      placeholder="姓"
                      onChange={this.onHandleChange}
                      onBlur={this.onHandleBlur}
                    />
                    {this.validator.message(
                      "em_inputer_lastname",
                      this.state.em_inputer_lastname,
                      "required"
                    )}
                  </div>
                  <div className="wd_140 inline_block mr_5 top">
                    <input
                      type="text"
                      className="full_wide"
                      name="em_inputer_firstname"
                      value={this.state.em_inputer_firstname}
                      placeholder="名"
                      onChange={this.onHandleChange}
                      onBlur={this.onHandleBlur}
                    />
                    {this.validator.message(
                      "em_inputer_firstname",
                      this.state.em_inputer_firstname,
                      "required"
                    )}
                  </div>
                  <span className="error_show">必須</span>
                </div>
                <div className="mt_10">
                  <span className="wd_65 fs14 text-right mr_5 inline_block">
                    カナ
                  </span>
                  <div className="wd_140 inline_block top mr_5">
                    <input
                      type="text"
                      className="full_wide"
                      name="em_inputer_lastname_kana"
                      value={this.state.em_inputer_lastname_kana}
                      placeholder="セイ"
                      onChange={this.onHandleChange}
                      onBlur={this.onHandleBlur}
                    />
                    {this.validator.message(
                      "em_inputer_lastname_kana",
                      this.state.em_inputer_lastname_kana,
                      "required|furigana"
                    )}
                  </div>
                  <div className="wd_140 inline_block top mr_5">
                    <input
                      type="text"
                      className="full_wide"
                      name="em_inputer_firstname_kana"
                      value={this.state.em_inputer_firstname_kana}
                      placeholder="メイ"
                      onChange={this.onHandleChange}
                      onBlur={this.onHandleBlur}
                    />
                    {this.validator.message(
                      "em_inputer_firstname_kana",
                      this.state.em_inputer_firstname_kana,
                      "required|furigana"
                    )}
                  </div>
                  <span className="error_show">必須</span>
                </div>
                <div className="mt_10">
                  <span className="wd_65 fs14 text-right mr_5 inline_block">
                    電話番号
                  </span>
                  <div className="wd_280 inline_block mr_5">
                    <span className="fs14 inline_block mr_5">
                      {this.state.em_phone}
                    </span>
                    <img
                      className="inline_block mr_5 mt_8"
                      src={URLS.BASE_ABSOLUTE_URL + "/images/icon_verify.jpg"}
                    />
                    <span className="fs14 green inline_block">認証済</span>
                  </div>
                </div>
              </div>
            </div>
            <span className="error_show">必須</span>
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_150">
                <span className="tag-mg fs14">
                  <span className="tag-inner">事前連絡事項</span>
                </span>
              </div>
              <div className="wd_590">
                <textarea
                  className="full_wide ht_120"
                  placeholder="・原則、現場集合・ヘルメット等の基本的な最低限必要な道具は持参ください"
                  name="em_precontact"
                  onChange={this.onHandleChange}
                  value={this.state.em_precontact}
                >
                  {this.state.em_precontact}
                </textarea>
              </div>
            </div>
            <span className="error_show notify_show">任意</span>
          </div>
          <div className="element last">
            <div className="second_column">
              <div className="wd_150">
                <span className="tag-mg fs14">
                  <span className="tag-inner">紹介文</span>
                </span>
              </div>
              <div className="wd_590">
                <textarea
                  className="full_wide ht_160"
                  onChange={this.onHandleChange}
                  name="em_overview"
                  value={this.state.em_overview}
                >
                  {this.state.em_overview}
                </textarea>
              </div>
            </div>
            <span className="error_show notify_show">任意</span>
          </div>
          {this.showResultMessage()}
          <div className="button-container">
            <button className="grey-button large mt_50" onClick={this.onUpdate}>
              <div className="inner">
                登録する
                <i className="fa fa-angle-right"></i>
              </div>
            </button>
          </div>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatcherToProps)(EditProfileCom);
