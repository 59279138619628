import React, { Component } from "react";
import "../scss/job.scss";
import { Link } from "react-router-dom";
import * as URLS from "../../modules/urls";
import Loading from "../Include/Loading";
import { JobDetailComProp } from "../../modules/job/types";
import { AlertDialog } from "../Modal/Alert";
import * as FirebaseAPI from "../../firebase/api/message";
import * as Global from "../../modules/global";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import * as asyncactions from "../../modules/chat/async-actions";
import * as footerAsyncactions from "../../modules/footer/async-actions";
import { ChatActions } from "../../modules/chat/types";
import { IRootState } from "../../modules";
import { FooterActions } from "../../modules/footer";

type JobDirectOpenTypes = {
  em_id?: string;
};

const mapStateToProps = ({ message }: IRootState) => {
  const { text, messages } = message;
  return { text, messages };
};

const mapDispatcherToProps = (
  dispatch: Dispatch<ChatActions | FooterActions>
) => {
  return {
    sendSystemMessage: (
      from: number,
      to: number,
      docId: string,
      cmsg: string,
      title: string = "",
      emt_id: number,
      isKuraha: number = 1,
      isFromMe: boolean = true,
      msg = ""
    ) =>
      asyncactions.sendSystemMessageAsync(
        dispatch,
        from,
        to,
        docId,
        cmsg,
        title,
        emt_id,
        isKuraha,
        isFromMe,
        msg
      ),
    isRerender: (_isRerender: boolean) =>
      footerAsyncactions.isRerender(dispatch, _isRerender),
  };
};
type ReduxType = ReturnType<typeof mapDispatcherToProps> & JobDirectOpenTypes;

class JobOpenCom extends Component<ReduxType> {
  alertRef: any;
  mounted: boolean = false;
  state = {
    loading: false,
    items: [],
  };
  constructor(props: any) {
    super(props);
    this.alertRef = React.createRef();
  }
  componentWillUnmount = () => {
    this.mounted = false;
  };
  componentDidMount() {
    const that = this;
    this.mounted = true;
    this.setState({
      loading: true,
    });
    let em_id: any = Global.getEmId();
    const url: string =
      URLS.READ_OPEN_JOB +
      "&em_id=" +
      em_id +
      '&sort=[{"property":"jb_created","direction":"desc"},{"property":"jb_id","direction":"desc"}]';
    fetch(url, {
      method: "get",
    })
      .then(async function (response) {
        let ret = await response.json();
        if (!that.mounted) {
          return;
        }
        if (ret.total > 0) {
          const items = ret.items;
          that.setState(
            {
              loading: false,
              items: items,
            },
            async () => {
              //await that.props.isRerender( true );
            }
          );
        } else {
          that.setState(
            {
              loading: false,
              items: [],
            },
            async () => {
              //await that.props.isRerender( true );
            }
          );
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          items: [],
          errorMessage: error.toString(),
        });
      });
  }
  onApply = (w_em_id: number, jobId: number, jobName: string) => {
    const that = this;
    let em_id: any = Global.getEmId();
    if (em_id == "" || !w_em_id || !jobId) {
      return;
    }
    const point = Global.getPoint();
    if (point < 50) {
      this.alertRef.current.open("お知らせ", "ポイントが不足しています。");
      return;
    }
    let em_worker_company: any =
      localStorage.getItem("em_boss_company") != undefined
        ? localStorage.getItem("em_boss_company")?.toString()
        : "ニックネーム";
    if (em_worker_company === "") {
      em_worker_company = "ニックネーム";
    }
    let jb_id = jobId;
    let data = {
      em_id: w_em_id,
      jb_id: jb_id,
      jba_is_direct: 1,
    };
    fetch(URLS.APPLY_JOB, {
      method: "post",
      body: JSON.stringify(data),
    })
      .then(async function (response) {
        let ret = await response.json();
        if (ret.success) {
          let msg: string =
            em_worker_company + "があなたに" + `<pre jb_id="${jb_id}" type="contract">` + jobName +`</pre>` + "を依頼しました。";
          const contract = ret.data;
          let workerName: string = "";
          if (contract) {
            workerName = contract.em_firstname + contract.em_lastname + "さん";
          }
          const myMsg: string =
            workerName + "に" + `<pre jb_id="${jb_id}" type="contract">` + jobName +`</pre>`+ "の仕事を依頼しました。";

          const successMsg: string = workerName + "に" + jobName + "の仕事を依頼しました。";
          //let docId:string = em_id + "_" + jb_id;
          let docId: string = em_id;
          //FirebaseAPI.updateContacts( em_id, w_em_id, docId, msg ,"仕事の依頼がありました", parseInt(Global.getRole()!), 1, false,"仕事を依頼しました。" );
          that.props.sendSystemMessage(
            em_id,
            w_em_id,
            docId,
            msg,
            "仕事の依頼がありました",
            parseInt(Global.getRole()!),
            1,
            false,
            myMsg
          );
          //FirebaseAPI.addSystemMessage( em_id, w_em_id, "仕事を依頼しました。", parseInt(Global.getRole()!) );
          that.alertRef.current.open("成功", successMsg);
        } else {
          that.alertRef.current.open("失敗", ret.msg);
        }
        that.setState({
          loading: false,
        });
      })
      .catch((error) => {
        this.alertRef.current.open("エラー", "サーバへ保存できませんでした。");
        this.setState({ loading: false, errorMessage: error.toString() });
      });
  };
  //     componentDidUpdate = ( prevProps:ReduxType, prevState: any ) => {
  //         if( prevState.items.length != this.state.items.length ) {
  // console.log( 'JobOpenCom***=' + this.state.items.length );
  //             this.props.isRerender( true );
  //         }
  //     }
  render() {
    return (
      <div>
        {this.state.loading ? <Loading /> : <></>}
        <div className="job_inner">
          <AlertDialog ref={this.alertRef} />
          {this.state.items.length !== 0 ? (
            <div className="title">
              募集中の仕事
              <span className="mark_choosed_job_notify blue right_show inline_block ml_20">応募のあった仕事</span>
              <span className="mark_choosed_job_notify green right_show inline_block ml_20">直接依頼</span>
              <span className="mark_choosed_job_notify right_show">
                職人決定済み
              </span>
            </div>
          ) : (
            <div className="title">募集中の仕事</div>
          )}
          {this.state.items.length === 0 ? (
            <span className="no_content">募集中の仕事がありません。</span>
          ) : (
            <></>
          )}
          {this.state.items.map((item) => (
            <div className="element flex" key={item["jb_id"]}>
              <Link to={"/jobdetail/" + item["jb_id"] + "/"}>
                <div className="job_title">
                  <span>{item["jb_comp_name"]}</span>
                  {parseInt(item["cnt_ctr"]) > 0 ? (
                    <span className="mark_choosed_job ml_10"></span>
                  ) : ( <></> )}
                  {
                    ( parseInt(item["cnt_apply"]) - parseInt(item["cnt_apply_direct"]) ) > 0?
                    <span className="mark_apply_job ml_10"></span>
                    :
                    <></>
                  }
                  {item["cnt_apply_direct"] > 0 ? (
                    <span className="direct_job ml_10"></span>
                  ) : (
                    <></>
                  )}
                </div>
              </Link>
              {this.props.em_id && this.props.em_id !== "" ? (
                <div className="btn_area">
                  <div
                    className="grey-button vv-small mr_10"
                    onClick={() =>
                      this.onApply(
                        item["em_id"],
                        item["jb_id"],
                        item["jb_comp_name"]
                      )
                    }
                  >
                    <div className="inner">仕事を依頼する</div>
                  </div>
                </div>
              ) : (
                <div className="btn_area">
                  <div className="grey-button vv-small mr_10">
                    <Link to={"/jobdetail/" + item["jb_id"]}>
                      <div className="inner">詳細</div>
                    </Link>
                  </div>
                  <div className="grey-button vv-small">
                    <Link
                      to={
                        "/applicant_list/" +
                        item["jb_id"] +
                        "/" +
                        item["jb_comp_name"]
                      }
                    >
                      <div className="inner">
                        応募者・直接依頼した職人を見る
                      </div>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatcherToProps)(JobOpenCom);
