import { ActionType } from "typesafe-actions";
import * as actions from './actions';

export type SearchImmediatePeople = {
  jb_date: string,
  jb_time: string,
}
export type SearchImmediatePeopleState = SearchImmediatePeople & {
  loading: boolean,
  items?: Array<any>,
}
export enum actionTypes {
  SAVE = "searchimmdiatepeople/save",
}
export type SearchImmediatePeopleActions = ActionType<typeof actions>;