import React from 'react';
import * as URLS from '../../modules/urls';
import { Link, withRouter } from 'react-router-dom';

const ProviderNavigation = (props) => {
    return (
        <div bg="primary" variant="dark">
            <div id="basic-navbar-nav">
                <div className="menu-container">
                    <div className="inner">
                        <ul>
                            <li>
                                <Link to="/provider/search">
                                <div className="element">
                                    <img alt = '' src = { URLS.BASE_ABSOLUTE_URL + "/images/search_icon.png"}/><span className="desc">仕事を探す</span>									
                                </div>
                                </Link>
                            </li>
                            <li>
                                <Link to = "/myschedule">
                                    <div className="element">
                                        <img alt = ''  src = { URLS.BASE_ABSOLUTE_URL + "/images/job_icon.png"}/><span className="desc longer">空き時間管理</span>			
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link to="/messagelist">
                                    <div className="element">
                                        <img alt = ''  src = { URLS.BASE_ABSOLUTE_URL + "/images/message_icon.png"}/><span className="desc">メッセージ</span>
                                        {
                                            props.messageCount===0
                                            ?
                                            <></>
                                            :
                                            <span className="circle">{props.messageCount}</span>
                                        }
                                    </div>								
                                </Link>
                            </li>
                            <li className="last">
                                <Link to ="/provider/myprofile">
                                    <div className="element">
                                        <img alt = ''  src = { URLS.BASE_ABSOLUTE_URL + "/images/avata_icon.png"}/><span className="desc">プロフィール</span>
                                    </div>
                                </Link>    
                            </li>

                        </ul>
                    </div>                    
                </div>
            </div>
        </div>
    )
}

export default withRouter(ProviderNavigation);