import React from "react";
import "../scss/job.scss";
import "../scss/profile.scss";
import "../scss/change_type.scss";
import { Link } from "react-router-dom";
import * as Global from "../../modules/global";
import { Redirect } from "react-router-dom";
import * as URLS from "../../modules/urls";
import Loading from "../Include/Loading";
import { AlertDialog } from "../Modal/Alert";
import * as FireBaseUser from "../../firebase/api/users";
import { FireBaseUserInfo } from "../../modules/userinfo";

class ChangeAccountTypeconfirmCom extends React.Component<any> {
  mounted: boolean = false;
  alertRef: any;
  state = {
    loading: false,
  };
  constructor(props: any) {
    super(props);
    this.alertRef = React.createRef();
  }
  componentDidMount() {
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  renderRedirect = () => {
    let role = Global.getRole();
    let em_id: any = Global.getEmId();
    if (em_id === "") {
      let url = "/myprofile";
      if (role === "2") {
        url = "/provider/myprofile";
      }
      return <Redirect to={url} />;
    }
    return <></>;
  };
  onUpdateEmt = (e: any) => {
    let t_emt_id = Global.getTempEmtId();
    let em_id: any = Global.getEmId();
    const data = {
      update: [
        {
          em_id: em_id,
          emt_id: t_emt_id,
        },
      ],
    };
    this.setState({
      loading: true,
    });
    const that = this;
    fetch(URLS.SAVE_PROFILE, {
      method: "post",
      body: JSON.stringify(data),
    })
      .then(async function (response) {
        let ret = await response.json();
        if (ret.success) {
          const tempEmtId = Global.getTempEmtId()!;
          Global.setEmtIdById(tempEmtId);
          await that.registerToFirebase();
          Global.setTempEmtId("");
          that.alertRef.current.open("成功", "サーバに保存されました。", true);
          // if( emtId == '1' || emtId == '2' ){ // if you change your role to another one, you have to go to login.
          //     if(tempEmtId != '3' ){
          //         Global.setEmId('');
          //     }
          // }
          Global.setEmId("");
          Global.setDefaultRole("");
          if (!that.mounted) {
            return;
          }
          that.setState({
            loading: false,
          });
        } else {
          that.alertRef.current.open(
            "エラー",
            "サーバへ保存できませんでした。"
          );
          if (!that.mounted) {
            return;
          }
          that.setState({
            loading: false,
          });
        }
      })
      .catch((error) => {
        if (this.alertRef.current)
          this.alertRef.current.open(
            "エラー",
            "サーバへ保存できませんでした。"
          );
        if (!that.mounted) {
          return;
        }
        this.setState({ loading: false, errorMessage: error.toString() });
      });
  };
  registerToFirebase = () => {
    return;
    let em_id: any = Global.getEmId();
    let t_emt_id = Global.getTempEmtId();

    let emt_is_boss: string = "0";
    let emt_is_worker: string = "0";
    if (t_emt_id === "1") {
      emt_is_boss = "1";
    } else if (t_emt_id === "2") {
      emt_is_worker = "1";
    } else if (t_emt_id === "3") {
      emt_is_boss = "1";
      emt_is_worker = "1";
    }
    const fireBaseUserInfo: FireBaseUserInfo = {
      emt_is_boss: emt_is_boss,
      emt_is_worker: emt_is_worker,
    };
    FireBaseUser.addUserInfo(em_id, fireBaseUserInfo)
      .then((result: any) => {})
      .catch((error: any) => {
        this.alertRef.current.open("エラー", "サーバへ保存できませんでした。");
      });
  };
  public render() {
    let t_emt_id: string | null = Global.getTempEmtId();
    let emtId = Global.getEmtId();
    let emtName = "";
    let otherEmtName = "";
    let status = "";
    if (emtId === "1") {
      emtName = "親方/会社";
      status = "親方/会社";
    } else if (emtId === "2") {
      emtName = "職人";
      status = "職人";
    } else if (emtId === "3") {
      emtName = "親方/会社・職人";
      status = "親方/会社・職人";
    }
    if (t_emt_id) {
      if (t_emt_id === "1") {
        emtName = "親方/会社";
        otherEmtName = "職人";
      } else if (t_emt_id === "2") {
        emtName = "職人";
        otherEmtName = "親方/会社";
      } else {
        otherEmtName = "親方/会社・職人";
      }
    }
    const role = Global.getRole();
    return (
      <div className="change_type">
        {this.state.loading ? <Loading /> : <></>}
        {this.renderRedirect()}
        <AlertDialog
          ref={this.alertRef}
          returnURL={role === "1" ? "/myprofile/" : "provider/myprofile"}
        />
        <div className="change_type_inner">
          <div>
            <div className="fs16 f-meiryo-bold">現在の登録形態：{status}</div>
            {t_emt_id !== "3" ? (
              <div className="fs16 mt_30 dark_red">
                「{emtName}」に変更すると、「{otherEmtName}
                」のプロフィール、{t_emt_id == '1'?`応募仕事履歴`:t_emt_id == '2'?`仕事`:``}など登録内容がすべて削除されます。
                <br />
                よろしいですか？
                <br />
                変更後再度ログインしてください。
              </div>
            ) : (
              <div className="fs16 mt_30 dark_red">
                  変更後再度ログインしてください。
              </div>
            )}
            <div className="ml_20 mt_40">
              {emtId !== "1" ? (
                <label className="radio-item mr_20">
                  <span className="wd_30 m_wd_20">
                    <input
                      type="radio"
                      name="emt_id"
                      value="1"
                      checked={t_emt_id === "1" ? true : false}
                      disabled={true}
                    />
                    <span className="radiobtn"></span>
                  </span>
                  <span className="txt">親方/会社</span>
                </label>
              ) : (
                <></>
              )}
              {emtId !== "2" ? (
                <label className="radio-item mr_20">
                  <span className="wd_30 m_wd_20">
                    <input
                      type="radio"
                      name="emt_id"
                      value="2"
                      checked={t_emt_id === "2" ? true : false}
                      disabled={true}
                    />
                    <span className="radiobtn"></span>
                  </span>
                  <span className="txt">職人</span>
                </label>
              ) : (
                <></>
              )}
              {emtId !== "3" ? (
                <label className="radio-item">
                  <span className="wd_30 m_wd_20">
                    <input
                      type="radio"
                      name="emt_id"
                      value="3"
                      checked={t_emt_id === "3" ? true : false}
                      disabled={true}
                    />
                    <span className="radiobtn"></span>
                  </span>
                  <span className="txt">親方/会社・職人</span>
                </label>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className="button-container">
          <div className="grey-button white_bk large mt_70">
            <Link to={role === "1" ? "/myprofile/" : "/provider/myprofile"}>
              <div className="inner">
                キャンセルする
                <i className="fa fa-angle-left"></i>
              </div>
            </Link>
          </div>
          <div className="grey-button large mt_30">
            <div className="inner" onClick={this.onUpdateEmt}>
              登録形態を変更する
              <i className="fa fa-angle-right"></i>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ChangeAccountTypeconfirmCom;
