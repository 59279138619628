import React from 'react';
import '../scss/job.scss';
import '../scss/recommend.scss';
import Header from '../Include/Header'
import JobNavCom from "./JobNavCom";
import MyProviderCom from './MyProviderCom'
import Footer from '../Include/Footer';
class MyProvider extends React.Component<any>{
    public render(){
        const url = this.props.match.url;
        return (
            <div>
                <Header/>
                <div className="content-container" id = "content">
                    <div className="wrap-content">
                        <div className="main-title">仕事管理</div>
                        <div className="job_container mt_40">
                            <JobNavCom to = {url}/>
                            <div className="job recommend">
                                <div className="job_inner">
                                    <MyProviderCom/>
                                </div>    
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }

}
export default MyProvider; 