import React, {Component} from 'react';
import '../scss/job.scss';
import '../scss/editprofile.scss';
import {Link} from 'react-router-dom';
import Header from '../Include/Header'
import CreateAccountConfirmCom from './CreateAccountConfirmCom';
import Footer from '../Include/Footer';

class CreateAccountConfirm extends React.Component<any>{
    public render(){
        const url = this.props.match.url;
        return (
            <div>
                {/* <Header/> */}
                <div className="content-container" id = "content">
                    <div className="wrap-content">
                        <div className="main-title">新規登録</div>
                        <div className="job_container mt_40">
                            <div className ="job edit_profile create_profile">
                                <CreateAccountConfirmCom/>
                            </div>                            
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}
export default CreateAccountConfirm;