import React, { Component } from 'react';
import '../../scss/job.scss';
import '../../scss/search.scss';
import { Link } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import * as Global from '../../../modules/global';
import * as URLS from '../../../modules/urls';
import { BasicDropDown } from '../../Include/dropdown/BasicDropDown';
import BasicCheckBox from '../../Include/checkbox/BasicCheckBox';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ja from "date-fns/locale/ja";
import { connect } from 'react-redux';
import { IRootState } from '../../../modules';
import { Dispatch } from 'redux';
import * as asyncactions from '../../../modules/searchclient/async-actions';
import { AlertDialog } from '../../Modal/Alert';

import { SearchClient, SearchClientState, SearchClientActions } from '../../../modules/searchclient/types';
import { SearchArea } from '../../../modules/searchprovider';

const mapStateToProps = ({ searchClient }: IRootState) => {
  return { searchClient };
};
const mapDispatcherToProps = (dispatch: Dispatch<SearchClientActions>) => {
  return {
    save: (searchClient: SearchClient) => asyncactions.save(dispatch, searchClient),
  };
};

type ReduxType = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatcherToProps> & any

class PSearchCom extends Component<ReduxType, SearchClientState>{
  validator: SimpleReactValidator;

  alertRef: any;

  areaRef_0_1: any;
  areaRef_0_2: any;
  areaRef_0_3: any;

  areaRef_1_1: any;
  areaRef_1_2: any;
  areaRef_1_3: any;

  areaRef_2_1: any;
  areaRef_2_2: any;
  areaRef_2_3: any;
  state = {
    loading: false,
    jb_keyword: '',
    is_date_search: "0",
    jb_open_date: null,
    jb_close_date: null,
    jb_date: null,
    jb_time: '0',
    jb_total: '',
    sk_s: new Array<string>(),
    searchAreas: new Array<SearchArea>(),
    em_is_foreign: "0",
    em_daily_price_low: '',
    em_daily_price_hi: '',
  }
  constructor(props: any) {
    super(props);
    registerLocale("ja", ja);
    this.validator = new SimpleReactValidator({
      messages: Global.VALIDATION_MESSAGES,
      autoForceUpdate: this,
      className: 'error',
      validators: Global.VALIDATORS
    });

    this.alertRef = React.createRef();

    this.areaRef_0_1 = React.createRef();
    this.areaRef_0_2 = React.createRef();
    this.areaRef_0_3 = React.createRef();

    this.areaRef_1_1 = React.createRef();
    this.areaRef_1_2 = React.createRef();
    this.areaRef_1_3 = React.createRef();

    this.areaRef_2_1 = React.createRef();
    this.areaRef_2_2 = React.createRef();
    this.areaRef_2_3 = React.createRef();
  }
  componentDidMount() {
    const searchClient: SearchClientState = this.props.searchClient;
    this.setState({
      jb_keyword: searchClient.jb_keyword,
      is_date_search: searchClient.is_date_search,
      jb_open_date: searchClient.jb_open_date,
      jb_close_date: searchClient.jb_close_date,
      jb_date: searchClient.jb_date,
      jb_time: searchClient.jb_time,
      jb_total: searchClient.jb_total,
      sk_s: searchClient.sk_s,
      searchAreas: searchClient.searchAreas,
      em_is_foreign: searchClient.em_is_foreign,
      em_daily_price_low: searchClient.em_daily_price_low,
      em_daily_price_hi: searchClient.em_daily_price_hi,
    });
  }
  handleChange = (data: Date) => {
    this.setState({
      jb_open_date: data,
    });
  }
  handleChange_1 = (data: Date) => {
    this.setState({
      jb_close_date: data,
    });
  }
  handleChange_2 = (data: Date) => {
    this.setState({
      jb_date: data,
    });
  }

  onHandleChange = (e: any) => {
    let value: string = e.target.value;
    let name: string = e.target.name;
    if (name !== "st_id_0" && name !== "st_id_1" && name !== "st_id_2"
      && name !== 'ar_id_0_1' && name !== 'ar_id_0_2' && name !== 'ar_id_0_3'
      && name !== 'ar_id_1_1' && name !== 'ar_id_1_2' && name !== 'ar_id_1_3'
      && name !== 'ar_id_2_1' && name !== 'ar_id_2_2' && name !== 'ar_id_2_3') {
      //this.setState({ [name]: value });
      this.setState(prevState => ({
        ...prevState,
        [name]: value // No error here, but can't ensure that key is in StateKeys
      }));
    }
    if (name === 'ar_id_0_1' || name === 'ar_id_0_2' || name === 'ar_id_0_3') {
      const idx = parseInt(name.split('ar_id_0_')[1]);
      const searchAreas: Array<SearchArea> = this.state.searchAreas;
      searchAreas[0] = {
        st_id: searchAreas[0].st_id,
        ar_id_1: idx === 1 ? value : searchAreas[0].ar_id_1,
        ar_id_2: idx === 2 ? value : searchAreas[0].ar_id_2,
        ar_id_3: idx === 3 ? value : searchAreas[0].ar_id_3,
      }
      this.setState({
        searchAreas: searchAreas.concat([]),
      });
    }
    if (name === 'ar_id_1_1' || name === 'ar_id_1_2' || name === 'ar_id_1_3') {
      const idx = parseInt(name.split('ar_id_1_')[1]);
      const searchAreas: Array<SearchArea> = this.state.searchAreas;
      searchAreas[1] = {
        st_id: searchAreas[1].st_id,
        ar_id_1: idx === 1 ? value : searchAreas[1].ar_id_1,
        ar_id_2: idx === 2 ? value : searchAreas[1].ar_id_2,
        ar_id_3: idx === 3 ? value : searchAreas[1].ar_id_3,
      }
      this.setState({
        searchAreas: searchAreas.concat([]),
      });
    }
    if (name === 'ar_id_2_1' || name === 'ar_id_2_2' || name === 'ar_id_2_3') {
      const idx = parseInt(name.split('ar_id_2_')[1]);
      const searchAreas: Array<SearchArea> = this.state.searchAreas;
      searchAreas[2] = {
        st_id: searchAreas[2].st_id,
        ar_id_1: idx === 1 ? value : searchAreas[2].ar_id_1,
        ar_id_2: idx === 2 ? value : searchAreas[2].ar_id_2,
        ar_id_3: idx === 3 ? value : searchAreas[2].ar_id_3,
      }
      this.setState({
        searchAreas: searchAreas.concat([]),
      });
    }
    if (name === "st_id_0" || name === "st_id_1" || name === "st_id_2") {
      const idx = parseInt(name.split('st_id_')[1]);
      const searchAreas: Array<SearchArea> = this.state.searchAreas;
      searchAreas[idx] = {
        st_id: value,
        ar_id_1: '',
        ar_id_2: '',
        ar_id_3: '',
      }
      this.setState({
        searchAreas: searchAreas.concat([]),
      });
      if (idx === 0) {
        if (this.areaRef_0_1) {
          this.areaRef_0_1.current.fetch(URLS.GET_AREA_URL + "&st_id=" + value);
        }
        if (this.areaRef_0_2) {
          this.areaRef_0_2.current.fetch(URLS.GET_AREA_URL + "&st_id=" + value);
        }
        if (this.areaRef_0_3) {
          this.areaRef_0_3.current.fetch(URLS.GET_AREA_URL + "&st_id=" + value);
        }
      } else if (idx === 1) {
        if (this.areaRef_1_1) {
          this.areaRef_1_1.current.fetch(URLS.GET_AREA_URL + "&st_id=" + value);
        }
        if (this.areaRef_1_2) {
          this.areaRef_1_2.current.fetch(URLS.GET_AREA_URL + "&st_id=" + value);
        }
        if (this.areaRef_1_3) {
          this.areaRef_1_3.current.fetch(URLS.GET_AREA_URL + "&st_id=" + value);
        }
      } else if (idx === 2) {
        if (this.areaRef_2_1) {
          this.areaRef_2_1.current.fetch(URLS.GET_AREA_URL + "&st_id=" + value);
        }
        if (this.areaRef_2_2) {
          this.areaRef_2_2.current.fetch(URLS.GET_AREA_URL + "&st_id=" + value);
        }
        if (this.areaRef_2_3) {
          this.areaRef_2_3.current.fetch(URLS.GET_AREA_URL + "&st_id=" + value);
        }
      }
    } else if (name === "sk_s[]") {
      const target = e.target;
      const checked = target.type === "checkbox" ? target.checked : '';
      if (!checked) {
        const index = this.state.sk_s.indexOf(value);
        if (index > -1) {
          let business: Array<any> = this.state.sk_s.slice(0, index);
          business = business.concat(this.state.sk_s.slice(index + 1, this.state.sk_s.length));
          this.setState({
            sk_s: business,
          });
        }
      } else {
        const business = this.state.sk_s.concat(value);
        this.setState({
          sk_s: business,
        });
      }
      this.validator.showMessageFor(name);
    } else if (name === "is_date_search") {
      if (value === '0') {
        this.setState({
          jb_date: null,
        })
      } else {
        this.setState({
          jb_open_date: null,
          jb_close_date: null,
        })
      }
    }
  }
  onSearch = (e: any) => {
    let em_id: any = Global.getEmId();
    if (em_id === '') {
      return;
    }

    if (this.state.jb_open_date != null && this.state.jb_close_date != null
      && this.state.jb_open_date! > this.state.jb_close_date!) {
      this.alertRef.current.open("エラー", "開始日は終了日より先になります。");
      return;
    }

    if (this.state.em_daily_price_low != '' && this.state.em_daily_price_hi != ''
      && parseFloat(this.state.em_daily_price_low) > parseFloat(this.state.em_daily_price_hi)) {
      this.alertRef.current.open("エラー", "金額範囲の選定が正しくありません。");
      return;
    }

    if (this.validator.allValid()) {
      this.props.save(this.state);
      this.goToResultPage(em_id);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  goToResultPage = (em_id: string) => {
    this.props.history.push({
      pathname: '/provider/searchresult',
      //state: { params: params }
      state: { params: this.state },
    })
  }
  onHandleBlur = (e: any) => {
    let name: string = e.target.name;
    this.validator.showMessageFor(name);
  }
  renderSkill(skillStr: string) {
    if (!skillStr) {
      return [];
    }
    const skills = skillStr.split(',');
    let ret = [];
    for (let i = 0; i < skills.length; i++) {
      ret.push(<span className="tag-mg fs14" key={i}><span className="tag-inner">{skills[i]}</span></span>)
    }
    return ret;
  }
  renderSearchAreaElement = () => {
    const ret = [];
    for (let i = 0; i < this.state.searchAreas.length; i++) {
      const area = this.state.searchAreas[i];
      let ref_1 = null;
      let ref_2 = null;
      let ref_3 = null;
      if (i === 0) {
        ref_1 = this.areaRef_0_1;
        ref_2 = this.areaRef_0_2;
        ref_3 = this.areaRef_0_3;
      } else if (i === 1) {
        ref_1 = this.areaRef_1_1;
        ref_2 = this.areaRef_1_2;
        ref_3 = this.areaRef_1_3;
      } else if (i === 2) {
        ref_1 = this.areaRef_2_1;
        ref_2 = this.areaRef_2_2;
        ref_3 = this.areaRef_2_3;
      }
      const key = `searcharea_` + i;
      ret.push(
        <div key={key} className={i !== 0 ? `mt_5` : ''}>
          <div className="wd_100 inline_block mr_5">
            <BasicDropDown
              className="full_wide" name={"st_id_" + i} idKey={"st_id"} valueKey={"st_label"} url={URLS.GET_PREFECTURE_URL} checkedValue={area.st_id} defaultName="都道府県"
              onHandleChange={this.onHandleChange} onHandleBlur={this.onHandleBlur} noPrefix={true} />
          </div>
          <div className="wd_100 inline_block mr_5">
            <BasicDropDown ref={ref_1}
              className="full_wide" name={`ar_id_${i}_1`} idKey={`ar_id`} valueKey={"ar_label"} url={URLS.GET_AREA_URL + "&st_id=" + area.st_id} checkedValue={area.ar_id_1} defaultName="エリア"
              onHandleChange={this.onHandleChange} onHandleBlur={this.onHandleBlur} noPrefix={true} />
          </div>
          <div className="wd_100 inline_block mr_5">
            <BasicDropDown ref={ref_2}
              className="full_wide" name={`ar_id_${i}_2`} idKey={`ar_id`} valueKey={"ar_label"} url={URLS.GET_AREA_URL + "&st_id=" + area.st_id} checkedValue={area.ar_id_2} defaultName="エリア"
              onHandleChange={this.onHandleChange} onHandleBlur={this.onHandleBlur} noPrefix={true} />
          </div>
          <div className="wd_100 inline_block mr_5">
            <BasicDropDown ref={ref_3}
              className="full_wide" name={`ar_id_${i}_3`} idKey={`ar_id`} valueKey={"ar_label"} url={URLS.GET_AREA_URL + "&st_id=" + area.st_id} checkedValue={area.ar_id_3} defaultName="エリア"
              onHandleChange={this.onHandleChange} onHandleBlur={this.onHandleBlur} noPrefix={true} />
          </div>
        </div>
      )
    }
    return ret;
  }
  public render() {
    return (
      <div>
        <AlertDialog ref={this.alertRef} />
        <div className="search_input fa wd_650 mb_20">
          <input type="text" placeholder="フリーワードを入力" name="jb_keyword" value={this.state.jb_keyword} onChange={this.onHandleChange} onBlur={this.onHandleBlur} />
        </div>
        <div className="s_element">
          <div className="mr_5"><span className="tag-mg fs14"><span className="tag-inner">期間</span></span></div>
          <div className="second_column">
            <div>
              <div>
                <label className="radio-item">
                  <span className="wd_30 m_wd_20"><input type="radio" name="is_date_search" value="0" checked={this.state.is_date_search === "0" ? true : false} onChange={this.onHandleChange} /><span className="radiobtn"></span></span>
                  <span className="txt mr_5 fs14 inline_block">開始日</span>
                  <div className="wd_160 inline_block top">
                    {/* <input type = "text" className="ht_30" placeholder="2020/6/10" name = "jb_open_date" value = {this.state.jb_open_date} onChange={this.onHandleChange} onBlur={this.onHandleBlur}/>
                                        {this.validator.message('jb_open_date', this.state.jb_open_date, 'date' ) } */}
                    <DatePicker
                      dateFormat="yyyy/MM/dd"
                      selected={this.state.jb_open_date}
                      onChange={this.handleChange}
                      locale='ja'
                    />
                  </div>
                  <span className="txt mr_5 fs14 inline_block">終了日</span>
                  <div className="wd_160 inline_block top">
                    {/* <input type = "text" className="ht_30" placeholder="2020/10/10" name = "jb_close_date" value = {this.state.jb_close_date} onChange={this.onHandleChange} onBlur={this.onHandleBlur}/>
                                        {this.validator.message('jb_close_date', this.state.jb_close_date, 'date' ) } */}
                    <DatePicker
                      dateFormat="yyyy/MM/dd"
                      selected={this.state.jb_close_date}
                      onChange={this.handleChange_1}
                      locale='ja'
                    />
                  </div>
                </label>
              </div>
              <div className="mt_10">
                <label className="radio-item">
                  <span className="wd_30 m_wd_20"><input type="radio" name="is_date_search" value="1" checked={this.state.is_date_search === "1" ? true : false} onChange={this.onHandleChange} /><span className="radiobtn"></span></span>
                  <span className="txt mr_5 fs14 inline_block">日付を指定</span>
                  <div className="wd_160 inline_block top mr_5">
                    {/* <input type = "text" className="ht_30" placeholder="2020/6/10" name = "jb_date" value = {this.state.jb_date} onChange={this.onHandleChange} onBlur={this.onHandleBlur}/>
                                        {this.validator.message('jb_date', this.state.jb_date, 'date' ) }                                         */}
                    <DatePicker
                      dateFormat="yyyy/MM/dd"
                      selected={this.state.jb_date}
                      onChange={this.handleChange_2}
                      locale='ja'
                    />
                  </div>
                  {/* <div className="inline_block top">
                                        <div className="wd_160 inline_block top  mr_5">
                                            <BasicDropDown
                                                className="full_wide" name="jb_time" idKey={"grp_ft_id"} valueKey={"grp_ft_name"} url={''} data={Global.DATE_DEVIDE_OPTIONS} checkedValue={this.state.jb_time !== undefined ? this.state.jb_time : '0'} defaultName="時間"
                                                onHandleChange={this.onHandleChange} onHandleBlur={this.onHandleBlur} noPrefix={true} />
                                        </div>
                                    </div> */}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="s_element both_ways">
          <div className="wd_65"><span className="tag-mg fs14"><span className="tag-inner">業種</span></span></div>
          <div>
            <Link to="/job_type">
              <img alt='' src={URLS.BASE_ABSOLUTE_URL + "/images/icon_help.jpg"} />
              <span className="fs14 text-right mr_5 inline_block">業種説明</span>
            </Link>
          </div>
        </div>
        <div className="s_element">
          <div className="ml_10">
            <BasicCheckBox
              className="full_wide" name="sk_s[]" idKey={"sk_id"} valueKey={"sk_label"} url={URLS.GET_SKILL} checkedValues={this.state.sk_s}
              onHandleChange={this.onHandleChange} onHandleBlur={this.onHandleBlur} noPrefix={true} />
          </div>
        </div>
        <div className="s_element">
          <div className="mr_5"><span className="tag-mg fs14"><span className="tag-inner">地域</span></span></div>
          <div className="second_column top">
            <div>
              {this.renderSearchAreaElement()}
            </div>
          </div>
        </div>
        {/* <div className="s_element">
                    <div className="mr_5"><span className="tag-mg fs14"><span className="tag-inner">国籍</span></span></div>
                    <div className="second_column">
                        <label className="radio-item mr_20" onChange={this.onHandleChange}>
                            <span className="wd_30 m_wd_20">
                                <input type="radio" name="em_is_foreign" value= "0" checked = {this.state.em_is_foreign=="0"?true:false} onChange={this.onHandleChange} /><span className="radiobtn"></span>
                            </span>
                            <span className="txt">日本</span>
                        </label>
                        <label className="radio-item" onChange={this.onHandleChange}>
                            <span className="wd_30 m_wd_20">
                                <input type="radio" name="em_is_foreign" value="1" checked = {this.state.em_is_foreign=="1"?true:false} onChange={this.onHandleChange} /><span className="radiobtn"></span>
                            </span>
                            <span className="txt">外国</span>
                        </label>                        
                    </div>
                </div> */}
        <div className="s_element">
          <div className="mr_5"><span className="tag-mg fs14"><span className="tag-inner">募集人数</span></span></div>
          <div className="second_column">
            <div className="wd_50 inline_block">
              <input type="text" placeholder="1" name="jb_total" value={this.state.jb_total} onChange={this.onHandleChange} onBlur={this.onHandleBlur} />
              {this.validator.message('jb_total', this.state.jb_total, 'numeric')}
            </div>
            <span className="inline_block top fs14 mr_5">人</span>
          </div>
        </div>
        <div className="s_element">
          <div className="mr_5"><span className="tag-mg fs14"><span className="tag-inner">金額</span></span></div>
          <div className="second_column top">
            <div>
              <div className="wd_85 inline_block top mr_5">
                <input type="text" className="full_wide" placeholder="10000" name="em_daily_price_low" value={this.state.em_daily_price_low} onChange={this.onHandleChange} onBlur={this.onHandleBlur} />
              </div>
              <span className="fs14 mr_5">～</span>
              <div className="wd_85 inline_block top mr_5">
                <input type="text" className="full_wide" placeholder="30000" name="em_daily_price_hi" value={this.state.em_daily_price_hi} onChange={this.onHandleChange} onBlur={this.onHandleBlur} />
              </div>
              <span className="inline_block top fs14 mr_5">円/人工</span>
              {this.validator.message('em_daily_price_low', this.state.em_daily_price_low, 'numeric')}
              {this.validator.message('em_daily_price_hi', this.state.em_daily_price_hi, 'numeric')}
            </div>

          </div>
        </div>
        <div className="grey-button yellow v-large rb mt_20 mb_20">
          {/* <Link to = "/myschedule_edit/1" > */}
          <div className="inner" onClick={this.onSearch}>
            <i className="fa fa-search"></i>
            この条件で仕事を探す
          </div>
          {/* </Link> */}
        </div>
      </div>
    );
  }
}
// export default compose(
//     withRouter,
//     connect(mapStateToProps, mapDispatcherToProps)
// )(PSearchCom);

export default connect(mapStateToProps, mapDispatcherToProps)(PSearchCom);


//export default withRouter(PSearchCom);
