import { Dispatch } from 'redux';
import * as actions from './actions';
import { ChatActions, Message } from './types';
import {doGetMessage, messageListener, detatchListener, addMessage, addSystemMessage, messageCountListener, detatchCountListener, } from '../../firebase/api/message';
import * as Global from '../../modules/global';

// function sleep(timeout: number) {
//     return new Promise((resolve) => setTimeout(() => resolve(), timeout));
// }

export async function loadMessageAsync( dispatch: Dispatch<ChatActions>, from: number, to:number, lastObject:any, isAllDownloaded:boolean = false ) {
    // from = 2;
    // to = 1;
//    doGetContacts(dispatch, from );
    dispatch( actions.wait() );
    doGetMessage( dispatch, from, to, lastObject, isAllDownloaded );
//    dispatch(actions.loadMessage( from, to ));
}
export async function writeMessageAsync(dispatch: Dispatch<ChatActions>, item: string) {
    dispatch(actions.writeMessage(item));
}

export async function messageCountlistenerFree( dispatch:Dispatch<ChatActions>, from:number, to:number ){
    // from = 1;
    // to = 2;
    detatchCountListener( dispatch, from, to );    
}
export async function initializeAsync( dispatch:Dispatch<ChatActions> ){
    dispatch(actions.initializeCount());
}
export async function messageCountlistenerAsync( dispatch:Dispatch<ChatActions>, from:number ){
    messageCountListener( dispatch, from );    
}
export async function deleteTemp( dispatch:Dispatch<ChatActions> ){
    dispatch(actions.deleteReduxTempMessage());
}
export async function listenerFree( dispatch:Dispatch<ChatActions>, from:number, to:number ){
    // from = 2;
    // to = 1;
    detatchListener( from, to );    
}
export async function listenerAsync( dispatch:Dispatch<ChatActions>, from:number, to:number ){
    messageListener( dispatch, from, to );    
}
export async function addMessageAsync( dispatch: Dispatch<ChatActions>, message:Message ) {
    dispatch(actions.addMessage(message));
}
export async function deleteNewMessageHistoryAsync(dispatch: Dispatch<ChatActions>, from:number, to:number ){
    dispatch( actions.deleteNewMessageHistory(from, to ) );
}
export async function sendMessageAsync(dispatch: Dispatch<ChatActions>, from:number, to:number, id:number, text: string) {
    // from = 2;
    // to = 1;

    const now:Date = new Date();
    const date:string = (now.getMonth()+1) + "月" + now.getDate() + "日";
    const time:string = now.getHours() + ":" + now.getMinutes();
    const message:Message = {
        id: id+1,
        from:from,        
        to:to,
        isFirstNewDate:false,
        isViewed:false,
        year:now.getFullYear().toString(),
        date:date,
        str_time:time,
        text,
        emt_id:parseInt(Global.getRole()! ),
        img: "thumb_photo_2.png",
        isWrited:false,        
    }
    addMessage( dispatch, message, from, to, parseInt(Global.getRole()! )  );
    dispatch(actions.sendMessage(message));
    //dispatch(actions.setLoading(false));
}
export async function sendSystemMessageAsync(dispatch: Dispatch<ChatActions>,  from:number, to:number, docId:string, cmsg:string, title:string = "", emt_id:number, isKuraha:number = 0, isFromMe:boolean = true, msg = "" ) {
    // from = 2;
    // to = 1;

    addSystemMessage( dispatch, from, to, docId, cmsg, title, emt_id, isKuraha, isFromMe, msg );
    //dispatch(actions.setLoading(false));
}