import React, {Component} from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import Header from '../Include/Header'
import Footer from '../Include/Footer';
import * as URLS from '../../modules/urls';
import CheckoutForm from './CheckoutForm';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
//const stripePromise = loadStripe("pk_test_51GvNajIo5FRe7JkX9zANJXxRrKEsakKZCOoCwEYRQfxQ1nlWqUyzUNUyFQAmoEZk1BljOAhUJ6dWHLhiSKKgq2iq00gzVpdHiy");
const stripePromise = loadStripe( URLS.STRIPE_PUBLICK_KEY);

export default class PayIndex extends Component{
    // state = {
    //     clientSecret: '',
    // }
//     componentWillMount(){
//         var response = fetch(URLS.BUY_POINT)
//         .then( function(response) {
//             return response.json();
//         }).then( function(responseJson) {
//             var clientSecret = responseJson.clientSecret;
// console.log( "Client Secret code is " + clientSecret );            
//             this.setState({
//                 clientSecret:clientSecret,
//             })
//             // Call stripe.confirmCardPayment() with the client secret.
//           });
//     }
    render(){
//        let id = this.props.match.params.id?this.props.match.params.id:-1;  
        let clientSecret = this.props.location.state.clientSecret?this.props.location.state.clientSecret:'';
        let pt_id = this.props.location.state.pt_id?this.props.location.state.pt_id:'';
        let pt_money = this.props.location.state.pt_money?this.props.location.state.pt_money:0;
        let pt_amount = this.props.location.state.pt_amount?this.props.location.state.pt_amount:0;
        return (
          <div>
            <Header/>
            <div className="content-container" id = "content">
                <div className="wrap-content">
                    <div className="main-title">ポイント購入</div>
                    <Elements stripe={stripePromise}>
                      <CheckoutForm clientSecret = {clientSecret} pt_id = {pt_id} pt_amount = {pt_amount} pt_money = {pt_money} />
                    </Elements>
                </div>    
            </div>
            <Footer/>
          </div>  
          );
    }
};