import React from 'react';
import '../../scss/job.scss';
import '../../scss/profile.scss';
import * as URLS from '../../../modules/urls';
import JobDetailCom from '../../Find/JobDetailCom'
import {JobDetailComProp} from '../../../modules/job/types'
import { Link } from 'react-router-dom';

class PJobDetailCom extends React.Component<JobDetailComProp>{
    public render(){
        const jobId = this.props.jobId?this.props.jobId:"-1";
        const company = this.props.company?this.props.company:'';
        const depart = this.props.depart?this.props.depart:'';
        const photo =  this.props.photo?this.props.photo:'';
        const em_id =  this.props.em_id?this.props.em_id:'';
        return (
            <div>
                <div className="job_inner myprofile_inner">
                    <div className="l_block">
                        <img alt = '' src = { URLS.IMAGE_URL + photo}/>
                        <div className="company_info mt_15">
                            <div>
                                {em_id !== ''?
                                    <Link  to={'/client/profile/' + em_id}><div className="fs20">{company}</div></Link>
                                    :
                                    <div className="fs20">{company}</div>
                                }
                                <div className="fs14">{depart}</div>
                            </div>
                        </div>
                    </div>
                    <div className="r_block">
                        <JobDetailCom jobId = {jobId}/>
                    </div>                
                </div>
            </div>
        );
    }
}
export default PJobDetailCom; 