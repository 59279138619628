import { actionTypes, Message, MessageGroup } from './types'
import { action } from 'typesafe-actions';

export function sendMessage( message: Message ) {
    return action(actionTypes.SEND_MSG, {
        message
    });
}
export function writeMessage( text: string) {
    return action(actionTypes.WRITE_MSG, {
        text
    });
}
export function wait(){
    return action(actionTypes.WAIT_MSG, {
    });
}
export function initializeCount(){
    return action( actionTypes.INITIALIZE );
}
export function addMessage( message: Message) {
    return action(actionTypes.ADD_MSG, {
        message
    });
}
export function countMessage( count:number, newMessage?:Message, isAdded:boolean = false, isStatusOK = true ) {
    return action(actionTypes.COUNT_MSG, {
        newMessage, count, isAdded, isStatusOK
    });
}

export function uploadSucessMessage( status:boolean, message:Message ){
    if( status )
        return action(actionTypes.SUCCESS_SAVE_MSG, {
            status, message
        });
    else
        return action(actionTypes.FAILURE_SAVE_MSG, {
            status, message
        });         
}
export function updatePoint( status:boolean, point:number ){
    if( status )
        return action(actionTypes.SUCCESS_SAVE_POINT, {
            status, point
        });
    else
        return action(actionTypes.FAILURE_SAVE_POINT, {
            status, point
        });         

}
export function deleteReduxTempMessage(){
    return action(actionTypes.DELETE_MSG, {
    });
}
export function uploadFailureMessage( status:boolean ){
    return action(actionTypes.FAILURE_SAVE_MSG, {
        status
    });
}
export function downloadSucessMessage( list:Message[], lastObject:any, isRealTime:boolean = false, listener:any = null ){
    return action(actionTypes.SUCCESS_GET_MSG, {
        list, lastObject, isRealTime, listener
    });    
}
export function registerCountListener( listener:any ){
    return action(actionTypes.REGISTER_COUNT_LISTENER, {
        listener
    });    
}
export function registerMessageListener( listener:any ){
    return action(actionTypes.REGISTER_MESSAGE_LISTENER, {
        listener
    });    
}
export function realTimeSucessMessage( list:Message[] ){
    return action(actionTypes.SUCCESS_REAL_TIME_MSG, {
        list
    });    
}
export function downloadFailureMessage( list:MessageGroup[] ){
    return action(actionTypes.FAILURE_GET_MSG, {
        list
    });    
}
export function loadMessage( from: number, to:number ) {
    return action(actionTypes.LOAD_MSG, {
        from, to
    });
}
export function deleteNewMessageHistory( from:number, to:number ){
    return action(actionTypes.DELETE_NEW_MESSAGE_HISTORY, {
        from, to
    });
}
export function setLoading(loading: boolean) {
    return action(actionTypes.SET_LOADING, {
        loading
    });
}
