import React, {Component} from 'react';
import '../scss/job.scss';
import '../scss/message.scss';
import { Message } from '../../modules/chat';
import Loader from 'react-loader-spinner';
import * as Global from '../../modules/global';
import * as URLS from '../../modules/urls';
import {nl2br} from '../../modules/global';
import moment from 'moment';

export type MessageItemProps = {
    message: Message,
    img:string,
    from:any,
    is_not_available?:number
};

export default class MessageItemCom extends Component<MessageItemProps>{
    shouldComponentUpdate( nextProps:MessageItemProps ){
        return true;
    }
    removeDecoration = (text: string ) => {
        text = text.replace(/^<pre [a-zA-Z_='0-9"]+>/g, "").replace("</pre>", "");
        return text;
    
    }
    render(){
        const my_photo = Global.getEmSmallPhoto();
        const is_not_available = this.props.is_not_available?this.props.is_not_available:0;
        return(
            <div className={ parseInt(this.props.message.from.toString() ) === parseInt(this.props.from)?"element_block even":"element_block odd" }>
                <div className="time_txt">{moment(this.props.message.str_time,'h:mm').format('h:mm A')}</div>
                {parseInt(this.props.message.from.toString() ) !== parseInt(this.props.from)?
                    (
                    <div className="d_element">
                        {
                            is_not_available === 1?
                                <img alt = '' className = "no_radius" src = {URLS.IMAGE_URL + URLS.LEAVE_USER_IMAGE } />    
                            :
                                <img alt = '' src = {URLS.IMAGE_URL + this.props.img}/>
                        }
                        {
                            !this.props.message.isWrited?
                            <Loader
                                type="TailSpin"
                                color="#333"
                                height={20}
                                width={20}
                            />
                          :<></>  
                        }                        
                        <div className="msg" dangerouslySetInnerHTML = { {__html: nl2br(this.removeDecoration(this.props.message.text)) } }></div>
                    </div>
                    ):(
                    <div className="d_element">
                        {
                            !this.props.message.isWrited?
                            <Loader
                                type="TailSpin"
                                color="#333"
                                height={20}
                                width={20}
                            />
                          :<div></div>  
                        }
                        <div className="msg" dangerouslySetInnerHTML = { {__html: nl2br(this.removeDecoration(this.props.message.text))} }></div>
                        <img alt = '' src = {URLS.IMAGE_URL + my_photo}/>
                    </div>
                    )
                }
            </div>        
        );
    }
}