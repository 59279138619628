import React, { Component } from 'react';
import '../scss/job.scss';
import '../scss/myprofile.scss';
import { Link } from 'react-router-dom';
import * as Global from '../../modules/global';
import * as URLS from '../../modules/urls';
import { Redirect } from 'react-router-dom';
import Loading from '../Include/Loading';
import { AlertDialog } from '../Modal/Alert';
import { IRootState } from '../../modules';
import { connect } from 'react-redux';

const mapStateToProps = ({ message, user }: IRootState) => {
    const { count, point, removeCountListener } = message;
    const { userInfo } = user;
    return { count, point, removeCountListener, userInfo };
}
type ReduxType = ReturnType<typeof mapStateToProps>;

class MyProfileCom extends Component<ReduxType, any>{
    mounted: boolean = false;
    alertRef: any;
    state = {
        loading: false,
        em_notify: '0',
        isLogout: false,
        errorMessage: '',
        keyword: '',
        em_id: '-1',
        em_photo: '',
        em_photo_s: '',
        em_is_legal: "-1",
        em_company: '',
        em_company_legal: '',
        em_person_legal: '',
        ctrc_value_b: '',
        em_founding: '',
        year: "0",
        month: "0",
        day: "0",
        em_legal_number: '',
        em_total: '',
        em_depart: '',
        em_post: '',
        st_id: "",
        st_label: '',
        em_street_address: '',
        ar_id: '',
        em_contact_phone: '',
        sk_s: [],
        sk_label_s: '',
        em_skill_keyword: '',
        em_inputer_depart: '',
        em_inputer_pos: '',
        em_inputer_lastname: '',
        em_inputer_firstname: '',
        em_inputer_lastname_kana: '',
        em_inputer_firstname_kana: '',
        em_precontact: '',
        em_overview: '',
        cnt_ctr_b_total: '0',
    }
    constructor(props: any) {
        super(props);
        this.alertRef = React.createRef();
    }
    changeStatus = () => {
        let em_id = Global.getEmId();
        if (em_id == "") {
            return;
        }
        this.setState({
            loading: true,
        });
        let em_notify = this.state.em_notify == '1' ? '0' : '1';
        const data = {
            update: [{
                em_id: em_id,
                em_notify: em_notify,
            }]
        }
        const that = this;
        fetch(URLS.SAVE_PROFILE, {
            method: 'post',
            body: JSON.stringify(data),
        }).then(async function (response) {
            if (!that.mounted) {
                return;
            }
            let ret = await response.json();
            if (ret.success) {
                that.alertRef.current.open("成功", "保存されました。", true);
                that.setState({
                    loading: false,
                    em_notify: that.state.em_notify == '0' ? '1' : '0',
                });
            } else {
                that.alertRef.current.open("エラー", "保存できませんでした。", true);
                that.setState({
                    loading: false,
                });
            }
            //return response.json();
        }).catch(error => {
            this.alertRef.current.open("エラー", "保存できませんでした。", true);
            that.setState({
                loading: false,
            });
        });
    }
    logOut = () => {
        Global.setEmId('');
        Global.setPoint('0');
        Global.setDefaultRole('');
        this.setState({
            isLogout: true,
        });
    }
    componentDidMount() {
        this.mounted = true;
        let em_id: any = Global.getEmId();
        let role = Global.getRole();
        if (role !== '1') {
            return;
        }
        let keyword = 'boss';
        let url = URLS.GET_PROFILE + "?em_id=" + em_id + "&keyword=" + keyword;
        const that = this;
        this.setState({
            loading: true,
        });
        fetch(url, {
            method: 'get',
        }).then(async function (response) {
            let ret = await response.json();
            let dateList = Global.DateToString(ret.emp.em_founding);
            Global.setEmBigPhoto(ret.emp.em_photo);
            Global.setEmSmallPhoto(ret.emp.em_photo_s);
            Global.setEmBigPhoto(ret.emp.em_photo);
            Global.setEmSmallPhoto(ret.emp.em_photo_s);
            if (ret.emp.ctrc_value_b) {
                Global.setStar(ret.emp.ctrc_value_b);
            }
            if (!that.mounted) {
                return;
            }
            that.setState({
                em_photo: ret.emp.em_photo,
                em_photo_s: ret.emp.em_photo_s,
                em_is_legal: ret.emp.em_is_legal,
                em_company: ret.emp.em_company,
                em_company_legal: ret.emp.em_company_legal,
                em_person_legal: ret.emp.em_person_legal,
                ctrc_value_b: ret.emp.ctrc_value_b ? ret.emp.ctrc_value_b : '0',
                em_founding: ret.emp.em_founding,
                year: dateList.year,
                month: dateList.month,
                day: dateList.day,
                em_legal_number: ret.emp.em_legal_number,
                em_total: ret.emp.em_total ? ret.emp.em_total : '',
                em_depart: ret.emp.em_depart,
                em_post: ret.emp.em_post,
                st_id: ret.emp.st_id,
                st_label: ret.emp.st_label,
                em_street_address: ret.emp.em_street_address,
                ar_id: ret.emp.ar_id,
                sk_s: [],
                sk_label_s: ret.emp.sk_label_s ? ret.emp.sk_label_s : '',
                em_contact_phone: ret.emp.em_contact_phone,
                em_skill_keyword: ret.emp.em_skill_keyword,
                em_inputer_depart: ret.emp.em_inputer_depart,
                em_inputer_pos: ret.emp.em_inputer_pos,
                em_inputer_lastname: ret.emp.em_inputer_lastname,
                em_inputer_firstname: ret.emp.em_inputer_firstname,
                em_inputer_lastname_kana: ret.emp.em_inputer_lastname_kana,
                em_inputer_firstname_kana: ret.emp.em_inputer_firstname_kana,
                em_precontact: ret.emp.em_precontact,
                em_overview: ret.emp.em_overview,
                cnt_ctr_b_total: ret.emp.cnt_ctr_b_total ? ret.emp.cnt_ctr_b_total : '0',
                em_notify: ret.emp.em_notify,
                loading: false,
            });
        }).catch(error => {
            if (!that.mounted) {
                return;
            }
            this.setState({ loading: false, errorMessage: error.toString() });
        });
    }
    renderRedirect = () => {
        const em_id = Global.getEmId();
        if (this.state.isLogout || em_id == "") {
            let url: string = '/';
            return <Redirect to={url} />
        } else {
            let role = Global.getRole();
            if (role != "1") {
                let url: string = '/choose';
                return <Redirect to={url} />
            } else {
                return <></>
            }
        }
    }
    componentWillUnmount() {
        this.mounted = false;
    }
    renderSkill(skillStr: string) {
        if (!skillStr) {
            return [];
        }
        const skills = skillStr.split(',');
        let ret = [];
        for (let i = 0; i < skills.length; i++) {
            ret.push(<span className="tag-mg fs14" key={i}><span className="tag-inner">{skills[i]}</span></span>)
        }
        return ret;
    }
    public render() {
        let em_company = this.state.em_is_legal == "0" ? this.state.em_company : this.state.em_company_legal;
        let em_depart = this.state.em_depart;
        let em_star = parseInt(this.state.ctrc_value_b);
        return (
            <div className="myprofile_inner">
                {this.state.loading ?
                    <Loading />
                    : <></>
                }
                <AlertDialog ref={this.alertRef} />
                {this.renderRedirect()}
                {this.state.loading ?
                    <Loading />
                    : <></>
                }
                <div className="l_block">
                    <img src={URLS.IMAGE_URL + Global.getEmSmallPhoto()} />
                    <div className="company_info">
                        <div>
                            <div className="fs20">{em_company}</div>
                            <div className="fs14">{em_depart}</div>
                        </div>
                        <Link to="/feedback">
                            <div className="star_container mt_20">
                                <span className={em_star > 0 ? "star active" : "star"}></span>
                                <span className={em_star > 1 ? "star active" : "star"}></span>
                                <span className={em_star > 2 ? "star active" : "star"}></span>
                                <span className={em_star > 3 ? "star active" : "star"}></span>
                                <span className={em_star > 4 ? "star active" : "star"}></span>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="r_block">
                    <div className="info">
                        <div>
                            <div className="fs14 map-icon">{this.state.st_label}</div>
                            <div>
                                {this.renderSkill(this.state.sk_label_s)}
                            </div>
                        </div>
                        <div className="btn-container mt_0">
                            <Link to="/editprofile">
                                <div className="grey-button v-small">
                                    <div className="inner font_14 fa modify">修正する</div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="profile_detail">
                        <div className="d_element pd_b mt_10">
                            <span className="desc">{this.state.em_overview}</span>
                        </div>
                        <div className="d_element pd_b both_ways middle">
                            <span className="desc">仕事依頼件数</span>
                            <span><span className="fs20">{this.state.cnt_ctr_b_total}</span><span className="fs14">件</span></span>
                        </div>
                        <div className="d_element pd_b">
                            <Link to="/account_type"><span className="desc">ログイン切替/登録形態変更</span></Link>
                        </div>
                        <div className="d_element pd_b both_ways">
                            <div>
                                <div className="desc">通知設定</div>
                                <div className='notifiy'>※通知はアプリのみとなります</div>
                            </div>
                            <span className={this.state.em_notify == '1' ? 'status_slider active' : 'status_slider'} onClick={this.changeStatus}></span>
                        </div>
                        {/* <div className="d_element pd_b">
                        <Link to = "/account_delivery"><span className="desc">アカウントデータ引継ぎ</span></Link>
                        </div> */}
                        <div className="d_element pd_b">
                            <Link to="/delete_profile"><span className="desc">アカウント削除</span></Link>
                        </div>
                        <div className="d_element pd_b pointer" onClick={this.logOut}>
                            <span className="desc">ログアウト</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default connect(mapStateToProps)(MyProfileCom);