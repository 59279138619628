import React, { Component } from 'react';
import { DropDownProps, DropDownState } from "../../../modules/dropdown/types";
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as asyncActions from '../../../modules/basic/async-actions';
import { IRootState } from '../../../modules';

// const mapStateToProps = ( { skill }: IRootState, props:DropDownProps ) => {
// 	const{ onHandleChange, url, name, idKey, valueKey, checkedValues, noPrefix, className, onHandleBlur} = props;
//     return { onHandleChange, url, name, idKey, valueKey, checkedValues, noPrefix, className, onHandleBlur };
// }
import * as footerAsyncactions from '../../../modules/footer/async-actions';
import { FooterActions } from '../../../modules/footer';

const mapDispatcherToProps = (dispatch: Dispatch<FooterActions>) => {
    return {
        isRerender: (_isRerender: boolean) => footerAsyncactions.isRerender(dispatch, _isRerender),
    }
}
type ReduxType = ReturnType<typeof mapDispatcherToProps> & any;

class BasicCheckBox extends Component<ReduxType, DropDownState>{    
    mounted: boolean = false;
    state = {
        total: 0,
        items: [],
    }
    _onHandleChange = (e: any) => {
        this.props.onHandleChange(e);
    }
    componentDidMount() {
        this.mounted = true;
        const url: string = this.props.url;
        const that = this;
        //that.props.isRerender(true);        
        fetch(url, {
            method: 'get',
        }).then(async function (response) {
            let ret = await response.json();
            if (!that.mounted) {
                return;
            }
            if (ret.total > 0) {
                const items = ret.items;
                that.setState({
                    items: items,
                    total: ret.total,
                }, async() => {
                    await that.props.isRerender(true);
                });
            } else {
                that.setState({
                    total: 0, 
                    items: [],
                }, async() => {
                    await that.props.isRerender(true);
                });
            }
        }).catch(error => {
            this.setState({ total: 0, items: [], errorMessage: error.toString() }, async () => {
                that.props.isRerender(true);
            });
        });

        // fetch(url).then(async response => {
        //     if (!this.mounted) {
        //         return;
        //     }
        //     const data = await response.json();

        //     // check for error response
        //     if (!response.ok) {
        //         // get error message from body or default to response statusText
        //         const error = (data && data.message) || response.statusText;
        //         this.setState({ total: 0, items: [] }, async () => {
        //             await this.props.needToRerender(true);
        //         });
        //         return Promise.reject(error);
        //     }
        //     this.setState({ total: data.total, items: data.items }, async () => {
        //         await this.props.needToRerender(true);
        //     });
        // }).catch(error => {
        //     this.setState({ errorMessage: error.toString() });
        //     console.error('There was an error!', error);
        // });
    }
    componentWillUnmount() {
        this.mounted = false;
    }
    isShowElement = (item: any) => {
        let flag = false;
        if (this.props.emtType) {
            if (parseInt(this.props.emtType.toString()) === 1 && item['sk_enable_boss'] === '0') {
                flag = true;
            } else if (parseInt(this.props.emtType.toString()) === 2 && item['sk_enable_worker'] === '0') {
                flag = true;
            }
        }
        return flag;
    }
    render() {
        return (
            this.state.items.map(item => (
                !this.isShowElement(item) ?
                    <label className="control-checkbox pd_s mr_25" key={item[this.props.idKey]}>
                        <input type="checkbox" name={this.props.name} value={this.props.noPrefix ? item[this.props.idKey] : (item[this.props.idKey] + "_" + item[this.props.valueKey])} onChange={this._onHandleChange}
                            checked={this.props.checkedValues!.includes(this.props.noPrefix ? item[this.props.idKey] : (item[this.props.idKey] + "_" + item[this.props.valueKey])) ? true : false} />
                        <span className="control__indicator"></span>
                        {item[this.props.valueKey]}
                    </label>
                    : <></>
            ))
        );
    }
}
export default connect(null, mapDispatcherToProps)(BasicCheckBox);
