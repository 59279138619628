import React, {Component} from 'react';
import '../../scss/job.scss';
import '../../scss/search.scss';
import Header from '../../Include/Header'
import SearchNavCom from '../../Find/SearchNavCom';
import PSearchCom from './PSearchCom';
import Footer from '../../Include/Footer';
import { withRouter } from 'react-router-dom';

class PSearch extends Component<any> {
    componentDidMount() {
    }
    public render(){
        return(
            <div>
                <Header/>
                <div className="content-container" id = "content">
                    <div className="wrap-content">
                        <div className="main-title">仕事・親方/会社を探す</div>
                        <div className="job_container mt_40">
                            <SearchNavCom/>                            
                            <div className="job search">
                                <PSearchCom history = {this.props.history}/>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>

        );
    }
}
export default withRouter(PSearch);