import { ActionType } from "typesafe-actions";
import * as actions from "./actions";
import { UserInfo } from "../../modules/userinfo/types";
export type Message = {
  id?: number;
  docId?: string;
  from: number;
  fromInfo?: UserInfo;
  to: number;
  toInfo?: UserInfo;
  text: string;
  isKuraha?: number;
  time?: any;
  isViewed: boolean;
  str_time: string;
  year: string;
  date: string;
  img?: string;
  fulldate?: string;
  title?: string;
  isFirstNewDate: boolean;
  emt_id: number;
  emt_is_worker?: number;
  emt_is_boss?: number;
  isWrited: boolean; // successfully saved to firebase.
  numUnviewed?: number;
};
export type MsgBrief = {
  docId: string;
  from: string;
  to: string;
  is_not_available: string;
  jobStatus?: string;
  is_from_message_list?: number;
  jba_is_direct?: string;
};
export type MessageGroup = {
  id: number;
  date: string;
  list: Message[];
};
export type FireBaseMessage = {
  id: string;
  from: number;
  to: number;
  text: string;
  time: any;
  isViewed: boolean;
  emt_id: number;
  img?: string;
};
export type FireBaseWorkerContact = {
  title_worker: string;
  text_worker: string;
  time_worker: any;
  from_worker: number;
  to_worker: number;
  emt_is_worker: number;
  isKuraha_worker: number;
  isViewed_worker: boolean;
  numUnViewed_worker: number;
};
export type FireBaseBossContact = {
  title_boss: string;
  text_boss: string;
  time_boss: any;
  from_boss: number;
  to_boss: number;
  emt_is_boss: number;
  isKuraha_boss: number;
  isViewed_boss: boolean;
  numUnViewed_boss: number;
};
export type FireBaseSendContact = FireBaseWorkerContact | FireBaseBossContact;
export type FireBaseResponseContact = {
  title_worker?: string;
  text_worker?: string;
  time_worker?: any;
  from_worker?: number;
  to_worker?: number;
  emt_is_worker?: number;
  isKuraha_worker?: number;
  isViewed_worker?: boolean;
  numUnViewed_worker?: number;

  title_boss?: string;
  text_boss?: string;
  time_boss?: any;
  from_boss?: number;
  to_boss?: number;
  emt_is_boss?: number;
  isKuraha_boss?: number;
  isViewed_boss?: boolean;
  numUnViewed_boss?: number;
};

export type MessageState = {
  text: string;
  point?: number;
  count: number;
  messageListener?: any;
  removeCountListener?: any;
  isRealTime: boolean;
  isTypedNewText: boolean;
  isAllDownloaded: boolean;
  isDownloading: boolean;
  lastObject?: any;
  isSucess: boolean;
  isPayUpdateSuccess: boolean;
  newMessages: Message[];
  messages: Message[];
  loading: boolean;
};
export const PAGE_PER_COUNT: number = 3;
export enum actionTypes {
  LOAD_MSG = "message/load",
  SUCCESS_GET_MSG = "message/success_download",
  FAILURE_GET_MSG = "message/failure_download",
  SUCCESS_REAL_TIME_MSG = "message/realtime_download",
  WRITE_MSG = "message/write",
  SEND_MSG = "message/send",
  DELETE_MSG = "message/DELETE",
  ADD_MSG = "message/add",
  SUCCESS_SAVE_MSG = "message/success_upload",
  FAILURE_SAVE_MSG = "message/failure_upload",
  SUCCESS_SAVE_POINT = "message/success_update_point",
  FAILURE_SAVE_POINT = "message/failure_update_point",
  SET_LOADING = "message/SET_LOADING",
  COUNT_MSG = "message/count",
  DELETE_NEW_MESSAGE_HISTORY = "message/del_history",
  WAIT_MSG = "message/wait",
  INITIALIZE = "count/initialize",
  REGISTER_COUNT_LISTENER = "count/listener",
  REGISTER_MESSAGE_LISTENER = "message/listener",
}
export function createMessageGroup(list: Message[]) {
  let currentYear: string = "";
  let currentDate: string = "";
  let groupList: MessageGroup[] = [];
  for (let i = 0; i < list.length; i++) {
    let message: Message = list[i];
    if (message.year != currentYear || message.date != currentDate) {
      currentYear = message.year;
      currentDate = message.date;
      const messageGroup: MessageGroup = {
        id: groupList.length,
        date: currentDate,
        list: [],
      };
      groupList.push(messageGroup);
      messageGroup.list.push(message);
    } else {
      groupList[groupList.length - 1].list.push(message);
    }
  }
  return groupList;
}
export function insertMessageGroup(
  groupList: MessageGroup[],
  message: Message
): MessageGroup[] {
  let isExist: boolean = true;
  if (!groupList.length) {
    isExist = false;
  }
  let messageGroup: MessageGroup = groupList[groupList.length - 1];
  let id: number = 0;
  if (messageGroup.date != message.date) {
    //id = messageGroup.list[messageGroup.list.length-1].id + 1;
    isExist = false;
  }
  if (!isExist) {
    let messageGroup: MessageGroup = {
      id: id,
      date: message.date,
      list: [message],
    };
    groupList.push(messageGroup);
  } else {
    messageGroup.list.push(message);
  }
  let newGroupList: MessageGroup[] = [];
  return newGroupList.concat(groupList);
}
export function addDateFlag(messages: Message[], message: Message): Message[] {
  if (messages.length) {
    let lastMessage: Message = messages[messages.length - 1];
    if (lastMessage.date != message.date) {
      message.isFirstNewDate = true;
    }
  } else {
    message.isFirstNewDate = true;
  }
  return messages.concat(message);
}
export function addDatesFlag(
  messages: Message[],
  newMessages: Message[],
  isRealTime: boolean = false
) {
  let date: string = "";
  newMessages.reverse();
  let temp: Message[] = [];
  for (let i = 0; i < messages.length; i++) {
    const message = messages[i];
    for (let j = 0; j < newMessages.length; j++) {
      const newMessage = newMessages[j];
      if (message.id == newMessage.id) {
        newMessages.splice(j, 1);
      }
    }
  }
  if (!isRealTime) temp = newMessages.concat(messages);
  else temp = messages.concat(newMessages);

  for (let i = 0; i < temp.length; i++) {
    let message: Message = temp[i];
    message.isFirstNewDate = true;
    if (message.date == date) {
      message.isFirstNewDate = false;
    } else {
      date = message.date;
    }
  }
  temp.sort((a, b) => a.time - b.time);
  return temp;
}
export function insertMessagesGroup(
  groupList: MessageGroup[],
  messageList: Message[]
): MessageGroup[] {
  const tempList: Message[] = [];
  for (let i = 0; i < groupList.length; i++) {
    let group: MessageGroup = groupList[i];
    for (let j = 0; j < group.list.length; j++) {
      tempList.push(group.list[j]);
    }
  }
  if (messageList == null || !messageList.length) {
    messageList = [];
  }
  messageList = messageList.concat(tempList);
  return createMessageGroup(messageList);
}
export function getNewMessageList(messages: Message[], newMessage?: Message) {
  if (!newMessage) {
    return messages;
  }
  let exitMessages: Message[] = [];
  if (!newMessage.isViewed) {
    exitMessages = messages.filter(
      (message) => message.docId === newMessage.docId
    );
    if (!exitMessages || !exitMessages.length) {
      messages = messages.concat(newMessage);
    } else {
      const idx = messages.findIndex(
        (message) => message.docId === newMessage.docId
      );
      if (idx !== -1) {
        messages[idx] = newMessage;
        messages = messages.concat([]);
      }
    }
    return messages;
  } else {
console.log( 'Not existing message');	  
    exitMessages = messages.filter(
      (message) => message.docId !== newMessage.docId
    );
    return exitMessages;
  }
}

export function getNewMessageHistory(
  messages: Message[],
  from: number,
  to: number
) {
  const list = messages.filter(
    (message) =>
      (message.from != from || message.to != to) &&
      (message.from != to || message.to != from)
  );
  return list;
}

export type ChatActions = ActionType<typeof actions>;
