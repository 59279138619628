import { ActionType } from 'typesafe-actions';
import * as actions from './actions';

export type FooterState = {
    _isRerender:boolean,
}
export enum actionTypes {
    RERENDER = "footer/rerender",
}
export type FooterActions = ActionType<typeof actions>;

