import * as React from 'react'
import { EventInput } from '@fullcalendar/core'
import {Link} from 'react-router-dom';
import '../scss/myprofile.scss';
import '../scss/schedule.scss';
import CalendarCom from './CalendarCom'
import * as Global from '../../modules/global';
import {Redirect} from 'react-router-dom';
import * as URLS from '../../modules/urls';

interface ScheduleState {
  redirect?:boolean,
  date?:String,
  calendarWeekends: boolean,
  calendarEvents: EventInput[]

}

export default class ScheduleViewCom extends React.Component<any, ScheduleState> {
    renderRedirect = () => {
        let role = Global.getRole()!;
        let info = this.props.info?this.props.info:{};
        if( info.em_name == "" ){
            let url:string = '/';
            return <Redirect to = {url}/>
        }
    }    
  public render() {
    let em_id = this.props.em_id?this.props.em_id:'';
    // let em_company = this.state.em_is_legal == "0"?this.state.em_company:this.state.em_company_legal;
    // let em_depart = this.state.em_depart;
    // let em_star = parseInt(this.state.ctrc_value_b);
    let info = this.props.info?this.props.info:{};
    const em_star = info.ctrc_value?info.ctrc_value:0;
    return (
    <div>    
        <div className='myprofile_inner mt_40'>
            <div className="l_block">
                <img src = {URLS.IMAGE_URL + (info.em_photo_s?info.em_photo_s:'dummy.jpg')}/>
                <div className="company_info">
                    <div>
                        <div className="fs20">{info.em_name?info.em_name:''}</div>
                        <div className="fs14">{info.em_company?info.em_company:''}</div>
                    </div>
                    <Link to = {{ pathname: "/feedback/" + info.em_id, state: { em_photo_s:(info.em_photo_s?info.em_photo_s:'dummy.jpg'), em_name: info.em_name?info.em_name:'', em_company:info.em_company?info.em_company:'', ctrc_value_w:em_star } }}>
                        <div className="star_container">
                            <span className={em_star > 0 ?"star active":"star"}></span>
                            <span className={em_star > 1 ?"star active":"star"}></span>
                            <span className={em_star > 2 ?"star active":"star"}></span>
                            <span className={em_star > 3 ?"star active":"star"}></span>
                            <span className={em_star > 4 ?"star active":"star"}></span>
                        </div>
                    </Link>
                </div>
            </div>
            <div className="r_block schedule_container">
				<CalendarCom em_id = {em_id} is_mine = {false}/>
            </div>                                
        </div>
        <div className="button-container">
            <div className="grey-button large mt_40">
                <Link to = {"/message/" + info.em_id }>
                    <div className="inner">
                    メッセージを送る
                    <i className="fa fa-angle-right"></i>
                    </div>
                </Link>
            </div>										
        </div>
    </div>    
    );
  }
}
