import { BasicActions, actionTypes, BasicState } from './types';

const initialState:BasicState = {
    total:0,
    items:[],
};
const SKILL_SET = actionTypes.SKILL_SET;
const STATE_SET = actionTypes.STATE_SET;
const AREA_SET =  actionTypes.AREA_SET;
export function BasicReducer(state: BasicState = initialState, action: BasicActions ): BasicState{
    let s = null;
    switch (action.type ) {
        case SKILL_SET:
            s = action.payload.basicState;
console.log( "--- SKILL ---" + s.items.length );
            return {...state, error:s.error, errorMessage:s.errorMessage, items:s.items, total:s.items.length };
        case STATE_SET:
            s = action.payload.basicState;
console.log( "---state ---" + s.items.length );
            return {...state, error:s.error, errorMessage:s.errorMessage, items:s.items, total:s.items.length };
        case AREA_SET:
            s = action.payload.basicState;
console.log( "---AREA ---" + s.items.length );
            return {...state, error:s.error, errorMessage:s.errorMessage, items:s.items, total:s.items.length };
        default:
            return state;            
    }
}