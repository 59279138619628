import React, {Component} from 'react';
import '../scss/job.scss';
import '../scss/search.scss';
import ClientSearchNavCom from '../Include/ClientSearchNavCom';
import ProviderSearchNavCom from '../Include/ProviderSearchNavCom';
export type SearchNavProps = {
    to?: string;
};
export default class SearchNavCom extends Component<SearchNavProps>{
    public render(){
        const url = this.props.to;                    
        const role = localStorage.getItem( 'role' );        
        return (
            <div>
            {
                role === '1'?( <ClientSearchNavCom to = {url}/> ):( <ProviderSearchNavCom to = {url}/> )
            }
            </div>
        )
    }
}
