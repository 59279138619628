import React from 'react';
import '../scss/job.scss';
import Header from '../Include/Header'
import JobNavCom from "./JobNavCom";
import JobOpenCom from './JobOpenCom'
import JobCloseCom from './JobCloseCom'
import Footer from '../Include/Footer';
import { Link } from 'react-router-dom';
class JobView extends React.Component<any>{
    componentDidMount = () => {
    }    
    public render() {
        const url = this.props.match.url;
console.log( '==JobView==');
        return (
            <div>
                <Header />
                <div className="content-container" id="content">
                    <div className="wrap-content">
                        <div className="main-title">仕事管理</div>
                        <div className="job_container mt_40">
                            <JobNavCom to={url} />
                            <div className="job">
                                <div className="button-container">
                                    <Link to="/jobcreate">
                                        <button className="grey-button yellow large_300 mb_20">
                                            <div className="inner">
                                                新規登録
                                        </div>
                                        </button>
                                    </Link>
                                    <div className="clear"></div>
                                </div>
                                <JobOpenCom />
                                <JobCloseCom />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}
export default JobView; 