import React from 'react';
import '../scss/job.scss';
import '../scss/change_type.scss';
import Header from '../Include/Header'
import AccountTypeCom from './AccountTypeCom';
import Footer from '../Include/Footer';
import * as Global from '../../modules/global';

import { IRootState } from '../../modules';
import { Dispatch } from 'redux';
import {UserInfo,UserInfoActions } from '../../modules/userinfo';
import * as asyncactions from '../../modules/userinfo/async-actions';
import { connect } from 'react-redux';
import {Redirect} from 'react-router-dom';

const mapStateToProps = ({ user }: IRootState ) => {
    const { userInfo } = user;
    return { userInfo };
}
const mapDispatcherToProps = (dispatch: Dispatch<UserInfoActions>) => {
    return {
        getProfile:( role:string, userInfo:UserInfo ) => asyncactions.getProfileAsync( dispatch, role, userInfo ),
    }
}
type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;

class AccountType extends React.Component<ReduxType>{
    state = {
        isRedirect:false,
    }
    anotherAccount = () =>{
        this.setState({
            isRedirect:true,
        })    
    }
    renderRedirect = () =>{
        if( this.state.isRedirect ){
            let role = Global.getRole();
            if( role === '1' ){
                role = '2';
                Global.setDefaultRole(role);                
            }else if( role === '2'){
                role = '1';
                Global.setDefaultRole(role);
            }
            return <Redirect to = {"/choose/"}/>
        }else{
            return <></>;
        }
    }
    public render(){
        const role = Global.getRole();
        const emtId = Global.getEmtId();        
        return (
            <div>
                <Header/>
                {this.renderRedirect()}                
                <div className="content-container" id = "content">
                    <div className="wrap-content">
                        {
                            emtId === '3'?
                            <div className="button-container">
                                <div className="grey-button large mt_70">
                                    <div className="inner" onClick = {this.anotherAccount}>
                                    {
                                        role === '1'?"職人でログイン":"親方/会社でログイン"
                                    }
                                    <i className="fa fa-angle-right"></i>
                                    </div>
                                </div>										
                            </div>								
                            :<></>
                        }
                        <div className="job_container mt_40">
                            <div className ="change_type">
                                <AccountTypeCom/>
                            </div>                            
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}
export default connect(mapStateToProps,mapDispatcherToProps )(AccountType);