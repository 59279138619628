import * as firebase from "firebase/app";
import "firebase/auth";
import 'firebase/firestore';

const config = {

   //YY
  //  apiKey: "AIzaSyArBQYqSTrbkQ0Eyg6zk1vorOSOvMauEHU",
  //  authDomain: "craha-133df.firebaseapp.com",
  //  projectId: "craha-133df",
  //  storageBucket: "craha-133df.appspot.com",
  //  messagingSenderId: "1052179280476",
  //  appId: "1:1052179280476:web:04e2bddb8cc34e74672c99",
  //  measurementId: "G-PWT2MGV94K"

  //Craha
  apiKey: "AIzaSyBujnDX_58TOS53anK4HyShRMQDXZgSWm8",
  authDomain: "craha-1e806.firebaseapp.com",
  projectId: "craha-1e806",
  storageBucket: "craha-1e806.appspot.com",
  messagingSenderId: "529346655848",
  appId: "1:529346655848:web:7951f95ec48d25fd472ddd",
  measurementId: "G-VJ0PEH3G1W"  
};

firebase.initializeApp(config);

//export const auth = firebase.auth();
export const db = firebase.firestore();
export const firebaseTimestamp = firebase.firestore.Timestamp;
