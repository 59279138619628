import React, {Component} from 'react';
import '../scss/job.scss';
import '../scss/message.scss';
import Header from '../Include/Header'
import Footer from '../Include/Footer';


class NotFoundPage extends Component<any, any>{
    render(){
        return(
            <div>
                <Header/>
                <div className="content-container" id = "content">
                    <div className="wrap-content">
                        <div className="job_container mt_40 layout_center">
                            <div className = "fs20 f-meiryo-bold">ページが見つかりません</div>
                        </div>
                    </div>
                </div>
                <Footer/>                
            </div>
        );
    }
}
export default NotFoundPage; 