import React from "react";
import "../scss/job.scss";
import * as URLS from "../../modules/urls";
import { convertDateToJapan } from "../../modules/global";
import Loading from "../Include/Loading";
import { AlertDialog } from "../Modal/Alert";
import { ConfirmDialog } from "../Modal/Confirm";
//import * as FirebaseAPI from '../../firebase/api/message';
import { Link } from "react-router-dom";
import * as Global from "../../modules/global";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import * as asyncactions from "../../modules/chat/async-actions";
import { ChatActions } from "../../modules/chat/types";
import { IRootState } from "../../modules";
import {
  updateContacts,
  updateMySystemContacts,
} from "../../firebase/api/message";
import { Redirect } from "react-router-dom";
import { FooterActions } from "../../modules/footer";
import * as footerAsyncactions from "../../modules/footer/async-actions";

type JobDetailTypes = {
  jobId: string;
};

const mapStateToProps = ({ message }: IRootState) => {
  const { text, messages } = message;
  return { text, messages };
};

const mapDispatcherToProps = (
  dispatch: Dispatch<ChatActions | FooterActions>
) => {
  return {
    sendSystemMessage: (
      from: number,
      to: number,
      docId: string,
      cmsg: string,
      title: string = "",
      emt_id: number,
      isKuraha: number = 1,
      isFromMe: boolean = true,
      msg = ""
    ) =>
      asyncactions.sendSystemMessageAsync(
        dispatch,
        from,
        to,
        docId,
        cmsg,
        title,
        emt_id,
        isKuraha,
        isFromMe,
        msg
      ),
    isRerender: (_isRerender: boolean) =>
      footerAsyncactions.isRerender(dispatch, _isRerender),
  };
};
type ReduxType = ReturnType<typeof mapDispatcherToProps> & JobDetailTypes;

class JobDetailCom extends React.Component<ReduxType> {
  alertRef: any;
  confirmRef: any;
  confirmRef_1: any;
  deleteAlertRef: any;
  deleteSuccessRef: any;
  state = {
    loading: false,
    isEditable: false,
    isRedirect: false,
    jb_id: "",
    jb_comp_name: "",
    jb_comp_overview: "",
    jb_place_post: "",
    em_id: "",
    st_id: "",
    st_label: "",
    ar_id: "",
    ar_label: "",
    sk_label_s: "",
    jb_street_address: "",
    jb_open_date: "",
    jb_close_date: "",
    jb_time_from: "",
    jb_time_to: "",
    jb_total: "",
    jb_daily_price_low: "",
    jb_daily_price_hi: "",
    jb_note: "",
    cnt_apply: "0",
    // has_relation_em:'0',
    jba_is_direct: null,
    jb_finished: "0",
    jb_created: "",
    ctr_id: null,
    jba_id: null,
    cnt_ctr: "0",
    em_is_legal: "0",
    em_company: "",
    em_company_legal: "",
  };
  constructor(props: any) {
    super(props);
    this.alertRef = React.createRef();
    this.confirmRef = React.createRef();
    this.confirmRef_1 = React.createRef();
    this.deleteAlertRef = React.createRef();
    this.deleteSuccessRef = React.createRef();
  }
  componentDidMount() {
    //console.log( "ID is " + this.props.match.params.id );
    let id = this.props.jobId ? this.props.jobId : "";
    let em_id: any = Global.getEmId();
    if (!id || id === "" || em_id === "") {
      return;
    }
    const that = this;
    const role = localStorage.getItem("role");
    let url = "";
    if (role === "2") {
      url =
        URLS.READ_DETAIL_JOB +
        "&jb_id=" +
        id +
        "&sta_em_id=" +
        em_id +
        "&inc_sk_label=1";
    } else {
      url = URLS.READ_DETAIL_JOB + "&jb_id=" + id + "&inc_sk_label=1";
    }

    that.setState({
      loading: true,
    });

    fetch(url, {
      method: "get",
    })
      .then(async function (response) {
        let ret = await response.json();
        console.log(ret);
        if (ret.total > 0) {
          const item = ret.items[0];
          let isEditable = false;
          let em_id: any = Global.getEmId();
          if (item.em_id && item.em_id === em_id && role && role === "1") {
            isEditable = true;
          }
          that.setState(
            {
              loading: false,
              isEditable: isEditable,
              em_id: item.em_id,
              jb_id: item.jb_id,
              jb_comp_name: item.jb_comp_name,
              jb_comp_overview: item.jb_comp_overview,
              jb_place_post: item.jb_place_post,
              st_id: item.st_id ? item.st_id : "",
              st_label: item.st_label ? item.st_label : "",
              ar_id: item.ar_id ? item.ar_id : "",
              ar_label: item.ar_label ? item.ar_label : "",
              sk_label_s: item.sk_label_s ? item.sk_label_s : "",
              jb_street_address: item.jb_street_address
                ? item.jb_street_address
                : "",
              jb_open_date: item.jb_open_date
                ? convertDateToJapan(item.jb_open_date)
                : "",
              jb_close_date: item.jb_close_date
                ? "～" + convertDateToJapan(item.jb_close_date)
                : "",
              jb_time_from: item.jb_time_from ? item.jb_time_from : ``,
              jb_time_to: item.jb_time_to ? item.jb_time_to : ``,
              jb_total: item.jb_total ? item.jb_total + "人" : "",
              jb_daily_price_low: item.jb_daily_price_low
                ? Global.numberWithCommas(
                    parseInt(item.jb_daily_price_low).toString()
                  )
                : "",
              jb_daily_price_hi: item.jb_daily_price_hi
                ? "～" +
                  Global.numberWithCommas(
                    parseInt(item.jb_daily_price_hi).toString()
                  ) +
                  "円/人工"
                : "",
              jb_note: item.jb_note ? item.jb_note : "",
              jb_created: item.jb_created,
              cnt_apply: item.cnt_apply ? item.cnt_apply : "0",
              jba_is_direct: item.jba_is_direct ? item.jba_is_direct : null,
              jb_finished: item.jb_finished ? item.jb_finished : "0",
              jba_id: item.jba_id ? item.jba_id : null,
              ctr_id: item.ctr_id ? item.ctr_id : null,
              cnt_ctr: item.cnt_ctr ? item.cnt_ctr : "0",
              em_is_legal: item.em_is_legal ? item.em_is_legal : "0",
              em_company: item.em_company ? item.em_company : "",
              em_company_legal: item.em_company_legal
                ? item.em_company_legal
                : "",
              // has_relation_em: item.has_relation_em != undefined?item.has_relation_em:(role === '1'?'1':'0'),
            },
            async () => {
              await that.props.isRerender(true);
            }
          );
        } else {
          that.setState(
            {
              loading: false,
            },
            async () => {
              await that.props.isRerender(true);
            }
          );
        }
      })
      .catch((error) => {
        this.setState(
          { loading: false, errorMessage: error.toString() },
          async () => {
            await that.props.isRerender(true);
          }
        );
      });
  }
  onDirectGiveImplement = () => {
    const that = this;
    let em_id: any = Global.getEmId();
    let jbaId = this.state.jba_id ? this.state.jba_id : "";
    let data = {
      jba_id: jbaId,
    };
    this.setState({
      loading: true,
    });
    fetch(URLS.CONTRACT_JOB, {
      method: "post",
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        let ret = await response.json();
        if (ret.success) {
          let em_worker_name = Global.getEmWorkerName();
          if (em_worker_name === "") {
            em_worker_name = "ニックネーム";
          }

          let em_company =
            that.state.em_is_legal === "0"
              ? that.state.em_company
              : that.state.em_company_legal;

          let contactMsg: string =
            em_worker_name +
            "さんが" +
            `<pre jb_id="${this.props.jobId}" type="contract">` +
            that.state.jb_comp_name +
            `</pre>` +
            "の仕事の直接依頼に同意しました。";
          // let myMsg: string = em_company + 'の' + that.state.jb_comp_name + "の仕事の直接依頼に同意しました。"
          let msg: string =
            `<pre jb_id="${this.props.jobId}" type="contract">` +
            that.state.jb_comp_name +
            `</pre>` +
            "の仕事の直接依頼に同意しました。";
          that.props.sendSystemMessage(
            em_id,
            parseInt(that.state.em_id),
            em_id,
            contactMsg,
            "仕事の直接依頼に同意がありました",
            parseInt(Global.getRole()!),
            1,
            false,
            msg
          );
          updateMySystemContacts(
            `contact_${em_id}`,
            that.state.em_id,
            parseInt(that.state.em_id),
            em_id,
            "仕事の直接依頼に同意しました",
            msg,
            parseInt(Global.getRole()!),
            true
          );

          that.alertRef.current.open(
            "成功",
            that.state.jb_comp_name + "の仕事の直接依頼に同意しました。",
            true
          );
        } else {
          that.alertRef.current.open(
            "エラー",
            "サーバへ保存できませんでした。"
          );
        }
        that.setState({
          loading: false,
        });
      })
      .catch((error) => {
        this.alertRef.current.open("エラー", "サーバへ保存できませんでした。");
        this.setState({ loading: false, errorMessage: error.toString() });
      });
  };
  onDirectGive = () => {
    let em_id: any = Global.getEmId();
    let jbaId = this.state.jba_id ? this.state.jba_id : "";
    if (em_id === "" || jbaId === "") {
      return;
    }
    const point = Global.getPoint();
    if (point < 50) {
      this.alertRef.current.open("お知らせ", "ポイントが不足しています。");
      return;
    } else {
      this.confirmRef_1.current.open(
        "お知らせ",
        "50ポイント消費します。よろしいですか。"
      );
    }
  };
  onApplyImplement = () => {
    const that = this;
    let em_id: any = Global.getEmId();
    if (em_id === "") {
      return;
    }
    this.setState({
      loading: true,
    });
    let em_worker_name: any = Global.getEmWorkerName();
    if (em_worker_name === "") {
      em_worker_name = "ニックネーム";
    }
    let jb_id = this.props.jobId;
    let data = {
      em_id: em_id,
      jb_id: jb_id,
    };
    fetch(URLS.APPLY_JOB, {
      method: "post",
      body: JSON.stringify(data),
    })
      .then(async function (response) {
        let ret = await response.json();
        if (ret.success) {
          // let msg: string = em_worker_name + "さんが" + that.state.jb_comp_name + "に応募しました。";

          const cmsg: string =
            `<pre jb_id="${jb_id}" type="jobapply">` +
            em_worker_name + "さんが" +
            that.state.jb_comp_name +
            `</pre>` +
            "の仕事に応募しました。";

          const msg: string =
            `<pre jb_id="${jb_id}" type="jobapply">` +
            that.state.jb_comp_name +
            `</pre>` +
            "の仕事に応募しました。";
          //let docId:string = em_id + "_" + that.state.jb_id;
          const docId: string = em_id;
          const em_company =
            that.state.em_is_legal === "0"
              ? that.state.em_company
              : that.state.em_company_legal;
          const msg2: string =
            em_company +
            "の" +
            `<pre jb_id="${jb_id}" type="jobapply">` +
            that.state.jb_comp_name +
            `</pre>` +
            "の仕事に応募しました。";

          //boss contact create/update.
          that.props.sendSystemMessage(
            em_id,
            parseInt(that.state.em_id),
            docId,
            cmsg,
            "仕事の応募がありました",
            parseInt(Global.getRole()!),
            1,
            false,
            msg
          );

          //my contact( worker) create/update.
          updateMySystemContacts(
            `contact_${em_id}`,
            that.state.em_id,
            parseInt(that.state.em_id),
            em_id,
            "仕事に応募しました",
            msg2,
            parseInt(Global.getRole()!),
            true
          );
          that.alertRef.current.open(
            "成功",
            that.state.jb_comp_name + "の仕事に応募しました。",
            true
          );
        } else {
          that.alertRef.current.open("エラー", ret.msg);
        }
        that.setState({
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.alertRef.current.open("エラー", "サーバへ保存できませんでした。");
        this.setState({ loading: false, errorMessage: error.toString() });
      });
  };
  onApply = () => {
    let em_id: any = Global.getEmId();
    if (em_id === "") {
      return;
    }
    const point = Global.getPoint();
    if (point < 50) {
      this.alertRef.current.open("お知らせ", "ポイントが不足しています。");
      return;
    } else {
      this.confirmRef.current.open(
        "お知らせ",
        "50ポイント消費します。よろしいですか？"
      );
    }
  };
  onDelete = () => {
    if (this.state.cnt_apply !== "0") {
      this.deleteAlertRef.current.open(
        "お知らせ",
        "応募者・直接依頼した職人がいます。本当に削除してよろしいですか？"
      );
    } else {
      this.deleteAlertRef.current.open(
        "お知らせ",
        "本当に削除してよろしいですか？"
      );
    }
  };
  onDeleteImplement = () => {
    //here is a delete action to server.
    let jb_id = this.props.jobId;
    const data = {
      destroy: [{ jb_id }],
    };
    const that = this;
    fetch(URLS.EDIT_JOB, {
      method: "post",
      body: JSON.stringify(data),
    })
      .then(async function (response) {
        let ret = await response.json();
        if (ret.success) {
          that.deleteSuccessRef.current.open(
            "成功",
            "仕事を削除しました。",
            true
          );
          that.setState({
            loading: false,
          });
        } else {
          that.alertRef.current.open("エラー", "仕事を削除できませんでした。");
          that.setState({
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.alertRef.current.open("エラー", "仕事を削除できませんでした。");
        this.setState({ loading: false, errorMessage: error.toString() });
      });
  };
  public render() {
    const role = Global.getRole();
    return (
      <div>
        {this.state.loading ? <Loading /> : <></>}
        <div className="job_inner">
          <AlertDialog ref={this.alertRef} returnURL={"/provider/myjob"} />
          <AlertDialog ref={this.deleteSuccessRef} returnURL={"/jobview"} />
          <ConfirmDialog
            ref={this.deleteAlertRef}
            func={this.onDeleteImplement}
          />
          <ConfirmDialog ref={this.confirmRef} func={this.onApplyImplement} />
          <ConfirmDialog
            ref={this.confirmRef_1}
            func={this.onDirectGiveImplement}
          />
          {role === "2" ? (
            this.state.jba_is_direct === "1" &&
            this.state.jba_id != null &&
            this.state.ctr_id == null ? ( // if there is a direct job.
              <div
                className="fs12 ml_10 mb_20"
                style={{ color: "#B80B03", textAlign: "right" }}
              >
                「仕事に同意する」と、相手にメッセージが送信されます。50ポイントが使用されます。
              </div>
            ) : this.state.cnt_apply === "0" ? (
              <div
                className="fs12 ml_10 mb_20"
                style={{ color: "#B80B03", textAlign: "right" }}
              >
                「仕事に応募する」と、相手にメッセージが送信されます。50ポイントが使用されます。
              </div>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
          <div className="both_ways">
            <div className="title">{this.state.jb_comp_name}</div>
            {role === "2" ? (
              this.state.jba_is_direct === "1" &&
              this.state.jba_id != null &&
              this.state.ctr_id == null ? ( // if there is a direct job.
                <div className="flex_start">
                  <div className="btn-container mb_20">
                    <div
                      className="grey-button v-small"
                      onClick={this.onDirectGive}
                    >
                      <div className="inner font_14 message_send">
                        依頼に同意する
                      </div>
                    </div>
                  </div>
                  <div className="btn-container ml_10 mb_20">
                    <Link
                      to={{
                        pathname: "/client/profile/" + this.state.em_id,
                        state: {
                          jobId: this.state.jb_id,
                          jba_is_direct: this.state.jba_is_direct,
                        },
                      }}
                    >
                      <div className="grey-button v-small">
                        <div className="inner font_14 message_send">
                          親方/会社を見る
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              ) : this.state.cnt_apply === "0" ? ( // if you haven't applied to this job.
                <div className="btn-container mb_20">
                  <div className="grey-button v-small" onClick={this.onApply}>
                    <div className="inner font_14 message_send">
                      仕事に応募する
                    </div>
                  </div>
                </div>
              ) : (
                //if you already applied to this job.
                <div className="btn-container mb_20">
                  <Link
                    to={{
                      pathname: "/client/profile/" + this.state.em_id,
                      state: {
                        jobId: this.state.jb_id,
                        jba_is_direct: this.state.jba_is_direct,
                      },
                    }}
                  >
                    <div className="grey-button v-small">
                      <div className="inner font_14 message_send">
                        親方/会社を見る
                      </div>
                    </div>
                  </Link>
                </div>
              )
            ) : (
              <></>
            )}
          </div>
          <div className="d_element first">
            <span className="desc_title">工事内容</span>
            <span className="desc">{this.state.jb_comp_overview}</span>
          </div>
          <div className="d_element first">
            <span className="desc_title">業種</span>
            <span className="desc">{this.state.sk_label_s}</span>
          </div>
          <div className="d_element">
            <span className="desc_title">現場住所</span>
            <span className="desc">
              {role === "1" || parseInt(this.state.cnt_ctr) > 0
                ?(this.state.jb_place_post ! =''?`〒`:``) + this.state.jb_place_post + " "
                : ""}{" "}
              {this.state.st_label + this.state.ar_label}
              {role === "1" || parseInt(this.state.cnt_ctr) > 0
                ? this.state.jb_street_address
                : ""}
            </span>
          </div>
          <div className="d_element">
            <span className="desc_title">募集日時</span>
            <span className="desc">
              {this.state.jb_open_date +
                this.state.jb_close_date +
                " " +
                this.state.jb_time_from + 
                ((this.state.jb_time_from != ``||this.state.jb_time_to) != `` ? `～`:``) +
                this.state.jb_time_to}
            </span>
          </div>
          <div className="d_element">
            <span className="desc_title">募集人数</span>
            <span className="desc">{this.state.jb_total}</span>
          </div>
          <div className="d_element">
            <span className="desc_title">予定金額</span>
            <span className="desc">
              {this.state.jb_daily_price_low + this.state.jb_daily_price_hi}
            </span>
          </div>
          <div className="d_element">
            <span className="desc_title">備考</span>
            <span className="desc">{this.state.jb_note}</span>
          </div>
          <div className="d_element">
            <span className="desc_title">仕事登録日</span>
            <span className="desc">
              {convertDateToJapan(this.state.jb_created)}
            </span>
          </div>
        </div>
        {this.state.isEditable ? (
          <>
            {parseInt(this.state.jb_finished) <= 0 ? (
              <div className="grey-button yellow v-large rb mt_40 mb_30">
                <Link to={"/jobedit/" + this.props.jobId}>
                  <div className="inner">
                    <i className="fa fa-pencil"></i>
                    仕事内容を編集する
                  </div>
                </Link>
              </div>
            ) : (
              <></>
            )}
            {parseInt(this.state.cnt_ctr) < 1 ? (
              <div
                className="grey-button yellow v-large rb mt_40 mb_30"
                onClick={this.onDelete}
              >
                <div className="inner">
                  <i className="fa fa-trash"></i>
                  仕事を削除する
                </div>
              </div>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
        {role === "2" &&
        this.state.jba_is_direct === "1" &&
        this.state.jba_id != null &&
        this.state.ctr_id == null ? (
          <>
            <div
              className="fs12 mb_20 mt_70"
              style={{ color: "#B80B03", textAlign: "center" }}
            >
              「依頼に同意する」と、相手にメッセージが送信されます。50ポイントが使用されます。
            </div>
            <div className="button-container">
              <div className="grey-button large">
                <div className="inner" onClick={this.onDirectGive}>
                  依頼に同意する
                  <i className="fa fa-angle-right"></i>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        {role === "2" ? (
          <div className="button-container">
            <div className="grey-button large mt_20">
              <Link to={"/message/" + this.state.em_id}>
                <div className="inner">
                  メッセージを送る
                  <i className="fa fa-angle-right"></i>
                </div>
              </Link>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatcherToProps)(JobDetailCom);
