import { ActionType } from 'typesafe-actions';
import * as actions from './actions';
export type FireBaseUserInfo = {
    em_id?:number,
    em_company?:string,
    em_company_legal?:string,
    em_company_w?:string,
    em_depart?:string,
    em_firstname?:string,
    em_is_individual?:string,
    em_is_legal?:string,
    em_lastname?:string,
    em_photo?:string,
    emt_is_boss?:string,
    emt_is_worker?:string,
}
export type UserInfo = {
    id?:number;
    role?:string;
    em_name?:string;
    em_company?:string;
    em_boss_company?:string,
    em_depart?:string,
    em_worker_company?:string,
    em_worker_name?:string,
    em_role?:string,
    em_passwd?:string;
    lastLogIn?:string;
    image?:string;
    bigImage?:string;
    point?:number;
    star?:string;
    sk_s?:string,
    em_system?:string,
    is_not_available?:number;
}
export type UserState = {
    isLogIn:boolean;
    userInfo:UserInfo;
    roleCheckListener:any,
};

export enum actionTypes {
    LOGIN = "userinfo/login",
    LOGOUT = "userinfo/logout",
    PROFILE = "userinfo/profile",
    CHANGE_AVARTA = "userinfo/avarta",
    ADD_ROLE_LISTENER = "userinfo/addRole",
    REMOVE_ROLE_LISTENER =  "userinfo/removeRole",
}
export type UserInfoActions = ActionType<typeof actions>;