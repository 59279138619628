import React from 'react';
import '../scss/job.scss';
import '../scss/privacy.scss';
import Header from '../Include/Header'
import {Link} from 'react-router-dom';
import Footer from '../Include/Footer';
import * as Global from '../../modules/global';
class Privacy extends React.Component<any>{
    public render(){
        const em_id = Global.getEmId();		
        return (
            <div>
                {
                    (em_id && em_id !== "")?<Header/>:<></>
                }
                <div className="content-container" id = "content">
                    <div className="wrap-content">
					<div className="main-title">利用規約・個人情報の取り扱いについて</div>					
					<div className="job_container mt_40">
						<div className="privacy">
							<div className="privacy_inner">
								<div className="element last mb_0">
									<div className="title fs24 mb_0">利用規約</div>																	
								</div>								
								<div className="element">
									<div className="fs14">
										<p>
										この利用規約（以下、「本規約」といいます。）は、有限会社寺田ガラス（以下、「当社」といいます。）が提供するクラハ（クラフトマンハウス／CRAFTSMAN HOUSE）アプリケーションでの、インターネットを利用した情報提供、課金・決済サービス（以下、「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下、「ユーザー」といいます。）には、本規約に従って、本サービスをご利用いただきます。	
										</p>
									</div>
								</div>
								<div className="element">
									<div className="title">第1条（定義）</div>																	
									<div className="fs14">
										<p>本規約では、以下の用語を使用します。</p>
										<p className = "flex_start ml_30"><span>①</span><span>「コンテンツ」とは、文章、画像、動画、音声、音楽、ソフトウェア、プログラム、コードその他の情報のことをいいます。</span></p>
										<p className = "flex_start ml_30"><span>②</span><span>「ポイント」とは、本サービスにおいて有償で提供されるサービスまたはコンテンツと交換可能な前払式手段およびこれに類する</span></p>
									</div>
								</div>
								<div className="element">
									<div className="title">第2条（適用）</div>																	
									<div className="fs14">
										<p className = "flex_start"><span>1.</span><span className = "ml_10">本規約は、ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。</span></p>
										<p className = "flex_start"><span>2.</span><span className = "ml_10">当社は本サービスに関し、本規約のほか、ご利用にあたってのルール等、各種の定め（以下、「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず、本規約の一部を構成するものとします。</span></p>
										<p className = "flex_start"><span>3.</span><span className = "ml_10">本規約の規定が前条の個別規定の規定と矛盾する場合には、個別規定において特段の定めなき限り、個別規定の規定が優先されるものとします。</span></p>										
									</div>
								</div>
								<div className="element">
									<div className="title">第3条（利用規約の変更）</div>																	
									<div className="fs14">
										<p>
										当社は、必要と判断した場合には、ユーザーに通知することなくいつでも本規約を変更することができるものとします。変更された規約は、ユーザーへ適宜の方法で通知した時をもって効力が生じ、ユーザーに適用されるものとします。効力が生じた後、本サービスを利用した場合には、当該ユーザーは変更後の規約に同意したものとみなします。											
										</p>
									</div>
								</div>
								<div className="element">
									<div className="title">第4条（利用登録／アカウント作成）</div>																	
									<div className="fs14">
										<p className = "flex_start"><span>1.</span><span className = "ml_10">本サービスにおいては、登録希望者が本規約に同意の上、当社の定める方法によって利用登録を申請し、当社がこれを承認することによって、利用登録が完了しアカウントが作成されるものとします。この場合、登録希望者は、真実、正確かつ完全な情報を登録しなければなりません。</span></p>
										<p className = "flex_start"><span>2.</span><span className = "ml_10">当社は、利用登録の申請者に以下の事由があると判断した場合、利用登録の申請を承認しないことがあり、その理由については一切の開示義務を負わないものとします。<br/>もののことをいいます。</span></p>
										<p className = "flex_start ml_30"><span>①</span><span className="ml_10">利用登録の申請に際して虚偽の事項を届け出た場合</span></p>
										<p className = "flex_start ml_30"><span>②</span><span className="ml_10">本規約に違反したことがある者からの申請である場合</span></p>
										<p className = "flex_start ml_30"><span>③</span><span className="ml_10">その他、当社が利用登録を相当でないと判断した場合</span></p>
										<p className = "flex_start"><span>3.</span><span className="ml_10">未成年の登録希望者は、親権者その他の法定代理人も本規約に同意したうえで利用登録の申請を行うものとします。</span></p>
										<p className = "flex_start"><span>4.</span><span className="ml_10">本サービスを事業者のために利用登録する場合は、当該事業者も本規約に同意したうえで申請を行うものとします。</span></p>																				
									</div>
								</div>
								<div className="element">
									<div className="title">第5条（利用登録の内容変更）</div>																	
									<div className="fs14">
										<p className = "flex_start"><span>1.</span><span className = "ml_10">ユーザーは、登録した内容に変更が生じた場合には、速やかに本サービス所定の方法で変更の届出をするものとします。</span></p>
										<p className = "flex_start"><span>2.</span><span className = "ml_10">ユーザーが、前項の届出をするまでの間または前項の届出を怠ったことにより、不利益を被ったとしても、当社は一切その責任を負いません。</span></p>
									</div>
								</div>
								<div className="element">
									<div className="title">第6条（ユーザーIDおよびパスワードの管理）</div>																	
									<div className="fs14">
										<p className = "flex_start"><span>1.</span><span className = "ml_10">ユーザーは、自己の責任において、本サービスのユーザーIDおよびパスワードを適切に管理するものとします。</span></p>
										<p className = "flex_start"><span>2.</span><span className = "ml_10">ユーザーは、いかなる場合にも、ユーザーIDおよびパスワードを第三者に譲渡または貸与し、もしくは第三者と共用することはできません。当社は、ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には、そのユーザーIDを登録しているユーザー自身による利用とみなします。</span></p>
										<p className = "flex_start"><span>3.</span><span className = "ml_10">ユーザーIDおよびパスワードが第三者によって使用されたことによって生じた損害は、当社に故意または重大な過失がある場合を除き、当社は一切の責任を負わないものとします。</span></p>
										<p className = "flex_start"><span>4.</span><span className = "ml_10">単一のユーザーIDにより本サービスを、ユーザーと他者により同時に利用した接続、または複数のユーザーIDを利用して同じ端末から同時に行われた接続等の機能および品質について、当社は一切保証しません。</span></p>
									</div>
								</div>
								<div className="element">
									<div className="title">第7条（ポイント）</div>																	
									<div className="fs14">
										<p className = "flex_start"><span>1.</span><span className = "ml_10">ポイントは、本サービスでの購入、キャンペーンその他、当社が指定する方法によりユーザーに付与されます。ポイントの購入単位、価格、有効期間、決済方法、その他のポイントの付与条件は、当社が定め本サービスまたは当社ウェブサイトに表示します。</span></p>
										<p className = "flex_start"><span>2.</span><span className = "ml_10">本サービス内の一部については、ポイントと交換することによる有料サービスまたは有料コンテンツとなります。サービスまたはコンテンツとの交換に必要なポイント数は、当社が定め本サービスまたは当社ウェブサイトに表示します。</span></p>
										<p className = "flex_start"><span>3.</span><span className = "ml_10">当社は、本サービスにおいて無償または有料とされているサービスまたはコンテンツについて、当社の裁量でその価格を変更することがあります。</span></p>
										<p className = "flex_start"><span>4.</span><span className = "ml_10">ポイントは、当社が指定するサービスまたはコンテンツ以外の、現金、財物その他の経済上の利益と交換することはできません。直接、第三者による仲介またはインターネットオークション等その手段を問わない売買、現金や財物その他の経済上の対価を授受する形でのあらゆる譲渡、譲受、貸与、借用等する行為（いわゆるRMT【リアルマネートレード】を含みますが、これに限られません。また、本サービス内におけるユーザーの行為に対して現金や財物その他の経済上の対価を授受することも含みます。）を禁止いたします。</span></p>
										<p className = "flex_start"><span>5.</span><span className = "ml_10">ポイントは、これを取得したアカウントでのみ利用できます。また、オペレーティングシステムの異なる端末機器で提供されるポイントは、同一名称であっても種類が異なりますので、承継や合算はできません。</span></p>
										<p className = "flex_start"><span>6.</span><span className = "ml_10">ポイントは、理由を問わず、一切払戻しを行いません。ただし、法令上必要な場合はこの限りではありません。この場合、ポイントの払戻方法は、法令に従って当社が定め、当社のウェブサイト等に表示します。</span></p>
										<p className = "flex_start"><span>7.</span><span className = "ml_10">ユーザーは、利用に必要なポイント数、ポイントの利用期間その他の利用条件に従うものとします。ポイントの利用条件は、当社が定め本サービスまたは当社ウェブサイトに表示します。</span></p>
										<p className = "flex_start"><span>8.</span><span className = "ml_10">本サービスの画面上で「購入」、「販売」等の表示がされている場合であっても、当社がユーザーに対し提供する本コンテンツに関する知的財産権その他の権利はユーザーに移転せず、ユーザーには、ポイントの利用権のみが付与されます。</span></p>										
									</div>
								</div>
								<div className="element">
									<div className="title">第8条（当社の役割・立場）</div>																	
									<div className="fs14">
										<p className = "flex_start"><span>1.</span><span className = "ml_10">本サービスは、ユーザー間における仕事の受発注その他の取引（以下、「個別取引」といいます。）のための場・機会を提供することを内容とするものです。そのため、当社は、個別取引の当事者にはならず、別途特に定めた場合を除き、受発注の取り消し、解除、保証などの個別取引に関しては一切関与いたしません。</span></p>
										<p className = "flex_start"><span>2.</span><span className = "ml_10">ユーザーは、個別取引における一切の義務を自己の費用・責任において遂行するものとします。</span></p>
									</div>
								</div>
								<div className="element">
									<div className="title">第9条（禁止事項）</div>																	
									<div className="fs14">
										<p className = "flex_start"><span>1.</span><span className = "ml_10">ユーザーは、本サービスの利用にあたり、以下の行為をしてはなりません。</span></p>
										<p className = "flex_start ml_30"><span>①</span><span className = "ml_10">当社もしくは他者の権利を侵害する行為または侵害するおそれのある行為</span></p>
										<p className = "flex_start ml_30"><span>②</span><span className = "ml_10">他者を差別、誹謗中傷する行為または他者の名誉もしくは信用を毀損する行為</span></p>
										<p className = "flex_start ml_30"><span>③</span><span className = "ml_10">第三者になりすまして本サービスを利用する行為、意図的に虚偽の情報を流布させる行為、不正アクセスまたはこれを試みる行為、不正な目的を持って本サービスを利用する行為</span></p>
										<p className = "flex_start ml_30"><span>④</span><span className = "ml_10">選挙の事前運動や選挙運動またはこれらに類似する行為、宗教活動や団体等への勧誘行為、面識のない異性との出会いを目的とした行為</span></p>
										<p className = "flex_start ml_30"><span>⑤</span><span className = "ml_10">営業活動、営利を目的とした利用またはその準備行為</span></p>
										<p className = "flex_start ml_30"><span>⑥</span><span className = "ml_10">詐欺等の犯罪に結びつく行為または犯罪行為に関連する行為</span></p>
										<p className = "flex_start ml_30"><span>⑦</span><span className = "ml_10">過度に暴力的な表現、わいせつな表現、画像など他者に不快感を与えるコンテンツを送信または表示する行為</span></p>
										<p className = "flex_start ml_30"><span>⑧</span><span className = "ml_10">本人の同意を得ることなく、または詐欺的な手段により他者の個人情報を収集または蓄積する行為</span></p>
										<p className = "flex_start ml_30"><span>⑨</span><span className = "ml_10">法令、本規約もしくは公序良俗に違反する行為、本サービスの運営を妨害するおそれのある行為、当社の信用を毀損し、もしくは当社の財産を侵害する行為または他者もしくは当社に不利益、損害、不快感を与える行為</span></p>
										<p className = "flex_start ml_30"><span>⑩</span><span className = "ml_10">自己または他者の個人情報を開示する行為</span></p>
										<p className = "flex_start ml_30"><span>⑪</span><span className = "ml_10">当社が承認した場合を除き、他者に対して本サービス、および本サービスから得た情報を再頒布、貸与または販売する行為</span></p>
										<p className = "flex_start ml_30"><span>⑫</span><span className = "ml_10">本サービスの不具合や障害を不正な目的で利用し、またはそれを他者へ伝達する行為</span></p>
										<p className = "flex_start ml_30"><span>⑬</span><span className = "ml_10">当社もしくは他者のサーバーまたはネットワークの機能を破壊したり、妨害したりする行為</span></p>
										<p className = "flex_start ml_30"><span>⑭</span><span className = "ml_10">不当な目的または態様でのリバースエンジニアリング、逆アセンブルを行う行為、その他の方法でソースコードを解読する行為</span></p>
										<p className = "flex_start ml_30"><span>⑮</span><span className = "ml_10">当社のサービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為</span></p>
										<p className = "flex_start ml_30"><span>⑯</span><span className = "ml_10">その他当社が不適当と判断する行為</span></p>
										<p className = "flex_start"><span>2.</span><span className = "ml_10">ユーザーは、当社の提供する本サービスならびに本サービスのコンテンツおよびその内容について、その全部または一部を問わず、商業目的で利用（使用、複製、複写、蓄積、再生、販売、再販売その他形態のいかんを問いません）することはできません。</span></p>
										<p className = "flex_start"><span>3.</span><span className = "ml_10">ユーザーは、当社の書面による事前の承諾なしに、本規約に基づく地位、権利もしくは義務の全部もしくは一部を第三者に譲渡し、引き受けさせ、または担保に供してはならないものとします。</span></p>
										<p className = "flex_start"><span>4.</span><span className = "ml_10">当社は、本条または法令の遵守状況等を確認する必要がある場合、法令に反しない限り、ユーザーの利用登録内容および送信コンテンツの内容等を確認することができ、ユーザーはそれに同意するものとします。ただし、当社はそのような確認を行う義務を負うものではありません。</span></p>
									</div>
								</div>
								<div className="element">
									<div className="title">第10条（本サービスの提供）</div>																	
									<div className="fs14">
										<p className = "flex_start"><span>1.</span><span className = "ml_10">ユーザーは、本サービスを利用するにあたり、必要なパーソナルコンピュータ、携帯電話機、スマートフォンその他の通信機器、オペレーションシステム、通信手段および電力等を、ユーザーの費用と責任で用意しなければなりません。</span></p>
										<p className = "flex_start"><span>2.</span><span className = "ml_10">当社は、ユーザーに通知することなく、本サービスの内容を変更することができるものとし、これによってユーザーが負担した一切の費用（電話代、通信料、プロバイダーとの契約等に基づく費用をいいますが、それに限りません）について支払いの義務を負わないものとします。</span></p>
										<p className = "flex_start"><span>3.</span><span className = "ml_10">当社は、以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。</span></p>
										<p className = "flex_start ml_30"><span>①</span><span className = "ml_10">本サービス、その他本サービスを提供するために必要なシステムのメンテナンス、電気通信設備の保守上または工事上やむを得ないとき、またこれらにやむを得ない障害が発生したとき</span></p>
										<p className = "flex_start ml_30"><span>②</span><span className = "ml_10">本サービスに著しい負荷や障害が与えられることによって正常なサービスを提供することが困難である場合、または困難であると当社が判断したとき</span></p>
										<p className = "flex_start ml_30"><span>③</span><span className = "ml_10">データの改ざん、ハッキング等本サービスを提供することにより、ユーザー、第三者等が著しい損害を受ける可能性を当社が認知したとき</span></p>
										<p className = "flex_start ml_30"><span>④</span><span className = "ml_10">電気通信事業者または国内外の電気通信事業体による電気通信サービス、電力会社による電力供給サービス、その他の公共サービスの提供が停止されることで、本サービスの提供が困難になったとき</span></p>
										<p className = "flex_start ml_30"><span>⑤</span><span className = "ml_10">火災・停電等の事故、地震・津波・台風・落雷またはその他の天災地変、戦争、内乱、暴動、労働争議、法令の制定改廃その他不可抗力等により、非常事態が発生した場合、またはそのおそれがあるとき</span></p>
										<p className = "flex_start ml_30"><span>⑥</span><span className = "ml_10">その他、当社が本サービスの提供が困難と判断した場合</span></p>
										<p className = "flex_start"><span>4.</span><span className = "ml_10">当社は、ユーザーへの適宜の方法で通知することにより、本サービスを終了することができるものとします。</span></p>
										<p className = "flex_start"><span>5.</span><span className = "ml_10">当社は、本サービスの内容の変更、停止または中断、終了により、ユーザーまたは第三者が被ったいかなる不利益または損害についても、一切の責任を負わないものとします。</span></p>
									</div>
								</div>
								<div className="element">
									<div className="title">第11条（広告表示）</div>																	
									<div className="fs14">
										<p>
										当社は、本サービスに当社または第三者の広告を掲載することができます。											
										</p>
									</div>
								</div>
								<div className="element">
									<div className="title">第12条（利用制限および登録抹消）</div>																	
									<div className="fs14">
										<p className = "flex_start"><span>1.</span><span className = "ml_10">当社は、ユーザーが以下のいずれかに該当する場合には、事前の通知なく、ユーザーに対して、本サービスの全部もしくは一部の利用を制限し、またはユーザーとしての登録を抹消（アカウント削除）することができるものとします。</span></p>
										<p className = "flex_start ml_30"><span>①</span><span className = "ml_10">本規約のいずれかの条項に違反した場合</span></p>
										<p className = "flex_start ml_30"><span>②</span><span className = "ml_10">登録事項に虚偽の事実があることが判明した場合</span></p>
										<p className = "flex_start ml_30"><span>③</span><span className = "ml_10">料金等の支払債務の不履行があった場合</span></p>
										<p className = "flex_start ml_30"><span>④</span><span className = "ml_10">当社からの連絡に対し、一定期間返答がない場合</span></p>
										<p className = "flex_start ml_30"><span>⑤</span><span className = "ml_10">本サービスについて、最終の利用から一年間以上利用がない場合</span></p>
										<p className = "flex_start ml_30"><span>⑥</span><span className = "ml_10">その他、当社が本サービスの利用を適当でないと判断した場合</span></p>
										<p className = "flex_start"><span>2.</span><span className = "ml_10">ユーザーの本サービスにおけるすべての利用権（ポイントの利用権を含みます。）は、理由を問わず、登録抹消された時点で消滅します。</span></p>
										<p className = "flex_start"><span>3.</span><span className = "ml_10">当社は、本条に基づき当社が行った行為によりユーザーに生じた損害について、一切の責任を負いません。</span></p>
									</div>
								</div>
								<div className="element">
									<div className="title">第13条（退会／アカウント削除）</div>																	
									<div className="fs14">
										<p className = "flex_start"><span>1.</span><span className = "ml_10">ユーザーが、本サービスの利用を終了する場合は、当社所定の方法によりユーザー自身で退会の手続きをするものとし、当社が確認したことをもってユーザーが退会した（利用契約が解除された）ものとし、アカウントを削除します。</span></p>
										<p className = "flex_start"><span>2.</span><span className = "ml_10">ユーザーの本サービスにおけるすべての利用権（ポイントの利用権を含みます。）は、退会された時点で消滅します。ユーザーが誤って退会した場合であっても、登録情報およびポイントを復旧できませんのでご注意ください。</span></p>
									</div>
								</div>
								<div className="element">
									<div className="title">第14条（保証の否認および免責事項）</div>																	
									<div className="fs14">
										<p className = "flex_start"><span>1.</span><span className = "ml_10">本サービスは、当社がユーザーに対して本サービスを提供する時点において当社にとって提供可能な内容のものとし、当社は、本サービスに事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。</span></p>
										<p className = "flex_start"><span>2.</span><span className = "ml_10">本サービスに関する当社のユーザーに対する責任は、ユーザーが支障なく本サービスを利用できるよう善良なる管理者の注意をもって本サービスを運営することに限られるものとします。当社は、本サービスに起因し、またはこれに関連して発生したユーザーもしくはその他の第三者の損害（ユーザーおよび他者の間で生じたトラブルに起因する損害も含みます）、および本サービスを利用できなかったことにより発生したユーザーまたはその他の第三者の損害（本条において、以下に具体的に定める損害を含み、これらに限られません）に対し、当社に故意または重大な過失がある場合を除き、いかなる責任も負わないものとし、損害賠償義務も一切負わないものとします。ただし、本サービスに関する当社とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合、この免責規定は適用されません。</span></p>
										<p className = "flex_start"><span>3.</span><span className = "ml_10">前項ただし書に定める場合であっても、当社は、当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（当社またはユーザーが損害発生につき予見し、または予見し得た場合を含みます。）について一切の責任を負いません。また、当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害の賠償は、ユーザーから当該損害が発生した月に受領した当該本サービスの利用料の額を上限とします。</span></p>
										<p className = "flex_start"><span>4.</span><span className = "ml_10">ユーザーは、本サービスの利用および本サービスを利用して行ったすべての行為ならびにその結果について責任を負うものとします。当社は、本サービスに関して、ユーザーと他のユーザーまたは第三者との間において生じた個別取引の内容、品質、適法性、安全性などについては一切保証いたしません。また、個別取引に関する役務の提供または代金の支払いの可能性に関し、一切保証いたしません。ユーザー間の論議・紛争その他のトラブルについて、当事者同士の責任で解決するものとし、当社は一切責任を負いません。ユーザーは、当社もしくは第三者に対する損害を与えた場合、ユーザー自身の責任と費用をもって解決するものとします。</span></p>
										<p className = "flex_start"><span>5.</span><span className = "ml_10">本サービスを提供する機器の故障、トラブル、停電など、および通信回線の異常など当社の予測を超えた不可抗力、またはシステムの障害などにより個人認証情報、個人情報その他のユーザーに関するデータ等が消失または紛失することがあります。このような事態の発生によりユーザーに関するデータ等が 消失または紛失した場合であっても、当社は、自らに故意または重大な過失がある場合を除いて、これにより発生した損害につき一切責任を負いません。</span></p>
										<p className = "flex_start"><span>6.</span><span className = "ml_10">ユーザーは、自己の判断により、健全な環境を乱すような本サービスの利用を避ける義務を負うものとします。ユーザーが適切な利用を逸脱したことにより生じた社会的、精神的、肉体的な損害に関し、当社は一切責任を負わないものとします。 消失または紛失した場合であっても、当社は、自らに故意または重大な過失がある場合を除いて、これにより発生した損害につき一切責任を負いません。</span></p>
										<p className = "flex_start"><span>7.</span><span className = "ml_10">ユーザーは、自己の個人認証情報を利用して本サービス上でなされた一切の行為およびその結果について、当該行為を自己が行ったか否かを問わず、一切の責任を負うものとし、当社は、ユーザーの個人認証情報が他者に使用されたことによってユーザーまたは第三者が被る損害について、当該ユーザーの故意過失の有無にかかわらず一切責任を負わないものとします。 消失または紛失した場合であっても、当社は、自らに故意または重大な過失がある場合を除いて、これにより発生した損害につき一切責任を負いません。</span></p>
										<p className = "flex_start"><span>8.</span><span className = "ml_10">本サービスのサイトからは他のウェブサイトなどへリンクをしている場合があります。移動した先のホームページは当社が管理運営するものではなく、本サービス外のサイトやリソースの利用可能性については責任を有しておりません。また、当該サイトやリソースに包含され、または当該サイトやリソース上で利用が可能となっているコンテンツ等、広告、商品、役務などについては一切責任を負うものではありません。したがって、当社には、それらのコンテンツ等、広告、商品、サービスなどに起因または関連して生じた一切の損害（間接的であると直接的であるとを問いません）について賠償する責任はありません。 消失または紛失した場合であっても、当社は、自らに故意または重大な過失がある場合を除いて、これにより発生した損害につき一切責任を負いません。</span></p>
										<p className = "flex_start"><span>9.</span><span className = "ml_10">本サービスにおいて広告（懸賞広告を含みます。）または宣伝を行っている広告主との取引（懸賞等のプロモーションへの参加を含みます。）は、ユーザーと当該広告主の責任において行っていただきます。つまり、商品等の代金の支払い、契約条件の決定、保証、担保責任などは、すべてユーザーと広告主が当事者として責任を負うことになります。当社は、本サービスにおいて掲載されている広告等によって行われる取引に起因する損害および広告等が掲載されたこと自体に起因する損害については一切責任を負いません。</span></p>
									</div>
								</div>
								<div className="element">
									<div className="title">第15条（個人情報の取り扱い）</div>																	
									<div className="fs14">
										<p>
										当社は、本サービスの利用によって取得する個人情報については、「クラハプライバシーポリシー」に従い適切に取り扱うものとします。											
										</p>
									</div>
								</div>
								<div className="element">
									<div className="title">第16条（通知または連絡）</div>																	
									<div className="fs14">
										<p>
										ユーザーと当社との間の通知または連絡は、当社の定める方法によって行うものとします。当社は、ユーザーから、当社が別途定める方式に従った変更届け出がない限り、現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い、これらは、発信時にユーザーへ到達したものとみなします。											
										</p>
									</div>
								</div>
								<div className="element">
									<div className="title">第17条（本規約違反等への対処）</div>																	
									<div className="fs14">
										<p className = "flex_start"><span>1.</span><span className = "ml_10">当社は、ユーザーが本規約に違反した場合もしくはそのおそれのある場合、またはユーザーによる本サービスの利用に関し第三者から当社にクレーム・請求等がなされ、かつ当社が必要と認めた場合、ユーザーに対しその調査の協力を求めることができ、ユーザーはこれに協力するものとします。また、その他の理由で必要と当社が判断した場合は、当該ユーザーに対し、以下のいずれかまたは複数の措置を組み合わせて講ずることがあります。</span></p>
										<p className = "flex_start ml_30"><span>①</span><span className = "ml_10">規約等に違反する行為またはそのおそれのある行為を止めること、および同様の行為を繰り返さないことを要求します。</span></p>
										<p className = "flex_start ml_30"><span>②</span><span className = "ml_10">第三者との間で、クレーム・請求等の解消のための協議（裁判外紛争解決手続を含みます。）を行うことを要求します。</span></p>
										<p className = "flex_start ml_30"><span>③</span><span className = "ml_10">ユーザーが発信または表示する情報の全部もしくは一部を削除します。</span></p>
										<p className = "flex_start ml_30"><span>④</span><span className = "ml_10">その他、必要と判断する対応を行います。</span></p>
										<p className = "flex_start"><span>2.</span><span className = "ml_10">当社は、本条第1項に定める措置を講ずることにつき何ら義務を負うものではなく、また当該措置に起因する結果につき一切責任を負いません。</span></p>
										<p className = "flex_start"><span>3.</span><span className = "ml_10">ユーザーは、本条第1項に定める措置は、当社の裁量により事前に通知なく行われることを承諾します。</span></p>
									</div>
								</div>
								<div className="element">
									<div className="title">第18条（使用言語）</div>																	
									<div className="fs14">
										<p>
										本サービスおよび本サービスの利用にかかる個別規定および問合せ等は、日本語で行うものとします。											
										</p>
									</div>
								</div>
								<div className="element">
									<div className="title">第19条（準拠法・裁判管轄）</div>																	
									<div className="fs14">
										<p className = "flex_start"><span>1.</span><span className = "ml_10">本規約の解釈にあたっては、日本法を準拠法とします。</span></p>
										<p className = "flex_start"><span>3.</span><span className = "ml_10">本サービスに関して紛争が生じた場合には、当社の本店所在地を管轄する裁判所を専属的合意管轄とします。</span></p>
									</div>
								</div>
								<div className="element last mb_0">
									<div className="title fs24 mb_0">クラハ プライバシーポリシー</div>
								</div>
								<div className="element">
									<div className="fs14">
										<p>
										有限会社寺田ガラス（以下、「当社」といいます。）は、当社が提供するクラハ（クラフトマンハウス／CRAFTSMAN HOUSE）アプリケーションでの、インターネットを利用したサービス（以下、「本サービス」といいます。）における、登録ユーザーの皆さま（以下、「ユーザー」といいます。）の個人情報（以下の定義に従います。）の取扱いについて、以下のとおりプライバシーポリシー（以下、「本ポリシー」といいます。）を定め、遵守します。
										</p>
									</div>
								</div>
								<div className="element">
									<div className="title flex_start"><span className = "f-meiryo-bold">1.</span><span className = "f-meiryo-bold ml_10">基本的考え方</span></div>																	
									<div className="fs14">
										<p>
										当社は、本サービスの利用によって取得する個人情報については、「クラハプライバシーポリシー」に従い適切に取り扱うものとします。											
										</p>
									</div>
								</div>
								<div className="element">
									<div className="title flex_start"><span className = "f-meiryo-bold">2.</span><span className = "f-meiryo-bold ml_10">個人情報とパーソナル情報</span></div>																	
									<div className="fs14">
										<p>
										「個人情報」とは、個人情報の保護に関する法律（以下、「個人情報保護法」といいます。）に規定される「個人情報」を指すものとし、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含む。）を指します。
										「パーソナル情報」とは、個人を識別することができるかどうかによらない、個人に関する情報全般を指します。「パーソナル情報」には「個人情報」が含まれます。
										</p>
									</div>
								</div>
								<div className="element">
									<div className="title flex_start"><span className = "f-meiryo-bold">3.</span><span className = "f-meiryo-bold ml_10">個人情報を含むパーソナル情報の収集方法</span></div>																	
									<div className="fs14">
										<p>
										当社は、ユーザーが本サービスを利用するにあたり当社に提供した情報（①職人の公開プロフィール：氏名、所属会社名、生年月日、住所（都道府県、市区町村）、業種、国籍、金額、②職人の公開プロフィール以外のプロフィール：住所（番地など）、特記事項、保険加入状況、家族連絡先、健康診断日、血圧、血液型、特殊健康診断情報、保有資格、空き時間、③親方／会社の公開プロフィール：、会社名、代表者、創業（設立）年月日、法人番号、従業員数、支店名、部署名、所在地、業種、入力担当者名、事前連絡事項、④電話番号、メールアドレス、⑤クレジットカード番号を含む金融情報、⑥サービス利用履歴、検索条件などの内容、当社との連絡内容、統計情報等を含みますが、これらに限られません。）を収集します。また、ユーザーと提携先などとの間でなされたユーザーの個人情報を含む取引記録や決済に関する情報を、当社の提携先などから収集することがあります。																					
										</p>
									</div>
								</div>
								<div className="element">
									<div className="title flex_start"><span className = "f-meiryo-bold">4.</span><span className = "f-meiryo-bold ml_10">個人情報を含むパーソナル情報を収集・利用する目的</span></div>																	
									<div className="fs14">
										<p>当社が個人情報を含むパーソナル情報を収集・利用する目的は、以下のとおりです。</p>
										<p className = "flex_start ml_30"><span>①</span><span className = "ml_10">本サービスの提供・運営のため</span></p>
										<p className = "flex_start ml_30"><span>&nbsp;</span><span className = "ml_10">（主に、ユーザー間で一部の情報を開示し合ってマッチングするサービスの提供）</span></p>
										<p className = "flex_start ml_30"><span>②</span><span className = "ml_10">本サービス利用に関する統計データを収集・作成するため</span></p>
										<p className = "flex_start ml_30"><span>③</span><span className = "ml_10">ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）</span></p>
										<p className = "flex_start ml_30"><span>④</span><span className = "ml_10">ユーザーが利用中のサービスの新機能、更新情報、キャンペーン等および当社が提供する他のサービスの案内のメールを送付するため</span></p>
										<p className = "flex_start ml_30"><span>⑤</span><span className = "ml_10">メンテナンス、重要なお知らせなど必要に応じたご連絡のため</span></p>
										<p className = "flex_start ml_30"><span>⑥</span><span className = "ml_10">利用規約に違反したユーザーや、不正・不当な目的でサービスを利用しようとするユーザーの特定をし、ご利用をお断りするため</span></p>
										<p className = "flex_start ml_30"><span>⑦</span><span className = "ml_10">ユーザーにご自身の登録情報の閲覧や変更、削除、ご利用状況の閲覧を行っていただくため</span></p>
										<p className = "flex_start ml_30"><span>⑧</span><span className = "ml_10">有料サービスにおいて、ユーザーに利用料金を請求するため</span></p>
										<p className = "flex_start ml_30"><span>⑨</span><span className = "ml_10">上記の利用目的に付随する目的</span></p>
									</div>
								</div>
								<div className="element">
									<div className="title flex_start"><span className = "f-meiryo-bold">5.</span><span className = "f-meiryo-bold ml_10">個人情報を含むパーソナル情報利用目的の変更</span></div>																	
									<div className="fs14">
										<p>
										当社は、利用目的が変更前と関連性を有すると合理的に認められる場合に限り、パーソナル情報の利用目的を変更するものとします。利用目的の変更を行った場合には、変更後の目的について、当社所定の方法により、ユーザーに通知し、または当社ウェブサイト上に公表するものとします。
										</p>
									</div>
								</div>
								<div className="element">
									<div className="title flex_start"><span className = "f-meiryo-bold">6.</span><span className = "f-meiryo-bold ml_10">Cookie（クッキー）</span></div>																	
									<div className="fs14">
										<p>
										当社は、ユーザーが便利に本サービスを利用できるようにするためCookieを使用することがあります。 ユーザーは、ブラウザの設定でCookie等を無効にすることにより、当社のCookieの使用を拒否することができます。ただし、Cookieを無効にした場合、本サービスの一部を利用できなくなる可能性があります。
										</p>
									</div>
								</div>
								<div className="element">
									<div className="title flex_start"><span className = "f-meiryo-bold">7.</span><span className = "f-meiryo-bold ml_10">SSLセキュリティ</span></div>																	
									<div className="fs14">
										<p>
										本サービスは、ユーザーの個人情報を保護するために「SSL」に対応しています。
										SSLとは「Secure Socket Layer」の略で、WebサーバーとWebブラウザーとの間に暗号化し送受信できる通信方法です。
										セキュリティ機能に対応したブラウザを使用することで、ユーザーが入力する氏名や住所あるいは電話番号などの個人情報が自動的に暗号化されて送受信されるため、万が一、送受信データが第三者に傍受された場合でも、内容が盗み取られる心配はありません。
										</p>
									</div>
								</div>
								<div className="element">
									<div className="title flex_start"><span className = "f-meiryo-bold">8.</span><span className = "f-meiryo-bold ml_10">ログの保存について</span></div>																	
									<div className="fs14">
										<p>
										ユーザーが本サービスを利用する場合、利用時にドメイン名、IPアドレス、デバイス情報、ブラウザ種類、ブラウザ言語、アクセス日時、リンク元などが保存されます。これらの情報は本サービスの保守管理（不正行為の防止を含む。）や利用状況に関する統計分析のために活用されますが、それ以外の目的で利用されることはありません。
										</p>
									</div>
								</div>
								<div className="element">
									<div className="title flex_start"><span className = "f-meiryo-bold">9.</span><span className = "f-meiryo-bold ml_10">個人情報の利用制限</span></div>																	
									<div className="fs14">
										<p>
										当社は、公表した利用目的の達成に必要な範囲内でのみユーザーの個人情報を取り扱います。ただし、以下の各号に該当する場合は、ユーザーの同意なく、予め公表した利用目的の達成に必要な範囲を超えてユーザーの個人情報を取り扱うことがあります。
										</p>
										<p className = "flex_start ml_30"><span>①</span><span className = "ml_10">法令に基づく場合</span></p>
										<p className = "flex_start ml_30"><span>②</span><span className = "ml_10">人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき</span></p>
										<p className = "flex_start ml_30"><span>③</span><span className = "ml_10">公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき</span></p>
										<p className = "flex_start ml_30"><span>④</span><span className = "ml_10">国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</span></p>
									</div>
								</div>
								<div className="element">
									<div className="title flex_start"><span className = "f-meiryo-bold">10.</span><span className = "f-meiryo-bold ml_10">管理体制について</span></div>																	
									<div className="fs14">
										<p>
										当社は、全ての個人情報に対し、不正アクセス、盗難、紛失、滅失、破壊、改ざん、棄損及び漏えい等を防止するため、必要な予防・是正措置を講じ、適正に管理します。 また、個人情報の処理を第三者に委託する場合は、個人情報保護を規定した契約を締結するとともに、適正な管理が行われるよう管理・監督します。
										</p>
									</div>
								</div>
								<div className="element">
									<div className="title flex_start"><span className = "f-meiryo-bold">11.</span><span className = "f-meiryo-bold ml_10">情報共有</span></div>																	
									<div className="fs14">
										<p>
										ユーザーの公開プロフィールは、他のユーザーと共有されることになります。また、公開プロフィール以外のプロフィールは、ユーザーが本サービス上で開示を了承した特定のユーザーとの間に限り共有されることになります。情報が共有されたあとは、ユーザーご自身も当社も他者の行動をコントロールすることはできません。情報の管理には十分ご注意ください。
										</p>
									</div>
								</div>																
								<div className="element">
									<div className="title flex_start"><span className = "f-meiryo-bold">12.</span><span className = "f-meiryo-bold ml_10">個人情報の第三者への提供</span></div>																	
									<div className="fs14">
										<p className = "flex_start"><span>(1)</span><span className = "ml_10">当社は、次に掲げる場合を除いて、あらかじめユーザーの同意を得ることなく、第三者に個人情報を提供することはありません。ただし、個人情報保護法その他の法令で認められる場合を除きます。</span></p>
										<p className = "flex_start ml_30"><span>①</span><span className = "ml_10">人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき</span></p>
										<p className = "flex_start ml_30"><span>②</span><span className = "ml_10">公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき</span></p>
										<p className = "flex_start ml_30"><span>③</span><span className = "ml_10">国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</span></p>
										<p className = "flex_start ml_30"><span>④</span><span className = "ml_10">その他、ユーザー・当社・第三者にとって重大かつ緊急の必要がある場合</span></p>
										<p className = "flex_start"><span>(2)</span><span className = "ml_10">前項の定めにかかわらず、次に掲げる場合には、当該情報の提供先は第三者に該当しないものとします。</span></p>										
										<p className = "flex_start ml_30"><span>①</span><span className = "ml_10">当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する 場合</span></p>
										<p className = "flex_start ml_30"><span>②</span><span className = "ml_10">合併その他の事由による事業の承継に伴って個人情報が提供される場合</span></p>
										<p className = "flex_start ml_30"><span>③</span><span className = "ml_10">個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について、あらかじめ本人に通知し、または本人が容易に知り得る状態に置いた場合</span></p>
									</div>
								</div>
								<div className="element">
									<div className="title flex_start"><span className = "f-meiryo-bold">13.</span><span className = "f-meiryo-bold ml_10">第三者提供の例外</span></div>																	
									<div className="fs14">
										<p>当社は、以下の場合には、第三者による個人情報の取得に関し、何らの責任も負いません。</p>
										<p className = "flex_start ml_30"><span>①</span><span className = "ml_10">ユーザー自らが、本サービス上の機能、または別の手段を用いて、他のユーザーに個人情報を明らかにする場合</span></p>
										<p className = "flex_start ml_30"><span>②</span><span className = "ml_10">活動情報、またはその他のユーザーが本サービス上に入力した情報により、期せずして本人が特定できてしまった場合</span></p>
										<p className = "flex_start ml_30"><span>③</span><span className = "ml_10">ユーザー本人以外のユーザーが個人を識別できる情報（ユーザID、パスワード等）を入手した場合</span></p>
									</div>
								</div>
								<div className="element">
									<div className="title flex_start"><span className = "f-meiryo-bold">14.</span><span className = "f-meiryo-bold ml_10">個人情報の開示等</span></div>																	
									<div className="fs14">
										<p>当社は、本人から個人情報の開示を求められたときは、本人に対し、遅滞なくこれを開示します。ただし、開示することにより次のいずれかに該当する場合は、その全部または一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。ただし、履歴情報および特性情報などの個人情報以外の情報については、原則として開示いたしません。</p>
										<p className = "flex_start ml_30"><span>①</span><span className = "ml_10">本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合</span></p>
										<p className = "flex_start ml_30"><span>②</span><span className = "ml_10">当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</span></p>
										<p className = "flex_start ml_30"><span>③</span><span className = "ml_10">その他法令に違反することとなる場合</span></p>
									</div>
								</div>
								<div className="element">
									<div className="title flex_start"><span className = "f-meiryo-bold">15.</span><span className = "f-meiryo-bold ml_10">個人情報の訂正および削除</span></div>																	
									<div className="fs14">
										<p>当社は、本人から個人情報の開示を求められたときは、本人に対し、遅滞なくこれを開示します。ただし、開示することにより次のいずれかに該当する場合は、その全部または一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。ただし、履歴情報および特性情報などの個人情報以外の情報については、原則として開示いたしません。</p>
									</div>
								</div>
								<div className="element">
									<div className="title flex_start"><span className = "f-meiryo-bold">16.</span><span className = "f-meiryo-bold ml_10">個人情報の利用停止等</span></div>																	
									<div className="fs14">
										<p className = "flex_start"><span>(1)</span><span className = "ml_10">当社は、本人から、個人情報が、利用目的の範囲を超えて取り扱われているという理由、または不正の手段により取得されたものであるという理由により、その利用の停止または消去（以下、「利用停止等」といいます。）を求められた場合には、遅滞なく必要な調査を行います。</span></p>
										<p className = "flex_start"><span>(2)</span><span className = "ml_10">前項の調査結果に基づき、その請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の利用停止等を行います。</span></p>
										<p className = "flex_start"><span>(3)</span><span className = "ml_10">当社は、前項の規定に基づき利用停止等を行った場合、または利用停止等を行わない旨の決定をしたときは、遅滞なく、これをユーザーに通知します。</span></p>
										<p className = "flex_start"><span>(4)</span><span className = "ml_10">(4)	前2項にかかわらず、利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、ユーザーの権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じるものとします。</span></p>
									</div>
								</div>
								<div className="element">
									<div className="title flex_start"><span className = "f-meiryo-bold">17.</span><span className = "f-meiryo-bold ml_10">リンクについて</span></div>																	
									<div className="fs14">
										<p>本サービスにおいて、外部のサイトへのリンクが貼られることがあります。この外部のサイトで登録される個人情報は、当社で管轄する情報ではないため、一切の責任を負うことができません。 外部サイトで個人情報を登録される際は、そのサイトのプライバシーポリシーをご確認ください。</p>
									</div>
								</div>
								<div className="element">
									<div className="title flex_start"><span className = "f-meiryo-bold">18.</span><span className = "f-meiryo-bold ml_10">海外からのアクセスの場合</span></div>																	
									<div className="fs14">
										<p>日本国外からアクセスするユーザーの個人情報は、日本の個人情報保護法に基づき日本国内で処理され保存されます。ユーザーは、個人情報と通信内容の収集、利用、譲渡、または公開に対して、日本の法律が適用されることに同意するものとします。</p>
									</div>
								</div>
								<div className="element">
									<div className="title flex_start"><span className = "f-meiryo-bold">19.</span><span className = "f-meiryo-bold ml_10">プライバシーポリシーの改定</span></div>																	
									<div className="fs14">
										<p>本プライバシーポリシーの内容は予告なく変更される場合があります。 変更が生じた場合は、ユーザーへの適宜の方法で通知し、変更後のプライバシーポリシーをサイト上に掲載した時点で変更の効力を生じるものとします。</p>
									</div>
								</div>
								<div className="element last mb_0">
									<div className="title fs24 mb_0">お問い合わせ先</div>																	
								</div>
								<div className="element">
									<div className="fs14">
										<p>社名：有限会社寺田ガラス</p>
										<p>Eメールアドレス：xxxxxx@xxxxxxxx.xx</p>
									</div>
								</div>							
							</div>
							<div className="button-container">
								<div className="grey-button large mt_30">
									<Link to = "/createconfirm">
										<div className="inner">
										閉じる
										<i className="fa fa-angle-right"></i>
										</div>
									</Link>
								</div>										
							</div>								
						</div>
					</div>
                    </div>
                </div>
				<Footer/>
            </div>
        );
    }
}
export default Privacy;