import React, { Component } from 'react';
import '../scss/recommend.scss';
import {Link} from 'react-router-dom';
import * as Global from '../../modules/global';
import {Redirect} from 'react-router-dom';

class RecommendLinkCom extends Component<any>{
    state = {
        isRedirect:false,
    }
    anotherAccount = () =>{
        this.setState({
            isRedirect:true,
        })    
    }
    renderRedirect = () =>{
        if( this.state.isRedirect ){
            let role = Global.getRole();
            if( role == '1' ){
                role = '2';
                Global.setDefaultRole(role);                
            }else if( role == '2'){
                role = '1';
                Global.setDefaultRole(role);
            }
            return <Redirect to = {"/choose"}/>
        }else{
            return <></>;
        }
    }    
    render(){
        const emtId = Global.getEmtId();        
        return(
            <div className="right-menu-container mt_70">
                {this.renderRedirect()}                
                <ul>
                    <li>
                        <Link to = "/provider/search" className="fa"><span className='block'>仕事を探す・応募する、親⽅/会社を</span><span className='block'>探す</span></Link>
                    </li>
                    <li>
                        <Link to = "/myschedule" className="fa"><span className='block'>空き時間管理、応募仕事履歴、親⽅/</span><span className='block'>会社履歴</span></Link>
                    </li>
                    <li>
                        <Link to = {"/messagelist" } className="fa"><span>メッセージ</span></Link>
                    </li>
                    <li>
                        <Link to = "/provider/myprofile" className="fa"><span>職⼈プロフィールの登録・修正</span></Link>
                    </li>
                    <li>
                        <Link to = "/pointview" className="fa"><span>所持ポイント表⽰、ポイント購⼊</span></Link>
                    </li>
                    {
                        emtId === '3'?
                        <li>
                            <a className="fa" onClick = {this.anotherAccount}><div className="fa"><span>親方/会社でログイン</span></div></a>
                        </li>							
                        :<></>
                    }
                </ul>
            </div>
        );
    }

}
export default RecommendLinkCom; 
