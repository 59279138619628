import React, { Component } from 'react';
import '../scss/job.scss';
import {Link} from 'react-router-dom';
import * as URLS from '../../modules/urls';
import Loading from '../Include/Loading';
import {AlertDialog} from '../Modal/Alert';
import * as Global from '../../modules/global';
import * as footerAsyncactions from '../../modules/footer/async-actions';
import { FooterActions } from '../../modules/footer';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

const mapDispatcherToProps = (dispatch: Dispatch<FooterActions>) => {
    return {
        isRerender:( _isRerender: boolean ) => footerAsyncactions.isRerender( dispatch, _isRerender ),             
    }
}

type ReduxType = ReturnType<typeof mapDispatcherToProps> & any;
class JobCom extends Component<ReduxType>{
    alertRef:any;    
    state = {
        loading:false,
        items:[]
    }
    constructor(props:any ){
        super(props);
        this.alertRef = React.createRef();
    }
    componentDidMount(){
        const that = this;
        let em_id:any = Global.getEmId();
        let work_em_id:any = this.props.em_id?this.props.em_id:"";
        if( em_id == "" || work_em_id == "" ){
            return;
        }
        let role = Global.getRole();
        if( role == "2" ){
            let temp_id = em_id;
            em_id = work_em_id;
            work_em_id = temp_id;
        }
        this.setState({
            loading:true,
        });
        const sort = [{"property":"jb_created","direction":"desc"},{"property":"jb_id","direction":"desc"}];             
// http://192.168.1.103/index.php/job/read?start=NaN&
// sort=[{"property":"jb_created","direction":"desc"},{"property":"jb_id","direction":"desc"}]&tim_id=17&em_id=142&sta_em_id=137&cnt_ctr_over=0&inc_sk_label=1        
        fetch( URLS.READ_JOB +"&em_id="+ em_id + "&sort=" + JSON.stringify(sort) + "&sta_em_id=" + work_em_id + "&cnt_ctr_over=0", {
            method: 'get',
        }).then(async function(response) {
            let ret = await response.json();
            if( ret.total > 0 ){
                const items = ret.items;
                that.setState({
                    loading:false,
                    items:items,
                }, async() => {
                    await that.props.isRerender( true );
                });
            }else{
                that.setState({
                    loading:false,
                    items:[],
                }, async() => {
                    await that.props.isRerender( true );
                });
            }
        }).catch(error => {
            this.setState({ loading:false, items:[], errorMessage: error.toString() }, async() => {
                await that.props.isRerender( true );
            });
        });   
    }
    canFeedback = (item:any):boolean => {
        let can:boolean = false;
        const role = Global.getRole();
        let cnt_ctrc = 0;
        if( role == "1" ){
            cnt_ctrc = parseInt(item.cnt_ctrc_w);
        }else{
            cnt_ctrc = parseInt(item.cnt_ctrc_b);            
        }
        if( parseInt(item.cnt_ctr) > 0 &&  cnt_ctrc == 0 ){
            can = true;
        }
        return can;
    }
    getIsBoss = () =>{
        const role = Global.getRole();
        if( role == '1' ){
            return '0';
        }else{
            return '1';
        }     
    }
    render(){
        return (
            <div>
                {this.state.loading?
                    <Loading/>
                    :<></>
                }
                <div className="job_inner">
                    <AlertDialog ref = {this.alertRef} returnURL = "/myprovider"/>                                
                    {
                        this.state.items.length == 0?<span>仕事がありません。</span>:<></>
                    }
                    {
                    this.state.items.map( item => (
                        <div className="element flex" key = {item['jb_id']}>
                            <Link to = {"/jobdetail/" + item['jb_id']}>  
                                <span className={item['cnt_ctr'] > 0?"job_title no_flex mark_choosed_job":'job_title no_flex'}>
                                    <span className = " mr_10">{item['jb_comp_name']}</span>
                                </span>
                            </Link>
                            <div className="btn_area">
                                <div className={"grey-button vv-small mr_10" + ( this.canFeedback(item)?"":' disable' ) }>
                                {
                                    this.canFeedback(item)?
                                    <Link to = { {pathname:"/leave_feedback/" + this.props.em_id, state:{ em_photo_s: item['em_photo_s'], ctrId:item['ctr_id'], is_boss:this.getIsBoss()}}}>
                                        <div className="inner">
                                        評価をつける		
                                        </div>
                                    </Link>
                                    :
                                    <div className="inner">
                                    評価をつける		
                                    </div>
                                }
                                </div>
                            </div>
                        </div>
                    ))
                    }
                </div>
            </div>
        );        
    }
}
export default connect(null, mapDispatcherToProps)(JobCom);