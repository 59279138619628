import React, { Component } from "react";
import "../../scss/job.scss";
import "../../scss/search.scss";
import Header from "../../Include/Header";
import { Link, withRouter } from "react-router-dom";
import SearchNavCom from "../../Find/SearchNavCom";
import SimpleReactValidator from "simple-react-validator";
import { BasicDropDown } from "../../Include/dropdown/BasicDropDown";
import * as Global from "../../../modules/global";
import * as URLS from "../../../modules/urls";
import Footer from "../../Include/Footer";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ja from "date-fns/locale/ja";
import moment from "moment";

import { connect } from "react-redux";
import { IRootState } from "../../../modules";
import { compose, Dispatch } from "redux";
import * as asyncactions from "../../../modules/searchimmediatejob/async-actions";
import Loading from "../../Include/Loading";

import {
  SearchImmediateJob,
  SearchImmediateJobState,
  SearchImmediateJobActions,
} from "../../../modules/searchimmediatejob";

const mapStateToProps = ({ searchImmediateJob }: IRootState) => {
  return { searchImmediateJob };
};
const mapDispatcherToProps = (
  dispatch: Dispatch<SearchImmediateJobActions>
) => {
  return {
    save: (searchImmediateJob: SearchImmediateJob) =>
      asyncactions.save(dispatch, searchImmediateJob),
  };
};

type ReduxType = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatcherToProps> &
  History;

class PSearchImmediateJobCom extends Component<
  ReduxType,
  SearchImmediateJobState
> {
  validator: SimpleReactValidator;
  state = {
    loading: false,
    jb_date: moment().format("MM/DD/YYYY"),
    jb_time: "0",
    items: [],
    errorMessage: "",
  };
  constructor(props: any) {
    super(props);
    registerLocale("ja", ja);
    this.validator = new SimpleReactValidator({
      messages: Global.VALIDATION_MESSAGES,
      autoForceUpdate: this,
      className: "error",
      validators: Global.VALIDATORS,
    });
  }
  onHandleChange = (e: any) => {
    let value: string = e.target.value;
    let name: string = e.target.name;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value, // No error here, but can't ensure that key is in StateKeys
    }));
  };
  onHandleBlur = (e: any) => {
    let name: string = e.target.name;
    this.validator.showMessageFor(name);
  };
  // handleChange = (data:Date ) =>{
  //     this.setState({
  //         rj_jb_date:data,
  //       });
  // }
  componentDidMount() {
    const searchImmediateJob: SearchImmediateJobState =
      this.props.searchImmediateJob;
    this.setState({
      jb_date: searchImmediateJob.jb_date,
      jb_time: searchImmediateJob.jb_time,
    });
    if (searchImmediateJob.jb_date && searchImmediateJob.jb_date !== "") {
      this.search();
    }
  }
  onSearch = (e: any) => {
    if (this.validator.allValid()) {
      this.props.save(this.state);
      this.search();
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  search = () => {
    let em_id: any = Global.getEmId();

    if (em_id === "") {
      return;
    }
    const that = this;
    this.setState({
      loading: true,
    });
    let url = URLS.SEARCH_JOB_PERIOD;
    let params = "";
    if (this.state.jb_date !== "0") {
      params += "&jb_date=" + this.state.jb_date;
    }
    if (this.state.jb_time !== "0") {
      const value = parseInt(this.state.jb_time);
      const free_element = Global.DATE_DEVIDE_OPTIONS.filter(
        (item) => item.grp_ft_id === value
      );
      if (free_element && free_element.length > 0) {
        params += "&ft_id_s=" + free_element[0].grp_ft_value;
      }
    }
    let sta_em_id: any = Global.getEmId();
    params += "&sta_em_id=" + sta_em_id;
    params += "&jb_finished=0";
    params += "&em_id_not=" + em_id;
    params +=
      '&sort=[{"property":"jb_created","direction":"desc"},{"property":"jb_id","direction":"desc"}]';
    url = url + params;
    fetch(url, {
      method: "get",
    })
      .then(async function (response) {
        let ret = await response.json();
        if (ret.total > 0) {
          const items = ret.items;
          that.setState({
            loading: false,
            items: items,
          });
        } else {
          that.setState({
            loading: false,
            items: [],
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          items: [],
          errorMessage: error.toString(),
        });
      });
  };
  renderSkill(skillStr: string) {
    if (!skillStr) {
      return [];
    }
    const skills = skillStr.split(",");
    let ret = [];
    for (let i = 0; i < skills.length; i++) {
      ret.push(
        <span className="tag-mg fs14" key={i}>
          <span className="tag-inner">{skills[i]}</span>
        </span>
      );
    }
    return ret;
  }

  public render() {
    return (
      <div className="recommend job find search">
        {this.state.loading ? <Loading /> : <></>}
        <div className="s_element mt_20 border">
          <div className="mr_5">
            <span className="tag-mg fs14 mt_5">
              <span className="tag-inner">日</span>
            </span>
          </div>
          <div className="mt_3">
            <div className="second_column top mr_20">
              <div className="wd_145 inline_block mr_5">
                <BasicDropDown
                  className="full_wide"
                  name="jb_date"
                  idKey={"jb_date"}
                  valueKey={"jb_date_name"}
                  url={""}
                  data={Global.NEAR_DATE_OPTIONS}
                  checkedValue={
                    this.state.jb_date !== "0" ? this.state.jb_date : "0"
                  }
                  defaultName="日付"
                  onHandleChange={this.onHandleChange}
                  onHandleBlur={this.onHandleBlur}
                  noPrefix={true}
                  notNeedDefaultOption={true}
                />
              </div>
              {/* <div className="inline_block">
                                <div className="wd_160 inline_block top mr_5">
                                    <BasicDropDown
                                        className="full_wide" name="jb_time" idKey={"grp_ft_id"} valueKey={"grp_ft_name"} url={''} data={Global.DATE_DEVIDE_OPTIONS} checkedValue={this.state.jb_time !== undefined ? this.state.jb_time : '0'} defaultName="時間"
                                        onHandleChange={this.onHandleChange} onHandleBlur={this.onHandleBlur} noPrefix={true} />
                                </div>
                            </div> */}
            </div>
            {this.validator.message("jb_date", this.state.jb_date, "required")}
          </div>
          <div className="btn-container">
            <div className="grey-button v-small s_pd_t" onClick={this.onSearch}>
              <div className="inner font_14 fa search">検索する</div>
            </div>
          </div>
        </div>
        <div className="job_inner recommend-inner">
          {this.state.items.length === 0 ? (
            <span className="no_content mt_20">
              条件に一致する仕事がありません。
            </span>
          ) : (
            <></>
          )}
          {this.state.items.map((item) => (
            <div className="element flex" key={item["jb_id"]}>
              <Link
                to={
                  "/pjobdetail/" +
                  item["jb_id"] +
                  "/" +
                  (item["em_is_legal"] === "0"
                    ? item["em_company"]
                    : item["em_company_legal"]) +
                  "/" +
                  (item["em_depart"] != "" ? item["em_depart"] : " ") +
                  "/" +
                  item["em_photo_s"]
                }
              >
                <span className={"job_title"}>
                  <span>{item["jb_comp_name"]}</span>
                </span>
                <div className="fs14">
                  {item["em_is_legal"] === 0
                    ? item["em_company"]
                    : item["em_company_legal"]}
                </div>
                <div className="fs14 grey_color">募集日時</div>
                <div className="fs14 ">
                  {item["jb_open_date"] +
                    `～` +
                    item["jb_close_date"] +
                    ` ` +
                    (item["jb_time_from"] ? item["jb_time_from"] : "") +
                    `～` +
                    (item["jb_time_to"] ? item["jb_time_to"] : "")}
                </div>
              </Link>
              <div className="btn_area">
                <div className="grey-button vv-small mr_10">
                  <Link
                    to={
                      "/pjobdetail/" +
                      item["jb_id"] +
                      "/" +
                      (item["em_is_legal"] === "0"
                        ? item["em_company"]
                        : item["em_company_legal"]) +
                      "/" +
                      (item["em_depart"] != "" ? item["em_depart"] : " ") +
                      "/" +
                      item["em_photo_s"]
                    }
                  >
                    <div className="inner">詳細</div>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
// export default compose(
//     withRouter,
//     connect(mapStateToProps, mapDispatcherToProps)
// )(PSearchImmediateJobCom);
export default connect(
  mapStateToProps,
  mapDispatcherToProps
)(PSearchImmediateJobCom);
