import { action } from 'typesafe-actions';
import { actionTypes, UserInfo } from './types';

export function LogIn( isLogIn:boolean, userInfo:UserInfo ) {
    return action(actionTypes.LOGIN, {
        isLogIn, userInfo
    });
}
export function SetProfile( userInfo:UserInfo ) {
    return action(actionTypes.PROFILE, {
        userInfo
    });
}

export function ChangeAvarta( image:any, isBigImage:boolean = true ) {
    return action(actionTypes.CHANGE_AVARTA, {
        image, isBigImage
    });
}
export function LogOut( isLogIn:boolean, userInfo:UserInfo ) {
    return action(actionTypes.LOGOUT, {
        isLogIn, userInfo
    });
}
export function registerRoleListener( listener:any ){
    return action(actionTypes.ADD_ROLE_LISTENER, {
        listener
    });    
}
export function removeRoleListener(){
    return action(actionTypes.REMOVE_ROLE_LISTENER, {
    });    
}