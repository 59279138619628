import React, {Component} from 'react';
import '../scss/job.scss';
import '../scss/search.scss';
import {Link} from 'react-router-dom';

export type SearchNavProps = {
    to?: string;
};
export default class ProviderNavCom extends Component<SearchNavProps>{
    public render(){
        let url = this.props.to;
        let which:number = 1;
        if( url !== undefined ){
            if( url === "/myschedule" ){
                which = 1;
            }else if( url.indexOf("/provider/myjob") !== -1 ){
                which = 2;
            }else if( url.indexOf("/jobdetail") !== -1 ){
                which = 2;
            }else if( url.indexOf("/job/") !== -1 ){
                which = 3;
            }else if( url.indexOf("/leave_feedback/") !== -1 ){
                which = 3;
            }else if( url === "/myclient" ){
                which = 3;
            }else if( url.indexOf("/client/profile" ) !== -1 ){
                which = 3;
            }            
        }        
        return(
            <div className="job_sidebar">
                <div className="job_sidebar_inner">
                    <ul>
                        <li>
                            <Link to = "/myschedule" className = {which=== 1?"n_active":""}> 
                                <span className="fa search">空き時間管理</span>
                            </Link>
                        </li>
                        <li>
                            <Link to = "/provider/myjob" className = {which=== 2?"n_active":""}> 
                                <span className="fa search">応募仕事履歴</span>
                            </Link>
                        </li>								
                        <li>
                            <Link to = "/myclient" className = {which=== 3?"n_active":""}> 
                                <span className="fa search">親⽅/会社履歴</span>
                            </Link>
                        </li>								

                    </ul>
                    <div className="clear"></div>								
                </div>
            </div>
        );
    }
}
