import React from 'react';
import '../scss/job.scss';
import {Link} from 'react-router-dom';

export type JobNavComProps = {
    to?: string;
};
 
class JobNavCom extends React.Component<JobNavComProps> {
    public render(){
        let url = this.props.to;
        let which:number = 1;
        if( url !== undefined ){
            if( url.indexOf( "job" ) !== -1 ){
                which = 1;
            }else if( url.indexOf( "applicant" ) !== -1 ){
                which = 1;
            }else if( url.indexOf( "profile" ) !== -1 ){
                which = 2;
            }else if( url.indexOf( "feedback" ) !== -1 ){
                which = 2;
            }else if( url.indexOf( "myprovider" ) !== -1 ){
                which = 2;
            }            
        }
        return (
            <div className="job_sidebar">
                <div className="job_sidebar_inner">
                    <ul>
                        <li>
                            <Link to = "/jobview" className = {which=== 1?"n_active":""}>                        
                                <span>仕事管理</span>
                            </Link>    
                        </li>
                        <li>
                            <Link to = "/myprovider" className = {which=== 2?"n_active":""}>                        
                                <span>職人履歴</span>
                            </Link>                            
                        </li>								
                    </ul>
                    <div className="clear"></div>								
                </div>
            </div>
        );
    }
}


export default JobNavCom; 