// import React from 'react';
// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   Link,
//   useRouteMatch,
//   useParams
// } from 'react-router-dom';

// // import './components/scss/common.scss';
// // import './components/scss/choose.scss';

// export default function App() {
//   return (
//     <Router>
//       <div>
//         <ul>
//           <li>
//             <Link to="/">Home</Link>
//           </li>
//           <li>
//             <Link to="/choose">About</Link>
//           </li>
//           <li>
//             <Link to="/topics">Topics</Link>
//           </li>
//         </ul>

//         <Switch>
//           <Route path="/about">
//             <About />
//           </Route>
//           <Route path="/topics">
//             <Topics />
//           </Route>
//           <Route path="/">
//             <Home />
//           </Route>
//         </Switch>
//       </div>
//     </Router>
//   );
// }

// function Home() {
//   return <h2>Home</h2>;
// }

// function About() {
//   return <h2>About</h2>;
// }

// function Topics() {
//   let match = useRouteMatch();

//   return (
//     <div>
//       <h2>Topics</h2>

//       <ul>
//         <li>
//           <Link to={`${match.url}/components`}>Components</Link>
//         </li>
//         <li>
//           <Link to={`${match.url}/props-v-state`}>
//             Props v. State
//           </Link>
//         </li>
//       </ul>

//       {/* The Topics page has its own <Switch> with more routes
//           that build on the /topics URL path. You can think of the
//           2nd <Route> here as an "index" page for all topics, or
//           the page that is shown when no topic is selected */}
//       <Switch>
//         <Route path={`${match.path}/:topicId`}>
//           <Topic />
//         </Route>
//         <Route path={match.path}>
//           <h3>Please select a topic.</h3>
//         </Route>
//       </Switch>
//     </div>
//   );
// }

// function Topic() {
//   let { topicId } = useParams();
//   return <h3>Requested topic ID: {topicId}</h3>;
// }


// import React from 'react';
// import Page from './components/splash';

// function App() {
//   return (
//       <Page />
//   );
// }

// export default App;

import React, { Component } from 'react';
import Routes from './Routes';
import './components/scss/common.scss';
import './components/scss/main.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import IdleTimer from 'react-idle-timer'
// function App() {
//   return (
//       <Page />
//   );
// }

// export default App;
class App extends Component<any,{}>{
    // constructor(props) {
    //   super(props)
    //   this.idleTimer = null
    //   this.onAction = this._onAction.bind(this)
    //   this.onActive = this._onActive.bind(this)
    //   this.onIdle = this._onIdle.bind(this)
    // } 
  render(){
    return (
        <Router>       
          <Routes />
        </Router>  
  );
  }
}
export default App;
