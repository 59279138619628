import React, {Component} from 'react';
import '../scss/job.scss';
//import '../scss/profile.scss';
import '../scss/change_type.scss';
import {Link} from 'react-router-dom';
import * as Global from '../../modules/global';
import * as URLS from '../../modules/urls';

class AccountTypeCom extends Component<any>{
    public render(){
        const emtId = Global.getEmtId();
        const role = Global.getRole();
        let status = "";
        if( emtId === '1' ){
            status = '親方/会社';
        }else if( emtId === '2' ){
            status = '職人';            
        }else if( emtId === '3' ){
            status = '親方/会社・職人';            
        }
        const photo = Global.getEmSmallPhoto();
        return(
        <div className="change_type">
            <div className="change_type_inner">
                <div>
                    <div className="fs16 f-meiryo-bold">{"現在の登録形態：" + status }</div>
                    <div className="box_container">
                        {
                            emtId === '1' || emtId === '3'?
                            <div className={ role === '1'?"box active":'box'}>
                                <div>
                                    <span className="tag-mg fs12"><span className="tag-inner fs12">親方/会社</span></span>
                                    <div className="company_info">
                                        <img alt = '' src = {URLS.IMAGE_URL + photo}/>
                                        <div>
                                            <div className="fs15">{Global.getEmBossCompany()}</div>
                                            <div className="fs11">{Global.getEmDepart()}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :<></>
                        }
                        {
                            emtId === '2' || emtId === '3'?
                            <div className={ role === '2'?"box active":'box'}>
                                <div>
                                    <span className="tag-mg fs14"><span className="tag-inner">職人</span></span>
                                    <div className="company_info">
                                        <img alt = '' src = {URLS.IMAGE_URL + photo}/>
                                        <div>
                                            <div className="fs11">{Global.getEmWorkerCompany()}</div>
                                            <div className="fs15">{Global.getEmWorkerName()}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :<></>
                        }
                    </div>
                </div>
            </div>
            <div className="button-container">
                <div className="grey-button large mt_70">
                    <Link to = "/change_account_type">
                        <div className="inner">
                        登録形態を変更する
                        <i className="fa fa-angle-right"></i>
                        </div>
                    </Link>
                </div>										
            </div>								
        </div>
        );
    }
}
export default AccountTypeCom;