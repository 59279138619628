import React, {Component} from 'react';
import '../scss/job.scss';
import '../scss/search.scss';
import {Link} from 'react-router-dom';

export type SearchNavProps = {
    to?: string;
};
export default class ProviderSearchNavCom extends Component<SearchNavProps>{
    public render(){
        let url = this.props.to;
        let which:number = 1;
        if( url !== undefined ){
            if( url.indexOf("provider/search") !== -1 ){
                which = 1;
            }
            if( url.indexOf("/provider/searcharea") !== -1 ){
                which = 2;
            }
            if( url.indexOf("/provider/immediate") !== -1 ){
                which = 3;
            }
            if( url === "/pjobview" ){
                which = 4;
            }
            if( url === "/freepeople2" ){
                which = 5;
            }
        }        
        return(
            <div className="job_sidebar">
                <div className="job_sidebar_inner provider_search">
                    <ul>
                        <li>
                            <Link to = "/provider/search" className = {which=== 1?"n_active":""}> 
                                <span className="fa search">条件を指定して仕事を検索する</span>
                            </Link>
                        </li>
                        <li>
                            <Link to = "/provider/searcharea" className = {which=== 2?"n_active":""}> 
                                <span className="fa search">条件を指定して親方を検索する</span>
                            </Link>
                        </li>								
                        <li>
                            <Link to = "/provider/immediate" className = {which=== 3?"n_active":""}> 
                                <span className="fa search">すぐに働ける仕事を探す</span>
                            </Link>
                        </li>								
                        {/* <li>
                            <Link to = "/pjobview" className = {which== 4?"n_active":""}> 
                                <span className="fa search">仕事を一覧で表示する</span>
                            </Link>
                        </li>
                        <li>
                            <Link to = "/freepeople2" className = {which== 5?"n_active":""}> 
                                <span className="fa search">親方を一覧で表示する</span>
                            </Link>
                        </li>                         */}
                    </ul>
                    <div className="clear"></div>								
                </div>
            </div>
        );
    }
}
