// export const BASE_ABSOLUTE_URL:string = "http://3.129.55.44";
export const BASE_ABSOLUTE_URL:string = "https://craftsmanhouse.jp";

export const BASE_SERVER_URL:string = "https://craftsmanhouse.jp/server/src";

// export const BASE_ABSOLUTE_URL:string = "http://localhost:3000";
// export const BASE_SERVER_URL:string = "http://192.168.0.107";
//export const BASE_SERVER_URL:string = "http://3.129.55.44/server/src";

//  export const BASE_ABSOLUTE_URL:string = "http://localhost:3000";
//  export const BASE_SERVER_URL:string = "http://www.local.kurahaserver.com";

// export const BASE_ABSOLUTE_URL:string = "https://www.kuraha.onlineservices365.com";
// export const BASE_SERVER_URL:string = "https://www.kuraha.onlineservices365.com/server/src";



export const BASE_URL:string = BASE_SERVER_URL + "/index.php";
export const IMAGE_URL:string = BASE_SERVER_URL + "/upload/";
export const GET_PREFECTURE_URL = BASE_URL + "/state/read?page_all=1";
export const GET_AREA_URL = BASE_URL + "/area/read?page_all=1";
export const SAVE_PROFILE = BASE_URL + "/emp/sync";
export const DELETE_ACCOUNT = BASE_URL + "/emp/sync";
export const GET_PROFILE = BASE_URL + "/emp/profile";
export const LOGIN = BASE_URL + "/emp/read";
export const GET_EMP_LIST = BASE_URL + "/emp/read";
//export const SAVE_PROFILE_PICTURE = "http://192.168.1.100/savepicture.php";
export const DUMMY_IMG = BASE_SERVER_URL + "/upload/uphoto/dummy.jpg";
export const DUMMY_IMG_S = BASE_SERVER_URL + "/upload/uphoto/dummy_s.jpg";
export const SAVE_PROFILE_PICTURE = BASE_URL + "/emp/photoupload";;
export const CREATE_ACCOUNT = BASE_URL + "/emp/register";
//export const CREATE_ACCOUNT = "http://192.168.1.100/saveprofile.php";
//export const CHECK_USER_UNIQUE = "http://192.168.1.100/checkuser.php";
export const CHECK_FORGET_USER_SMS_CODE = BASE_URL + '/emp/lostIDVerifyCode';
export const GET_FORGET_USERID_SMS_CODE = BASE_URL + '/emp/lostIDSendCode';
export const CHECK_USER_UNIQUE = BASE_URL + "/emp/nameCheck";
export const CHECK_PHONE_UNIQUE = BASE_URL + "/emp/phoneCheck";;
export const GET_SKILL = BASE_URL + '/skill/read?page_all=1&sort=[{"property":"sk_sort","direction":"desc"}]';
export const GET_DETAIL_SKILL = GET_SKILL + "&tim_id=35";
export const GET_BOSS_SKILL = GET_SKILL + "&sk_enable_boss=1";
export const GET_WORKER_SKILL = GET_SKILL + "&sk_enable_worker=1";
export const GET_SPECIAL_NOTE = BASE_URL + "/special/read?page_all=1";
export const GET_HEALTH_CARE = BASE_URL + "/healthInsurance/read?page_all=1";
export const GET_YEAR_CARE = BASE_URL + "/pensionInsurance/read?page_all=1";
export const GET_EMPLOYEE_CARE = BASE_URL + "/employeeInsurance/read?page_all=1";
export const GET_SPECIAL_KIND = BASE_URL + "/specialHealth/read?page_all=1";
export const GET_BLOOD_TYPE = BASE_URL + "/blt/read?page_all=1";
export const CREATE_JOB = BASE_URL + "/job/register";
export const EDIT_JOB = BASE_URL + "/job/sync";
export const READ_DETAIL_JOB = BASE_URL + "/job/read?tim_id=17";
export const APPLY_JOB = BASE_URL + "/jobApply/register";
export const CONTRACT_JOB = BASE_URL + "/contract/register";
//export const GET_POINT_SECRET_KEY = "http://192.168.1.100/index.php";
export const GET_POINT_SECRET_KEY =  BASE_URL + "/empPoint/getkey";
export const READ_JOB = BASE_URL + "/job/read?tim_id=17";
export const READ_OPEN_JOB = BASE_URL + "/job/read?tim_id=17&jb_finished=0";
export const READ_CLOSE_JOB = BASE_URL + "/job/read?tim_id=17&jb_finished=1";
export const READ_FREE_TIME = BASE_URL + "/empFreetime/read";
export const SAVE_FREE_TIME = BASE_URL + "/empFreetime/sync";
export const GET_APPLICANT_LIST = BASE_URL + "/jobApply/read?inc_sk_label=1";
export const GET_RECOMMEND_PROVIDER_LIST =  BASE_URL + "/emp/read?tim_id=23&inc_sk_label=1&match_all_over=0&limit=20";
export const GET_RECOMMEND_CLIENT_LIST = BASE_URL + "/emp/read?tim_id=24&inc_sk_label=1&match_all_over=0&limit=20";
export const GET_RECOMMEND_JOB_LIST =  BASE_URL + "/job/read?inc_sk_label=1&tim_id=28&jb_finished=0&cnt_apply_below=1&match_all_over=0&limit=20";
export const GET_DIRECT_OPEN_LIST = BASE_URL + "/job/read?inc_sk_label=1&tim_id=17&cnt_apply_below=1&jb_finished=0";
export const GET_MY_PROVIDER_LIST = BASE_URL + "/emp/read?tim_id=21&inc_sk_label=1";
export const GET_MY_CLIENT_LIST = BASE_URL + "/emp/read?tim_id=22&inc_sk_label=1";
export const GET_MY_PROVIDER_JOB_LIST = BASE_URL + "/job/read?tim_id=17&inc_sk_label=1";
export const SEARCH_PROVIDER_PERIOD = BASE_URL + "/emp/read?tim_id=27&inc_sk_label=1";
export const SEARCH_CLIENT_PERIOD = BASE_URL + "/emp/read?tim_id=26&inc_sk_label=1";
export const SEARCH_JOB_PERIOD = BASE_URL + "/job/read?tim_id=17&inc_sk_label=1";
export const READ_POINT_HISTORY = BASE_URL + "/empPoint/read";
export const READ_MSG_TEMPLATE = BASE_URL + "/msgTemplate/read";
export const MSG_TEMPLATE_CREATE = BASE_URL + "/msgTemplate/register";
export const MSG_TEMPLATE_SYNC = BASE_URL + "/msgTemplate/sync";
export const POINT_LIST = BASE_URL + "/point/read?pt_amount_over=0";
export const PAYMENT_REGISTER = BASE_URL + "/empPoint/register";
export const RELATION_REGISTER = BASE_URL + "/empMessage/register";
export const GET_SMS_CODE = BASE_URL + "/empActivate/getcode";
export const GET_FORGET_SMS_CODE = BASE_URL + "/emp/sendCode";
export const SET_PASSWORD = BASE_URL + "/emp/passwd";
export const CHECK_FORGET_PASS_SMS_CODE = BASE_URL + "/emp/verifyCode";
export const CHECK_SMS_CODE = BASE_URL + "/empActivate/verifycode";
export const READ_COMMENT = BASE_URL + "/contractComment/read";
export const WRITE_COMMENT = BASE_URL + "/contractComment/register";
export const READ_CONTRACT = BASE_URL + "/contract/read";
export const READ_ADS = BASE_URL + "/adver/read?start=0&limit=2";
export const LEAVE_USER_IMAGE = "uphoto/icon-taikaiuser.png";
export const STRIPE_PUBLICK_KEY = 'pk_live_51H0lBTLkD4fKKefLuZ8tPUgBHAPl0UK6bl0B9u4Tktc4yZdWVqnQ01nA3zGmwLiNadq2CVGlegAbwaGjvaSPTGey00YtWc4dF6';