import React from 'react';
import '../scss/job.scss';
import '../scss/message.scss';
import Header from '../Include/Header'
import MessageListCom from './MessageListCom';
import Footer from '../Include/Footer';
import * as Global from '../../modules/global';
import {Link} from 'react-router-dom';

class MessageList extends React.Component<any>{
    public render(){
        const role = Global.getRole();
        return(
            <div>
                <Header/>
                <div className="content-container" id = "content">
                    <div className="wrap-content">
                        <div className="main-title">メッセージ一覧</div>
                        <div className="job_container mt_40">
                            <MessageListCom/>
                        </div>
                        {/* <div className=" mt_50">
                            <Link to = {role == '1'?"/search":"/provider/search"} className="underline">
                                <div className="fs14">&lt; 検索結果一覧に戻る</div>
                            </Link>												
    					</div> */}
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}
export default MessageList;