import React from 'react';
import '../scss/job.scss';
import '../scss/change_type.scss';
import {Link} from 'react-router-dom';
import Footer from '../Include/Footer';
import { VALIDATORS, VALIDATION_MESSAGES, YEAR, MONTH, DAY } from '../../modules/global';
import SimpleReactValidator from 'simple-react-validator';
import * as URLS from '../../modules/urls';
import {AlertDialog} from '../Modal/Alert';
import {Redirect} from 'react-router-dom';
import Loading from '../Include/Loading';

class ResetUserId extends React.Component<any>{
    validator:SimpleReactValidator;
    alertRef:any;    
    state = {
        passcode:'',
        em_phone:'',
        em_id:'',
        em_auth:'',
        isSendSMSCode:false,
        loading:false,
        errorMessage:'',
        isCheckSMSCode:false,
        em_code:'',
    }
    constructor(props:any){
        super( props );
        this.validator = new SimpleReactValidator({
            messages: VALIDATION_MESSAGES,
            autoForceUpdate: this,
            className:'error error_center',
            validators:VALIDATORS
        });
        this.alertRef = React.createRef();
    }
    handleClick = () =>{
        if( !this.state.isSendSMSCode ){
            this.getSMSCode();
        }else{
            let flag = this.validator.allValid();
            if (flag){
                this.checkSMSCode();
            }else{
                this.validator.showMessages();
                this.forceUpdate();
            }
        }
    }
    checkSMSCode = () => {
        this.setState({
            loading:true,
        });
        const data = {
            em_phone:this.state.em_phone,
            em_code:this.state.passcode,
        }
        const that = this;
        fetch( URLS.CHECK_FORGET_USER_SMS_CODE, {
            method: 'post',
            body: JSON.stringify( data ),
        }).then(async function(response){
            let ret = await response.json();
            if( ret.success ){
                that.alertRef.current.open("成功", `IDを送信しました`, true);                
                that.setState({
                    loading:false,
                    isCheckSMSCode:true,
                    em_auth:ret.em_auth,
                });
            }else{
                that.alertRef.current.open( 'エラー', 'パスコードが間違っています。', false );
                that.setState({
                    loading:false,
                    isCheckSMSCode:false,
                    em_auth:'',
                });
            }
            //return response.json();
        }).catch(error => {
            that.alertRef.current.open( 'エラー', 'パスコードが間違っています。', false );
            that.setState({
                loading:false,
                isCheckSMSCode:false,
                em_auth:'',
            });
        });        
    }
    resendPassCode = () =>{
        this.getSMSCode();
    }
    onHandleChange = ( e:any ) =>{
        let value:string = e.target.value;
        let name:string = e.target.name;
        this.setState( { [name]:value} );
    }
    onHandleBlur = ( e:any ) =>{
        let name:string = e.target.name;
        this.validator.showMessageFor( name );
    }

    getSMSCode = () =>{
        const that = this;
        this.setState({
            loading:true,
        })
        fetch( URLS.GET_FORGET_USERID_SMS_CODE + "?em_phone=" + this.state.em_phone, {
            method: 'get',
        }).then(async function(response){
            let ret = await response.json();
            if( ret.success ){
                that.setState({
                    loading:false,
                    isSendSMSCode:true,
                });
            }else{
                that.alertRef.current.open( "エラー", "パスコードを送信することができませんでした。", false );                     
                that.setState({
                    loading:false,
                });
            }
            //return response.json();
        }).catch(error => {
            this.alertRef.current.open( "エラー", "パスコードを送信することができませんでした。", false );
            this.setState({
                loading:false,
            });
        });        
    }
    renderRedirect = () => {
        // if( this.state.isCheckSMSCode && this.state.em_auth != '' ){
        //     let url = '/';
        //     return <Redirect to = {{pathname:url }}/>
        // }
        // return <></>
    }    
    public render(){
        const url = this.props.match.url;
        return (
            <div>
                {
                    this.state.loading?
                    <Loading/>
                    :<></>
                }
                {/* {this.renderRedirect()} */}
                <AlertDialog ref = {this.alertRef} returnURL = "/"/>                                                
                <div className="content-container" id = "content">
                    <div className="wrap-content">
                        <div className="main-title">IDを送る</div>					
                        <div className="job_container mt_40">
                            <div className="change_type space">
                                <div className="title f-meiryo-bold mb_20">電話番号</div>
                                <div className="change_type_inner">
                                    <div>
                                        <div className="fs16">
                                        {
                                            (!this.state.isSendSMSCode)?
                                                "登録いただきました携帯番号にパスコードをお送りいたします。"
                                            :
                                                "SMSに届いた認証番号を入力してください。"
                                        }                                                
                                        </div>
                                    </div>
                                </div>
                                <div className="button-container">
                                    {
                                        (!this.state.isSendSMSCode)?
                                        <input type = "text" name = "em_phone" className="input-txt border l-center large mt_20" placeholder="携帯番号を入力してください" value = {this.state.em_phone}  onChange={this.onHandleChange}  onBlur={this.onHandleBlur}/>
                                        :<></>
                                    }
                                    {
                                        (this.state.isSendSMSCode)?
                                        <input type = "text" name = "passcode" className="input-txt border l-center large mt_20" placeholder="パスコードを入力してください" value = {this.state.passcode}  onChange={this.onHandleChange}  onBlur={this.onHandleBlur}/>
                                        :<></>
                                    }
                                    {
                                        (!this.state.isSendSMSCode)?
                                        this.validator.message('em_phone', this.state.em_phone, 'required' )
                                        :<></>
                                    }
                                    {
                                        (this.state.isSendSMSCode)?
                                        this.validator.message('passcode', this.state.passcode, 'required' )
                                        :<></>
                                    }
                                    <div className="grey-button large mt_30">
                                        <div className="inner" onClick = {this.handleClick}>
                                        {!this.state.isSendSMSCode?"パスコードを送信する":"認証する"}
                                        <i className="fa fa-angle-right"></i>
                                        </div>
                                    </div>
                                    {
                                    this.state.isSendSMSCode?
                                    <div className="grey-button yellow large_300 rb mt_30">
                                        <div className="inner"  onClick = {this.resendPassCode}>
                                            <i className="fa fa-mobile"></i>								
                                            パスコードを再送する
                                        </div>
                                    </div>:
                                    <></>
                                    }							
                                    <Link to = {"/"}><div className="underline"><span className="fs14" style={{cursor: "pointer"}}>&lt;ログイン</span></div></Link>
                                </div>	                                
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}
export default ResetUserId;
