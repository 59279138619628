import {
  actionTypes,
  Message,
  PAGE_PER_COUNT,
  addDateFlag,
  addDatesFlag,
  MessageState,
  ChatActions,
  getNewMessageList,
  getNewMessageHistory,
} from "./types";
import { removeItem } from "../global";
const SEND_MSG = actionTypes.SEND_MSG;
const WRITE_MSG = actionTypes.WRITE_MSG;
const SUCCESS_GET_MSG = actionTypes.SUCCESS_GET_MSG;
const SUCCESS_SAVE_MSG = actionTypes.SUCCESS_SAVE_MSG;
const FAILURE_GET_MSG = actionTypes.FAILURE_GET_MSG;
const SUCCESS_SAVE_POINT = actionTypes.SUCCESS_SAVE_POINT;
const FAILURE_SAVE_POINT = actionTypes.FAILURE_SAVE_POINT;
const COUNT_MSG = actionTypes.COUNT_MSG;
const DELETE_MSG = actionTypes.DELETE_MSG;
const DELETE_NEW_MESSAGE_HISTORY = actionTypes.DELETE_NEW_MESSAGE_HISTORY;
const WAIT_MSG = actionTypes.WAIT_MSG;
const INITIALIZE = actionTypes.INITIALIZE;
const REGISTER_COUNT_LISTENER = actionTypes.REGISTER_COUNT_LISTENER;
const REGISTER_MESSAGE_LISTENER = actionTypes.REGISTER_MESSAGE_LISTENER;
export const initialState: MessageState = {
  text: "",
  count: 0,
  isTypedNewText: false,
  isAllDownloaded: false,
  isRealTime: false,
  isSucess: false,
  isPayUpdateSuccess: true,
  isDownloading: false,
  newMessages: [],
  messages: [],
  loading: false,
};

export function MessageReducer(
  state: MessageState = initialState,
  action: ChatActions
): MessageState {
  let text: string = "";
  let messages: Message[] = [];
  switch (action.type) {
    case SEND_MSG:
      action.payload.message.img = "../images/" + action.payload.message.img;
      messages = addDateFlag(state.messages, action.payload.message as Message);
      return {
        ...state,
        messages: messages,
        loading: true,
        text: "",
        isTypedNewText: true,
        isSucess: true,
      };
    case WRITE_MSG:
      text = action.payload.text;
      return { ...state, text: text, isSucess: true };
    case WAIT_MSG:
      return { ...state, isDownloading: true };
    case SUCCESS_GET_MSG:
      let list: Message[] = action.payload.list;
      let isAllDownloaded: boolean = false;
      let isRealTime: boolean = action.payload.isRealTime;
      if (list.length < PAGE_PER_COUNT) {
        isAllDownloaded = true;
      }
      messages = state.messages;
      messages = addDatesFlag(messages, list, isRealTime);
      // console.log( 'Message REcueser');
      // console.log( list );

      if (action.payload.listener != null) {
        return {
          ...state,
          messages: messages,
          loading: true,
          text: "",
          isRealTime: isRealTime,
          lastObject: action.payload.lastObject,
          isAllDownloaded: isAllDownloaded,
          isTypedNewText: false,
          isSucess: true,
          messageListener: action.payload.listener,
          isDownloading: false,
        };
      }
      return {
        ...state,
        messages: messages,
        loading: true,
        text: "",
        isRealTime: isRealTime,
        lastObject: action.payload.lastObject,
        isAllDownloaded: isAllDownloaded,
        isTypedNewText: false,
        isSucess: true,
        isDownloading: false,
      };
    case SUCCESS_SAVE_MSG:
      let idx: number = state.messages.indexOf(action.payload.message);
      if (idx != -1) {
        state.messages[idx].isWrited = true;
        let createMessages: Message[] = state.messages.concat([]);
        return { ...state, messages: createMessages, isSucess: true };
      }
      return state;
    case SUCCESS_SAVE_POINT:
      return {
        ...state,
        point: action.payload.point,
        isPayUpdateSuccess: true,
      };
    case FAILURE_SAVE_POINT:
      return {
        ...state,
        point: action.payload.point,
        isPayUpdateSuccess: false,
      };
    case DELETE_NEW_MESSAGE_HISTORY:
      const oldNewMessages = state.newMessages;
      const newMessages = getNewMessageHistory(
        oldNewMessages,
        action.payload.from,
        action.payload.to
      );
      if (oldNewMessages.length != newMessages.length) {
        return {
          ...state,
          count: newMessages.length,
          newMessages: newMessages,
          isPayUpdateSuccess: false,
        };
      }
      return { ...state };
    case INITIALIZE:
      if (state.removeCountListener) {
        state.removeCountListener();
      }
console.log( 'Initialize here' );      
      return {
        text: "",
        count: 0,
        isTypedNewText: false,
        isAllDownloaded: false,
        isRealTime: false,
        isSucess: false,
        isPayUpdateSuccess: true,
        isDownloading: false,
        newMessages: [],
        messages: [],
        removeCountListener: undefined,
        loading: false,
      };
    case REGISTER_COUNT_LISTENER:
      return { ...state, removeCountListener: action.payload.listener };
    case REGISTER_MESSAGE_LISTENER:
      return { ...state, messageListener: action.payload.listener };
    case COUNT_MSG:
      let count: number = 0;
      if (action.payload.isAdded) {
        const oldNewMessages = state.newMessages;
        const newMessages = getNewMessageList(
          oldNewMessages,
          action.payload.newMessage
        );
        if (oldNewMessages != undefined && oldNewMessages != newMessages) {
          return {
            ...state,
            count: newMessages.length,
            newMessages: newMessages,
            isSucess: action.payload.isStatusOK,
          };
        } else {
          return { ...state, isSucess: action.payload.isStatusOK };
        }
      } else {
        count = action.payload.count;
      }
      if (count == undefined) {
        count = 0;
      }
      return { ...state, count: count, isSucess: action.payload.isStatusOK };
    case DELETE_MSG:
      if (state.messageListener) {
        state.messageListener();
      }
      return {
        ...state,
        messages: [],
        lastObject: undefined,
        isAllDownloaded: false,
        isRealTime: false,
        isTypedNewText: false,
        isSucess: true,
        messageListener: null,
      };
    case FAILURE_GET_MSG:
      return { ...state, isSucess: false };
    default:
      return state;
  }
}
