import React from 'react';
import '../scss/job.scss';
import '../scss/recommend.scss';
import Header from '../Include/Header'
import JobNavCom from "./JobNavCom";
import JobApplicantCom from './JobApplicantCom'
import Footer from '../Include/Footer';
class JobApplicantDetail extends React.Component<any>{

    public render(){
        const url = this.props.match.url;
        let id = this.props.match.params.id?this.props.match.params.id:-1;
        let name = this.props.match.params.name?this.props.match.params.name:"";                
        return (
            <div>
                <Header/>
                <div className="content-container" id = "content">
                    <div className="wrap-content">
                        <div className="main-title">仕事管理</div>
                        <div className="job_container mt_40">
                            <JobNavCom to = {url}/>
                            <div className="job recommend">
                                <div className="job_inner">
                                    <div className="title">{name}</div>
                                    <JobApplicantCom jobId = {id} jobName = {name}/>
                                </div>    
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }

}
export default JobApplicantDetail; 