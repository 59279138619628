import React from 'react';
import '../scss/job.scss';
import '../scss/editprofile.scss';
import Header from '../Include/Header'
import JobDirectOpenCom from './JobDirectOpenCom'
import Footer from '../Include/Footer';
import * as URLS from '../../modules/urls';

class DirectRequest extends React.Component<any>{
    public render(){
        let id = this.props.match.params.id?this.props.match.params.id:"";
        let em_name = this.props.location.state.em_name?this.props.location.state.em_name:"";
        let em_company = this.props.location.state.em_company?this.props.location.state.em_company:"";
        let em_photo_s = this.props.location.state.em_photo_s?this.props.location.state.em_photo_s:"";
        return (
            <div>
                <Header/>
                <div className="content-container" id = "content">
                    <div className="wrap-content">
                        <div className="main-title">仕事管理</div>
                        <div className="job_container mt_40">
                            <div className ="job myprofile">
                            <div className="job_inner myprofile_inner">
                                <div className="l_block">
                                    <img alt = '' src = {URLS.IMAGE_URL + em_photo_s }/>
                                    <div className="company_info mt_15">
                                        <div>
                                            <div className="fs20">{em_name}</div>
                                            <div className="fs14">{em_company}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="r_block">
                                    <JobDirectOpenCom em_id = {id}/>
                                </div>                
                            </div>
                            </div>                            
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}
export default DirectRequest;