import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { IRootState } from '../../modules';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import EditPhto from '../Profile/EditPhoto';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as asyncactions from '../../modules/userinfo/async-actions';
import { UserInfoActions } from '../../modules/userinfo';
import * as URLS from '../../modules/urls';
import Loading from '../Include/Loading';
import * as Global from '../../modules/global';
import {AlertDialog} from '../Modal/Alert';
import * as FireBaseUser from "../../firebase/api/users";
import {FireBaseUserInfo } from '../../modules/userinfo';

function PaperComponent(props:any ) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}
type dialogProps = {
	open:boolean,
	setOpen:any,
	clsName?:string,
}
const mapStateToProps = ({ user }: IRootState, props:dialogProps ) => {
	const { userInfo } = user;
	const{ open, setOpen, clsName } = props;
    return { userInfo, open, setOpen, clsName };
}
const mapDispatcherToProps = (dispatch: Dispatch<UserInfoActions>) => {
    return {
        changeAvarta:( image:any, isBigImage:boolean ) => asyncactions.changeAvartaAsync( dispatch, image, isBigImage ),
    }
}

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;;

class DraggableDialog extends Component<ReduxType> {
	imageCropRef = React.createRef<any>();
	fileInput:any ;
    alertRef:any;	
	bigFile:any = null;
	state = {
		loading:false,
		isUploadOK:true,
	}
    constructor(props:any){
        super( props );
		this.alertRef = React.createRef();
		this.fileInput = React.createRef<HTMLInputElement>();
    }	
	handleClickOpen = () => {
		this.props.setOpen(true);
	};
	handleClose = () => {
		this.props.setOpen(false);
	};
	saveBigPhoto = async() =>{
		if( this.bigFile != null ){
			const em_id = Global.getEmId();
			if(em_id == "" ) return;
			const fileReader = new FileReader();
			fileReader.onloadend = () => {
				const result:any = fileReader.result;
				const data = {
					bigfile:result,
					em_id:em_id,
				}
				//var formData = new FormData();
				// formData.append( 'bigfile', result );
				// formData.append( 'em_id', em_id! );
				const that = this;
	//			const reader = await this.convertFileToBase64( croppedImage );
				fetch( URLS.SAVE_PROFILE_PICTURE, {
					method: 'POST',
					body: JSON.stringify(data),
				}).then(async function(response){
					let ret = await response.json();
					if( ret.success ){
						//that.props.changeAvarta( URLS.IMAGE_URL + ret.em_photo, true );						
						Global.setEmBigPhoto(ret.em_photo);
					}else{
						if( that.alertRef.current ){
							that.alertRef.current.open( "エラー", "保存できませんでした。" );
						}
						that.setState({
							isUploadOK:false,
						});
					}
				}).catch(error => {
					if( that.alertRef.current ){
						that.alertRef.current.open( "エラー", "保存できませんでした。" );						
					}					
					this.setState({ isUploadOK:false, errorMessage: error.toString() });
				});
			}
//console.log( "Hello Big Images!!!" );
			fileReader.readAsDataURL( this.bigFile );
		}
	}
	saveCroppedPhoto = async() =>{
		const croppedImage = await this.imageCropRef.current.showCroppedImage();
		const fileReader = new FileReader();
		const em_id = Global.getEmId();
		if(em_id == "" ) return;

		fileReader.onloadend = () => {
			const result:any = fileReader.result;
			//var formData = new FormData();
			// formData.append('cropfile', result );
			// formData.append('em_id', em_id! );
			const data = {
				cropfile:result,
				em_id:em_id,
			}
			const that = this;
//			const reader = await this.convertFileToBase64( croppedImage );
			fetch( URLS.SAVE_PROFILE_PICTURE, {
				method: 'POST',
				body: JSON.stringify(data),
			}).then(async function(response) {
				const ret = await response.json();
				if( ret.success ){
					Global.setEmSmallPhoto(ret.em_photo_s );
					that.props.setOpen(false);
					const url = URL.createObjectURL(croppedImage);
					that.props.changeAvarta( url, false );
					that.setState({
						loading:false,
					});
					that.registerToFirebase( ret.em_photo_s );
				}else{
					that.setState({
						loading:false,
						isUploadOK:false,
					});
                    that.alertRef.current.open( "エラー", "保存できませんでした。" );					
				}
			}).catch(error => {
				that.alertRef.current.open( "エラー", "保存できませんでした。" );				
				this.setState({ isUploadOK:false, loading:false, errorMessage: error.toString() });
			});
		}
		if( croppedImage ){
//console.log( "Cropped Image is " + croppedImage );
			fileReader.readAsDataURL( croppedImage );		
		}else{
			this.setState({ loading:false });			
		}
	}
    registerToFirebase = ( em_photo_s:string ) =>{
        let em_id:any = Global.getEmId();        
        const fireBaseUserInfo:FireBaseUserInfo = {
            em_photo:em_photo_s,
        };
        FireBaseUser.addUserInfo( em_id, fireBaseUserInfo ).then( (result:any) =>{
        }).catch( (error:any) =>{
            this.alertRef.current.open( "エラー", "サーバへ保存できませんでした。" );   
        } );
    }	
	savePhoto = async() => {
		if( this.imageCropRef.current ){
			this.setState({
				loading:true,
				isUploadOK:true,
			});
			this.saveBigPhoto();
			this.saveCroppedPhoto();
		}
	}
    fileSelectedHandler = (e:any ) =>{
		if( this.imageCropRef.current ){
			this.bigFile = e.target.files[0];
			this.imageCropRef.current.onChangeImage( URL.createObjectURL( e.target.files[0]) );
		}
	}
	render(){
		return (
			<div>
				{this.state.loading?
					<Loading/>
					:<></>
				}
                <AlertDialog ref = {this.alertRef}/>				
				<Dialog
					open={this.props.open?this.props.open:false}
					onClose={this.handleClose}
					PaperComponent={PaperComponent}
					classes = {{root:this.props.clsName?this.props.clsName:''}}
					aria-labelledby="draggable-dialog-title"
					fullWidth = {true}
				>
					<Toolbar style={{ cursor: 'move' }} id="draggable-dialog-title">
						<DialogTitle>
							写真を編集
						</DialogTitle>
						<IconButton edge="end" color="inherit" onClick={this.handleClose} aria-label="close">
							<CloseIcon />
						</IconButton>
					</Toolbar>
					<DialogContent>
						<EditPhto ref = {this.imageCropRef} bigImage = {URLS.IMAGE_URL + Global.getEmBigPhoto() }/>
					</DialogContent>
					<DialogActions>
		               	<input type = "file" name = "bigfile" style = {{display:'none'}} onChange = {this.fileSelectedHandler} ref = {fileInput => this.fileInput = fileInput} 
							accept=".gif,.jpg,.jpeg,.png"/>
						<Button onClick={() => this.fileInput.click()} color="primary">
						写真を登録
						</Button>
						<Button onClick={this.savePhoto} color="primary">
						写真を保存
						</Button>
					</DialogActions>
				</Dialog>
			</div>
			);
	}
}
export default connect(mapStateToProps, mapDispatcherToProps )(DraggableDialog);