import React, { Component } from 'react';
import '../../scss/job.scss';
import '../../scss/search.scss';
import SimpleReactValidator from 'simple-react-validator';
import * as Global from '../../../modules/global';
import * as URLS from '../../../modules/urls';
import Loading from '../../Include/Loading';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { IRootState } from '../../../modules';
import { compose } from 'redux';
import { SearchArea } from '../../../modules/searchprovider';
const mapStateToProps = ({ searchProviderJob  }: IRootState) => {
    return { searchProviderJob };
};
type ReduxType = ReturnType<typeof mapStateToProps> & RouteComponentProps

class PSearchAreaResultCom extends Component<ReduxType, any>{
    mounted: boolean = false;
    validator: SimpleReactValidator;
    state = {
        loading: false,
        items:[],
    }
    constructor(props: any) {
        super(props);
        this.validator = new SimpleReactValidator({
            messages: Global.VALIDATION_MESSAGES,
            autoForceUpdate: this,
            className: 'error',
            validators: Global.VALIDATORS
        });
    }
    componentDidMount() {
        this.mounted = true;
        let em_id: any = Global.getEmId();
        if (em_id === '') {
            return;
        }
        this.search();
    }    
    onSearch = (e: any) => {
        if (this.validator.allValid()) {
            this.search();
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    getParams = (em_id: string) => {
        let params = "";
        const searchProviderJob = this.props.searchProviderJob;
        if (searchProviderJob.em_skill_keyword !== "") {
            params += "&em_skill_keyword_b=" + searchProviderJob.em_skill_keyword;
        }
        if (searchProviderJob.em_company !== "") {
            params += "&em_company_b=" + searchProviderJob.em_company;
        }

        let regions = [];
        for( let i = 0; i < searchProviderJob.searchAreas.length; i ++ ) {
            const searchArea:SearchArea = searchProviderJob.searchAreas[i];
            regions.push({
                st_id:searchArea.st_id ===''?null:searchArea.st_id,
                ar_ids:[
                        searchArea.ar_id_1 === ''?null:searchArea.ar_id_1,
                        searchArea.ar_id_2 === ''?null:searchArea.ar_id_2,
                        searchArea.ar_id_3 === ''?null:searchArea.ar_id_3
                        ],
            })                
        }
        params += '&regions_b=' + JSON.stringify(regions);
        params += "&em_system=0";
        params += "&em_id_not=" + em_id;
        if (searchProviderJob.sk_s.length) {
            params += "&sk_s_b=" + Global.ArrayToString(searchProviderJob.sk_s);
        }

        params += '&sort=[{"property":"em_id","direction":"desc"}]';
        return params;
    }
    componentWillUnmount() {
        this.mounted = false;
    }
    search = () => {
        let em_id: any = Global.getEmId();
        if (em_id === '') {
            return;
        }
        const that = this;
        this.setState({
            loading: true,
        })
        let url = URLS.SEARCH_CLIENT_PERIOD;
        let params = this.getParams(em_id);
        url = url + params;
        fetch(url, {
            method: 'get',
        }).then(async function (response) {
            if( !that.mounted) {
                return;
            }
            let ret = await response.json();
            if (ret.total > 0) {
                const items = ret.items;
                that.setState({
                    loading: false,
                    is_search_result: true,
                    items: items,
                });
            } else {
                that.setState({
                    loading: false,
                    is_search_result: true,
                    items: [],
                });
            }
        }).catch(error => {
            this.setState({ loading: false, items: [], errorMessage: error.toString() });
        });
    }
    renderSkill(skillStr: string) {
        if (!skillStr) {
            return [];
        }
        const skills = skillStr.split(',');
        let ret = [];
        for (let i = 0; i < skills.length; i++) {
            ret.push(<span className="tag-mg fs14" key={i}><span className="tag-inner">{skills[i]}</span></span>)
        }
        return ret;
    }
    goBack = () => {
        this.props.history.goBack();
    }    
    render() {
        return (
            <div>
                {this.state.loading ?
                    <Loading />
                    :
                    <>
                        <div className="underline" onClick={this.goBack}><span className="fs14" style={{ cursor: "pointer" }}>&lt;検索条件に戻る</span></div>
                        <div className="job_inner recommend-inner">
                            {
                                this.state.items.length === 0 ? <span className="no_content">条件に一致する親方がありません。</span> : <></>
                            }
                            {
                                this.state.items.map(item => (
                                    <div className="element flex" key={item['em_id']}>
                                        <div className="flex_start middle">
                                            <div className="first">
                                                <img alt = '' src={URLS.IMAGE_URL + item['em_photo_s']} />
                                            </div>
                                            <div className="second">
                                                <div className="fs20">{item["em_is_legal"] === '0' ? item['em_company'] : item['em_company_legal']}</div>
                                                <div className="fs14">{item['em_depart']}</div>
                                            </div>
                                            <div className="third">
                                                <div className="fs14 map-icon">{item['st_label']}</div>
                                                <div>
                                                    {this.renderSkill(item["sk_label_s"])}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="btn-container">
                                            <div className="grey-button v-small">
                                                <Link to={"/client/profile/" + item['em_id'] + "/0"}>
                                                    <div className="inner">
                                                        詳細
                                            </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </>
                }
            </div>
        )
    }
}
export default compose(
    withRouter,
    connect(mapStateToProps)
)(PSearchAreaResultCom);
