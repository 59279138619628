import React, { Component } from 'react';
import '../../scss/job.scss';
import '../../scss/myprofile.scss';
import { Link } from 'react-router-dom';
import * as Global from '../../../modules/global';
import * as URLS from '../../../modules/urls';
import { Redirect } from 'react-router-dom';
import Loading from '../../Include/Loading';
import { AlertDialog } from '../../Modal/Alert';
import { IRootState } from '../../../modules';
import { connect } from 'react-redux';

const mapStateToProps = ({ message, user }: IRootState) => {
    const { count, point, removeCountListener } = message;
    const { userInfo } = user;
    return { count, point, removeCountListener, userInfo };
}
type ReduxType = ReturnType<typeof mapStateToProps>;

class PMyProfileCom extends Component<ReduxType>{
    mounted: boolean = false;
    alertRef: any;
    state = {
        loading: false,
        em_notify: '0',
        em_id: '-1',
        isLogout: false,
        em_firstname: '',
        em_lastname: '',
        em_firstname_kana: '',
        em_lastname_kana: '',
        em_birthday: '',
        year: "0",
        month: "0",
        day: "0",
        sex_id: '1',
        sex_name:'',
        em_is_individual: '-1',
        em_company: '',
        sk_s: [],
        sk_label_s: '',
        sk_years_s: [],
        em_skill_keyword: '',
        em_daily_price: '',
        em_post: '',
        st_id: '0',
        st_label: '',
        ar_id: '0',
        em_overview: '',
        ctrc_value_w: '0',
        cnt_ctr_w_total: '0',
    }
    constructor(props: any) {
        super(props);
        this.alertRef = React.createRef();
    }
    changeStatus = () => {
        let em_id = Global.getEmId();
        if (em_id === "") {
            return;
        }
        this.setState({
            loading: true,
        });
        let em_notify = this.state.em_notify === '1' ? '0' : '1';
        const data = {
            update: [{
                em_id: em_id,
                em_notify: em_notify,
            }]
        }
        const that = this;
        fetch(URLS.SAVE_PROFILE, {
            method: 'post',
            body: JSON.stringify(data),
        }).then(async function (response) {
            if( !that.mounted ) {
                return;
            }            
            let ret = await response.json();
            if (ret.success) {
                that.alertRef.current.open("成功", "保存されました。", true);
                that.setState({
                    loading: false,
                    em_notify: that.state.em_notify === '0' ? '1' : '0',
                });
            } else {
                that.alertRef.current.open("エラー", "保存できませんでした。", true);
                that.setState({
                    loading: false,
                });
            }
            //return response.json();
        }).catch(error => {
            this.alertRef.current.open("エラー", "保存できませんでした。", true);
            that.setState({
                loading: false,
            });
        });
    }
    componentDidMount() {
        this.mounted = true;
        let em_id: any = Global.getEmId();
        let role = Global.getRole();
        if (role !== '2') {
            return;
        }
        let keyword = 'worker';
        let url = URLS.GET_PROFILE + "?em_id=" + em_id + "&keyword=" + keyword;
        const that = this;
        this.setState({
            loading: true,
        });
        fetch(url, {
            method: 'get',
        }).then(async function (response) {
            let ret = await response.json();
            if (ret.emp.ctrc_value_w) {
                Global.setStar(ret.emp.ctrc_value_w);
            }
            if( !that.mounted ) {
                return;
            }
            that.setState({
                em_firstname: ret.emp.em_firstname,
                em_lastname: ret.emp.em_lastname,
                em_firstname_kana: ret.emp.em_firstname_kana,
                em_lastname_kana: ret.emp.em_lastname_kana,
                em_birthday: ret.emp.em_birthday,
                sex_id: ret.emp.sex_id,
                sex_name: ret.emp.sex_name,
                em_is_individual: ret.emp.em_is_individual,
                em_company: ret.emp.em_company,
                sk_s: [],
                em_notify: ret.emp.em_notify,
                sk_label_s: ret.emp.sk_label_s ? ret.emp.sk_label_s : '',
                sk_years_s: [],
                st_id: ret.emp.st_id == null ? "0" : ret.emp.st_id,
                st_label: ret.emp.st_label ? ret.emp.st_label : '',
                em_overview: ret.emp.em_overview ? ret.emp.em_overview : '',
                cnt_ctr_w_total: ret.emp.cnt_ctr_w_total ? ret.emp.cnt_ctr_w_total : '0',
                ctrc_value_w: ret.emp.ctrc_value_w ? ret.emp.ctrc_value_w : '0',
                loading: false,
            });
        }).catch(error => {
            if( !that.mounted ) {
                return;
            }
            this.setState({ loading: false, errorMessage: error.toString() });
        });
    }
    componentWillUnmount () {
        this.mounted = false;
    }
    renderRedirect = () => {
        const em_id = Global.getEmId();
        if (this.state.isLogout || em_id === "") {
            let url: string = '/';
            return <Redirect to={url} />
        } else {
            let role = Global.getRole();
            if (role !== "2") {
                let url: string = '/choose';
                return <Redirect to={url} />
            } else {
                return <></>
            }
        }
    }
    logOut = () => {
        Global.setPoint('0');
        Global.setEmId('');
        Global.setDefaultRole('');
        this.setState({
            isLogout: true,
        });
    }
    renderSkill(skillStr: string) {
        if (!skillStr) {
            return [];
        }
        const skills = skillStr.split(',');
        let ret = [];
        for (let i = 0; i < skills.length; i++) {
            ret.push(<span className="tag-mg fs14" key={i}><span className="tag-inner">{skills[i]}</span></span>)
        }
        return ret;
    }
    public render() {
        let em_name = Global.getEmName();
        let em_company = Global.getEmCompany();
        let em_star = this.state.ctrc_value_w ? parseInt(this.state.ctrc_value_w) : 0;
        return (
            <div className="myprofile_inner">
                {this.state.loading ?
                    <Loading />
                    : <></>
                }
                <AlertDialog ref={this.alertRef} />
                {this.renderRedirect()}
                {this.state.loading ?
                    <Loading />
                    : <></>
                }
                <div className="l_block">
                    <img alt='' src={URLS.IMAGE_URL + Global.getEmSmallPhoto()} />
                    <div className="company_info">
                        <div>
                            <div className="fs14">{em_company}</div>
                            <div className="fs20">{em_name}</div>
                            <div className = 'fs14'><span>{Global.getAge(this.state.em_birthday)}歳</span><span className = 'ml_20'>{this.state.sex_name}</span></div>
                        </div>
                        <Link to="/feedback">
                            <div className="star_container">
                                <span className={em_star > 0 ? "star active" : "star"}></span>
                                <span className={em_star > 1 ? "star active" : "star"}></span>
                                <span className={em_star > 2 ? "star active" : "star"}></span>
                                <span className={em_star > 3 ? "star active" : "star"}></span>
                                <span className={em_star > 4 ? "star active" : "star"}></span>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="r_block">
                    <div className="info">
                        <div>
                            <div className="fs14 map-icon">{this.state.st_label}</div>
                            <div>
                                {this.renderSkill(this.state.sk_label_s)}
                            </div>
                        </div>
                        <div className="btn-container mt_0">
                            <div className="grey-button v-small">
                                <Link to="/provider/editprofile">
                                    <div className="inner font_14 fa modify">修正する</div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="profile_detail">
                        <div className="d_element pd_b mt_10">
                            <span className="desc">{this.state.em_overview}</span>
                        </div>
                        <div className="d_element pd_b both_ways middle">
                            <span className="desc">仕事達成件数</span>
                            <span><span className="fs20">{this.state.cnt_ctr_w_total}</span><span className="fs14">件</span></span>
                        </div>
                        <div className="d_element pd_b">
                            <Link to="/account_type"><span className="desc">ログイン切替/登録形態変更</span></Link>
                        </div>
                        <div className="d_element pd_b both_ways">
                            <div>
                                <div className="desc">通知設定</div>
                                <div className='notifiy'>※通知はアプリのみとなります</div>
                            </div>
                            <span className={this.state.em_notify === '1' ? 'status_slider active' : 'status_slider'} onClick={this.changeStatus}></span>
                        </div>
                        {/* <div className="d_element pd_b">
                            <Link to = "/account_delivery"><span className="desc">アカウントデータ引継ぎ</span></Link>
                        </div> */}
                        <div className="d_element pd_b">
                            <Link to="/provider/delete_profile"><span className="desc">アカウント削除</span></Link>
                        </div>
                        <div className="d_element pd_b pointer" onClick={this.logOut}>
                            <span className="desc">ログアウト</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default connect(mapStateToProps)(PMyProfileCom);