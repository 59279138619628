import { SearchProviderJobActions, SearchProviderJobState, actionTypes, SearchProviderJob } from './types';

const initialState: SearchProviderJobState = {
    loading: false,
    em_skill_keyword: '',
    em_company: '',
    sk_s: [],
    searchAreas:[{st_id:'', ar_id_1:'', ar_id_2:'', ar_id_3:''},{st_id:'', ar_id_1:'', ar_id_2:'', ar_id_3:''},{st_id:'', ar_id_1:'', ar_id_2:'', ar_id_3:''}],
};
const SAVE = actionTypes.SAVE;
export function SearchProviderJobReducer(state: SearchProviderJobState = initialState, action: SearchProviderJobActions): SearchProviderJobState {
    switch (action.type) {
        case SAVE:
            const searchProviderJob: SearchProviderJob = action.payload.searchProviderJob;
            return {
                ...state,
                em_skill_keyword: searchProviderJob.em_skill_keyword,
                em_company: searchProviderJob.em_company,
                sk_s: searchProviderJob.sk_s,
                searchAreas: searchProviderJob.searchAreas,
            };
        default:
            return state;
    }
}