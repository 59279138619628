import React, { Component } from 'react';
import '../scss/point.scss';
import PointHistoryCom from './PointHistoryCom';
import Header from '../Include/Header'
import {Link} from 'react-router-dom';
import Footer from '../Include/Footer';
import * as Global from '../../modules/global';
import { IRootState } from '../../modules';
import { Dispatch } from 'redux';
import {UserInfoActions } from '../../modules/userinfo';
import * as asyncactions from '../../modules/userinfo/async-actions';
import * as URLS from '../../modules/urls';
import { connect } from 'react-redux';
import Loading from '../Include/Loading';
import * as footerAsyncactions from '../../modules/footer/async-actions';
import { FooterActions } from '../../modules/footer';

const mapStateToProps = ({ user }: IRootState ) => {
    const { userInfo } = user;
    return { userInfo };
}
const mapDispatcherToProps = (dispatch: Dispatch<UserInfoActions|FooterActions>) => {
    return {
        changePoint:( point:string ) => asyncactions.changePointAsync( dispatch, point ),
        isRerender:( _isRerender: boolean ) => footerAsyncactions.isRerender( dispatch, _isRerender ),        
    }
}
type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;

class PointHistory extends Component<ReduxType>{
    state = {
        loading:false,
        total:0,
        items:[],
    }
    componentDidMount() {
        this.fetch( URLS.READ_POINT_HISTORY );
    }
    fetch( url:string = "" ){
        let em_id:any = Global.getEmId();
        if( em_id == "" ){
            return;
        }
        this.setState({
            loading:true,
        });
        const that = this;
        const sort = [{"property":"ep_created","direction":"desc"},{"property":"ep_id","direction":"desc"}];
        fetch( url + '?em_id=' + em_id + '&page_all=1&sort=' + JSON.stringify(sort) ).then(async response => {    
            const item = await response.json();
            this.setState({
                total: item.total,
                items:item.items,
                loading:false,
            }, async() => {
                await that.props.isRerender( true );
            });
            that.props.changePoint(item.em_point);
            Global.setPoint( item.em_point );
        }).catch(error => {
            this.setState({
                loading:false,
            }, async() => {
                await that.props.isRerender( true );
            });
        });
    }    
    render(){
        const point = Global.getPoint();
        return(
            <div>
                <Header/>
                <div className="content-container" id = "content">
                    {this.state.loading?
                        <Loading/>
                        :<></>
                    }
                    <div className="wrap-content">
                        <div className="lt-hoz-eq mb_40">
                            <div className="con">
                                <div className="item">
                                    <span className="round-title">現在の所持ポイント</span>
                                </div>
                            </div>
                        </div>
                        <span className="point-info"><span className="big">{point}</span>pt</span>
                        <div className="button-container">
                            <div className="grey-button large mt_40">
                                <Link to = "/buypoint">                    
                                    <div className="inner">
                                    ポイントを購入する
                                    <i className="fa fa-angle-right"></i>
                                    </div>
                                </Link>    
                            </div>
                        </div>
                        <PointHistoryCom items = {this.state.items}/>
                    </div>    
                </div>
                <Footer/>
            </div>
        );
    }

}
export default connect(mapStateToProps,mapDispatcherToProps )(PointHistory);
