import React, {Component} from 'react';
import '../scss/job.scss';
import '../scss/message_template.scss';
import Header from '../Include/Header'
import Footer from '../Include/Footer';
import * as URLS from '../../modules/urls';
import {AlertDialog} from '../Modal/Alert';
import { Link } from 'react-router-dom';
import * as Global from '../../modules/global';

class MessageTemplateEdit extends Component<any>{
    alertRef:any;        
    state = {
        mgt_id:-1,
        em_id:-1,
        mgt_note:'',
    }
    constructor(props:any){
        super( props );
        this.alertRef = React.createRef();
    }    
    componentDidMount(){
        let id = this.props.location && this.props.location.state && this.props.location.state.id?this.props.location.state.id:'';
        if( id == null || id == "" ){
            return;
        }
        const that = this;
        that.setState({
            loading:true,
        });
        fetch( URLS.READ_MSG_TEMPLATE + "?mgt_id=" + id + "&page_all=1", {
            method: 'get',
        }).then(async function(response) {
            let ret = await response.json();
            if( ret.total > 0 ){
                const item = ret.items[0];
                that.setState({
                    loading:false,
                    mgt_id:item.mgt_id,
                    mgt_note:item.mgt_note,
                });
            }else{
                that.setState({
                    loading:false,
                });
            }
        }).catch(error => {
            this.setState({ loading:false, errorMessage: error.toString() });
        });
    }
    onHandleChange = ( e:any ) =>{
        let value:string = e.target.value;
        let name:string = e.target.name;
        this.setState( { [name]:value} );
    }    
    onSend = () =>{
        let em_id:any = Global.getEmId();
        if( em_id == "" ){
            return;
        }        
        let id = this.props.location && this.props.location.state && this.props.location.state.id?this.props.location.state.id:'';
        let url = "";
        let data = {};
        const that = this;
        const stateData = this.state;
        stateData.em_id = em_id;
        if( id == "" ){
            url = URLS.MSG_TEMPLATE_CREATE;
            data = stateData;
        }else{
            url = URLS.MSG_TEMPLATE_SYNC;
            stateData.mgt_id = id;
            data = {
                update:stateData
            }
        }
        fetch( url, {
            method: 'post',
            body: JSON.stringify( data ),
        }).then(async function(response) {
            let ret = await response.json();
            if( ret.success ){
                that.alertRef.current.open( "成功", "サーバに保存されました。", true );                                            
                that.setState({
                    loading:false,
                });
            }else{
                if( that.alertRef.current )
                that.alertRef.current.open( "エラー", "サーバへ保存できませんでした。" );                            
                that.setState({
                    loading:false,
                });
    
            }
            //return response.json();
        }).catch(error => {
            this.setState({ loading:false, errorMessage: error.toString() });
            if( this.alertRef.current )
                this.alertRef.current.open( "エラー", "サーバへ保存できませんでした。" );                            
        });   
    }
    public render(){
        let id = this.props.location && this.props.location.state && this.props.location.state.id?this.props.location.state.id:'';
        let em_id:any = Global.getEmId();
        let to = this.props.location && this.props.location.state && this.props.location.state.to?this.props.location.state.to:'';
        if( to == '' ){
            to =  this.props.match && this.props.match.params? this.props.match.params.to:'';
        }
        const returnURL = to == '' ? "/messagetemplate" : ("/messagetemplate/" + to);
        return(
            <div>
                <Header/>
                <div className="content-container" id = "content">
                    <div className="wrap-content">
                        <div className="main-title">テンプレートを追加・編集</div>
                        <div className="job message_template mt_40">
                            <div className="message_template_inner">
                                <AlertDialog ref = {this.alertRef} returnURL = {returnURL}/>                                                
								<div className="textarea_container">
                                    <textarea placeholder="〇〇工務店の鈴木と申します。お仕事の発注の件でご連絡しました。"
                                         name = "mgt_note" value = {this.state.mgt_note}  onChange={this.onHandleChange}
                                    >
                                    {this.state.mgt_note}        
                                    </textarea>
								</div>
							</div>
							<button className="grey-button yellow large_300 rb mt_30 mb_20">
								<div className="inner message_icon" onClick = {this.onSend}>
                                テンプレートを保存
								</div>
							</button>
                            {
                                to != ''?
                                <button className="grey-button yellow large_300 rb mt_30 mb_20">
                                    <Link to = {"/message/" + to }>
                                        <div className="inner message_icon">
                                            メッセージ画面に戻る
                                        </div>
                                    </Link>                                
                                </button>
                                :<></>
                            }		
						
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}
export default MessageTemplateEdit;