import React, { Component } from "react";
import "../scss/job.scss";
import "../scss/message.scss";
import { Link } from "react-router-dom";
import { doGetContacts } from "../../firebase/api/message";
import Loading from "../Include/Loading";
import * as Global from "../../modules/global";
import * as URLS from "../../modules/urls";
import { IRootState } from "../../modules";
import { connect } from "react-redux";
import { Message } from "../../modules/chat/types";
import * as FirebaseAPI from "../../firebase/api/message";
import * as footerAsyncactions from "../../modules/footer/async-actions";
import { FooterActions } from "../../modules/footer";
import { Dispatch } from "redux";

const mapStateToProps = ({ message }: IRootState) => {
  const { newMessages } = message;
  return { newMessages };
};
const mapDispatcherToProps = (dispatch: Dispatch<FooterActions>) => {
  return {
    isRerender: (_isRerender: boolean) =>
      footerAsyncactions.isRerender(dispatch, _isRerender),
  };
};
type ReduxType = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatcherToProps>;

class MessageListCom extends Component<ReduxType> {
  em_id: string | null = null;
  role: string = "";
  state = {
    loading: false,
    messages: [],
  };
  constructor(props: any) {
    super(props);
    this.role = Global.getRole()!;
  }
  componentDidMount() {
    this.em_id = Global.getEmId();
    if (!this.em_id) {
      return;
    }
    this.setState({
      loading: true,
    });
    this.onGetContacts();
  }
  onGetContacts = () => {
    const em_id: any = Global.getEmId();
    doGetContacts(em_id)
      .then((result: any) => {
        this.getContact(result);
      })
      .catch((error: any) => {
        this.setState({
          loading: false,
        });
      });
  };
  deleteContacts = (contactIds: number[], ids: string[]) => {
    let deleteIds = [];
    let em_id: any = Global.getEmId();
    if (em_id === "" || parseInt(em_id) < 0) {
      return;
    }

    for (let i = 0; i < contactIds.length; i++) {
      const id = contactIds[i];
      if (ids.indexOf(id.toString()) === -1) {
        deleteIds.push(id.toString());
      }
    }
    // for( let j = 0; j < deleteIds.length; j ++ ){
    //     FirebaseAPI.deleteDocument( em_id, deleteIds[j] );
    // }
    if (deleteIds.length) {
      FirebaseAPI.deleteMultipleDocument(em_id, deleteIds);
    }
  };
  getContact = (messages: Message[]) => {
    const idList: number[] = [];
    let ids: string = "";
    const em_id: string | null = Global.getEmId();
    if (em_id === null) {
      return;
    }
    for (let i = 0; i < messages.length; i++) {
      const message = messages[i];
      // if( message.isKuraha && message.isKuraha! == 1 ){
      //     continue;
      // }
      const from =
        message.from!.toString() === em_id!.toString()
          ? message.to!
          : message.from!;
      if (idList.indexOf(from) === -1) {
        idList.push(from);
        if (ids !== "") {
          ids += ",";
        }
        ids += from;
      }
    }
    if (idList.length) {
      const data = {
        em_id_s: ids,
      };
      const that = this;
      fetch(URLS.GET_EMP_LIST, {
        method: "post",
        body: JSON.stringify(data),
      })
        .then(async function (response) {
          let ret = await response.json();
          for (let j = 0; j < messages.length; j++) {
            let flag = false;
            //ret.items.find( (item: { em_id: string; }) => parseInt(item.em_id) === messages[j].from );
            for (let k = 0; k < ret.items.length; k++) {
              flag = that.setDetailInfo(messages[j], ret.items[k]);
              if (flag) {
                break;
              }
            }
            // if( !flag ){
            //     messages[j].toInfo = {
            //         em_name:'退会ユーザー',
            //         image:URLS.LEAVE_USER_IMAGE,
            //         is_not_available:1,
            //     }
            // }
          }
          that.setState(
            {
              loading: false,
              messages: messages,
            },
            async () => {
              await that.props.isRerender(true);
            }
          );
        })
        .catch((error) => {
          this.setState(
            {
              loading: false,
              messages: [],
            },
            async () => {
              await that.props.isRerender(true);
            }
          );
        });
    } else {
      this.setState(
        {
          loading: false,
          messages: messages,
        },
        async () => {
          await this.props.isRerender(true);
        }
      );
    }
  };
  setDetailInfo(message: Message, item: any) {
    if (!this.em_id) {
      return false;
    }
    const em_id: string | null = Global.getEmId();
    const from: number =
      message.from!.toString() === em_id!.toString()
        ? message.to!
        : message.from!;
    //const from: number = message.from!;
    if (parseInt(item.em_id) === parseInt(from.toString())) {
      let em_name = "";
      let is_not_available = 0;
      if (this.role === "2") {
        em_name =
          item.em_is_legal === "0" ? item.em_company : item.em_company_legal;
        if (this.em_id === from.toString()) {
          // message from login user as worker.
          // em_name = item.em_lastname +' '+ item.em_firstname;
          is_not_available =
            item.emt_is_worker != null && item.emt_is_worker === "0" ? 1 : 0;
        } else {
          // message from another user as boss
          // em_name = item.em_is_legal === '0' ? item.em_company : item.em_company_legal;
          is_not_available =
            item.emt_is_boss != null && item.emt_is_boss === "0" ? 1 : 0;
        }
      } else {
        em_name = item.em_lastname + " " + item.em_firstname;
        if (this.em_id === from.toString()) {
          // message from login user as worker.
          // em_name = item.em_is_legal === '0' ? item.em_company : item.em_company_legal;
          is_not_available =
            item.emt_is_boss != null && item.emt_is_boss === "0" ? 1 : 0;
        } else {
          // message from another user as boss
          // em_name = item.em_lastname +' '+ item.em_firstname;
          is_not_available =
            item.emt_is_worker != null && item.emt_is_worker === "0" ? 1 : 0;
        }
      }
      message.fromInfo = {
        em_name: em_name,
        image: item.em_photo_s,
        is_not_available: is_not_available,
        em_system: item.em_system,
      };
      return true;
    }
    return false;
  }
  public isNewMessage(message: Message) {
    return !message.isViewed ? true : false;
    // if( this.props.newMessages && this.props.newMessages.length ){
    //     const temp = this.props.newMessages.filter( newMessage => newMessage.docId === message.docId && !newMessage.isViewed );
    //     if( temp && temp.length > 0 ){
    //         return true;
    //     }
    // }
    // return false;
  }
  componentDidUpdate(prevProps: ReduxType) {
    if (this.props.newMessages != prevProps.newMessages) {
      this.onGetContacts();
    }
  }
  public getImagePath = (item: Message) => {
    let url = "";
    let is_not_available = 0;
    // console.log( 'MessageList===');
    // console.log( item );
    // console.log( '****MessageList****');

    if (
      item.isKuraha === undefined ||
      item.isKuraha === 0 ||
      (item.docId && parseInt(item.docId) > 1)
    ) {
      if (
        item.fromInfo &&
        item.fromInfo.is_not_available &&
        item.fromInfo.is_not_available === 1
      ) {
        url = URLS.IMAGE_URL + URLS.LEAVE_USER_IMAGE;
        is_not_available = 1;
      } else {
        if (item.fromInfo && item.fromInfo.image) {
          url = URLS.IMAGE_URL + item.fromInfo.image;
        } else {
          url = URLS.DUMMY_IMG_S;
        }
      }
    } else {
      url = URLS.BASE_ABSOLUTE_URL + "/images/kuraha_msg.png";
    }
    return {
      url: url,
      is_not_available: is_not_available,
    };
  };

  getPath = (item: Message) => {
    const role = Global.getRole();
    const em_id = Global.getEmId()!;
    const from = item["from"] === parseInt(em_id) ? item["to"] : item["from"];
    const is_not_available =
      item.fromInfo && item.fromInfo.is_not_available
        ? item.fromInfo.is_not_available
        : 0;
    const em_system =
      item.fromInfo && item.fromInfo.em_system ? item.fromInfo.em_system : "0";
    const info = this.getInfoFromText(item);
    const jobId = info && info.length==7 && info[2] =='jobapply'?info[1]:'';
    const jobStatus =  info && info.length==7?info[2]:'';
    const jba_is_direct = info && info.length==7 && info[4]!=undefined?info[4]:'0';
    const data = {
      pathname:
        item["isKuraha"] === 0 || is_not_available === 1
          ? "/message/" + from
          : role === "1"
          ? "/provider/profile/" + from + `/${jobId}`
          : "/client/profile/" + from + `/${jobId}`,
        state: {
          from: from,
          to: item["to"],
          docId: item["docId"],
          jobId:jobId,
          jobStatus:jobStatus,
          is_from_message_list:1,
          jba_is_direct:jba_is_direct,
          is_not_available: item.fromInfo?.is_not_available,
          em_system: em_system,
        },
    };
    return data;
  };
  getName = (item: Message) => {
    let name = "";
    // if (item['fromInfo'] && (item['isKuraha'] === undefined || item['isKuraha'] === 0)) {
    if (item["fromInfo"]) {
      if (
        item.fromInfo.image &&
        item.fromInfo.is_not_available &&
        item.fromInfo.is_not_available === 1
      ) {
        name = "退会ユーザー";
      } else {
        name =
          item.fromInfo && item.fromInfo.em_name ? item.fromInfo.em_name : "";
      }
    }
    // } else {
    //     name = item.title ? item.title : '';
    // }
    return name;
  };
  decorateContent = (item: Message) => {
    let text: string = item.text;
    text = text?text.replace("<pre", "<span class = 'underline'").replace("</pre>", "</span>"):'';
    return text;
  };
  getInfoFromText = ( item:Message) => {
    const text:string = item.text;
    const info = text?text.split(/<pre jb_id="(.+?)".*?type="(.+?)".*?(jba_is_direct="(.+?)")?[ ]*?>(.+?)<\/pre>/gi):'';
    return info;
  }
  public renderElement(item: Message) {
    const is_not_available = item.fromInfo?.is_not_available;
    //        if (item['isKuraha'] !== undefined && item['isKuraha'] === 1 && (is_not_available != null && is_not_available === 1)) {
    //         if (item['isKuraha'] !== undefined && item['isKuraha'] === 1 && (is_not_available != null && is_not_available === 1)) {
    // console.log( '!!!!!!')
    // console.log( item );
    //             return <div key={item['docId']}></div>
    //         }
    // if( !item.fromInfo ){
    //     return <div key = {item['docId']}></div>
    // }
    let imageInfo = this.getImagePath(item);
    return (
      <Link to={this.getPath(item)} key={item["docId"]}>
        <div className="element flex_start">
          <div className="l_block">
            <img
              alt=""
              className={imageInfo.is_not_available === 1 ? "no_radius" : ""}
              src={imageInfo.url}
            />
            <div className="company_info">
              <div className="company_name">
                <div
                  className={
                    this.isNewMessage(item)
                      ? "fs20 f-meiryo-bold relative mark_choosed_job space_10"
                      : "fs20 f-meiryo-bold"
                  }
                >
                  {this.getName(item)}
                </div>
              </div>
            </div>
          </div>
          <div className="r_block pl_20 wd_550">
            {/* <span className="msg_content" dangerouslySetInnerHTML={{ __html: item["text"] }}></span> */}
            <div>
              {item.isKuraha != undefined && item.isKuraha == 1}
              <div className="msg_content ml_0 bold">{item["title"]}</div>
              <div
                className="msg_content ml_0"
                dangerouslySetInnerHTML={{ __html: this.decorateContent(item) }}
              ></div>
              <div className="msg_content ml_0 bold small">{(item["numUnviewed"] && item["numUnviewed"] > 1?`その他${item["numUnviewed"]-1}件`:'')}</div>
            </div>
            <span className="date">{item["fulldate"]}</span>
          </div>
        </div>
      </Link>
    );
  }
  public render() {
    return (
      <div>
        {this.state.loading ? <Loading /> : <></>}
        <div className="job message">
          <div className="job_inner message_inner">
            <div className="title">
              &nbsp;
              <span className="mark_choosed_job_notify right_show">
                新しいメッセージ
              </span>
            </div>
            {this.state.messages.length === 0 ? (
              <span className="no_content">メッセージがありません。</span>
            ) : (
              <></>
            )}
            {this.state.messages.map((item) => this.renderElement(item))}
          </div>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatcherToProps)(MessageListCom);
