import React from 'react';
import ClientNavigation from '../Navigation/ClientNavbar';
import ProviderNavigation from '../Navigation/ProviderNavbar';
import '../scss/top.scss';
import {Link} from 'react-router-dom';
import { IRootState } from '../../modules';
import { connect } from 'react-redux';
import * as asyncactions from '../../modules/chat/async-actions';
import * as userAsyncactions from '../../modules/userinfo/async-actions';
import { ChatActions } from '../../modules/chat/types';
import { Dispatch } from 'redux';
import * as URLS from '../../modules/urls';
import {Redirect} from 'react-router-dom';
import * as Global from '../../modules/global';
import { UserInfoActions } from '../../modules/userinfo';
import { FooterActions } from '../../modules/footer';

 const mapStateToProps = ({ message, user }: IRootState ) => {
    const { count, point, removeCountListener } = message;
    const { userInfo, roleCheckListener } = user;    
    return { count, point, removeCountListener, userInfo, roleCheckListener };
}
const mapDispatcherToProps = (dispatch: Dispatch<ChatActions|UserInfoActions|FooterActions>) => {
    return {
        countMessage:( from:number ) => asyncactions.messageCountlistenerAsync( dispatch, from ),
        registerRoleListener:() => userAsyncactions.registerRoleListenerAysnc( dispatch ),
        // isRerender:( _isRerender: boolean ) => footerAsyncactions.isRerender( dispatch, _isRerender ),        
    }
}
type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;

class Header extends React.Component<ReduxType>{
    mounted: boolean = false;
    componentDidMount = async() => {
        this.mounted = true;
        const from = Global.getEmId();
        const that = this;
        const URL:string = URLS.LOGIN + "?em_id=" + from;
        fetch( URL, {
            method: 'GET',
        }).then( async function(response){
            if( !that.mounted ) {
                return;
            }
            const ret = await response.json();
            if( ret.items.length ){
                localStorage.setItem('em_id', ret.items[0].em_id);
                localStorage.setItem('em_worker_name', ret.items[0].em_lastname + ret.items[0].em_firstname);
                localStorage.setItem('em_boss_company',  ret.items[0].em_is_legal?ret.items[0].em_company:ret.items[0].em_company_legal);
                Global.setEmtId( ret.items[0].emt_is_boss, ret.items[0].emt_is_worker);
                Global.setEmBossCompany(ret.items[0].em_is_legal === '0'?ret.items[0].em_company:ret.items[0].em_company_legal);
                Global.setEmBigPhoto( ret.items[0].em_photo?ret.items[0].em_photo:'' );
                Global.setEmSmallPhoto( ret.items[0].em_photo_s?ret.items[0].em_photo_s:'' );                
                Global.setEmDepart(ret.items[0].em_depart?ret.items[0].em_depart:'');
                Global.setEmWorkerCompany( ret.items[0].em_is_individual == '1'?'個人事業主':ret.items[0].em_company_w);
                Global.setEmWorkerName(ret.items[0].em_lastname + ret.items[0].em_firstname);
                Global.setPoint(ret.items[0].em_point?ret.items[0].em_point:'0');
                that.setState({
                    isLogIn:true,
                    loading:false,
                });
            }else{
                that.setState({ loading:false });                
            }
        }).catch(error => {
            this.setState({ loading:false, errorMessage: error.toString() });
        });           

        if( this.props.removeCountListener === undefined || this.props.removeCountListener == null )
        {
            const from = Global.getEmId();
            if( from && from !== "" ){
                if( !this.props.removeCountListener ){
                    this.props.countMessage(parseInt(from) );
                }
            }
        }
        if( this.props.roleCheckListener === undefined || this.props.roleCheckListener == null ){
            this.props.registerRoleListener();
        }
    }
    componentWillUnmount() {
        this.mounted = false;
    }
    renderRedirect = () => {
        const em_id = Global.getEmId();
        let role = Global.getRole();
        
        if( em_id === "" ){
            if( this.props.removeCountListener ){                
                this.props.removeCountListener();
            }
            let url:string = '/';
            return <Redirect to = {url}/>
        }else{
            if( role !== "1" && role !== "2" ){
                let url:string = '/choose';
                return <Redirect to = {url}/>
            }else{
                return <></>
            }
        }
    }    
    public render() {
        const role = Global.getRole();
        const em_name = Global.getEmName();
        let em_id = Global.getEmId();
        const defaultRole = Global.getDefaultRole();
        let homeUrl = '/';
        if( em_id != null && em_id !== '' ){
            if( defaultRole === '1' ){
                homeUrl = '/findpeople';
            }else{
                homeUrl = '/findjob';
            }
        }
        const point = this.props.point?this.props.point:Global.getPoint();
        const photo = Global.getEmSmallPhoto();        
        return(
            <header className="top-container" id = 'header'>
                {this.renderRedirect()}            
                <div className="top-info">
                    <Link to = {homeUrl}><img alt = '' className = "logo" src = { URLS.BASE_ABSOLUTE_URL + '/images/logo.png'}/></Link>
                    <Link to = "/pointview"><span className="point-info"><span className="big">{point}</span>pt</span></Link>
                    <span className="company-info"><span className="inner">
                    {
                        role === '1'?"親方/会社":"職人"
                    }
                    </span></span>
                    <Link to = {role === "1"?"/myprofile":"/provider/myprofile"}>
                        <span className="avata-info">
                            <img alt = ''  src = { URLS.IMAGE_URL + photo} />
                            <span className="name-info"><span className="b-name">{em_name}</span>{'さん'}</span>
                        </span>
                    </Link>
                </div>
                {
                    role === '1'?( <ClientNavigation messageCount={ this.props.count}/> ):( <ProviderNavigation messageCount={ this.props.count}/> )
                }
            </header>
        );
    }    
}
export default connect(mapStateToProps,mapDispatcherToProps )(Header);
