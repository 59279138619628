import React, { Component } from 'react';
import '../scss/job.scss';
import '../scss/recommend.scss';
import { Link } from 'react-router-dom';
import * as URLS from '../../modules/urls';
import Loading from '../Include/Loading';
import * as Global from '../../modules/global';
import * as footerAsyncactions from '../../modules/footer/async-actions';
import { FooterActions } from '../../modules/footer';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

const mapDispatcherToProps = (dispatch: Dispatch<FooterActions>) => {
    return {
        isRerender: (_isRerender: boolean) => footerAsyncactions.isRerender(dispatch, _isRerender),
    }
}
type ReduxType = ReturnType<typeof mapDispatcherToProps>;

class MyProviderCom extends Component<ReduxType>{
    state = {
        loading: false,
        items: []
    }
    componentDidMount() {
        const that = this;
        let em_id: any = Global.getEmId();
        if (em_id != "") {
            this.setState({
                loading: true,
            });
            const url = URLS.GET_MY_PROVIDER_LIST + "&em_id_1=" + em_id + '&sort=[{"property":"emm_created","direction":"desc"},{"property":"em_id","direction":"desc"}]';
            fetch(url, {
                method: 'get',
            }).then(async function (response) {
                let ret = await response.json();
                if (ret.total > 0) {
                    const items = ret.items;
                    that.setState({
                        loading: false,
                        items: items,
                    }, async() => {
                        await that.props.isRerender( true );
                    });
                } else {
                    that.setState({
                        loading: false,
                        items: [],
                    }, async() => {
                        await that.props.isRerender( true );
                    });
                }
            }).catch(error => {
                this.setState({ loading: false, items: [], errorMessage: error.toString() }, async() => {
                    await that.props.isRerender( true );
                });
            });
        }
    }
    renderSkill(skillStr: string) {
        if (!skillStr) {
            return;
        }
        const skills = skillStr.split(',');
        let ret = [];
        for (let i = 0; i < skills.length; i++) {
            ret.push(<span className="tag-mg fs14" key={i}><span className="tag-inner">{skills[i]}</span></span>)
        }
        return ret;
    }
    render() {
        return (
            <div className="recommend-inner">
                {this.state.loading ?
                    <Loading />
                    : <></>
                }
                {
                    this.state.items.length == 0 ? <span className="no_content">応募者がありません。</span> : <></>
                }
                {
                    this.state.items.map(item => (
                        <div className="element flex" key={item['em_id']}>
                            <div className="flex_start">
                                <div className="first">
                                    {
                                        item['emt_is_worker'] == '1' ?
                                            <img src={URLS.IMAGE_URL + item['em_photo_s']} />
                                            :
                                            <img className="no_radius" src={URLS.IMAGE_URL + URLS.LEAVE_USER_IMAGE} />
                                    }
                                </div>
                                {
                                    item['emt_is_worker'] == '1' ?
                                        <>
                                            <div className="second">
                                                <div className="fs14">{item["em_is_individual"] == '1' ? '個人事業主' : item['em_company']}</div>
                                                <div className="fs20">{item['em_lastname'] + item['em_firstname']}</div>
                                            </div>
                                            <div className="third">
                                                <div className="fs14 map-icon">{item['st_label']}</div>
                                                <div>
                                                    {this.renderSkill(item["sk_label_s"])}
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="second">
                                                <div className="fs14">退会ユーザー</div>
                                            </div>
                                        </>
                                }
                            </div>
                            <div className="btn-container">
                                <div className="grey-button v-small">
                                    {
                                        item['emt_is_worker'] == '1' ?
                                            <Link to={"/provider/profile/" + item['em_id']}>
                                                <div className="inner f-meiryo-bold">
                                                    詳細
                                                </div>
                                            </Link>
                                            :
                                            <Link to={{ pathname: "/message/", state: { id: item['em_id'], is_not_available: 1 } }}>
                                                <div className="inner f-meiryo-bold">
                                                    メッセージを見る
                                                </div>
                                            </Link>
                                    }
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        );
    }
}
export default connect(null, mapDispatcherToProps)(MyProviderCom);