import React from 'react';
import '../scss/job.scss';
import Header from '../Include/Header'
import DeleteProfileCom from './DeleteProfileCom';
import Footer from '../Include/Footer';

class DeleteProfile extends React.Component<any>{
    public render(){
        const url = this.props.match.url;
        return (
            <div>
                <Header/>
                <div className="content-container" id = "content">
                    <div className="wrap-content">
					    <div className="main-title">アカウント削除</div>
                        <div className="job_container mt_40">
                            <DeleteProfileCom/>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}
export default DeleteProfile;