import React from 'react';
import '../scss/job.scss';
import '../scss/myprofile.scss';
import * as Global from '../../modules/global';
import * as URLS from '../../modules/urls';
import {FeedbackType} from '../../modules/feedback/types'

import * as footerAsyncactions from '../../modules/footer/async-actions';
import { FooterActions } from '../../modules/footer';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

const mapDispatcherToProps = (dispatch: Dispatch<FooterActions>) => {
    return {
        isRerender:( _isRerender: boolean ) => footerAsyncactions.isRerender( dispatch, _isRerender ),             
    }
}
type ReduxType = ReturnType<typeof mapDispatcherToProps> & any;

class FeedbackCom extends React.Component<ReduxType>{
    state = {
        loading:false,
        items:[]
    }
    componentDidMount(){

        let params = "";
        let feedbackInfo:FeedbackType = this.props.info?this.props.info:{};
        if( !feedbackInfo.em_id ){
            return;
        }
        if( feedbackInfo.is_my_feedback ){
            if( feedbackInfo.role === "1" ){
                params = "?em_id_b=" + feedbackInfo.em_id;
            }else{
                params = "?em_id_w=" + feedbackInfo.em_id;                
            }
        }else{
            if( feedbackInfo.role === "1" ){
                params = "?em_id_w=" + feedbackInfo.em_id;
            }else{
                params = "?em_id_b=" + feedbackInfo.em_id;                
            }
        }
        params += "&=page_all=1";                        
        params += '&sort=[{"property":"ctrc_date","direction":"desc"}]';
        let url = URLS.READ_COMMENT + params;
        const that = this;
        this.setState({
            loading:true,
        });
        fetch( url, {
            method: 'get',
        }).then(async function(response) {
            let ret = await response.json();
            that.setState({
                loading:false,
                items:ret.items,
            }, async() => {
                await that.props.isRerender( true );
            });
        }).catch(error => {
            this.setState({ loading:false, errorMessage: error.toString() }, async() => {
                await that.props.isRerender( true );
            });
        });        
    }    
    public render(){
        const em_name = this.props.info.em_name;
        const em_company = this.props.info.em_company;
        const em_star = this.props.info.ctrc_value != ''?parseFloat( this.props.info.ctrc_value ):0;
        const em_photo_s = this.props.info.em_photo_s;
        return(
        <div className="myprofile_inner">
            <div className="l_block block">
                <div className="both_ways">
                    <img src = {URLS.IMAGE_URL + em_photo_s}/>
                    <div className="company_info">
                        <div>
                            <div className="fs20">{em_name}</div>
                            <div className="fs14">{em_company}</div>
                        </div>
                    </div>
                </div>
                <div className="mt_20 ml_40">
                    <span className="round-title small">現在の評価</span>										
                    <div className="flex_middle mt_20">
                        <span className="f_arial fs25 bold mr_5">{em_star == 0?'':em_star.toString() }</span>
                        <div className="star_container mt_5">
                            <span className={em_star > 0 ?"star active":"star"}></span>
                            <span className={em_star > 1 ?"star active":"star"}></span>
                            <span className={em_star > 2 ?"star active":"star"}></span>
                            <span className={em_star > 3 ?"star active":"star"}></span>
                            <span className={em_star > 4 ?"star active":"star"}></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="r_block">
                <div className ="info">
                    <div className="fs14 ml_20">評価とコメント</div>
                </div>
                {
                    this.state.items.length == 0 ?<span className = 'no_content mt_20'>評価がありません。</span>:<></>
                }
                {
                    this.state.items.map( item=>(
                        <div className="profile_element pd_b" key = {item['ctrc_id']}>
                            <div className="date_name">
                                <span className="date">{Global.convertDateToJapan(item['ctrc_date']) }</span>
                                <span className="name">{item['is_boss'] == '1'?(item['em_lastname'] + item['em_firstname'] ):(item['em_is_legal'] == '0'?item['em_company']:item['em_company_legal']) }</span>
                            </div>
                            <div className="star_container">
                                <span className={parseInt(item['ctrc_value']) > 0 ?"star active":"star"}></span>
                                <span className={parseInt(item['ctrc_value']) > 1 ?"star active":"star"}></span>
                                <span className={parseInt(item['ctrc_value']) > 2 ?"star active":"star"}></span>
                                <span className={parseInt(item['ctrc_value']) > 3 ?"star active":"star"}></span>
                                <span className={parseInt(item['ctrc_value']) > 4 ?"star active":"star"}></span>
                            </div>
                            <span dangerouslySetInnerHTML = { {__html: item['ctrc_note']} }></span>
                        </div>                        
                    ))
                }
            </div>
        </div>

        );
    }
}
export default connect(null, mapDispatcherToProps)(FeedbackCom);