import React from 'react';
import '../scss/job.scss';
import '../scss/editprofile.scss';
import Header from '../Include/Header'
import EditProfileCom from './EditProfileCom';
import Footer from '../Include/Footer';

class EditProfile extends React.Component<any>{
    public render(){
        const url = this.props.match.url;
        return (
            <div>
                <Header/>
                <div className="content-container" id = "content">
                    <div className="wrap-content">
                        <div className="main-title">プロフィール修正</div>
                        <div className="job_container mt_40">
                            <div className ="edit_profile">
                                <EditProfileCom/>
                            </div>                            
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}
export default EditProfile;