import React, {Component} from 'react';
import '../scss/job.scss';
import '../scss/editprofile.scss';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import { IRootState } from '../../modules';
import { Dispatch } from 'redux';
import * as asyncactions from '../../modules/createinfo/async-actions';
import {CreateInfoActions } from '../../modules/createinfo';
import {Redirect} from 'react-router-dom';
import {getSelectedLabel, getLabel} from '../../modules/basic/types';
import SimpleReactValidator from 'simple-react-validator';
import { VALIDATORS, VALIDATION_MESSAGES } from '../../modules/global';

const mapStateToProps = ({ create }: IRootState ) => {
    const { isUserExist, page, createInfo } = create;
    return { isUserExist, page, createInfo };
}
const mapDispatcherToProps = (dispatch: Dispatch<CreateInfoActions>) => {
    return {
        back:( page:number ) => asyncactions.backAsync( dispatch, page ),
    }
}
type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;
class CreateAccountConfirmCom extends Component<ReduxType>{

    state = {
        loading:false,
        errorMessage:'',
        page:-1,
        is_agree:false,
    };

    validator:SimpleReactValidator;
    constructor(props:any){
        super( props );
        this.validator = new SimpleReactValidator({
            messages: VALIDATION_MESSAGES,
            autoForceUpdate: this,
            className:'error error_center',
            validators:VALIDATORS
        });
    }
    onHandleChange = ( e:any ) =>{
        const target = e.target;
        let name:string = e.target.name;
        if( name === "is_agree" ){
            const checked = target.type === "checkbox" ? target.checked:'';
            this.setState({
                'is_agree':checked,
            });
        }
    }
    onClickHandler = (e:any ) =>{
        let flag = this.validator.allValid();
        if (flag){
            this.props.back(2);            
        }else{
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    back =() =>{
        this.props.back(0);
    }
    renderRedirect = () => {
        if( this.props.page <= 0 ){
            let url = '/create';
            return <Redirect to = {url}/>
        }else if( this.props.page == 2 ){
            let url = '/phoneverify';
            return <Redirect to = {url}/>
        }
        return <></>
    }
    public render(){
        const skillLabels = getSelectedLabel( this.props.createInfo.sk_s );
        const addressLabel = getLabel( this.props.createInfo.st_id );
        const areaLabel = getLabel( this.props.createInfo.ar_id );
        let address = '';
        if( addressLabel != null ){
            address = addressLabel.label;
        }
        if( areaLabel != null ){
            address += areaLabel.label;
        }
        return(
            <>
                {this.renderRedirect()}
                <div className="edit_profile_inner">
                    <span className="fs14">この内容でよければ登録ボタンを押してください。</span>
                    <div className="element border_none pb_0">
                        <div className="second_column flex_middle">
                            <div className="wd_100 mr_40 text_right"><span className="tag-mg fs12"><span className="tag-inner">会社名</span></span></div>
                            <span className="fs18 f-meiryo-bold">{this.props.createInfo.em_name}</span>
                        </div>
                    </div>
                    <div className="element">
                        <div className="second_column flex_middle">
                            <div className="wd_100 mr_40 text_right"><span className="tag-mg fs12"><span className="tag-inner">パスワード</span></span></div>
                            <span className="fs18 f-meiryo-bold">{this.props.createInfo.em_passwd}</span>											
                        </div>
                    </div>
                    <div className="element">
                        <div className="second_column">
                            <div className="wd_100 mr_40 text_right"><span className="tag-mg fs12"><span className="tag-inner">登録形態</span></span></div>
                            <span className="fs14">
                                {
                                    this.props.createInfo.emt_id == 1?"親方/会社":this.props.createInfo.emt_id == 2?"職人":"親方/会社・職人"
                                }
                            </span>
                        </div>
                    </div>
                    <div className="element">
                        <div className="second_column">
                            <div className="wd_100 mr_40 text_right"><span className="tag-mg fs12"><span className="tag-inner">業種</span></span></div>
                            <div>
                                {
                                    skillLabels.map( sk =>(
                                    <div key = {sk.key}>{sk.label}</div>        
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="element">
                        <div className="second_column">
                            <div className="wd_100 mr_40 text_right"><span className="tag-mg fs12"><span className="tag-inner">現住所</span></span></div>
                            <span className="fs14">{address}</span>
                        </div>
                    </div>
                    <div className="element">
                        <div className="second_column">
                            <div className="wd_100 mr_40 text_right"><span className="tag-mg fs12"><span className="tag-inner">携帯電話番号</span></span></div>
                            <div className="inline_block mr_5">
                                <span className="fs14 inline_block mr_5">{this.props.createInfo.em_phone}</span>
                            </div>
                        </div>
                    </div>
                    <div className="button-container">
                        <div className="layout_center mt_40">
                            <label className="control-checkbox">
                                <input type="checkbox" name="is_agree" value="1" onChange={this.onHandleChange}/>
                                <span className="control__indicator"></span>
                                <Link to = "/privacy" className="underline">「利用規約・個人情報の取り扱い」</Link>に同意する
                            </label>
                            {this.validator.message('is_agree', this.state.is_agree, 'accepted' )}                            
                        </div>
                        <button className="grey-button large mt_20">
                            <div className="inner"  onClick = {this.onClickHandler}>
                            登録する
                            <i className="fa fa-angle-right"></i>
                            </div>
                        </button>										
                        <div className="ml_220 mt_20">
                            <div className="underline">
                                <span className="fs14" style = {{cursor:"pointer"}} onClick = {this.back}>&lt;内容を修正する</span>
                            </div>
                        </div>
                    </div>																
                </div>                       
            </>
        );
    }
}
export default connect(mapStateToProps, mapDispatcherToProps )(CreateAccountConfirmCom);