import React, {Component} from 'react';
import {DropDownProps, DropDownState} from "../../../modules/dropdown/types";
export class BasicRadioBox extends Component<DropDownProps, DropDownState>{
    state = {
        total:0,
        items:[],
    }
    onHandleChange =(e:any) =>{
        this.props.onHandleChange(e);
    }
    onHandleBlur = (e:any) =>{
        this.props.onHandleBlur(e);
    }
    shouldComponentUpdate(){
        return true;
    }
    componentDidMount() {
        this.fetch();
    } 
    fetch( _url:string = "" ){
        const url:string = _url != ""? _url:this.props.url;
        const response = fetch( url ).then(async response => {    
            const data = await response.json();

            // check for error response
            if (!response.ok) {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
            this.setState({ total: data.total, items:data.items });
        }).catch(error => {
            this.setState({ errorMessage: error.toString() });
            console.error('There was an error!', error);
        });
    }
    render(){
        return(
            this.state.items.map( item => (
                <label className="radio-item mr_20"  key = {item[this.props.idKey]}>
                    <span className="wd_30 m_wd_20">
                        <input type="radio" name={this.props.name} value ={this.props.noPrefix?item[this.props.idKey]:(item[this.props.idKey] + "_" + item[this.props.valueKey]) }
                        checked = {this.props.checkedValue==item[this.props.idKey]?true:false} onChange={this.onHandleChange}/><span className="radiobtn"></span></span>
                    <span className="txt">{item[this.props.valueKey]}</span>
                </label>
            ))
        );
    }
}