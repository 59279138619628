/**
* Use the CSS tab above to style your Element's container.
*/

//import './CardSectionStyles.css'
import React, { useMemo, useState,useRef } from "react";
import {CardElement} from '@stripe/react-stripe-js';

import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from "@stripe/react-stripe-js";
import Loading from '../Include/Loading';
import {AlertDialog} from '../Modal/Alert';
import useResponsiveFontSize from "./useResponsiveFontSize";
import {Redirect} from 'react-router-dom';
import * as URLS from '../../modules/urls';
import * as Global from '../../modules/global';

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    }),
    [fontSize]
  );

  return options;
};

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const CardSection = ( props ) => {
  const stripe = useStripe();
  const [firstname, setFirstname]= useState( '' );
  const [cardType,setCardType] = useState('1');
  const[loading, setLoading] = useState(false);
  const [lastname, setLastname]= useState( '' );    
  const alertRef = useRef();
  const elements = useElements();
  const options = useOptions();
  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
	}
	let em_id = Global.getEmId();        
	let pt_id = props.pt_id?props.pt_id:'';
	let pt_money = props.pt_money?props.pt_money:'';

	if( em_id == "" || pt_id == "" || pt_money == '' ){
		return;			
	}

	const name = firstname + ' ' + lastname;
	setLoading(true);
    const result = await stripe.confirmCardPayment( props.clientSecret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: name,
        },
      }
    });
	setLoading(false);
    if (result.error) {
	  // Show error to your customer (e.g., insufficient funds)
	  if( result.error.code == "card_declined" ){
		  if( result.error.decline_code == "insufficient_funds" ){
			alertRef.current.open( "失敗", "金額が不足しています。", true  ); 
		  }else{
			alertRef.current.open( "失敗", result.error.message, true  ); 			  
		  }
	  }else{
		alertRef.current.open( "失敗", result.error.message ); 			  		  
	  }
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === 'succeeded') {
		console.log( "Payment Success!" );
		let em_id = Global.getEmId();
		const role = Global.getRole();
		let pt_id = props.pt_id?props.pt_id:'';
		let pt_money = props.pt_money?props.pt_money:'';
		if( em_id == "" || pt_id == "" || pt_money == '' ){
			return;			
		}
		let ep_worker = 0;
		if( role == 2 ){
			ep_worker = 1;
		}
		const data = {
			em_id:em_id,
			pt_id:pt_id,
			ep_worker:ep_worker,
			// ep_data:{
			// 	em_name:name,
			// 	card_type:cardType,
			// }
		}
        fetch( URLS.PAYMENT_REGISTER, {
            method: 'post',
            body: JSON.stringify( data ),
        }).then(async function(response){
            let ret = await response.json();
            if( ret.success ){
				alertRef.current.open( "成功", "支払いに成功しました。", true );
            }else{
				alertRef.current.open( "成功", "支払いに成功しました。", true );
            }
            //return response.json();
        }).catch(error => {
			alertRef.current.open( "成功", "支払いに成功しました。", true );
        });		
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
      }else{
        console.log( result.paymentIntent.status );		  
	  }
    }
  };
	const onPayTypeChange =(event) => {
		const type = event.target.value;
		setCardType(type);
	}
	const onHandleChangeFirstName = (event ) => {
		setFirstname(event.target.value );
	}
	const onHandleChangeLastName = (event) => {
		setLastname(event.target.value );
	}
	const renderRedirect = () => {
		if( props.clientSecret == undefined ){
			let url = '/buypoint';
			return <Redirect to = {url}/>
		}
		return <></>
	}
    return (
		<div>
			{renderRedirect()}
			{loading?
				<Loading/>
				:<></>
			}			
			<div className="point_buy point-history point_confirm mt_90">
				<AlertDialog ref = {alertRef} returnURL = {"/pointview"}/>                		  
				<div className="point-history-inner">
				<div className="point_title_container"><span className="point_title">{props.pt_amount}<span className="point_title_label">pt</span></span></div>
				<div className="point_confirm_content">
					<div>
						<span className="f-meiryo-bold fs18">合計</span><span>：{Global.moneyFormat(props.pt_money)}円（税込）</span>
					</div>
					<div className="pt_element mt_10">
					<div className="pt_element_inner">
						<div style ={cardType==3?{display:'none'}:{display:''}}>
							<form onSubmit={handleSubmit}>
								<div className="mt_30">
									<div className="f-meiryo-bold fs14 mb_10">クレジットカード（デビッドカード）番号入力</div>
									<div className = "wd_250">
									<CardNumberElement
										options={options}
										onReady={() => {
											console.log("CardNumberElement [ready]");
										}}
										onChange={event => {
											console.log("CardNumberElement [change]", event);
										}}
										onBlur={() => {
											console.log("CardNumberElement [blur]");
										}}
										onFocus={() => {
											console.log("CardNumberElement [focus]");
										}}
									/>
									</div>
								</div>
								<div className="mt_30">
									<div className="f-meiryo-bold fs14 mb_10">カード名義</div>
									<div>
									<div className="wd_130 inline_block mr_5">
									<input className = "full_wide" type = "text" name = "firstname" placeholder="TARO" onChange = {onHandleChangeFirstName}/>
									</div>
									<div className="wd_130 inline_block"><input className = "full_wide" type = "text" name = "lastname" placeholder="YAMADA" onChange = {onHandleChangeLastName}/></div>	
									</div>
								</div>
								<div className="mt_30">
									<div className="flex_start middle">
									<div className="mr_40">
										<div className="f-meiryo-bold fs14 mb_10">MM/YY</div>
										<div className="wd_90">
										<CardExpiryElement
											options={options}
											onReady={() => {
											console.log("CardNumberElement [ready]");
											}}
											onChange={event => {
											console.log("CardNumberElement [change]", event);
											}}
											onBlur={() => {
											console.log("CardNumberElement [blur]");
											}}
											onFocus={() => {
											console.log("CardNumberElement [focus]");
											}}
										/>	
										</div>													
									</div>
									<div>
										<div className="f-meiryo-bold fs14 mb_10">CVV</div>
										<div className= "wd_50">
										<CardCvcElement
											options={options}
											onReady={() => {
												console.log("CardNumberElement [ready]");
											}}
											onChange={event => {
												console.log("CardNumberElement [change]", event);
											}}
											onBlur={() => {
												console.log("CardNumberElement [blur]");
											}}
											onFocus={() => {
												console.log("CardNumberElement [focus]");
											}}
										/>
										</div>													
									</div>												
									</div>
								</div>
								<div className ="button-container">
									<button type="submit" className="grey-button large mt_50 mb_20" disabled={!stripe}>
										<div className="inner">
										決済する
										<i className="fa fa-angle-right"></i>
										</div>
									</button>										
								</div>
							</form>
						</div>
					</div>
					</div>
				</div>
				</div>
			</div>				
		</div>
    );
};

// function CardSection() {
//   return (
//     <label>
//       Card details
//       <CardElement options={CARD_ELEMENT_OPTIONS} />
//     </label>
//   );
// };

export default CardSection;