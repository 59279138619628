import React, { Component } from 'react';
import '../scss/recommend.scss';
import Header from '../Include/Header'
import RecomCom from '../Include/RecomCom';
import Advertise from '../Include/Advertise';
import RecommendLinkCom from '../Include/RecommendLinkCom'
import {Link} from 'react-router-dom';
import Footer from '../Include/Footer';
import * as Global from '../../modules/global';
import { IRootState } from '../../modules';
import { connect } from 'react-redux';

const mapStateToProps = ({ message }: IRootState ) => {
    const { count } = message;
    return { count };
}
type ReduxType = ReturnType<typeof mapStateToProps>;
class Recommend extends Component<any, any>{
    render(){
        let em_id:any = Global.getEmId();
        return(
            <div>
                <Header/>
                <div className="content-container" id = "content">
                    <div className="wrap-content">
                    {
                                this.props.count > 0? 
                                <div className="message-notify">
                                    <div className="message-notify-inner">
                                        新しい<Link to = {"/messagelist" } className="underline dark_red">メッセージ</Link>が届いています
                                    </div>
                                </div>
                                :<></>
                    }
                        <div className="recommend">
                            <span className="title bold">おすすめの職人</span>
                            <RecomCom/>
                            <div className="grey-button yellow v-large rb mt_40 mb_30">
                                <Link to= "/search">
                                    <div className="inner">
                                        <i className="fa fa-search"></i>								
                                        職⼈を探す・依頼する
                                    </div>
                                </Link>
                            </div>
                        </div>    
                        <RecommendLinkCom/>
                        <Advertise/>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}
export default connect(mapStateToProps)(Recommend);