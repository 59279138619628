import React from 'react';
import '../scss/job.scss';
import Header from '../Include/Header'
import JobNavCom from "./JobNavCom";
import JobDetailCom from './JobDetailCom'
import Footer from '../Include/Footer';
import ProviderNavCom from "../Include/ProviderNavCom";
//function JobDetail(){
class JobDetail extends React.Component<any>{
    public render() {
        const url = this.props.match.url;
        let id = this.props.match.params.id ? this.props.match.params.id : -1;
        let isRightMenuShow = this.props.match.params.isRightMenuShow ? this.props.match.params.isRightMenuShow : '1';
        const role = localStorage.getItem('role');
        let className = "job";
        if (isRightMenuShow == '0') {
            className += " message";
        }
        return (
            <div>
                <Header />
                <div className="content-container" id="content">
                    <div className="wrap-content">
                        <div className="main-title">
                            {
                                isRightMenuShow === '1' ?
                                    role === "1" ?
                                        '仕事管理' : '空き時間管理・応募仕事管理'
                                    :''
                        }
                        </div>
                        <div className="job_container mt_40">

                            {
                                isRightMenuShow === '1' ?
                                    role === "1" ?
                                        <JobNavCom to={url} />
                                        :
                                        <ProviderNavCom to={url} />
                                    : <></>
                            }
                            <div className={className}>
                                <JobDetailCom jobId={id} />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }

}
export default JobDetail; 