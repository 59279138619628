import React from 'react';
import '../scss/job.scss';
import '../scss/myprofile.scss';
import Header from '../Include/Header'
import MyFeedbackeCom from './FeedbackCom';
import Footer from '../Include/Footer';
import * as Global from '../../modules/global';
import {Redirect} from 'react-router-dom';
import {FeedbackType} from '../../modules/feedback/types'

class Feedback extends React.Component<any>{
    renderRedirect = () => {
        const em_id = Global.getEmId();
        if( em_id == "" ){
            let url:string = '/';
            return <Redirect to = {url}/>
        }
    }    
    public render(){
        let id = this.props.match.params.id?this.props.match.params.id:"";
        let role = Global.getRole()!;
        let feedbackInfo:FeedbackType = {
        }
        if( id != "" ){// other's profile information.
            if( this.props.location && this.props.location.state ){
                feedbackInfo.em_id = id;
                feedbackInfo.is_my_feedback = false;
                feedbackInfo.role = role;
                feedbackInfo.em_photo_s = this.props.location.state.em_photo_s?this.props.location.state.em_photo_s:'';                
                feedbackInfo.em_name = this.props.location.state.em_name?this.props.location.state.em_name:'';
                feedbackInfo.em_company = this.props.location.state.em_company?this.props.location.state.em_company:'';
                if( role == '1' ){
                    feedbackInfo.ctrc_value = this.props.location.state.ctrc_value_w?this.props.location.state.ctrc_value_w:'';
                }else{
                    feedbackInfo.ctrc_value = this.props.location.state.ctrc_value_b?this.props.location.state.ctrc_value_b:'';
                }
            }
        }else{// my profile information.
            feedbackInfo.em_id = Global.getEmId()!;
            feedbackInfo.em_photo_s = Global.getEmSmallPhoto()!;
            feedbackInfo.is_my_feedback = true;            
            feedbackInfo.role = role; 
            feedbackInfo.em_name = Global.getEmName()!;
            feedbackInfo.em_company = Global.getEmCompany()!;
            feedbackInfo.ctrc_value = Global.getStar()!;
        }
        return(
            <div>
                {this.renderRedirect()}
                <Header/>
                <div className="content-container" id = "content">
                    <div className="wrap-content">
                        <div className="main-title">評価を見る</div>
                        <div className="job_container mt_40">
                            <div className ="job myprofile feedback">
                                <MyFeedbackeCom info = {feedbackInfo}/>
                            </div>                            
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>

        );
    }
}
export default Feedback;