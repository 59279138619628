// import { combineReducers } from 'redux';
// import splash from './splash';
// import Messages from './message';
// import chats from './chat';
// const rootReducer = combineReducers({
//   splash,
//   Messages,
//   chats, 
// });

// export default rootReducer;

// export type RootState = ReturnType<typeof rootReducer>;

import { combineReducers, createStore } from 'redux';
import { MessageReducer } from './chat/reducer';
import { MessageState } from './chat/types';
import { UserReducer } from './userinfo/reducer';
import {BasicReducer} from './basic/reducer';
import { CreateInfoReducer } from './createinfo/reducer';
import {UserState} from './userinfo/types';
import {CreateState} from './createinfo/types';
import{BasicState} from './basic/types';
import { SearchClientState } from './searchclient/types';
import { SearchClientReducer } from './searchclient/reducer';
import { SearchProviderJobState } from './searchproviderjob/types';
import { SearchProviderJobReducer } from './searchproviderjob/reducer';
import { SearchImmediateJobState } from './searchimmediatejob/types';
import { SearchImmediateJobReducer } from './searchimmediatejob/reducer';

import { SearchProviderState } from './searchprovider/types';
import { SearchProviderReducer } from './searchprovider/reducer';

import { SearchImmediatePeopleState } from './searchimmediatepeople/types';
import { SearchImmediatePeopleReducer } from './searchimmediatepeople/reducer';

import { FooterState } from './footer/types';
import { FooterReducer } from './footer/reducer';

//import { SearchImmediatePeopleReducer, SearchImmediatePeopleState } from './searchimmediatepeople';

// import{ CropReducer } from './cropImage/reducer';
// import { CropImageState } from './cropImage/types'
export type IRootState = {
    message: MessageState,
    user:UserState,
    create:CreateState,
    skill:BasicState,
    prefecture:BasicState,
    area:BasicState,
    searchClient:SearchClientState,
    searchProviderJob: SearchProviderJobState,
    searchImmediateJob: SearchImmediateJobState,
    searchProvider:SearchProviderState,
    searchImmediatePeople: SearchImmediatePeopleState,
    footer: FooterState,
}

const store = createStore<IRootState, any, any, any>(
    combineReducers({
      message: MessageReducer,
      user:UserReducer,
      create:CreateInfoReducer,
      skill:BasicReducer,
      prefecture:BasicReducer,
      area:BasicReducer,
      searchClient:SearchClientReducer,
      searchProviderJob: SearchProviderJobReducer,
      searchImmediateJob: SearchImmediateJobReducer,
      searchProvider: SearchProviderReducer,
      searchImmediatePeople: SearchImmediatePeopleReducer,
      footer: FooterReducer,
    }));

export default store;