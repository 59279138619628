import React, { Component } from "react";
import { Router, Switch, Route } from "react-router-dom";
import history from './history';
import Choose from "./components/Choose/Choose";
import LogIn from "./components/LogIn/LogIn";
import NotFoundPage from "./components/LogIn/NotFoundPage";
import Recommend from "./components/Find/Recommend"
import PRecommend from "./components/Provider/Find/PRecommend"
import PointHistory from "./components/Point/PointHistory"
import PointBuy from "./components/Point/PointBuy"
import PayIndex from "./components/Point/PayIndex"
import JobView from "./components/Find/JobView"
import Job from "./components/Find/Job"
import JobApplicantDetail from './components/Find/JobApplicantDetail'
import JobDetail from './components/Find/JobDetail'
import ProviderProfileView from './components/Profile/ProviderProfileView'
import LeaveFeedback from "./components/Profile/LeaveFeedback";
import MyProfile from "./components/Profile/MyProfile";
import Feedback from "./components/Profile/Feedback";
import AccountType from './components/Profile/AccountType';
import DeleteProfile from "./components/Profile/DeleteProfile";
import ChangeAccountType from "./components/Profile/ChangeAccountType";
import ChangeAccountTypeConfirm from "./components/Profile/ChangeAccountTypeConfirm";
import AccountDelivery from "./components/Profile/AccountDelivery";
import EditProfile from "./components/Profile/EditProfile";
import JobEdit from "./components/Find/JobEdit";
import JobCreate from "./components/Find/JobCreate";
import CreateAccount from "./components/LogIn/CreateAccount";
import CreateAccountConfirm from "./components/LogIn/CreateAccountConfirm";
import Privacy from "./components/LogIn/Privacy";
import PhoneVerify from "./components/LogIn/PhoneVerify";
import PhoneSendSms from "./components/LogIn/PhoneSendSms";
import Search from "./components/Find/Search";
import People from "./components/Find/People";
import DirectRequest from "./components/Find/DirectRequest";
import MessageList from "./components/Message/MessageList";
import Message from "./components/Message/Message";
import MessageTemplate from "./components/Message/MessageTemplate";
import MessageTemplateEdit from "./components/Message/MessageTemplateEdit";
import FreePeople from "./components/Find/FreePeople";
import MySchedule from "./components/Schedule/MySchedule";
import MyScheduleEdit from "./components/Schedule/MyScheduleEdit";
import ScheduleView from "./components/Schedule/ScheduleView";
import PMyProfile from "./components/Provider/Profile/PMyProfile";
import PEditProfile from "./components/Provider/Profile/PEditProfile";
import ClientProfileView from "./components/Provider/Profile/ClientProfileView";
import PSearch from "./components/Provider/Find/PSearch";
import PSearchResult from "./components/Provider/Find/PSearchResult";
import PJobView from "./components/Provider/Find/PJobView";
import PSearchArea from "./components/Provider/Find/PSearchArea";
import JobType from "./components/Basic/JobType";
import PJobDetail from "./components/Provider/Find/PJobDetail";
import MyProvider from "./components/Find/MyProvider";
import MyClient from "./components/Provider/Find/MyClient";
import MyProviderJob from "./components/Provider/Find/MyProviderJob";
import PSearchImmediateJob from "./components/Provider/Find/PSearchImmediateJob";
import PDeleteProfile from "./components/Provider/Profile/PDeleteProfile";
import ResetPassword from "./components/LogIn/ResetPassword";
import ResetUserId from './components/LogIn/ResetUserId';
import SetPassword from "./components/LogIn/SetPassword";
import PSearchAreaResult from "./components/Provider/Find/PSearchAreaResult";
import SearchResult from "./components/Find/SearchResult";
export default class Routes extends Component {
    render() {
        return (
            <Router history={history}>
                <Switch>
                    <Route path="/" exact component = {LogIn} />
                    <Route path="/privacy" component = {Privacy} />
                    <Route path="/choose/:role?" component = {Choose} />                    
                    <Route path="/sendsms" component = {PhoneSendSms} />
                    <Route path="/phoneverify" component = {PhoneVerify} />
                    <Route path="/findpeople" component = {Recommend} />
                    <Route path="/myprovider" component = {MyProvider} />
                    <Route path="/directrequest/:id" render={(props) => <DirectRequest {...props} />} />                    
                    <Route path="/people" component = {People} />
                    <Route path="/freepeople" component = {FreePeople} />
                    <Route path="/messagelist" component = {MessageList} />
                    <Route path="/messagetemplate/:to?" render={(props) => <MessageTemplate {...props} />} />
                    <Route path="/templateedit/:to?" render={(props) => <MessageTemplateEdit {...props} />} />                    
                    <Route path="/search" component = {Search} />
                    <Route path="/searchresult" component = {SearchResult} />
                    <Route path="/jobview" component = {JobView} />
                    <Route path="/job/:id" component = {Job} />                    
                    <Route path="/myprofile" component = {MyProfile} />
                    <Route path="/editprofile" component = {EditProfile} />   
                    <Route path="/delete_profile" component = {DeleteProfile} />    
                    <Route path="/account_delivery" component = {AccountDelivery} />
                    <Route path="/feedback/:id?" render={(props) => <Feedback {...props} />} />
                    <Route path="/message/:id?" render={(props) => <Message {...props} />} />
                    <Route path="/jobdetail/:id/:isRightMenuShow?" render={(props) => <JobDetail {...props} />} />
                    <Route path="/pjobdetail/:id/:company?/:depart?/:basepath?/:photo?/:em_id?" render={(props) => <PJobDetail {...props} />} />                    
                    <Route path="/jobedit/:id" render={(props) => <JobEdit {...props} />} />
                    <Route path="/jobcreate" component = {JobCreate} />                    
                    <Route path="/provider/profile/:id/:jobId?/:jbaId?" render={(props) => <ProviderProfileView {...props} />} />
                    <Route path="/client/profile/:id/:isRightMenuShow?" render={(props) => <ClientProfileView {...props} />} />
                    <Route path="/schedule/:id" render={(props) => <ScheduleView {...props} />} />                    
                    <Route path="/myschedule_edit" component = {MyScheduleEdit}/>
                    <Route path="/change_account_type" render={(props) => <ChangeAccountType {...props} />} />
                    <Route path="/change_account_type_confirm" render={(props) => <ChangeAccountTypeConfirm {...props} />} />                    
                    <Route path="/account_type" render={(props) => <AccountType {...props} />} />                    
                    <Route path="/leave_feedback/:id" render={(props) => <LeaveFeedback {...props} />} />
                    <Route path="/applicant_list/:id/:name" component = {JobApplicantDetail}/>                    
                    <Route path="/pointview" component = {PointHistory} />
                    <Route path="/buypoint" component = {PointBuy} /> 
                    <Route path="/pay" component = {PayIndex}/>                     
                    <Route path="/login" component = {LogIn} />
                    <Route path="/create" component = {CreateAccount} />
                    <Route path="/resetpassword" component = {ResetPassword} />       
                    <Route path="/resetuserid" component = {ResetUserId} />       
                    <Route path="/setpassword" component = {SetPassword} />
                    <Route path="/createconfirm" component = {CreateAccountConfirm} />    
                    <Route path="/provider/myprofile" component = {PMyProfile} />                    
                    <Route path="/findjob" component = {PRecommend} />
                    <Route path="/myclient" component = {MyClient} />                            
                    <Route path="/myschedule" component = {MySchedule} />
                    <Route path="/provider/myjob" component = {MyProviderJob} />
                    <Route path="/provider/search" component = {PSearch} />              
                    <Route path="/provider/searchresult" component = {PSearchResult} />                                  
                    <Route path="/provider/searcharea" component = {PSearchArea} />
                    <Route path="/provider/searcharearesult" component = {PSearchAreaResult} />
                    <Route path="/provider/immediate" component = {PSearchImmediateJob} />
                    <Route path="/pjobview" component = {PJobView} />                                  
                    <Route path="/provider/editprofile" component = {PEditProfile} />
                    <Route path="/provider/delete_profile" component = {PDeleteProfile} />                        
                    <Route path="/freepeople2" component = {People} />    
                    <Route path="/job_type" component = {JobType} />    
                    <Route component = {NotFoundPage} />                    
                </Switch>
            </Router>
        )
    }
}

