import { ActionType } from "typesafe-actions";
import { SearchArea } from "../searchprovider/types";
import * as actions from './actions';

export type SearchClient = {
    jb_keyword: string,
    is_date_search: string,
    jb_open_date: Date|null,
    jb_close_date: Date|null,
    jb_date: Date|null,
    jb_time: string,
    jb_total: string,
    sk_s: Array<string>,
    searchAreas:Array<SearchArea>,
    em_is_foreign: string,
    em_daily_price_low: string,
    em_daily_price_hi: string,
}
export type SearchClientState = SearchClient & {
    loading: boolean,
}
export enum actionTypes {
    SAVE = "searchclient/save",
}
export type SearchClientActions = ActionType<typeof actions>;