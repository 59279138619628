import React from 'react';
import '../../scss/job.scss';
import Header from '../../Include/Header'
import ProviderNavCom from '../../Include/ProviderNavCom';
import ClientProfileDetailCom from "./ClientProfileDetailCom";
import Footer from '../../Include/Footer';
import * as Global from '../../../modules/global';
import {Redirect} from 'react-router-dom';
import {JobDetailComProp} from '../../../modules/job/types';
import {MsgBrief} from '../../../modules/chat/types';
class ClientProfileView extends React.Component<any>{
    renderRedirect = () => {
        let role = Global.getRole();
        if( role !== "2" ){
            let url:string = '/choose';
            return <Redirect to = {url}/>
        }else{
            return <></>
        }
    }    
    public render(){
        const url = this.props.match.url;
        let id = this.props.match.params.id?this.props.match.params.id:"";
        let isRightMenuShow = this.props.match.params.isRightMenuShow?this.props.match.params.isRightMenuShow:"1";
        let jbaId = this.props.location && this.props.location.state && this.props.location.state.jbaId?this.props.location.state.jbaId:"";
        let jobId = this.props.location && this.props.location.state && this.props.location.state.jobId?this.props.location.state.jobId:"";
        let ctrId = this.props.location && this.props.location.state && this.props.location.state.ctrId?this.props.location.state.ctrId:'';
        let jba_is_direct = this.props.location && this.props.location.state && this.props.location.state.jba_is_direct?this.props.location.state.jba_is_direct:'';     
        let docId = ( this.props.location && this.props.location.state && this.props.location.state.docId )?this.props.location.state.docId:'';
        let from =  ( this.props.location && this.props.location.state && this.props.location.state.from )?this.props.location.state.from:'';
        let to =  ( this.props.location && this.props.location.state && this.props.location.state.to )?this.props.location.state.to:'';
        const jobStatus = this.props.location.state && this.props.location.state.jobStatus?this.props.location.state.jobStatus:'';
        const is_not_available =  ( this.props.location && this.props.location.state && this.props.location.state.is_not_available )?this.props.location.state.is_not_available.toString():'0';        
        let jobInfo:JobDetailComProp = {
            em_id:id,
            jobId:jobId,
            jbaId:jbaId,
            ctrId:ctrId,
            jba_is_direct:jba_is_direct,
        }
        let msgBrief:MsgBrief = {
            docId:docId,
            from:from,
            to:to,
            is_not_available:is_not_available,            
            jobStatus:jobStatus,
            jba_is_direct:jba_is_direct,
        }
        let className = "job profile";
        let mode = 'narrow';
        isRightMenuShow = '0';
        if( isRightMenuShow === '0' ){
            className = "job profile message";
            mode = 'wide';
        }
        return (
            <div>
                <Header/>
                {this.renderRedirect()}                
                <div className="content-container" id = "content">
                    <div className="wrap-content">
                        <div className="main-title">親方/会社のプロフィール</div>
                        <div className="job_container mt_40">
                            {
                                isRightMenuShow === '1'?
                                <ProviderNavCom to = {url} />
                                :<></>
                            }
                            <div className ={className}>
                                <ClientProfileDetailCom  jobInfo = {jobInfo} message = {msgBrief} mode = {mode}/>
                            </div>                            
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    } 
}
export default ClientProfileView; 