import React from 'react';
import '../scss/job.scss';
import '../scss/change_type.scss';
import Footer from '../Include/Footer';
import * as URLS from '../../modules/urls';
import { VALIDATORS, VALIDATION_MESSAGES, YEAR, MONTH, DAY } from '../../modules/global';
import SimpleReactValidator from 'simple-react-validator';
import { connect } from 'react-redux';
import { IRootState } from '../../modules';
import { Dispatch } from 'redux';
import * as asyncactions from '../../modules/createinfo/async-actions';
import { CreateInfo, CreateInfoActions } from '../../modules/createinfo';
import { AlertDialog } from '../Modal/Alert';
import Loading from '../Include/Loading';
import { Redirect } from 'react-router-dom';
import { getKey } from '../../modules/basic/types';
import { Link } from 'react-router-dom';

const mapStateToProps = ({ create }: IRootState) => {
    const { page, createInfo } = create;
    return { page, createInfo };
}
const mapDispatcherToProps = (dispatch: Dispatch<CreateInfoActions>) => {
    return {
        back: (page: number) => asyncactions.backAsync(dispatch, page),
        create: (createInfo: CreateInfo, page: number) => asyncactions.createAsync(dispatch, createInfo, page),
    }
}
type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;
class PhoneVerify extends React.Component<ReduxType>{
    validator: SimpleReactValidator;
    alertRef: any;
    state = {
        passcode: '',
        isSendSMSCode: false,
        loading: false,
        errorMessage: '',
        isRegisterSuccess: false,
        ema_id: '',
    }
    constructor(props: any) {
        super(props);
        this.validator = new SimpleReactValidator({
            messages: VALIDATION_MESSAGES,
            autoForceUpdate: this,
            className: 'error error_center',
            validators: VALIDATORS
        });
        this.alertRef = React.createRef();
    }
    saveToServer = () => {
        this.setState({
            loading: true,
        });
        const that = this;
        this.props.createInfo.ar_id = getKey(this.props.createInfo.ar_id) as string;
        this.props.createInfo.st_id = getKey(this.props.createInfo.st_id) as string;
        this.props.createInfo.sk_s = getKey(this.props.createInfo.sk_s) as any;
        let data = this.props.createInfo;
        data.ema_id = this.state.ema_id;
        data.ema_code = this.state.passcode;
        fetch(URLS.CREATE_ACCOUNT, {
            method: 'POST',
            body: JSON.stringify(data),
        }).then(async function (response) {
            const ret = await response.json();
            if (ret.success) {
                that.alertRef.current.open("登録完了", "ご登録ありがとうございます。ログインのうえご利用ください。", true);
                that.setState({
                    loading: false,
                });
            } else {
                const msg = ret.msg ? ret.msg : "サーバへ保存できませんでした。";
                that.alertRef.current.open("エラー", msg);
                that.setState({
                    loading: false,
                    isRegisterSuccess: false,
                });
            }

        }).catch(error => {
            this.alertRef.current.open("エラー", "サーバへ保存できませんでした。");
            this.setState({ loading: false, errorMessage: error.toString() });
        });
    }
    onHandleChange = (e: any) => {
        let value: string = e.target.value;
        let name: string = e.target.name;
        this.setState({ [name]: value });
    }
    onHandleBlur = (e: any) => {
        let name: string = e.target.name;
        this.validator.showMessageFor(name);
    }
    resendPassCode = () => {
        this.getSMSCode();
    }
    handleClick = () => {
        if (!this.state.isSendSMSCode) {
            this.getSMSCode();
        } else {
            let flag = this.validator.allValid();
            if (flag) {
                this.checkSMSCode();
            } else {
                this.validator.showMessages();
                this.forceUpdate();
            }
        }
    }
    checkSMSCode = () => {
        if (!this.props.createInfo || this.props.createInfo.em_phone == "") {
            return;
        }
        this.setState({
            loading: true,
        });
        const data = {
            ema_id: this.state.ema_id,
            ema_code: this.state.passcode,
        }
        const that = this;

        fetch(URLS.CHECK_SMS_CODE, {
            method: 'post',
            body: JSON.stringify(data),
        }).then(async function (response) {
            let ret = await response.json();
            if (ret.success) {
                that.saveToServer();
            } else {
                that.alertRef.current.open('エラー', 'パスコードが間違っています。');
                that.setState({
                    loading: false,
                });
            }
            //return response.json();
        }).catch(error => {
        });
    }
    getSMSCode = () => {
        if (!this.props.createInfo || this.props.createInfo.em_phone == "") {
            return;
        }
        const data = {
            ema_phone: this.props.createInfo.em_phone,
        }
        const that = this;
        this.setState({
            loading: true,
        })
        fetch(URLS.GET_SMS_CODE, {
            method: 'post',
            body: JSON.stringify(data),
        }).then(async function (response) {
            let ret = await response.json();
            if (ret.success) {
                that.setState({
                    loading: false,
                    ema_id: ret.data.ema_id ? ret.data.ema_id : '',
                    isSendSMSCode: true,
                });
            } else {
                that.alertRef.current.open('エラー', 'パスコードを送信することができませんでした。');
                that.setState({
                    loading: false,
                });
            }
            //return response.json();
        }).catch(error => {
            that.alertRef.current.open('エラー', 'パスコードを送信することができませんでした。');
            that.setState({
                loading: false,
            });
        });
    }
    renderRedirect = () => {
        if (this.state.isRegisterSuccess) {
            let url = '/';
            return <Redirect to={url} />
        }
        if (this.props.page <= 0) {
            let url = '/create';
            return <Redirect to={url} />
        } else if (this.props.page == 2) {
            let url = '/phoneverify';
            return <Redirect to={url} />
        }
        return <></>
    }
    public render() {
        return (
            <>
                {this.renderRedirect()}
                {
                    this.state.loading ?
                        <Loading />
                        : <></>
                }
                <div>
                    {/* <Header/> */}
                    <div className="content-container" id="content">
                        <div className="wrap-content">
                            <div className="main-title">新規登録</div>
                            <div className="job_container mt_40">
                                <AlertDialog ref={this.alertRef} returnURL={"/"} />
                                <div className="change_type space">
                                    <div className="title f-meiryo-bold mb_20">電話番号認証</div>
                                    <div className="change_type_inner">
                                        <div>
                                            <div className="fs16">
                                                登録いただきました携帯番号に認証コードをお送りいたします。
                                        </div>
                                        </div>
                                    </div>
                                    <div className="button-container">
                                        <div className="fs14 mt_50 error error_center"><span className="">携帯電話番号：</span>{this.props.createInfo.em_phone}</div>
                                        {
                                            this.state.isSendSMSCode ?
                                                <input type="text" name="passcode" className="input-txt border l-center large  mt_20" placeholder="パスコードを入力してください" value={this.state.passcode} onChange={this.onHandleChange} onBlur={this.onHandleBlur} />
                                                : <></>
                                        }
                                        {this.validator.message('passcode', this.state.passcode, 'required')}
                                        <div className="grey-button large mt_30">
                                            <div className="inner" onClick={this.handleClick}>
                                                {!this.state.isSendSMSCode ? "パスコードを送信する" : "認証する"}
                                                <i className="fa fa-angle-right"></i>
                                            </div>
                                        </div>
                                        {
                                            this.state.isSendSMSCode ?
                                                <div className="grey-button yellow large_300 rb mt_30">
                                                    <div className="inner" onClick={this.resendPassCode}>
                                                        <i className="fa fa-mobile"></i>
                                            パスコードを再送する
                                        </div>
                                                </div> :
                                                <></>
                                        }
                                        <Link to={"/create"}><div className="underline"><span className="fs14" style={{ cursor: "pointer" }}>&lt;内容を修正する</span></div></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </>
        );
    }
}
export default connect(mapStateToProps, mapDispatcherToProps)(PhoneVerify);
