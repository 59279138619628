import React from "react";
import "../scss/job.scss";
import "../scss/jobedit.scss";
import { BasicDropDown } from "../Include/dropdown/BasicDropDown";
import Loading from "../Include/Loading";
import { AlertDialog } from "../Modal/Alert";
import * as URLS from "../../modules/urls";
import * as Global from "../../modules/global";
import SimpleReactValidator from "simple-react-validator";
import { JobDetailComProp } from "../../modules/job/types";
import BasicCheckBox from "../Include/checkbox/BasicCheckBox";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ja from "date-fns/locale/ja";
import moment from "moment";

class JobEditCom extends React.Component<JobDetailComProp> {
  alertRef: any;
  areaRef: any;
  unmounted: boolean = false;
  validator: SimpleReactValidator;
  state = {
    em_id: -1,
    loading: false,
    jb_id: "",
    jb_comp_name: "",
    jb_comp_overview: "",
    jb_place_post: "",
    sk_s: new Array<string>(),
    st_id: "0",
    ar_id: "0",
    jb_street_address: "",
    jb_open_date: "",
    rj_jb_open_date: null,
    jb_close_date: "",
    rj_jb_close_date: null,
    jb_time_from: "",
    jb_time_to: "",
    rj_jb_time_from: "",
    rj_jb_time_to: "",
    jb_total: "",
    isPriceRangeOK: true,
    jb_daily_price_low: "",
    jb_daily_price_hi: "",
    jb_note: "",
    jb_auto_finish: "0",
  };
  constructor(props: any) {
    super(props);
    registerLocale("ja", ja);
    this.validator = new SimpleReactValidator({
      messages: Global.VALIDATION_MESSAGES,
      autoForceUpdate: this,
      className: "error",
      validators:Global.VALIDATORS,
    });
    this.alertRef = React.createRef();
    this.areaRef = React.createRef();
  }
  onHandleChange = (e: any) => {
    let value: string = e.target.value;
    let name: string = e.target.name;
    this.setState({ [name]: value });
    if (name === "st_id") {
      this.setState({
        ar_id: "",
      });
      if (this.areaRef) {
        this.areaRef.current.fetch(URLS.GET_AREA_URL + "&st_id=" + value);
      }
    } else if (name === "jb_auto_finish") {
      const target = e.target;
      const checked = target.type === "checkbox" ? target.checked : "";
      if (!checked) {
        this.setState({
          jb_auto_finish: "0",
        });
      } else {
        this.setState({
          jb_auto_finish: "1",
        });
      }
    } else if (name === "sk_s[]") {
      const target = e.target;
      const checked = target.type === "checkbox" ? target.checked : "";
      if (!checked) {
        const sk_s = this.state.sk_s;
        const index = sk_s.indexOf(value);
        if (index > -1) {
          let business: Array<any> = sk_s.slice(0, index);
          business = business.concat(sk_s.slice(index + 1, sk_s.length));
          this.setState({
            sk_s: business,
          });
        }
      } else {
        const val = [value];
        const sk_s = this.state.sk_s;
        const business = val.concat(sk_s);
        this.setState({
          sk_s: business,
        });
      }
      this.validator.showMessageFor(name);
    }
  };
  handleOpenDateChange = (data: Date) => {
    this.setState({
      rj_jb_open_date: data,
    });
  };
  handleCloseDateChange = (data: Date) => {
    this.setState({
      rj_jb_close_date: data,
    });
  };
  componentWillUnmount() {
    this.unmounted = false;
  }
  componentDidMount() {
    this.unmounted = true;
    let id = this.props.jobId;
    if (id == null || id === "-1") {
      this.setState({
        sk_s: Global.getSkills(Global.getSkillString()!),
      });
      return;
    }
    const that = this;
    that.setState({
      loading: true,
    });
    fetch(URLS.READ_DETAIL_JOB + "&jb_id=" + id, {
      method: "get",
    })
      .then(async function (response) {
        if (!that.unmounted) {
          return;
        }
        let ret = await response.json();
        if (ret.total > 0) {
          const item = ret.items[0];
          let skills = Global.getSkills(item.sk_s);
          that.setState({
            loading: false,
            jb_id: item.jb_id,
            jb_comp_name: item.jb_comp_name,
            jb_comp_overview: item.jb_comp_overview,
            sk_s: item.sk_s ? skills : [],
            jb_place_post: item.jb_place_post,
            st_id: item.st_id ? item.st_id : "",
            st_label: item.st_label ? item.st_label : "",
            ar_id: item.ar_id ? item.ar_id : "",
            ar_label: item.ar_label ? item.ar_label : "",
            jb_street_address: item.jb_street_address
              ? item.jb_street_address
              : "",
            jb_open_date: Global.DateToString(item.jb_open_date, "/", "m/d/y"),
            jb_close_date: Global.DateToString(
              item.jb_close_date,
              "/",
              "m/d/y"
            ),
            rj_jb_open_date: item.jb_open_date
              ? Global.stringToDate(
                  Global.DateToString(item.jb_open_date, "/", "m/d/y", false)
                )
              : null,
            rj_jb_close_date: item.jb_open_date
              ? Global.stringToDate(
                  Global.DateToString(item.jb_close_date, "/", "m/d/y", false)
                )
              : null,
            jb_time_from: item.jb_time_from ? item.jb_time_from : "",
            jb_time_to: item.jb_time_to ? item.jb_time_to : "",
            rj_jb_time_from: item.jb_time_from ? item.jb_time_from : "",
            rj_jb_time_to: item.jb_time_to ? item.jb_time_to : "",

            jb_auto_finish: item.jb_auto_finish ? item.jb_auto_finish : "0",
            jb_total: item.jb_total ? item.jb_total : "",
            jb_daily_price_low: item.jb_daily_price_low
              ? Global.numberWithCommas(
                  parseInt(item.jb_daily_price_low).toString()
                )
              : "",
            jb_daily_price_hi: item.jb_daily_price_hi
              ? Global.numberWithCommas(
                  parseInt(item.jb_daily_price_hi).toString()
                )
              : "",
            jb_note: item.jb_note ? item.jb_note : "",
            jb_created: item.jb_created,
            isPriceRangeOK: that.priceRangeCompareWith( item.jb_daily_price_low, item.jb_daily_price_hi ),
          });
          if (that.areaRef) {
            that.areaRef.current.fetch(
              URLS.GET_AREA_URL + "&st_id=" + (item.st_id ? item.st_id : "0")
            );
          }
        } else {
          that.setState({
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false, errorMessage: error.toString() });
      });
  }
  onHandleBlur = (e: any) => {
    let name: string = e.target.name;
    const value: string = e.target.value;
    if (name === "jb_daily_price_low") {
      if (
        Number.isInteger(parseInt(value.trim())) &&
        Number.isInteger(parseInt(this.state.jb_daily_price_hi)) &&
        Number.isInteger(parseInt(value.trim())) &&
        Number.isInteger(parseInt(this.state.jb_daily_price_low))        
      ) {
        this.onCheckPriceRange();
      } else {
        this.setState({ isPriceRangeOK: true }, () =>{
          this.validator.showMessageFor(name);          
        });        
      }
    } else if (name === "jb_daily_price_hi") {
      if (
        Number.isInteger(parseInt(value.trim())) &&
        Number.isInteger(parseInt(this.state.jb_daily_price_low)) &&
        Number.isInteger(parseInt(value.trim())) &&
        Number.isInteger(parseInt(this.state.jb_daily_price_low))        
      ) {
        this.onCheckPriceRange();
      } else {
        this.setState({ isPriceRangeOK: true }, () =>{
          this.validator.showMessageFor(name);          
        });        
      }
    } else {
      this.validator.showMessageFor(name);
    }
  };

  priceRangeCompareWith = ( lPrice: string, hPrice: string ) => {
    const lowPrice: number = Global.moneyFormatToFloat(
      lPrice
    );
    const highPrice: number = Global.moneyFormatToFloat(
      hPrice
    );
    if (lowPrice > highPrice) {
      return false;
    }
    return true;
  }
  onCheckPriceRange = () => {
    const isRange = this.priceRangeCompareWith( this.state.jb_daily_price_low, this.state.jb_daily_price_hi );
    this.setState({ isPriceRangeOK: isRange });
  };
  onUpdate = (e: any) => {
    
    if (this.validator.allValid()) {

      //        if (true){
      if (this.state.rj_jb_open_date && this.state.rj_jb_close_date) {
        let from_date_str: string = moment(this.state.rj_jb_open_date!).format(
          "YYYY/MM/DD"
        );
        if (this.state.rj_jb_time_from !== "") {
          from_date_str =
            from_date_str +
            " " +
            moment(
              moment().format("YYYY-MM-DD ") + this.state.rj_jb_time_from
            ).format("HH:mm");
        }
        let to_date_str: string = moment(this.state.rj_jb_close_date!).format(
          "YYYY/MM/DD"
        );
        if (this.state.rj_jb_time_to !== "") {
          to_date_str =
            to_date_str +
            " " +
            moment(
              moment().format("YYYY-MM-DD ") + this.state.rj_jb_time_to
            ).format("HH:mm");
        }
        const from_date: Date = moment(from_date_str).toDate();
        const to_date: Date = moment(to_date_str).toDate();
        if (to_date < moment().toDate()) {
          this.alertRef.current.open(
            "エラー",
            "過去の仕事は登録することができません。"
          );
          return;
        }
        if (from_date.getTime() > to_date.getTime()) {
          this.alertRef.current.open(
            "エラー",
            "開始日は終了日より先になります。"
          );
          return;
        }

        if (
          this.state.rj_jb_time_from !== "" &&
          this.state.rj_jb_time_to !== ""
        ) {
          const from_time = moment(
            moment().format("YYYY-MM-DD ") + this.state.rj_jb_time_from
          )
            .toDate()
            .getTime();
          const to_time = moment(
            moment().format("YYYY-MM-DD ") + this.state.rj_jb_time_to
          )
            .toDate()
            .getTime();
          if (from_time > to_time) {
            this.alertRef.current.open(
              "エラー",
              "開始時間は終了時間より先になります。"
            );
            return;
          }
        }
      }

      // if (this.state.rj_jb_open_date && this.state.rj_jb_close_date && (this.state.rj_jb_open_date! > this.state.rj_jb_close_date!)) {
      //     this.alertRef.current.open("エラー", "開始日は終了日より先になります。");
      //     return;
      // }
      this.setState({
        loading: true,
      });
      const that = this;
      const stateData = eval("(" + JSON.stringify(this.state) + ")");
      let em_id: any = Global.getEmId();
      stateData.em_id = em_id;
      stateData.jb_open_date = Global.dateFormat(
        this.state.rj_jb_open_date,
        "%m/%d/%Y",
        false
      );
      stateData.jb_close_date = Global.dateFormat(
        this.state.rj_jb_close_date,
        "%m/%d/%Y",
        false
      );
      if (stateData.rj_jb_time_from === "") {
        stateData.jb_time_from = null;
      } else {
        stateData.jb_time_from = Global.timeFormat(stateData.rj_jb_time_from);
      }
      if (stateData.rj_jb_time_to === "") {
        stateData.jb_time_to = null;
      } else {
        stateData.jb_time_to = Global.timeFormat(stateData.rj_jb_time_to);
      }
      stateData.jb_daily_price_low = Global.moneyFormatToFloat(
        this.state.jb_daily_price_low
      );
      stateData.jb_daily_price_hi = Global.moneyFormatToFloat(
        this.state.jb_daily_price_hi
      );
      let url = URLS.EDIT_JOB;
      let data = {};

      if (
        !this.props.jobId ||
        this.props.jobId === "" ||
        this.props.jobId === "-1"
      ) {
        url = URLS.CREATE_JOB;
        data = stateData;
      } else {
        stateData.jb_id = this.props.jobId;
        data = {
          update: [stateData],
        };
      }
      fetch(url, {
        method: "post",
        body: JSON.stringify(data),
      })
        .then(async function (response) {
          let ret = await response.json();
          if (ret.success) {
            that.alertRef.current.open("成功", "登録されました。", true);
            that.setState({
              loading: false,
            });
          } else {
            that.alertRef.current.open("エラー", "登録できませんでした。");
            that.setState({
              loading: false,
            });
          }
          //return response.json();
        })
        .catch((error) => {
          this.setState({ loading: false, errorMessage: error.toString() });
          if (this.alertRef.current)
            this.alertRef.current.open("エラー", "登録できませんでした。");
        });
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  };
  public render() {
    return (
      <div>
        {this.state.loading ? <Loading /> : <></>}
        <div className="edit_project_inner">
          <AlertDialog ref={this.alertRef} returnURL="/jobview" />
          <div className="title">
            {this.props.jobId != null ? this.state.jb_comp_name : ""}
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_100">
                <span className="tag-mg fs14">
                  <span className="tag-inner">工事名称</span>
                </span>
              </div>
              <div className="wd_250">
                <input
                  type="text"
                  placeholder="登戸プロジェクト新築工事"
                  name="jb_comp_name"
                  value={this.state.jb_comp_name}
                  onChange={this.onHandleChange}
                  onBlur={this.onHandleBlur}
                />
                {this.validator.message(
                  "jb_comp_name",
                  this.state.jb_comp_name,
                  "required"
                )}
              </div>
            </div>
            <span className="error_show">必須</span>
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_100">
                <span className="tag-mg fs14">
                  <span className="tag-inner">工事内容</span>
                </span>
              </div>
              <div className="wd_450">
                <textarea
                  className="full_wide ht_60"
                  placeholder="ガラス搬入、ガラス取り付け（大板）、ミラー貼り付け"
                  name="jb_comp_overview"
                  value={this.state.jb_comp_overview}
                  onChange={this.onHandleChange}
                  onBlur={this.onHandleBlur}
                >
                  {this.state.jb_comp_overview}
                </textarea>
                {this.validator.message(
                  "jb_comp_overview",
                  this.state.jb_comp_overview,
                  "required"
                )}
              </div>
            </div>
            <span className="error_show">必須</span>
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_100">
                <span className="tag-mg fs14">
                  <span className="tag-inner">業種</span>
                </span>
              </div>
              <div className="wd_450">
                <BasicCheckBox
                  className="full_wide"
                  name="sk_s[]"
                  idKey={"sk_id"}
                  valueKey={"sk_label"}
                  url={URLS.GET_SKILL}
                  checkedValues={this.state.sk_s}
                  onHandleChange={this.onHandleChange}
                  onHandleBlur={this.onHandleBlur}
                  noPrefix={true}
                />
              </div>
            </div>
            <span className="error_show">必須</span>
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_100">
                <span className="tag-mg fs14">
                  <span className="tag-inner">現場住所</span>
                </span>
              </div>
              <div>
                <div>
                  <span className="wd_65 fs14 text-right mr_5 inline_block top">
                    郵便番号
                  </span>
                  <div className="wd_145 inline_block top mr_5">
                    <input
                      type="text"
                      className="full_wide"
                      name="jb_place_post"
                      value={this.state.jb_place_post}
                      onChange={this.onHandleChange}
                      onBlur={this.onHandleBlur}
                    />
                    {this.validator.message(
                      "jb_place_post",
                      this.state.jb_place_post,
                      "postcode"
                    )}
                  </div>
                  <span className="error_show notify_show">任意</span>
                </div>
                <div className="mt_10">
                  <span className="wd_65 fs14 text-right mr_5 inline_block">
                    住所
                  </span>
                  <div className="wd_125 inline_block mr_5">
                    <BasicDropDown
                      className="full_wide"
                      name="st_id"
                      idKey={"st_id"}
                      valueKey={"st_label"}
                      url={URLS.GET_PREFECTURE_URL}
                      checkedValue={this.state.st_id}
                      defaultName="都道府県"
                      onHandleChange={this.onHandleChange}
                      onHandleBlur={this.onHandleBlur}
                      noPrefix={true}
                    />
                    {this.validator.message(
                      "st_id",
                      this.state.st_id,
                      "select"
                    )}
                  </div>
                </div>
                <div className="mt_10">
                  <span className="wd_65 fs14 text-right mr_5 inline_block"></span>
                  <div className="wd_280 inline_block">
                    <BasicDropDown
                      ref={this.areaRef}
                      className="full_wide"
                      name="ar_id"
                      idKey={"ar_id"}
                      valueKey={"ar_label"}
                      url={URLS.GET_AREA_URL + "&st_id=" + this.state.st_id}
                      checkedValue={this.state.ar_id}
                      defaultName="エリア"
                      onHandleChange={this.onHandleChange}
                      onHandleBlur={this.onHandleBlur}
                      noPrefix={true}
                    />
                    {this.validator.message(
                      "ar_id",
                      this.state.ar_id,
                      "select"
                    )}
                  </div>
                </div>
                <div className="mt_10">
                  <span className="wd_65 fs14 text-right mr_5 inline_block"></span>
                  <div className="wd_280 inline_block mr_5">
                    <input
                      type="text"
                      name="jb_street_address"
                      className="full_wide"
                      placeholder="番地"
                      value={this.state.jb_street_address}
                      onChange={this.onHandleChange}
                      onBlur={this.onHandleBlur}
                    />
                  </div>
                  <span className="error_show notify_show">任意</span>
                </div>
              </div>
            </div>
            <span className="error_show">必須</span>
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_100">
                <span className="tag-mg fs14">
                  <span className="tag-inner">募集日時</span>
                </span>
              </div>
              <div>
                <div>
                  <span className="wd_65 fs14 text-right mr_5 inline_block top">
                    開始日
                  </span>
                  <div className="inline_block top">
                    <DatePicker
                      dateFormat="yyyy/MM/dd"
                      selected={this.state.rj_jb_open_date}
                      onChange={this.handleOpenDateChange}
                      locale="ja"
                    />
                    {this.validator.message(
                      "rj_jb_open_date",
                      this.state.rj_jb_open_date,
                      "required"
                    )}
                    {/* {this.validator.message('open_month', this.state.open_month, 'select' ) }
                                    {this.validator.message('open_day', this.state.open_day, 'select' ) }   */}
                  </div>
                </div>
                <div className="mt_10">
                  <span className="wd_65 fs14 text-right mr_5 inline_block top">
                    終了日
                  </span>
                  <div className="inline_block top">
                    <DatePicker
                      dateFormat="yyyy/MM/dd"
                      selected={this.state.rj_jb_close_date}
                      onChange={this.handleCloseDateChange}
                      locale="ja"
                    />

                    {this.validator.message(
                      "rj_jb_close_date",
                      this.state.rj_jb_close_date,
                      "required"
                    )}
                    {/* {this.validator.message('close_month', this.state.close_month, 'select' ) }
                                    {this.validator.message('close_day', this.state.close_day, 'select' ) }    */}
                  </div>
                </div>
                <div className="mt_10">
                  <span className="wd_65 fs14 text-right mr_5 inline_block">
                    時間
                  </span>
                  <div className="inline_block top">
                    <div className="wd_90 mr_5 inline_block top">
                      <input
                        className="full_wide mr_5"
                        type="text"
                        name="rj_jb_time_from"
                        placeholder="8:30"
                        value={this.state.rj_jb_time_from}
                        onChange={this.onHandleChange}
                        onBlur={this.onHandleBlur}
                      />
                    </div>
                    <span className="fs14 mr_5">～</span>
                    <div className="wd_90 mr_5 inline_block top">
                      <input
                        className="full_wide mr_5"
                        type="text"
                        name="rj_jb_time_to"
                        placeholder="19:30"
                        value={this.state.rj_jb_time_to}
                        onChange={this.onHandleChange}
                        onBlur={this.onHandleBlur}
                      />
                    </div>
                    {this.validator.message(
                      "rj_jb_time_from",
                      this.state.rj_jb_time_from,
                      "time"
                    )}
                    {this.validator.message(
                      "rj_jb_time_to",
                      this.state.rj_jb_time_to,
                      "time"
                    )}
                  </div>
                  <span className="error_show notify_show">任意</span>
                </div>
              </div>
            </div>
            <span className="error_show">必須</span>
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_125 inline_block top">
                <span className="tag-mg fs14">
                  <span className="tag-inner">募集人数</span>
                </span>
              </div>
              <div className="inline_block top">
                <div className="wd_85 inline_block top">
                  <input
                    type="text"
                    name="jb_total"
                    value={this.state.jb_total}
                    onChange={this.onHandleChange}
                    onBlur={this.onHandleBlur}
                  />
                </div>
                <span className="fs14 inline_block top">人</span>
                {this.validator.message(
                  "jb_total",
                  this.state.jb_total,
                  "required|numeric|min:1,num"
                )}
              </div>
            </div>
            <span className="error_show">必須</span>
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_125">
                <span className="tag-mg fs14">
                  <span className="tag-inner">予定金額</span>
                </span>
              </div>
              <div className="inline_block top">
                <div className="wd_65 inline_block top mr_5">
                  <input
                    type="text"
                    className="full_wide"
                    name="jb_daily_price_low"
                    value={this.state.jb_daily_price_low}
                    onChange={this.onHandleChange}
                    onBlur={this.onHandleBlur}
                  />
                </div>
                <span className="fs14 inline_block top mr_5">～</span>
                <div className="wd_65 inline_block top mr_5">
                  <input
                    type="text"
                    className="full_wide"
                    name="jb_daily_price_hi"
                    value={this.state.jb_daily_price_hi}
                    onChange={this.onHandleChange}
                    onBlur={this.onHandleBlur}
                  />
                </div>
                <span className="fs14 inline_block top">円/人工</span>
                {this.validator.message(
                  "jb_daily_price_low",
                  this.state.jb_daily_price_low,
                  "required|money"
                )}
                {this.validator.message(
                  "jb_daily_price_hi",
                  this.state.jb_daily_price_hi,
                  "required|money"
                )}
                {this.validator.message(
                  "jb_daily_price_low",
                  this.state.isPriceRangeOK,
                  "compareWith"
                )}
              </div>
            </div>
            <span className="error_show">必須</span>
          </div>
          <div className="element">
            <div className="second_column">
              <div className="wd_100">
                <span className="tag-mg fs14">
                  <span className="tag-inner">備考</span>
                </span>
              </div>
              <div className="wd_450">
                <textarea
                  className="full_wide ht_60"
                  placeholder="・近郊からの交通費実費支給（2,000円程度まで）"
                  name="jb_note"
                  value={this.state.jb_note}
                  onChange={this.onHandleChange}
                >
                  {this.state.jb_note}
                </textarea>
              </div>
            </div>
            <span className="error_show notify_show">任意</span>
          </div>
          <div className="button-container mt_30">
            <label className="control-checkbox">
              <input
                type="checkbox"
                name="jb_auto_finish"
                value={this.state.jb_auto_finish}
                onChange={this.onHandleChange}
                checked={this.state.jb_auto_finish === "1" ? true : false}
              />
              <span className="control__indicator"></span>
              応募者が決定したので募集を終了する
            </label>
            <div className="grey-button yellow large_300 rb mt_20">
              <div className="inner" onClick={this.onUpdate}>
                <i className="fa fa-pencil"></i>
                {this.props.jobId != null
                  ? "仕事内容を編集"
                  : "仕事内容を登録"}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default JobEditCom;
