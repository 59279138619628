import React, { Component } from 'react';
import '../scss/job.scss';
import '../scss/recommend.scss';
import '../scss/search.scss';
import Header from '../Include/Header'
import SearchNavCom from './SearchNavCom';
//import FreePeopleCom from '../Find/FreePeopleCom';
import { Link, withRouter } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import * as Global from '../../modules/global';
import * as URLS from '../../modules/urls';
import Loading from '../Include/Loading';
import Footer from '../Include/Footer';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ja from "date-fns/locale/ja";
import { BasicDropDown } from '../Include/dropdown/BasicDropDown';
import moment from 'moment';

import { connect } from 'react-redux';
import { compose, Dispatch } from 'redux';
import { IRootState } from '../../modules';

import * as asyncactions from '../../modules/searchimmediatepeople/async-actions';
import { SearchImmediatePeople, SearchImmediatePeopleState, SearchImmediatePeopleActions } from '../../modules/searchimmediatepeople';
import * as footerAsyncactions from '../../modules/footer/async-actions';
import { FooterActions } from '../../modules/footer';

const mapStateToProps = ({ searchImmediatePeople }: IRootState) => {
    return { searchImmediatePeople };
};
const mapDispatcherToProps = (dispatch: Dispatch<SearchImmediatePeopleActions|FooterActions>) => {
    return {
        save: (searchImmediatePeople: SearchImmediatePeople) => asyncactions.save(dispatch, searchImmediatePeople),
        isRerender:( _isRerender: boolean ) => footerAsyncactions.isRerender( dispatch, _isRerender ),                     
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatcherToProps> & History &any;


class FreePeople extends Component<ReduxType, SearchImmediatePeopleState> {
    validator: SimpleReactValidator;
    state = {
        loading: false,
        jb_date: moment().format('MM/DD/YYYY'),
        jb_time: '0',
        items: [],
    }
    constructor(props: any) {
        super(props);
        registerLocale("ja", ja);
        this.validator = new SimpleReactValidator({
            messages: Global.VALIDATION_MESSAGES,
            autoForceUpdate: this,
            className: 'error',
            validators: Global.VALIDATORS
        });
    }
    onHandleChange = (e: any ) => {
        let value: string = e.target.value;
        let name: string = e.target.name;
        
        // this.setState({ [name]: value });

        this.setState(prevState => ({
            ...prevState,
            [name]: value // No error here, but can't ensure that key is in StateKeys
          }));        
    }
    onHandleBlur = (e: any) => {
        let name: string = e.target.name;
        this.validator.showMessageFor(name);
    }
    componentDidMount() {
        const searchImmediatePeople: SearchImmediatePeopleState = this.props.searchImmediatePeople;
        this.setState({
            jb_date: searchImmediatePeople.jb_date,
            jb_time: searchImmediatePeople.jb_time,
        });
        if ( searchImmediatePeople.jb_date && searchImmediatePeople.jb_date !== '0') {
            this.search();
        }

    }
    onSearch = (e: any) => {
        if (this.validator.allValid()) {
            this.props.save(this.state);
            this.search();
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    search = () => {
        let em_id: any = Global.getEmId();
        if (em_id === '') {
            return;
        }
        const that = this;
        this.setState({
            loading: true,
        })
        let url = URLS.SEARCH_PROVIDER_PERIOD + "&ef_enable=1";
        let params = "";
        if (this.state.jb_date !== '0') {
            params += "&ef_date_from=" + this.state.jb_date;
            params += "&ef_date_to=" + this.state.jb_date;
        }
        if (this.state.jb_time !== "0") {
            const value = parseInt(this.state.jb_time);
            const free_element = Global.DATE_DEVIDE_OPTIONS.filter(item => item.grp_ft_id === value);
            if (free_element && free_element.length > 0) {
                params += "&ft_id_s=" + free_element[0].grp_ft_value;
            }
        }
        params += '&sort=[{"property":"em_id","direction":"desc"}';
        url = url + params + "&em_system=0&em_id_not=" + em_id;
        
        fetch(url, {
            method: 'get',
        }).then(async function (response) {
            let ret = await response.json();
            if (ret.total > 0) {
                const items = ret.items;
                that.setState({
                    loading: false,
                    items: items,
                }, async() => {
                    await that.props.isRerender( true );
                });
            } else {
                that.setState({
                    loading: false,
                    items: [],
                }, async() => {
                    await that.props.isRerender( true );
                });
            }
        }).catch(error => {
            this.setState({ loading: false, items: [], }, async() => {
                await that.props.isRerender( true );
            });
        });
    }
    renderSkill(skillStr: string) {
        if (!skillStr) {
            return [];
        }
        const skills: string[] = skillStr.split(',');
        let ret = [] as  any;;
        for (let i = 0; i < skills.length; i++) {
            ret.push(<span className="tag-mg fs14" key={i}><span className="tag-inner">{skills[i]}</span></span>)
        }
        return ret;
    }
    public render() {
        const url = this.props.match.url;
        return (
            <div>
                <Header />
                <div className="content-container" id="content">
                    {this.state.loading ?
                        <Loading />
                        : <></>
                    }
                    <div className="wrap-content">
                        <div className="main-title">職人を探す</div>
                        <div className="job_container mt_40">
                            <SearchNavCom to={url} />
                            <div className="recommend job find search">
                                <div className="s_element mt_20 border">
                                    <div className="mr_5 mt_5"><span className="tag-mg fs14"><span className="tag-inner">日時</span></span></div>
                                    <div>
                                        <div className="second_column top mr_20">
                                            <div className="wd_145 inline_block mr_5">
                                                <BasicDropDown
                                                    className="full_wide" name="jb_date" idKey={"jb_date"} valueKey={"jb_date_name"} url={''} data={Global.NEAR_DATE_OPTIONS} checkedValue={this.state.jb_date !== '0' ? this.state.jb_date : '0'} defaultName="日付"
                                                    onHandleChange={this.onHandleChange} onHandleBlur={this.onHandleBlur} noPrefix={true} notNeedDefaultOption = {true} />
                                                {/* <input type = "text" className="ht_30 full_wide" placeholder="2020/6/10"  name = "ef_datetime_between_date" value = {this.state.ef_datetime_between_date} onChange={this.onHandleChange} onBlur={this.onHandleBlur}/>
                                            {this.validator.message('ef_datetime_between_from_time', this.state.ef_datetime_between_date, 'date' ) }                                             */}
                                            </div>
                                            <div className="inline_block mt_5">
                                                <div className="wd_160 inline_block mr_5">
                                                    <BasicDropDown
                                                        className="full_wide" name="jb_time" idKey={"grp_ft_id"} valueKey={"grp_ft_name"} url={''} data={Global.DATE_DEVIDE_OPTIONS} checkedValue={this.state.jb_time !== undefined ? this.state.jb_time : '0'} defaultName="時間"
                                                        onHandleChange={this.onHandleChange} onHandleBlur={this.onHandleBlur} noPrefix={true} />
                                                </div>
                                            </div>
                                        </div>
                                        {this.validator.message('jb_date', this.state.jb_date, 'required')}                                        
                                    </div>
                                    <div className="btn-container">
                                        <div className="grey-button v-small s_pd_t" onClick={this.onSearch}>
                                            <div className="inner font_14 fa search">検索する</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="job_inner recommend-inner">
                                    {
                                        this.state.items.length === 0 ? <span className="no_content mt_20">条件に一致する職人がいません。</span> : <></>
                                    }
                                    {
                                        this.state.items.map(item => (
                                            <div className="element flex" key={item['em_id']}>
                                                <div className="flex_start">
                                                    <div className="first">
                                                        <img alt='' src={URLS.IMAGE_URL + item['em_photo_s']} />
                                                    </div>
                                                    <div className="second">
                                                        <div className="fs14">{item["em_is_individual"] === '1' ? '個人事業主' : item['em_company']}</div>
                                                        <div className="fs20">{(item['em_lastname'] ? item['em_lastname'] : '') + (item['em_firstname'] ? item['em_firstname'] : '')}</div>
                                                    </div>
                                                    <div className="third">
                                                        <div className="fs14 map-icon">{item['st_label']}</div>
                                                        <div>
                                                            {this.renderSkill(item["sk_label_s"])}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="btn-container">
                                                    <div className="grey-button v-small s_pd_t">
                                                        <Link to={"/message/" + item['em_id']}>
                                                            <div className="inner">
                                                                メッセージ送信
                                                    </div>
                                                        </Link>
                                                    </div>
                                                </div> */}
                                                <div className="btn-container">
                                                    <div className="grey-button v-small">
                                                        <Link to={{ pathname: "/provider/profile/" + item['em_id'], state: { isRightMenuShow: '0' } }}>
                                                            <div className="inner f-meiryo-bold">
                                                                詳細
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        );
    }
}
export default connect(mapStateToProps, mapDispatcherToProps)(FreePeople);

