import React, {Component} from 'react';
import '../scss/recommend.scss';
import * as URLS from '../../modules/urls';
import Loading from '../Include/Loading';
import * as Global from '../../modules/global';

export default class Advertise extends Component<{}>{
    state = {
        loading:false,
        items:[]
    }

    componentDidMount(){
        const that = this;
        this.setState({
            loading:true,
        })
        const sort = [{"property":"ad_sort","direction":"desc"}];      
        const url = URLS.READ_ADS + "&sort=" + JSON.stringify(sort);
        fetch( url, {
            method: 'get',
        }).then(async function(response) {
            let ret = await response.json();
            if( ret.total > 0 ){
                const items = ret.items;
                that.setState({
                    loading:false,
                    items:items,
                });
            }else{
                that.setState({
                    loading:false,
                    items:[],
                });
            }
        }).catch(error => {
            this.setState({ loading:false, items:[], errorMessage: error.toString() });
        });           
    }
    renderElement = () => {
        let ret = [];
        if( !this.state.items.length ){
            return <></>
        }
        for( let i = 0; i < this.state.items.length; i=i+2 ){
            let item_l =  this.state.items[i];
            let item_r = (i+1 < this.state.items.length)?this.state.items[i+1]:null;
            let element_l = <div className="item mr_20"><a href = {item_l['ad_url']} target = "_blank"><img src = { URLS.IMAGE_URL + item_l['ad_photo']} style = {{ width:294, height:138, objectFit:'cover'}}/></a></div>;
            let element_r = item_r?<div className="item mr_20"><a href = {item_r['ad_url']} target = "_blank"><img src = { URLS.IMAGE_URL + item_r['ad_photo']} style = {{width:294, height:138, objectFit:'cover'}}/></a></div>:<></>;
            let element = 
                    <div className="lt-hoz-eq mb_50" key = {item_l['ad_id']}>
                        <div className="con">
                            {element_l}
                            {element_r}
                        </div>
                    </div>
            ret.push( element );
        }
        return ret;
    }
    render(){
        return(
            <div>
                {this.state.loading?
                    <Loading/>
                    :<></>
                }
                {
                    this.renderElement()
                }                
            </div>                
        )
    }
}