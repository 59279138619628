import { action } from 'typesafe-actions';
import { actionTypes, CreateInfo } from './types';

export function create( loading:boolean, page:number, createInfo:CreateInfo ) {
    return action(actionTypes.CREATE, {
        loading, page, createInfo
    });
}
export function back( page:number ) {
    return action(actionTypes.BACK, {
        page
    });
}
export function confirm( loading:boolean, page:number, createInfo:CreateInfo ) {
    return action(actionTypes.CREATE, {
        loading, page, createInfo
    });
}
