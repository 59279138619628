import { ActionType } from "typesafe-actions";
import { SearchArea } from "../searchprovider/types";
import * as actions from './actions';

export type SearchProviderJob = {
    em_skill_keyword: string,
    em_company: string,
    sk_s: Array<string>,
    searchAreas:Array<SearchArea>,
}
export type SearchProviderJobState = SearchProviderJob & {
    loading: boolean,
    items?: [],
}
export enum actionTypes {
    SAVE = "searchproviderjob/save",
}
export type SearchProviderJobActions = ActionType<typeof actions>;