import React from 'react';
import '../scss/job.scss';
import '../scss/change_type.scss';
import Header from '../Include/Header'
import {Link} from 'react-router-dom';
import Footer from '../Include/Footer';
class PhoneSendSms extends React.Component<any>{
    public render(){
        const url = this.props.match.url;
        return (
            <div>
                <Header/>
                <div className="content-container" id = "content">
                    <div className="wrap-content">
                        <div className="main-title">新規登録</div>					
                        <div className="job_container mt_40">
                            <div className="change_type space">
                                <div className="title f-meiryo-bold mb_20">電話番号認証</div>
                                <div className="change_type_inner">
                                    <div>
                                        <div className="fs16">
                                            メールアドレスとパスワードを設定して、テキストテキストテキステキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                                        </div>
                                    </div>
                                </div>
                                <div className="button-container">
                                    <input type = "input" className="input-txt border l-center large  mt_50" placeholder="パスコードを入力してください" />
                                    <div className="grey-button large mt_30">
                                        <Link to = "/phoneverify">
                                            <div className="inner">
                                            パスコードを送信する
                                            <i className="fa fa-angle-right"></i>
                                            </div>
                                        </Link>
                                    </div>										
                                </div>								
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}
export default PhoneSendSms;
