import React, { Component } from 'react';
import '../../scss/recommend.scss';
import { Link } from 'react-router-dom';
import * as URLS from '../../../modules/urls';
import Loading from '../../Include/Loading';
import * as Global from '../../../modules/global';

import { FooterActions } from '../../../modules/footer';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as footerAsyncactions from '../../../modules/footer/async-actions';


const mapDispatcherToProps = (dispatch: Dispatch<FooterActions>) => {
    return {
        isRerender:( _isRerender: boolean ) => footerAsyncactions.isRerender( dispatch, _isRerender ),             
    }
}
type ReduxType = ReturnType<typeof mapDispatcherToProps>;
class PRecommendCom extends Component<ReduxType>{
    state = {
        items: [],
        bossItems: [],
        loading: false,
        totalCount: 0,
        startNo: 0,
        errorMessage: '',
    }
    componentDidMount() {
        this.getRecommendedJob();
        this.getRecommendedBoss();
    }
    getRecommendedJob  = () => {
        const that = this;
        let em_id: any = Global.getEmId();
        if (em_id == "") {
            return;
        }
        this.setState({
            loading: true,
        })
        const sort = [{ "property": "match_all", "direction": "desc" }];
        const url = URLS.GET_RECOMMEND_JOB_LIST + "&match_em_id=" + em_id + "&sta_em_id=" + em_id + `&em_id_not=${em_id}` + '&sort=' + JSON.stringify(sort);
        fetch(url, {
            method: 'get',
        }).then(async function (response) {
            let ret = await response.json();
            if (ret.total > 0) {
                const items = ret.items;
                that.setState({
                    loading: false,
                    items: items,
                }, async() => {
                    await that.props.isRerender( true );
                });
            } else {
                that.setState({
                    loading: false,
                    items: [],
                }, async() => {
                    await that.props.isRerender( true );
                });
            }
        }).catch(error => {
            this.setState({ loading: false, items: [], errorMessage: error.toString() }, async() => {
                await that.props.isRerender( true );
            });
        });

    }
    getRecommendedBoss = () => {
        let em_id: any = Global.getEmId();
        const that = this;
        if (em_id == "") {
            return;
        }
        this.setState({
            loading: true,
        })
        const sort = [{ "property": "match_all", "direction": "desc" }];
        const url = URLS.GET_RECOMMEND_CLIENT_LIST + `&em_id=${em_id}` + `&sort=` + JSON.stringify(sort);
        fetch(url, {
            method: 'get',
        }).then(async function (response) {
            let ret = await response.json();
            if (ret.total > 0) {
                const items = ret.items;
                that.setState({
                    loading: false,
                    bossItems: items,
                }, async() => {
                    await that.props.isRerender( true );
                });
            } else {
                that.setState({
                    loading: false,
                    bossItems: [],
                }, async() => {
                    await that.props.isRerender( true );
                });
            }
        }).catch(error => {
            this.setState({ loading: false, bossItems: [], errorMessage: error.toString() }, async() => {
                await that.props.isRerender( true );
            });
        });
    }
    renderSkill(skillStr: string) {
        if (!skillStr) {
            return [];
        }
        const skills = skillStr.split(',');
        let ret = [];
        for (let i = 0; i < skills.length; i++) {
            ret.push(<span className="tag-mg fs14" key={i}><span className="tag-inner">{skills[i]}</span></span>)
        }
        return ret;
    }
    render() {
        return (
            <div className="job_inner recommend-inner">
                {this.state.loading ?
                    <Loading />
                    : <></>
                }
                <span className="title bold">おすすめの仕事</span>
                {
                    this.state.items.length === 0 ? <span className="no_content mt_20 mb_50">おすすめの仕事がありません。</span> : <></>
                }
                <div className = 'mb_20'>
                {
                    this.state.items.map(item => (
                        <div className="element both_ways middle" key={item["jb_id"]}>
                            <div className="r_block ml_10">
                                <div className="fs20">{item["jb_comp_name"]}</div>
                                <div className="fs14">{item["em_is_legal"] == "0" ? item["em_company"] : item["em_company_legal"]}</div>
                            </div>
                            <div className="btn-container">
                                <Link to={'/pjobdetail/' + item["jb_id"] + '/' + (item["em_is_legal"] === "0" ? item["em_company"] : item["em_company_legal"]) + '/' + (item["em_depart"] != ''?item["em_depart"]:' ') + '/' + item['em_photo_s'] + '/' + item['em_id']}>
                                    <button className="grey-button v-small">
                                        <div className="inner  f-meiryo-bold">
                                            詳細
                                    </div>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    ))
                }
                </div>
                <span className="title bold">おすすめの親方/会社</span>                
                {
                    this.state.bossItems.length === 0 ? <span className="no_content mt_20">おすすめの親方/会社がありません。</span> : <></>
                }
                <div className = 'mt_20'>
                {
                    this.state.bossItems.map(item => (
                        <div className="element both_ways" key={item['em_id']}>
                            <div className="flex_start">
                                <div className="first">
                                    <img src={URLS.IMAGE_URL + item['em_photo_s']} />
                                </div>
                                <div className="second_large">
                                    <div className = 'both_ways'>
                                        <span className="fs20">{item["em_is_legal"] === '0' ? item['em_company'] : item['em_company_legal']}</span>
                                        <span className="fs14 map-icon">{item['st_label']}</span>
                                    </div>

                                    {/* <div className="fs14">{item['em_depart']}</div> */}
                                    <div>
                                        {this.renderSkill(item["sk_label_s"])}
                                    </div>
                                </div>
                            </div>
                            <div className="btn-container">
                                <div className="grey-button v-small">
                                    <Link to={"/client/profile/" + item['em_id']}>
                                        <div className="inner f-meiryo-bold">
                                            詳細
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))
                }
                </div>
            </div>
        );
    }
}
export default connect(null, mapDispatcherToProps)(PRecommendCom);