import React, {Component} from 'react';
import '../scss/job.scss';
import '../scss/message_template.scss';
import {Link} from 'react-router-dom';
import * as Global from '../../modules/global';
import * as URLS from '../../modules/urls';
import Loading from '../Include/Loading';
import * as footerAsyncactions from '../../modules/footer/async-actions';
import { FooterActions } from '../../modules/footer';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

const mapDispatcherToProps = (dispatch: Dispatch<FooterActions>) => {
    return {
        isRerender:( _isRerender: boolean ) => footerAsyncactions.isRerender( dispatch, _isRerender ),             
    }
}
type ReduxType = ReturnType<typeof mapDispatcherToProps> & any;

class MessageTemplateCom extends Component<ReduxType>{
    state = {
        loading:false,
        total:0,
        items:[],
    }
    componentDidMount() {
        this.fetch( URLS.READ_MSG_TEMPLATE );
    } 
    fetch( url:string = "" ){

        let em_id:any = Global.getEmId();
        if( em_id == "" ){
            return;
        }        
        this.setState({
            loading:true,
        });
        const sort = [{"property":"mgt_created","direction":"desc"}];
        url += "?em_id=" + em_id;
        url += "&page_all=1";
        url += "&sort=" + JSON.stringify(sort);
        const response = fetch( url ).then(async response => {    
            const data = await response.json();
            this.setState({
                total: data.total,
                items:data.items,
                loading:false,
            }, async() => {
                await this.props.isRerender( true );
            });
        }).catch(error => {
            this.setState({
                loading:false,
            }, async() => {
                await this.props.isRerender( true );
            });
        });
    }
    deleteTemplate = (mgt_id:number) =>{
        const data = {
            destroy:[{"mgt_id":mgt_id}]
        }
        const that = this;
        that.setState({
            loading:false,
        });
        fetch( URLS.MSG_TEMPLATE_SYNC, {
            method: 'post',
            body: JSON.stringify( data ),
        }).then(async function(response) {
            let ret = await response.json();
            if( ret.success ){
                let removeIndex = that.state.items.map(function(item:any) { return item.mgt_id; }).indexOf(mgt_id);
                let items = that.state.items.slice(0, removeIndex);
                items = items.concat( that.state.items.slice(removeIndex +1, that.state.items.length ) );
                that.setState({
                    items:items,
                    loading:false,
                });
            }else{
                that.setState({
                    loading:false,
                });
            }
            //return response.json();
        }).catch(error => {
            this.setState({ loading:false, errorMessage: error.toString() });
        });   

    }
    public render(){
        let to = this.props.location&&this.props.location.state&&this.props.location.state.to?this.props.location.state.to:'';
        if( to == '' ){
            to =  this.props.match && this.props.match.params? this.props.match.params.to:'';
        }
        if( to == '' ){
            to = this.props.to?this.props.to:'';
        }
        return(
            <div className="message_template_inner">
                {this.state.loading?
                    <Loading/>
                    :<></>
                }
                {
                this.state.items.length == 0?<span className="no_content mt_20">テンプレートがありません。</span>:<></>
                }
                {
                this.state.items.map(item=>(
                    <div className="t_element" key = {item['mgt_id']}>
                    <div className="l_block">
                        <span dangerouslySetInnerHTML = { {__html: item['mgt_note']} }>
                        </span>
                    </div>
                    <div className="r_block">
                        <Link to = {{pathname:"/templateedit", state:{to:to, id:item['mgt_id']} }}><div className="fa modify">修正する</div></Link>
                        <div className="fa delete pointer"  onClick = {() => this.deleteTemplate(item['mgt_id']) }>削除する</div>
                    </div>
                </div>                        
                ))                    
                }
            </div>

        );
    }
}
export default connect(null, mapDispatcherToProps)(MessageTemplateCom);