import React,{ Component } from 'react';
import Loader from 'react-loader-spinner';

type LoadingProps = {
    color?:string,
}
export default class Loading extends Component<LoadingProps>{
    render(){
        const color:string = this.props.color?this.props.color:"#333";
        return(
        <div>
            <div className="loading_container">
            </div>
            <div className= "layout_center max_800 fixed">
            <Loader
                type="Oval"
                color={color}
                height={50}
                width={50}
            />
            </div>
        </div>
        )
    }
}