import React, { useCallback } from 'react';
import Slider from '@material-ui/core/Slider';
import Cropper from 'react-easy-crop';
import {Area} from 'react-easy-crop/types';
import '../scss/editphoto.scss';
import getCroppedImg from '../../modules/imageCrop';
import * as URLS from '../../modules/urls';

type editPhotoProps = {
  bigImage:string,
}
export default class EditPhoto extends React.Component<editPhotoProps> {
  state = {
    imageSrc:
      URLS.DUMMY_IMG,
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 1,
    croppedAreaPixels:[],
  }
  onCropChange = ( crop:any ) => {
    this.setState({ crop })
  }
  onChangeImage = ( src:any ) =>{
    this.setState({
      imageSrc:src,
    });
  }
  showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        this.state.imageSrc,
        this.state.croppedAreaPixels,
        0, //rotation.
        "cropped.jpg"
      );
      return croppedImage;
//      setCroppedImage(croppedImage)
    } catch (e) {
      console.error(e)
      return null;
    }
  }

  onCropComplete = (croppedArea:any, _croppedAreaPixels:Area ) => {
    console.log(_croppedAreaPixels.width / _croppedAreaPixels.height)
    this.setState({
      croppedAreaPixels:_croppedAreaPixels,
    });
  }

  onZoomChange = ( _zoom:any ) => {
    this.setState({ zoom:_zoom })
  }
  componentDidMount(){
    if( this.props.bigImage != "" ){
      this.setState({
        imageSrc:this.props.bigImage
      })
    }
  }
  render() {
    return (
      <div className="crop-image-root">
        <div className="crop-container">
          <Cropper
              image={this.state.imageSrc}
              crop={this.state.crop}
              zoom={this.state.zoom}
              aspect={this.state.aspect}
              cropShape="round"
              showGrid={false}
              onCropChange={this.onCropChange}
              onCropComplete={this.onCropComplete}
              onZoomChange={this.onZoomChange}
          />
        </div>
        <div className="controls">
          <Slider
            value={this.state.zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e, zoom) => this.onZoomChange(zoom)}
            // classes={{ container: 'slider' }}
          />
        </div>
      </div>
    )
  }
}