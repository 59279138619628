import React,{ Component } from 'react';
import '../scss/job.scss';
import '../scss/business_type.scss';

type JobTypeProp = {
    no:number,
    index:number,
    setIndex:any,
    item:any,
}
export default class JobTypeItemCom extends Component<JobTypeProp, any>{
    toggleRef:any;
    state = {
        isExpanded:false,
        height:0,
    }
    constructor(props:any ){
        super( props );
        this.toggleRef = React.createRef();
    }
    handleToggle( e:any ){
        e.preventDefault();
        if( this.toggleRef && this.toggleRef.current ){
            if( this.props.no != this.props.index ){
                this.props.setIndex( this.props.no );
            }else{
                this.props.setIndex( -1 );                
            }

        }
    }
    render(){
        let height = 0;        
        if( this.props.no == this.props.index ){
            if( this.toggleRef && this.toggleRef.current ){
                height = this.toggleRef.current.clientHeight;
            }
        }
        const curentHeight = height;
        return(
        // <div className={`panel ${isExpanded?'is_Expanded':''}`} onClick = { (e) => this.handleToggle(e)}>
        <div className={`panel`} onClick = { (e) => this.handleToggle(e)}> 
            <div className="panel_heading fa">{this.props.item && this.props.item['sk_label']?this.props.item['sk_label']:''}</div>
            <div className="panel_collapse" style = {{height:curentHeight+'px'}}>
                <div className="panel_body" ref = {this.toggleRef}>
                    <div className="collapse">
                        <span className="tag-mg fs14"><span className="tag-inner">内容</span></span>
                        <span className="second">
                            {this.props.item &&this.props.item['sk_desc']?this.props.item['sk_desc']:''}
                        </span>
                    </div>
                    <div className="collapse">
                        <span className="tag-mg fs14"><span className="tag-inner">例示</span></span>
                        <span className="second">
                            {this.props.item && this.props.item['sk_example'] ?this.props.item['sk_example']:''}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}