import { SearchImmediatePeopleActions, SearchImmediatePeopleState, actionTypes, SearchImmediatePeople } from './types';
import moment from 'moment';

const initialState: SearchImmediatePeopleState = {
    loading: false,
    jb_date:  moment().format('MM/DD/YYYY'),
    jb_time: '0',
};
const SAVE = actionTypes.SAVE;
export function SearchImmediatePeopleReducer(state: SearchImmediatePeopleState = initialState, action: SearchImmediatePeopleActions): SearchImmediatePeopleState {
    switch (action.type) {
        case SAVE:
            const searchImmediatePeople: SearchImmediatePeople = action.payload.searchImmediatePeople;
            return {
                ...state,
                jb_date: searchImmediatePeople.jb_date,
                jb_time: searchImmediatePeople.jb_time,
            };
        default:
            return state;
    }
}