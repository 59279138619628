import * as React from 'react'
import FullCalendar from '@fullcalendar/react'
import { EventInput } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction' // needed for dayClick
import { Redirect } from 'react-router-dom'
import * as URLS from '../../modules/urls';
import * as Global from '../../modules/global';
import { AlertDialog } from '../Modal/Alert';
import moment from 'moment';

type ScheduleState = {
	isRedraw?: boolean,
	redirect?: boolean,
	date?: String,
	calendarWeekends: boolean,
	calendarEvents: EventInput[]
}
type CalendarComProps = {
	em_id?: string,
	is_mine?: boolean,
}
export default class CalendarCom extends React.Component<CalendarComProps, any>{
	mounted: boolean = false;
	calendarComponentRef = React.createRef<FullCalendar>()
	role:string|null = null;
	calendarItems: any = [];
	alertRef: any;
	state = {
		redirect: false,
		loading: false,
		date: "",
		calendarWeekends: true,
		calendarEvents: [ // initial event data
			// { title: '終日', start: '2020-05-25' },
			// { title: '午後', start: '2020-05-26' },        
			// { title: '終日', start: '2020-05-29' },
			// { title: '午後', start: '2020-05-30' },        
		]
	}
	constructor(props: any) {
		super(props);
		this.alertRef = React.createRef();
	}
	removeDateLabel() {
		let dayNumbers = document.getElementsByClassName("fc-day-number");
		for (let i = 0; i < dayNumbers.length; i++) {
			let element = dayNumbers[i];
			let parent = element.parentElement;
			let date = parent?.getAttribute("data-date");
			let html = element.innerHTML;
			// html = html.replace("日", '');
			element.innerHTML = html;
			let _state = this.calendarItems;
			for (let j = 0; j < _state.length; j++) {

				let fullDate = _state[j].date;
				if (fullDate == date) {
					if (_state[j].ft_id_s && _state[j].ft_id_s.indexOf("7") == -1) {
						html = html.replace("日", '');
						element.innerHTML = html;
						element.classList.add("day_active");
					}
					// if( _state[j].ef_note && _state[j].ef_note != '' ) {
					// 	html = html.replace("日", '');
					// 	element.innerHTML = html;
					// 	if( this.role === '2' ){
					// 		element.classList.add("dark_blue");
					// 	}
					// }
					// if(  _state[j].ft_id_s.indexOf( "1" ) != -1  ){
					// 	element.classList.add( "day_active_night" );
					// }
					// if(  _state[j].ft_id_s.includes("2") && _state[j].ft_id_s.includes("4") && _state[j].ft_id_s.includes("6")  ){
					// 	element.classList.add( "day_active_night" );
					// }
					// if(  _state[j].ft_id_s.includes("2") && _state[j].ft_id_s.includes("5") && _state[j].ft_id_s.includes("6")  ){
					// 	element.classList.add( "day_active_night" );
					// }								
				}
			}
		}
	}
	componentDidMount = async() => {
		// // let top = 92;
		// // let windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
		// const currentCalendar = this.calendarComponentRef;
		this.mounted = true;
		// const startDate = (new Date().getMonth() + 1) + "/1/" + new Date().getFullYear();
		// const endDate = (new Date().getMonth() + 1) + "/31/" + new Date().getFullYear();
		//this.fetchInfo(startDate, endDate);
		this.role = await localStorage.getItem('role');
	}
	componentWillUnmount() {
		this.mounted = false;
	}
	getTitle(item: any) {
		item.ft_id = item.ft_id_s;
		let title = '';
		if (!item.ft_id_s) {
			return;
		}
		if (item.ft_id_s.includes("1")) {
			title = '終日+夜';
		} else if (item.ft_id_s.includes("3")) {
			title = '終日';
		} else if (item.ft_id_s.includes("2") && item.ft_id_s.includes("4") && item.ft_id_s.includes("6")) {
			title = '午前+夜';
		} else if (item.ft_id_s.includes("2") && item.ft_id_s.includes("5") && item.ft_id_s.includes("6")) {
			title = '午後+夜';
		} else if (item.ft_id_s.includes("2") && item.ft_id_s.includes("6")) {
			title = '夜間';
		} else {
			title = item.ft_name;
		}
		return title;
	}
	fetchInfo = (startDate: string, endDate: string) => {
		let em_id: any = "";
		const is_mine = this.props.is_mine !== undefined ? this.props.is_mine : true;
		if (is_mine) {
			em_id = Global.getEmId();
		} else {
			em_id = this.props.em_id !== undefined ? this.props.em_id : '';
		}
		if (em_id === "") {
			return;
		}
		const that = this;
		const sort = [{ "property": "ef_date", "direction": "asc" }];
		const url = URLS.READ_FREE_TIME + "?em_id=" + em_id + "&ef_date_from=" + startDate + "&ef_date_to=" + endDate
			+ "&sort=" + JSON.stringify(sort);
		fetch(url, {
			method: 'get',
		}).then(async  (response) => {
			if (!that.mounted) {
				return;
			}
			let ret = await response.json();
			this.calendarItems = [];
			for (let i = 0; i < ret.items.length; i++) {
				let item = ret.items[i];
				let calendarItem = {
					title: that.getTitle(item),
					date: Global.convertDateToJapan(item.ef_date, "-", false),
					ft_id_s: item.ft_id_s,
					ef_note: item.ef_note,
				}
				that.calendarItems.push(calendarItem);

				// if( item.ft_id_s.indexOf( "7" ) == -1 ){
				// }
			}
			this.setState({
				loading: false,
				calendarEvents: that.calendarItems,
			});
			this.removeDateLabel();
			if( this.role == '2' ) {
				that.renderMemoMark();
			}
			
		}).catch(error => {
			this.setState({ loading: false, errorMessage: error.toString() });
		});
	}
	renderMemoMark = () => {
		const dayElements = document.getElementsByClassName("fc-day");
		for (let i = 0; i < dayElements.length; i++) {
			const dayElement = dayElements[i];
			const date: string | null = dayElement.getAttribute("data-date");
			if (!date) {
				continue;
			}
			const memo: string = this.getMemo(date);
			if (memo && memo != '') {
				var node = document.createElement("div");
				var textnode = document.createTextNode("");// Create a text node
				node.appendChild(textnode);
				dayElement.appendChild(node);
				dayElement.classList.add("memo_mark");
			}
		}
	}
	customizeRender = (arg: any) => {
		// 		let element = arg.el;
		// console.log( '---CustomizeRender---'  );		
	}
	refreshRender = (arg: any) => {
		const currentStartDate = arg.view.currentStart;
		const startDate = (currentStartDate.getMonth() + 1) + "/01" + "/" + currentStartDate.getFullYear();
		const endDate = (currentStartDate.getMonth() + 1) + "/31" + "/" + currentStartDate.getFullYear();
		this.fetchInfo(startDate, endDate);
	}
	showMemo = (date: Date) => {
		const clicked_date_str: string = moment(date).format('YYYY-MM-DD').toString();
		const memo: string = this.getMemo(clicked_date_str);
		if ( memo && memo !== '') {
			const clicked_date_title_str: string =  moment(date).format('YYYY年MM月DD日').toString();
			//this.alertRef.current.open(`${clicked_date_title_str}のメモ内容`, memo, true);
		}
	}
	getMemo = (date: string) => {
		let memo: string = '';
		for (let i = 0; i < this.state.calendarEvents.length; i++) {
			const freeTimeItem: any = this.state.calendarEvents[i];
			if (freeTimeItem.date == date) {
				memo = freeTimeItem.ef_note;
				break;
			}
		}
		return memo;
	}
	handleDateClick = (arg: any) => {

		const role = localStorage.getItem('role');
		if (role == '1') {
			this.showMemo(arg.date);
			return;
		}
		let date: Date = arg.date;
		let dateString: String = this.formatDateToString(date);

		this.setState({
			redirect: true,
			date: dateString,
		});
	}
	formatDateToString = (date: Date) => {
		// 01, 02, 03, ... 29, 30, 31
		var dd = (date.getDate() < 10 ? '0' : '') + date.getDate();
		// 01, 02, 03, ... 10, 11, 12
		var MM = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1);
		// 1970, 1971, ... 2015, 2016, ...
		var yyyy = date.getFullYear();

		// create the format you want
		return (yyyy + "-" + MM + "-" + dd);
	}
	handleEventClick = (view: any, element: any) => {
	}
	render() {
		return (
			<div>
				{
					this.role == '2' ? this.renderRedirect() : <></>
				}
				<AlertDialog ref={this.alertRef} />
				<FullCalendar
					defaultView="dayGridMonth"
					header={{
						left: 'prev',
						center: 'title',
						right: 'next'
					}}
					height={650}
					plugins={[dayGridPlugin, interactionPlugin]}
					ref={this.calendarComponentRef}
					weekends={this.state.calendarWeekends}
					events={this.state.calendarEvents}
					dateClick={this.handleDateClick}
					dayRender={this.customizeRender}
					datesRender={this.refreshRender}
					locale={'ja'}
				/>
				<div className="flex_start middle mt_20 inline_block">
					<span className="circle small yellow"></span>
					<span className="fs16 ml_10">空きあり</span>
					{
						this.role == '2'?
							<>
								<span className="ml_30 circle small dark_blue"></span>
								<span className="fs16 ml_10">メモあり</span>:
							</>
							:
							<></>
					}
					
					{/* <span className="ml_30 circle small dark_yellow"></span>
					<span className="fs16 ml_10">終日+夜間可</span>
					<span className="ml_30 circle small dark_yellow"></span>
					<span className="fs16 ml_10">午前+夜間可</span>
					<span className="ml_30 circle small dark_yellow"></span>
					<span className="fs16 ml_10">午後+夜間可</span> */}
				</div>
			</div>
		);
	}

	renderRedirect = () => {
		if (this.state.redirect) {
			let url = '/myschedule_edit';
			return <Redirect to={{
				pathname: url,
				state: { date: this.state.date }
			}} />
		}
	}
}