import React, { Component } from 'react';
import '../../scss/job.scss';
import { Link } from 'react-router-dom';
import * as URLS from '../../../modules/urls';
import Loading from '../../Include/Loading';
import * as Global from '../../../modules/global';
import * as footerAsyncactions from '../../../modules/footer/async-actions';
import { FooterActions } from '../../../modules/footer';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

const mapDispatcherToProps = (dispatch: Dispatch<FooterActions>) => {
    return {
        isRerender:( _isRerender: boolean ) => footerAsyncactions.isRerender( dispatch, _isRerender ),             
    }
}
type ReduxType = ReturnType<typeof mapDispatcherToProps>;

class MyProviderJobCom extends Component<ReduxType>{
    state = {
        loading: false,
        items: []
    }
    componentDidMount() {
        let em_id: any = Global.getEmId();
        if (em_id == "") {
            return;
        }

        const that = this;
        this.setState({
            loading: true,
        });
        const sort = [{ "property": "jba_updated", "direction": "desc" }, { "property": "jb_id", "direction": "desc" }];
        const url = URLS.GET_MY_PROVIDER_JOB_LIST + "&em_id_w=" + em_id + "&sort=" + JSON.stringify(sort);
        fetch(url, {
            method: 'get',
        }).then(async function (response) {
            let ret = await response.json();
            if (ret.total > 0) {
                const items = ret.items;
                that.setState({
                    loading: false,
                    items: items,
                }, async() => {
                    await that.props.isRerender( true );
                });
            } else {
                that.setState({
                    loading: false,
                    items: [],
                }, async() => {
                    await that.props.isRerender( true );
                });
            }
        }).catch(error => {
            this.setState({ loading: false, items: [], errorMessage: error.toString() }, async() => {
                await that.props.isRerender( true );
            });
        });
    }
    render() {
        return (
            <div className="job_inner">
                {this.state.loading ?
                    <Loading />
                    : <></>
                }
                {
                    this.state.items.length == 0 ?
                        <span className="no_content">仕事がありません。</span>
                        :
                        <div className="title">
                            応募仕事履歴
                            <span className="mark_choosed_job_notify green right_show inline_block ml_20">直接依頼</span>
                            <span className="mark_choosed_job_notify right_show ml_10">親方/会社決定済み</span>
                        </div>
                }
                {
                    this.state.items.map(item => (
                        <div className="element flex" key={item['jb_id']}>
                            <Link to={"/jobdetail/" + item['jb_id'] + "/"}>
                                <span className={"job_title"}>
                                    <div>{item['jb_comp_name']}</div>
                                    <div className='flex_start'>
                                        {
                                            item['jba_is_direct'] > 0 ? <span className="direct_job ml_10 mr_10"></span> : <></>
                                        }
                                        {
                                            item['cnt_ctr'] > 0 ? <span className={"mark_choosed_job" + (item['jba_is_direct'] > 0?``:` ml_10`) }></span> : <></>
                                        }
                                    </div>
                                </span>
                            </Link>
                            <div className="btn_area">
                                <div className="grey-button vv-small mr_10">
                                    <Link to={"/jobdetail/" + item['jb_id']}>
                                        <div className="inner">
                                            詳細
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        );
    }
}
export default connect(null, mapDispatcherToProps)(MyProviderJobCom);