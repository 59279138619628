import React, { Component } from 'react';
import '../scss/recommend.scss';

import * as asyncactions from '../../modules/chat/async-actions';
import * as footerAsyncactions from '../../modules/footer/async-actions';
import { IRootState } from '../../modules';
import { connect } from 'react-redux';
import { FooterActions } from '../../modules/footer';
import { Dispatch } from 'redux';

const mapStateToProps = ({ footer }: IRootState) => {
    const { _isRerender } = footer;
    return { _isRerender };
}
const mapDispatcherToProps = (dispatch: Dispatch<FooterActions>) => {
    return {
        isRerender: (_isRerender: boolean) => footerAsyncactions.isRerender(dispatch, _isRerender),
    }
}
type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;

class Footer extends Component<ReduxType, any>{
    state = {
        clsName: 'footer',
        offset: 0,
    }
    myRef: any = React.createRef();

    componentDidMount() {
        this.reRenderFooter();
    }
    reRenderFooter = () => {
        let top = 152;
        const totalBodyHeight = document.getElementById('root')?.offsetHeight;
        if (totalBodyHeight == undefined) {
            this.setState({
                clsName: 'footer fixed',
            });
            return;
        }
        let windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        const { offsetTop, clientHeight } = this.myRef.current;
//console.log('Footer component Did update=' + document.getElementById('root')?.offsetHeight + '=' + windowHeight);

        if ((totalBodyHeight ) < windowHeight) {
            this.setState({
                clsName: 'footer fixed',
            });
        } else {
            this.setState({
                clsName: 'footer',
            });
        }
    }
    componentDidUpdate = async (prevProps: ReduxType) => {
        if (this.props._isRerender) {
            this.reRenderFooter();
            await this.props.isRerender( false );
        }
    }
    render() {
        //const className = this.props.isFixed != undefined?(this.props.isFixed?'footer fixed':'footer'):this.state.clsName;
        return (
            <footer id="footer" className={this.state.clsName} ref={this.myRef}>
                © 2020 CRAFTSMAN HOUSE
            </footer>
        );
    }
}


export default connect(mapStateToProps, mapDispatcherToProps)(Footer);