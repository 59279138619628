import React, { Component } from 'react';
import '../scss/job.scss';
import {Link} from 'react-router-dom';
import * as URLS from '../../modules/urls';
import Loading from '../Include/Loading';
import {AlertDialog} from '../Modal/Alert';
//import * as FirebaseAPI from '../../firebase/api/message';
import * as Global from '../../modules/global';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as asyncactions from '../../modules/chat/async-actions';
import { ChatActions } from '../../modules/chat/types';
import { IRootState } from '../../modules';
import {ConfirmDialog} from '../Modal/Confirm';
import { updateMySystemContacts } from '../../firebase/api/message';

type JobDirectOpenTypes = {
    em_id:string,
}

const mapStateToProps = ({ message }: IRootState ) => {
    const {text, messages } = message;
    return { text, messages };
  }
  
const mapDispatcherToProps = (dispatch: Dispatch<ChatActions>) => {
    return {
        sendSystemMessage: ( from:number, to:number, docId:string, cmsg:string, title:string = "", emt_id:number, isKuraha:number = 1, isFromMe:boolean = true, msg = "" ) => 
                                                                            asyncactions.sendSystemMessageAsync(dispatch, from, to, docId, cmsg, title, emt_id, isKuraha, isFromMe, msg )
        }
}
type ReduxType = ReturnType<typeof mapDispatcherToProps> & JobDirectOpenTypes;

class JobDirectOpenCom extends Component<ReduxType>{
    alertRef:any;    
    confirmRef:any;
    state = {
        loading:false,
        items:[],
        jb_id:'',
        jb_comp_name:'',

    }
    constructor(props:any ){
        super(props);
        this.alertRef = React.createRef();
        this.confirmRef = React.createRef();
    }
    componentDidMount(){
        const that = this;
        let em_id:any = Global.getEmId();
        let work_em_id:any = this.props.em_id?this.props.em_id:"";
        if( em_id === "" || work_em_id === "" ){
            return;
        }
        this.setState({
            loading:true,
        });

        const sort = [{"property":"jb_created","direction":"desc"},{"property":"jb_id","direction":"desc"}];
        fetch( URLS.GET_DIRECT_OPEN_LIST +"&em_id="+ em_id + "&sort=" + JSON.stringify(sort) + "&sta_em_id=" + work_em_id, {
            method: 'get',
        }).then(async function(response) {
            let ret = await response.json();
            if( ret.total > 0 ){
                const items = ret.items;
                that.setState({
                    loading:false,
                    items:items,
                });
            }else{
                that.setState({
                    loading:false,
                    items:[],
                });
            }
        }).catch(error => {
            this.setState({ loading:false, items:[], errorMessage: error.toString() });
        });   
    }
    onApplyImplement = () => {
        const jobId = this.state.jb_id;
        const jobName = this.state.jb_comp_name;
        const that = this;
        let work_em_id:any = this.props.em_id?this.props.em_id:"";
        let em_id:any = Global.getEmId();
        if( em_id === "" || !work_em_id || !jobId ){
            return;
        }
        const point = Global.getPoint();
        if( point < 50 ){
          this.alertRef.current.open( "お知らせ", "ポイントが不足しています。" );
          return;
        }        
        let em_worker_company:any = localStorage.getItem('em_boss_company') !== undefined?localStorage.getItem('em_boss_company'):'ニックネーム';
        if( em_worker_company === "" ){
            em_worker_company = 'ニックネーム';
        }
        let jb_id = jobId;
        let data = {
            em_id:work_em_id,
            jb_id:jb_id,
            jba_is_direct:1,
        }
        fetch( URLS.APPLY_JOB, {
            method: 'post',
            body:JSON.stringify( data )
        }).then(async function(response) {
            let ret = await response.json();
            if( ret.success ){
                let workerName: string = '';
                const contract = ret.data;
                if (contract) {
                    workerName = contract.em_lastname + contract.em_firstname  + 'さん';
                }                
                const msg:string = em_worker_company + `があなたに`+ `<pre jb_id="${jb_id}" type="jobapply" jba_is_direct="1">` + jobName +`</pre>` + "の仕事を直接依頼しました。";
                const myMsg:string =  workerName + 'に' + `<pre jb_id="${jb_id}"  type="jobapply" jba_is_direct="1">` +  jobName +`</pre>` + "の仕事を直接依頼しました。"
                const successMsg: string = workerName + 'に'  +  jobName + "の仕事を直接依頼しました。"
                //let docId:string = em_id +"_"+ jb_id;
                const docId:string = em_id;
                
                //worker contact create/update.
                that.props.sendSystemMessage( em_id, work_em_id, docId, msg , "仕事の直接依頼がありました", parseInt(Global.getRole()!), 1, false, (jobName + 'の仕事を直接依頼しました。') );
                //my contact( worker) create/update.
                updateMySystemContacts(`contact_${em_id}`, work_em_id, parseInt(work_em_id), em_id, '仕事を直接依頼しました', myMsg, parseInt(Global.getRole()!), true);                
                that.alertRef.current.open( "成功", successMsg, 1 );
            }else{
                that.alertRef.current.open( "エラー", "仕事を依頼することができませんでした。" );                    
            }
            that.setState({
                loading:false,
            });
        }).catch(error => {
            this.alertRef.current.open( "エラー", "仕事を依頼することができませんでした。" );            
            this.setState({ loading:false, errorMessage: error.toString() });
        });        

    }
    onApply = ( jobId:number, jobName:string ) =>{
        let work_em_id:any = this.props.em_id?this.props.em_id:"";
        let em_id:any = Global.getEmId();
        if( em_id === "" || !work_em_id || !jobId ){
            return;
        }
        this.setState({
            jb_id:jobId,
            jb_comp_name:jobName,
        })
        const point = Global.getPoint();
        if( point < 50 ){
          this.alertRef.current.open( "お知らせ", "ポイントが不足しています。" );
          return;
        }else{
            this.confirmRef.current.open( "お知らせ", "50ポイント消費します。よろしいですか。" );            
        }
    }
    render(){
        return (
            <div>
                {this.state.loading?
                    <Loading/>
                    :<></>
                }
                <div className="job_inner">
                    <AlertDialog ref = {this.alertRef} returnURL = "/myprovider"/>  
                    <ConfirmDialog ref = {this.confirmRef} func = { this.onApplyImplement}/>

                    <div className="title">募集中の仕事</div>
                    {
                        this.state.items.length === 0?<span>募集中の仕事がありません。</span>:<></>
                    }
                    {
                        this.state.items.length > 0?
                        <div className = "fs12 ml_20 mb_10" style = {{color:"#B80B03", textAlign:'right'}}>「仕事を依頼する」と、相手にメッセージが送信されます。50ポイントが使用されます。</div>
                        :<></>
                    }
                    {
                    this.state.items.map( item => (
                        <div className="element flex" key = {item['jb_id']}>
                            <Link to = {"/jobdetail/" + item['jb_id'] + "/"}>  
                                <span className={item['cnt_ctr'] > 0?"job_title mark_choosed_job":'job_title'}>
                                    <span>{item['jb_comp_name']}</span>
                                </span>
                            </Link>
                            <div className="btn_area">
                                <div className="grey-button vv-small mr_10" onClick = {()=>this.onApply(item['jb_id'], item['jb_comp_name'] )}>
                                    <div className="inner">
                                        仕事を依頼する		
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                    }
                </div>
            </div>
        );        
    }
}
export default connect(mapStateToProps, mapDispatcherToProps)(JobDirectOpenCom);