import React from 'react';
import '../scss/job.scss';
import * as Global from '../../modules/global';
import * as URLS from '../../modules/urls';
import Loading from '../Include/Loading';
import { Redirect } from 'react-router-dom';
import { AlertDialog } from '../Modal/Alert';
import * as FireBaseUser from "../../firebase/api/users";
import { FireBaseUserInfo } from '../../modules/userinfo';

class DeleteProfileCom extends React.Component<any>{
    alertRef: any;
    state = {
        loading: false,
        isNotification: false,
        isLogout: false,
        errorMessage: '',
        keyword: '',
        em_photo_s: '',
        em_id: '-1',
        em_is_legal: "-1",
        em_company: '',
        em_company_legal: '',
        em_person_legal: '',
        ctrc_value_b: '',
        em_depart: '',
        st_label: '',
        ar_id: '',
        sk_label_s: '',
        em_skill_keyword: '',
        em_overview: '',

        em_is_individual: '-1',
        em_photo_s_w: '',
        em_company_w: '',
        em_firstname: '',
        em_lastname: '',
        ctrc_value_w: '0',
        em_overview_w: '',
        st_label_w: '',
        sk_label_s_w: '',
    }
    constructor(props: any) {
        super(props);
        this.alertRef = React.createRef();
    }
    getProfile = async () => {
        let em_id: any = Global.getEmId();
        let emt_id: string | null = Global.getEmtId();
        try {
            let bossResponse = null;
            let workerResponse = null;
            if (emt_id === null) {
                return;
            }
            if (emt_id === '1') {
                const bossUrl = URLS.GET_PROFILE + "?em_id=" + em_id + "&keyword=boss";
                bossResponse = await fetch(bossUrl);
            } else if (emt_id === '2') {
                const workerUrl = URLS.GET_PROFILE + "?em_id=" + em_id + `&keyword=worker`;
                workerResponse = await fetch(workerUrl);
            }
            if (emt_id === '3') {
                const bossUrl = URLS.GET_PROFILE + "?em_id=" + em_id + "&keyword=boss";
                bossResponse = await fetch(bossUrl);
                const workerUrl = URLS.GET_PROFILE + "?em_id=" + em_id + `&keyword=worker`;
                workerResponse = await fetch(workerUrl);
            }
            if (em_id)
                if ((bossResponse && !bossResponse.ok) || (workerResponse && !workerResponse.ok)) {
                    throw Error(bossResponse!.statusText);
                } else {
                    const bossRet = bossResponse ? await bossResponse.json() : null;
                    const workerRet = workerResponse ? await workerResponse.json() : null;
                    this.setState({
                        em_is_legal: bossRet ? bossRet.emp.em_is_legal : '-1',
                        em_company: bossRet ? bossRet.emp.em_company : '',
                        em_company_legal: bossRet ? bossRet.emp.em_company_legal : '',
                        em_person_legal: bossRet ? bossRet.emp.em_person_legal : '',
                        ctrc_value_b: bossRet && bossRet.emp.ctrc_value_b ? bossRet.emp.ctrc_value_b : '0',
                        em_photo_s: bossRet && bossRet.emp.em_photo_s ? bossRet.emp.em_photo_s : '',
                        em_depart: bossRet ? bossRet.emp.em_depart : '',
                        st_label: bossRet ? bossRet.emp.st_label : '',
                        sk_label_s: bossRet && bossRet.emp.sk_label_s ? bossRet.emp.sk_label_s : '',
                        em_overview: bossRet ? bossRet.emp.em_overview : '',

                        em_photo_s_w: workerRet && workerRet.emp.em_photo_s ? workerRet.emp.em_photo_s : '',
                        em_is_individual: workerRet && workerRet.emp.em_is_individual ? workerRet.emp.em_is_individual : '',
                        em_company_w: workerRet && workerRet.emp.em_company ? workerRet.emp.em_company : '',
                        em_firstname: workerRet && workerRet.emp.em_firstname ? workerRet.emp.em_firstname : '',
                        em_lastname: workerRet && workerRet.emp.em_lastname ? workerRet.emp.em_lastname : '',
                        ctrc_value_w: workerRet && workerRet.emp.ctrc_value_w ? workerRet.emp.ctrc_value_w : '0',
                        st_label_w: workerRet && workerRet.emp.st_label ? workerRet.emp.st_label : '',
                        em_overview_w: workerRet && workerRet.emp.em_overview ? workerRet.emp.em_overview : '',
                        sk_label_s_w: workerRet && workerRet.emp.sk_label_s ? workerRet.emp.sk_label_s : '',
                        loading: false,
                    });
                }
        } catch (error) {
            this.setState({ loading: false, errorMessage: error.toString() });
        }
    }
    componentDidMount() {
        this.setState({
            loading: true,
        });
        this.getProfile();
    }
    onDeleteAccount = (e: any) => {
        let em_id: string | null = Global.getEmId();
        if (em_id === null || em_id === '' || parseInt(em_id) < 0) {
            return;
        }

        let t_emt_id = '4';
        const data = {
            update: [
                {
                    em_id: em_id,
                    emt_id: t_emt_id,
                }
            ]
        }

        this.setState({
            loading: true,
        });

        const that = this;
        fetch(URLS.SAVE_PROFILE, {
            method: 'post',
            body: JSON.stringify(data),
        }).then(async function (response) {
            let ret = await response.json();
            if (ret.success) {
                const tempEmtId = Global.getTempEmtId()!;
                Global.setEmtIdById(tempEmtId);
                that.registerToFirebase();
                Global.setTempEmtId('');
                Global.setEmId('');
                Global.setDefaultRole('');
            } else {
                that.alertRef.current.open("エラー", "アカウントを削除できませんでした。");
                that.setState({
                    loading: false,
                });
            }
        }).catch(error => {
            this.setState({ loading: false, errorMessage: error.toString() });
            if (this.alertRef.current)
                this.alertRef.current.open("エラー", "アカウントを削除できませんでした。");
        });
    }
    registerToFirebase = () => {
        const em_id: any = Global.getEmId();
        const emt_is_boss: string = '0';
        const emt_is_worker: string = '0';
        const fireBaseUserInfo: FireBaseUserInfo = {
            emt_is_boss: emt_is_boss,
            emt_is_worker: emt_is_worker,
        };
        FireBaseUser.addUserInfo(em_id, fireBaseUserInfo).then((result: any) => {
            this.setState({
                loading: false,
            });
            this.alertRef.current.open("成功", "アカウントを削除しました。", true);
        }).catch((error: any) => {
            this.setState({
                loading: false,
            });
            this.alertRef.current.open("エラー", "サーバへ保存できませんでした。");
        });
    }
    renderSkill(skillStr: string) {
        if (!skillStr) {
            return [];
        }
        const skills = skillStr.split(',');
        let ret = [];
        for (let i = 0; i < skills.length; i++) {
            ret.push(<span className="tag-mg fs14" key={i}><span className="tag-inner">{skills[i]}</span></span>)
        }
        return ret;
    }
    renderRedirect = () => {
        let role = Global.getRole();
        if (role !== '1' && role !== '2') {
            let url: string = '/';
            return <Redirect to={url} />
        } else {
            return <></>
        }
    }
    public render() {
        let em_company = this.state.em_is_legal === "0" ? this.state.em_company : this.state.em_company_legal;
        let em_depart = this.state.em_depart;
        let em_star = parseInt(this.state.ctrc_value_b);
        const em_star_w = parseInt(this.state.ctrc_value_w);
        let emt_id: string | null = Global.getEmtId();
        return (
            <div className="job myprofile">
                {this.renderRedirect()}
                {this.state.loading ?
                    <Loading />
                    : <></>
                }
                <AlertDialog ref={this.alertRef} returnURL={'/'} />
                <div className="fs20 ml_60 mb_50">アカウントを削除すると、登録されているデータやポイントが完全に削除され、復旧することはできません。</div>
                {
                    emt_id && (emt_id === '1' || emt_id === '3' ) ?
                        <div className="myprofile_inner">
                            <div className="l_block">
                                <img alt='' src={URLS.IMAGE_URL + this.state.em_photo_s} />
                                <div className="company_info">
                                    <div>
                                        <div className="fs20">{em_company}</div>
                                        <div className="fs14">{em_depart}</div>
                                    </div>
                                    <div className="star_container">
                                        <span className={em_star > 0 ? "star active" : "star"}></span>
                                        <span className={em_star > 1 ? "star active" : "star"}></span>
                                        <span className={em_star > 2 ? "star active" : "star"}></span>
                                        <span className={em_star > 3 ? "star active" : "star"}></span>
                                        <span className={em_star > 4 ? "star active" : "star"}></span>
                                    </div>
                                </div>
                            </div>
                            <div className="r_block">
                                <div className="info">
                                    <div>
                                        <div className="fs14 map-icon">{this.state.st_label}</div>
                                        <div>
                                            {this.renderSkill(this.state.sk_label_s)}
                                        </div>
                                    </div>
                                </div>
                                <div className="profile_detail">
                                    <div className="d_element pd_b mt_10">
                                        <span className="desc">{this.state.em_overview}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :<></>
                }
                {
                    emt_id && (emt_id === '2' || emt_id === '3') ?
                        <div className="myprofile_inner mt_50">
                            <div className="l_block">
                                <img alt='' src={URLS.IMAGE_URL + this.state.em_photo_s_w} />
                                <div className="company_info">
                                    <div>
                                        <div className="fs14">{this.state.em_is_individual === "1" ? '個人事業主' : this.state.em_company_w}</div>
                                        <div className="fs20">{this.state.em_lastname + this.state.em_firstname}</div>
                                    </div>
                                    <div className="star_container">
                                        <span className={em_star_w > 0 ? "star active" : "star"}></span>
                                        <span className={em_star_w > 1 ? "star active" : "star"}></span>
                                        <span className={em_star_w > 2 ? "star active" : "star"}></span>
                                        <span className={em_star_w > 3 ? "star active" : "star"}></span>
                                        <span className={em_star_w > 4 ? "star active" : "star"}></span>
                                    </div>
                                </div>
                            </div>
                            <div className="r_block">
                                <div className="info">
                                    <div>
                                        <div className="fs14 map-icon">{this.state.st_label_w}</div>
                                        <div>
                                            {this.renderSkill(this.state.sk_label_s_w)}
                                        </div>
                                    </div>
                                </div>
                                <div className="profile_detail">
                                    <div className="d_element pd_b mt_10">
                                        <span className="desc">{this.state.em_overview_w}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <></>
                }
                <div className="button-container mt_40">
                    <button className="grey-button large">
                        <div className="inner" onClick={this.onDeleteAccount}>
                            アカウントを削除する
                        <i className="fa fa-angle-right"></i>
                        </div>
                    </button>
                </div>
            </div>
        );
    }
}
export default DeleteProfileCom;