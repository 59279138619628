import { FooterActions, actionTypes, FooterState } from './types';

const initialState:FooterState = {
    _isRerender: false,
};
const RERENDER = actionTypes.RERENDER;
export function FooterReducer(state: FooterState = initialState, action: FooterActions ): FooterState{
    let s = null;
    switch (action.type ) {
        case RERENDER:
            return {...state, _isRerender:action.payload._isRerender };
        default:
            return state;            
    }
}