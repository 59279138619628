import React from 'react';
import '../scss/job.scss';
import '../scss/profile.scss';
import {Redirect} from 'react-router-dom';
import StarCom from '../Include/StarCom'
import * as URLS from '../../modules/urls';
import * as Global from '../../modules/global';
import {FeedbackType} from '../../modules/feedback/types';
import SimpleReactValidator from 'simple-react-validator';
import Loading from '../Include/Loading';
import {AlertDialog} from '../Modal/Alert';

class LeaveFeedbackCom extends React.Component<any>{
    validator:SimpleReactValidator;
    alertRef:any;    
    state = {
        loading:false,
        ctrc_note:'',
        ctrc_value:'0',
        item:{},
    }
    setCtrcValue = ( value:number ) =>{
        this.setState({
            ctrc_value:value,
        });
    }
    renderRedirect = () => {
        let info = this.props.info?this.props.info:{};
        if( !info.em_id || !info.ctrId ){
            return <Redirect to = {"/"}/>            
        }else{
            return <></>            
        }
    }
    constructor(props:any){
        super( props );
        this.validator = new SimpleReactValidator({
            messages: Global.VALIDATION_MESSAGES,
            autoForceUpdate: this,
            className:'error',
            validators:Global.VALIDATORS
        });
        this.alertRef = React.createRef();
    }
    componentDidMount(){
        const that = this;
        let info:FeedbackType = this.props.info?this.props.info:{};

        if( !this.props.info.ctrId ){
            return;
        }
        if( !this.props.info.em_id ){
            return;
        }

        const em_id:any = info.em_id;
        const role = localStorage.getItem( 'role' );
        let keyword = 'boss';
        if( role === '1' ){
            keyword = 'worker';
        }
        let ctr_id:any = this.props.info.ctrId;

        this.setState({
            loading:true,
        });

        fetch( URLS.GET_PROFILE +"?em_id="+ em_id + "&keyword=" + keyword, {
            method: 'get',
        }).then(async function(response) {
            let ret = await response.json();
            if( ret.emp ){
                const item = ret.emp;
                that.setState({
                    loading:false,
                    item:item,
                });
            }else{
                that.setState({
                    loading:false,
                    items:{},
                });
            }
        }).catch(error => {
            this.setState({ loading:false, items:[], errorMessage: error.toString() });
        });   
    }    
    onLeaveComment = () =>{
        if (this.validator.allValid()){
            let info:FeedbackType = this.props.info?this.props.info:{};
            const data ={
                em_id:info.em_id,
                ctr_id:info.ctrId,
                is_boss:info.is_boss,
                ctrc_value:this.state.ctrc_value,
                ctrc_note:Global.nl2br(this.state.ctrc_note),
            }
            this.setState({
                loading:true,
            });
            const that = this;
            fetch( URLS.WRITE_COMMENT, {
                method: 'post',
                body: JSON.stringify( data ),
            }).then(async function(response){
                let ret = await response.json();
                that.setState({
                    loading:false,
                });
                if( ret.success ){
                    that.alertRef.current.open( "成功", "保存されました。", true );
                }else{
                    that.alertRef.current.open( "エラー", "保存できませんでした。" );
                }
                //return response.json();
            }).catch(error => {
                that.alertRef.current.open( "エラー", "保存できませんでした。", true );
                that.setState({
                    loading:false,
                });
            });            
        }else{
            this.validator.showMessages();
            this.forceUpdate();
        }        
    }
    onHandleChange = ( e:any ) =>{
        let value:string = e.target.value;
        let name:string = e.target.name;
        this.setState( { [name]:value} );
    }
    onHandleBlur = ( e:any ) =>{
        let name:string = e.target.name;
        this.validator.showMessageFor( name );
    }
    getName = () => {
        let role = Global.getRole();
        let name = '';
        let item:any = this.state.item;

        let em_is_legal = item['em_is_legal']?item['em_is_legal']:'0';
        if( role === '2' ){
            name = (em_is_legal === '0'?item['em_company']:item['em_company_legal']);
        }else{
            name = item['em_lastname'] + item['em_firstname'];
        }
        return name;
    }
    public render(){
        const item:any = this.state.item;

        const em_photo_s = item['em_photo_s'];
        const role = Global.getRole();
        return(
            <div className="job_inner">
                {this.state.loading?
                    <Loading/>
                    :<></>
                }
                <AlertDialog ref = {this.alertRef} returnURL = { role === '1'?"/jobview":'/myclient'}/>
                {this.renderRedirect()}                
                <div className="element v_center">
                    <div className="first">
                        <img alt = '' src = {URLS.IMAGE_URL + em_photo_s } />
                    </div>
                    <div className="second">
                        <div className="company_info">
                            <div className="company_name">
                                <div className="fs20">{this.getName()}<span className="fs14 ml_20">さんの</span><span className="fs18">評価をつける</span></div>
                            </div>
                        </div>
                        <StarCom stars = {[false,false,false,false,false ]} setStar = {this.setCtrcValue}/>
                    </div>
                </div>
                <textarea className="feedback_textarea" name = "ctrc_note" onChange={this.onHandleChange} value={this.state.ctrc_note}>{this.state.ctrc_note}</textarea>
                {this.validator.message('ctrc_note', this.state.ctrc_note, 'required' )}                
                <div className="button-container">
                    <button className="grey-button large mt_70">
                        <div className="inner" onClick = {this.onLeaveComment}>
                        送信する
                        <i className="fa fa-angle-right"></i>
                        </div>
                    </button>
                </div>								
            </div>
        );
    }
}
export default LeaveFeedbackCom;