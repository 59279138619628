import React from 'react';
import '../scss/job.scss';
import '../scss/message.scss';
import Header from '../Include/Header'
import MessageCom from './MessageCom';
import MessagesendCom from './MessageSendCom';
import MsgTemplateShowCom from './MsgTemplateShowCom';
import Footer from '../Include/Footer';
import * as Global from '../../modules/global';
import * as URLS from '../../modules/urls';
import {Link} from 'react-router-dom';

class Message extends React.Component<any>{
    state = {
        text: "",
        em_photo_s:'',
        em_is_legal:"-1",
        em_company:'',
        em_company_legal:'',
        em_depart:'',        
        em_firstname:'',
        em_lastname:'',
        em_worker_company:'',
    };
    changeValue = ( _text:any ) =>{
        this.setState(state => ({
            text: _text
          }));
    }
    componentDidMount(){
        let role = Global.getRole();
        let keyword = 'boss';        
        if( role != '1' && role != '2' ){
            return;
        }
        if( role == '1' ){
            keyword = "worker";
        }
        let em_id = ( this.props.location && this.props.location.state && this.props.location.state.id)?this.props.location.state.id:"";
        if( em_id == '' ){
            em_id = this.props.match.params.id?this.props.match.params.id:'';
        }
        
        if( em_id == '' ){
            return;
        }
        let url = URLS.GET_PROFILE + "?em_id=" + em_id + "&keyword=" + keyword;
        const that = this;
        this.setState({
            loading:true,
        });
        fetch( url, {
            method: 'get',
        }).then(async function(response) {
            let ret = await response.json();
            that.setState({
                em_photo_s:ret.emp.em_photo_s?ret.emp.em_photo_s:'',
                em_company:ret.emp.em_is_legal?ret.emp.em_is_legal==0?ret.emp.em_company:ret.emp.em_company_legal:'',
                em_depart:ret.emp.em_depart?ret.emp.em_depart:'',
                em_firstname:ret.emp.em_firstname?ret.emp.em_firstname:'',
                em_lastname:ret.emp.em_lastname?ret.emp.em_lastname:'',
                em_worker_company:ret.emp.em_is_individual != null?ret.emp.em_is_individual=='1'?'個人事業主':ret.emp.em_company:'',
                loading:false,
            });
        }).catch(error => {
            this.setState({ loading:false, errorMessage: error.toString() });
        });            
    }
    public render(){
        let id = ( this.props.location && this.props.location.state && this.props.location.state.id)?this.props.location.state.id:"";
        if( id == '' ){
            id = this.props.match.params.id?this.props.match.params.id:'';
        }
        let docId = ( this.props.location && this.props.location.state && this.props.location.state.docId )?this.props.location.state.docId:'';
        let is_not_available = ( this.props.location && this.props.location.state && this.props.location.state.is_not_available )?this.props.location.state.is_not_available:0;
        let em_system = ( this.props.location && this.props.location.state && this.props.location.state.em_system )?this.props.location.state.em_system:'0';
        let role = Global.getRole();
        let linkURL: string = '';
        if( em_system !== '1' && role == '1' ) {
            linkURL = `/provider/profile/${id}`;
        }else if(em_system !== '1' && role == '2' ){
            linkURL = `/client/profile/${id}`;
        }

        return(
            <div>
                <Header/>
                <div className="content-container" id = "content">
                    <div className="wrap-content">
                        <div className="main-title">メッセージ</div>
                        <div className="job_container mt_40">
						<div className="job message pd_0">
							<div className="message_inner">
                                <div className="both_ways top bt_border pd_l pt_30">
                                    <div className="name_block">
                                        {
                                            is_not_available == 1?
                                                <img className = "no_radius" src = {URLS.IMAGE_URL + URLS.LEAVE_USER_IMAGE } />    
                                            :(linkURL !==''?    
                                            <Link to = {linkURL}><img src = {URLS.IMAGE_URL+this.state.em_photo_s}/></Link>:<img src = {URLS.IMAGE_URL+this.state.em_photo_s}/>)
                                        }
                                        <div className="company_info">
                                            <div>
                                                {
                                                    is_not_available == 1?
                                                    <div className="fs20">退会ユーザー</div>
                                                    :(linkURL !==''?    
                                                    <Link to = {linkURL}><div className="fs20">{role == '2'?this.state.em_company:(this.state.em_lastname+' '+this.state.em_firstname)}</div></Link>:<div className="fs20">{role == '2'?this.state.em_company:(this.state.em_lastname+this.state.em_firstname)}</div>)
                                                }                                                
                                            </div>
                                        </div>
                                    </div>
                                    <MessageCom to = {id} img = {this.state.em_photo_s} docId = {docId} is_not_available = {is_not_available}/>
                                </div>
                                {
                                    is_not_available == 1 || id.toString() === '1' ?
                                    <></>
                                    :
                                    <div className="message_box">
                                        <div className="flex_end">
                                            <div className="message_box_inner">
                                                <MessagesendCom to = {id}/>                                            
                                                <MsgTemplateShowCom to = {id}/>
                                            </div>
                                        </div>
                                    </div>    
                                }
							</div>
						</div>                            
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>

        );
    }
}
export default Message;