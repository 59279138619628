import React, { Component } from 'react';
import '../scss/point.scss';
import * as URLS from '../../modules/urls';
import { Redirect } from 'react-router-dom';
import Loading from '../Include/Loading';
import * as Global from '../../modules/global';

import * as footerAsyncactions from '../../modules/footer/async-actions';
import { FooterActions } from '../../modules/footer';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

const mapDispatcherToProps = (dispatch: Dispatch<FooterActions>) => {
    return {
        isRerender:( _isRerender: boolean ) => footerAsyncactions.isRerender( dispatch, _isRerender ),             
    }
}
type ReduxType = ReturnType<typeof mapDispatcherToProps>;

class PointBuyCom extends Component<ReduxType>{
    state = {
        loading: false,
        items: [],
        total: 0,
        is_redirect: false,
        pt_id: -1,
        pt_amount: 0,
        pt_money: 0,
        clientSecret: '',
    }
    onClickBuyPoint = (e: any) => {
        const id = e.currentTarget.dataset.value;
        const that = this;
        this.setState({
            loading: true,
        });
        if (!id) {
            return;
        }
        const data = {
            pt_id: id
        }
        fetch(URLS.GET_POINT_SECRET_KEY, {
            method: 'post',
            body: JSON.stringify(data),
        }).then(async function (response) {
            let ret = await response.json();
            if (ret.success) {
                if (ret.keyinfo && ret.keyinfo.clientSecret && ret.keyinfo.clientSecret != "") {
                    that.setState({
                        is_redirect: true,
                        pt_id: ret.keyinfo.pt_id,
                        pt_amount: parseInt(ret.keyinfo.pt_amount),
                        pt_money: parseInt(ret.keyinfo.pt_money),
                        clientSecret: ret.keyinfo.clientSecret,
                    })
                } else {
                    that.setState({
                        loading: false,
                    });
                }
            } else {
                that.setState({
                    loading: false,
                });
            }
            //return response.json();
        }).catch(error => {
            this.setState({ loading: false, errorMessage: error.toString() });
        });
    }
    renderRedirect = () => {
        if (this.state.is_redirect) {
            let url = '/pay';
            return <Redirect to={{
                pathname: url,
                state: {
                    clientSecret: this.state.clientSecret
                    , pt_id: this.state.pt_id
                    , pt_amount: this.state.pt_amount
                    , pt_money: this.state.pt_money
                }
            }} />
        }
        return <></>
    }
    componentDidMount() {
        this.fetch(URLS.POINT_LIST);
    }
    fetch(url: string = "") {
        let em_id: any = Global.getEmId();
        if (em_id == "") {
            return;
        }
        this.setState({
            loading: true,
        });
        const response = fetch(url).then(async response => {
            const data = await response.json();
            this.setState({
                total: data.total,
                items: data.items,
                loading: false,
            }, async() => {
                await this.props.isRerender( true );
            });
        }).catch(error => {
            this.setState({
                loading: false,
            }, async() => {
                await this.props.isRerender( true );
            });
        });
    }
    render() {
        return (
            <div>
                {this.state.loading ?
                    <Loading />
                    : <></>
                }
                {this.renderRedirect()}
                <div className="point_buy point-history mt_40">
                    <div className="point-history-inner">
                        {
                            this.state.items.map(item => (
                                <div className="element" key={item['pt_id']}>
                                    <span className="one_column">{parseInt(item['pt_amount'])}ポイント</span>
                                    <div className="btn-container">
                                        <button className="grey-button v-small" data-value={item['pt_id']} onClick={this.onClickBuyPoint}>
                                            <div className="inner f-meiryo-bold">
                                                {`¥ ` + Global.moneyFormat(item['pt_money'])}
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(null, mapDispatcherToProps)(PointBuyCom);