import * as React from 'react'

import '../scss/schedule.scss';
import CalendarCom from './CalendarCom'

export default class MyScheduleCom extends React.Component<{}, any> {

  render() {
    return (
		<div className='schedule_container mt_40'>
			<CalendarCom/>
		</div>
    );
  }


}
