import React from 'react';
import '../../scss/job.scss';
import '../../scss/recommend.scss';
import Header from '../../Include/Header'
import ProviderNavCom from "../../Include/ProviderNavCom";
import MyProviderJobCom from './MyProviderJobCom'
import Footer from '../../Include/Footer';
class MyProviderJob extends React.Component<any>{
    public render(){
        const url = this.props.match.url;
        return (
            <div>
                <Header/>
                <div className="content-container" id = "content">
                    <div className="wrap-content">
                        <div className="main-title">空き時間管理・応募仕事管理</div>
                        <div className="job_container mt_40">
                            <ProviderNavCom to = {url}/>
                            <div className="job recommend">
                                <div className="job_inner">
                                    <MyProviderJobCom/>
                                </div>    
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }

}
export default MyProviderJob; 