import React, { Component } from 'react';
import '../scss/job.scss';
import '../scss/editschedule.scss';
import * as URLS from '../../modules/urls';
import * as Global from '../../modules/global';
import Loading from '../Include/Loading';
import { AlertDialog } from '../Modal/Alert';


type MyScheduleEditComProp = {
    date: string,
}
class MyScheduleEditCom extends Component<MyScheduleEditComProp>{
    alertRef: any;
    freeItems: any;
    state = {
        loading: false,
        free0: 0,
        is_night_work0: 0,
        is_not_sure0: 0,
        ef_note0: '',

        free1: 0,
        is_night_work1: 0,
        is_not_sure1: 0,
        ef_note1: '',

        free2: 0,
        is_night_work2: 0,
        is_not_sure2: 0,
        ef_note2: '',

        free3: 0,
        is_night_work3: 0,
        is_not_sure3: 0,
        ef_note3: '',

        free4: 0,
        is_night_work4: 0,
        is_not_sure4: 0,
        ef_note4: '',

        free5: 0,
        is_night_work5: 0,
        is_not_sure5: 0,
        ef_note5: '',

        free6: 0,
        is_night_work6: 0,
        is_not_sure6: 0,
        ef_note6: '',

        weekList: [],
        errorMessage: '',
    }
    constructor(props: any) {
        super(props);
        this.alertRef = React.createRef();
        this.freeItems = [];
    }
    componentDidMount() {
        const weekList = Global.getWeekList(this.props.date);
        this.getFreeTime(weekList);
    }
    setFreeTime(item: any) {
        item.ft_id = item.ft_id_s;
        item.is_night_work = 0;
        if (!item.ft_id_s) {
            return item;
        }
        if (item.ft_id_s == "1") {
            item.ft_id = 3;
            item.is_night_work = 1;
        } else if (item.ft_id_s.includes("2") && item.ft_id_s.includes("4") && item.ft_id_s.includes("6")) {
            item.ft_id = 4;
            item.is_night_work = 1;
        } else if (item.ft_id_s.includes("2") && item.ft_id_s.includes("5") && item.ft_id_s.includes("6")) {
            item.ft_id = 5;
            item.is_night_work = 1;
        } else if (item.ft_id_s.includes("2") && item.ft_id_s.includes("6")) {
            item.ft_id = 2;
        }
        return item;
    }
    getFreeTime(weekList: any) {
        this.setState({
            loading: true,
        })
        const that = this;
        const len = weekList.length;
        let em_id: any = Global.getEmId();

        const sort = [{ "property": "ef_date", "direction": "asc" }];
        const url = URLS.READ_FREE_TIME + "?em_id=" + em_id + "&ef_date_from=" + weekList[0]["date1"] + "&ef_date_to=" + weekList[len - 1]["date1"]
            + "&sort=" + JSON.stringify(sort);
        fetch(url, {
            method: 'get',
        }).then(async function (response) {
            let ret = await response.json();
            that.freeItems = ret.items;
            that.freeItems.map(function (item: any) {
                item = that.setFreeTime(item);
            });
            const status = that.getStatus(weekList);
            that.setState({
                loading: false,
                weekList: weekList,
                free0: status["free0"],
                is_night_work0: status["is_night_work0"],
                ef_note0: status["ef_note0"],

                free1: status["free1"],
                is_night_work1: status["is_night_work1"],
                ef_note1: status["ef_note1"],

                free2: status["free2"],
                is_night_work2: status["is_night_work2"],
                ef_note2: status["ef_note2"],

                free3: status["free3"],
                is_night_work3: status["is_night_work3"],
                ef_note3: status["ef_note3"],

                free4: status["free4"],
                is_night_work4: status["is_night_work4"],
                ef_note4: status["ef_note4"],

                free5: status["free5"],
                is_night_work5: status["is_night_work5"],
                ef_note5: status["ef_note5"],

                free6: status["free6"],
                is_night_work6: status["is_night_work6"],
                ef_note6: status["ef_note6"],
            });
        }).catch(error => {
            console.log('Error here');
            console.log(error);
            this.setState({ loading: false, weekList: weekList, errorMessage: error.toString() });
        });
    }
    onHandleChange = (e: any) => {
        let value: string = e.target.value;
        let name: string = e.target.name;
        const idx = name.split('free')[1];
        const is_not_sure = "is_not_sure" + idx;
        const is_night_work = 'is_night_work' + idx;

        this.setState({
            [name]: value,
            [is_not_sure]: 0,
        });

        if (name == "is_night_work0"
            || name == "is_night_work1"
            || name == "is_night_work2"
            || name == "is_night_work3"
            || name == "is_night_work4"
            || name == "is_night_work5"
            || name == "is_night_work6"
        ) {
            const target = e.target;
            const checked = target.type === "checkbox" ? target.checked : '';
            if (!checked) {
                this.setState({
                    [name]: 0,
                });
            } else {
                this.setState({
                    [name]: 1,
                });
            }
        } else {
            this.setState({
                [is_night_work]: 0,
            });
        }
    }
    onHandleCancel = (e: any) => {
        let value: string = e.target.value;
        let name: string = e.target.name;
        this.setState({ [name]: value });
        if (name == "is_not_sure0"
            || name == "is_not_sure1"
            || name == "is_not_sure2"
            || name == "is_not_sure3"
            || name == "is_not_sure4"
            || name == "is_not_sure5"
            || name == "is_not_sure6"
        ) {
            const target = e.target;
            const checked = target.type === "checkbox" ? target.checked : '';
            if (!checked) {
                this.setState({
                    [name]: 0,
                });
            } else {
                this.setState({
                    [name]: 1,
                });
            }
            if (checked) {
                const idx = name.split('is_not_sure')[1];
                const free = "free" + idx;
                const is_night_work = "is_night_work" + idx;
                this.setState({
                    [free]: 0,
                    [is_night_work]: 0,
                })
            }
        }
    }
    getStatus(weekList: any) {
        let status = {
        };
        for (let j = 0; j < weekList.length; j++) {
            let date = weekList[j]['date1'];
            let is_night_work = "is_night_work" + j;
            let freetime = "free" + j;
            let ef_note = `ef_note${j}`;
            status[freetime] = 0;
            status[is_night_work] = 0;
            for (let i = 0; i < this.freeItems.length; i++) {
                let item = this.freeItems[i];
                if (item.ef_date == date) {
                    status[freetime] = item.ft_id;
                    status[is_night_work] = item.is_night_work;
                    status[ef_note] = item.ef_note;
                    break;
                }
            }
        }
        return status;
    }
    prevWeek = () => {
        if (this.state.weekList.length > 0) {
            const date = Global.getSpecialDate(this.state.weekList[0]["date"], 1, false);
            const weekList = Global.getWeekList(date);
            this.getFreeTime(weekList);
        }
    }
    nextWeek = () => {
        if (this.state.weekList.length > 0) {
            const len = this.state.weekList.length;
            const date = Global.getSpecialDate(this.state.weekList[len - 1]["date"], 1, true);
            const weekList = Global.getWeekList(date);
            this.getFreeTime(weekList);
        }
    }
    getFtId = (id: number, is_work_night: number) => {
        let fd_id_s = [];
        if (id == 3 && is_work_night == 1) {
            fd_id_s.push("1");
        } else if ((id == 4 || id == 5) && is_work_night == 1) { // if morning or afternoon ( id =4, id = 5) and is_work_night = 1.
            fd_id_s.push(id);
            fd_id_s.push('2');
            fd_id_s.push('6');
        } else {
            if (id > 0)
                fd_id_s.push(id);
        }
        if (id == 2) {
            fd_id_s.push("6");
        }
        return fd_id_s;
    }
    onUpdate = () => {
        let em_id: any = Global.getEmId();
        let items = [];
        for (let i = 0; i < 7; i++) {
            const freeName: string = "free" + i;
            const is_night_work: string = "is_night_work" + i;
            const ft_id = this.getFtId(this.state[freeName], this.state[is_night_work]);
            let item = {
                em_id: em_id,
                ft_id: ft_id,
                ef_date: this.state.weekList[i]["date1"],
                ef_note: this.state[`ef_note${i}`],
            }
            items.push(item);
        }
        const data = {
            create: items
        }
        const that = this;
        fetch(URLS.SAVE_FREE_TIME, {
            method: 'post',
            body: JSON.stringify(data),
        }).then(async function (response) {
            let ret = await response.json();
            if (ret.success) {
                that.alertRef.current.open("成功", "保存されました。");
            } else {
                that.alertRef.current.open("エラー", "保存できませんでした。");
            }
            that.setState({
                loading: false,
            });
            //return response.json();
        }).catch(error => {
            this.setState({ loading: false, errorMessage: error.toString() });
            if (this.alertRef.current)
                this.alertRef.current.open("エラー", "保存できませんでした。");
        });
    }
    public render() {
        let now = new Date(Date.now() - 864e5).getTime();

        return (
            <div>
                {this.state.loading ?
                    <Loading />
                    : <></>
                }
                <div className="schedule_edit_inner">
                    <AlertDialog ref={this.alertRef} />
                    <div className="t_element">
                        <i className="fa fa-clock-o" aria-hidden="true"></i>
                        空き時間を設定する
                    </div>
                    <div className="both_ways mt_40">
                        <div className="nav" onClick={this.prevWeek}>
                            <i className="fa fa-chevron-left"></i>
                            前週
                        </div>
                        <div className="nav" onClick={this.nextWeek}>
                            翌週
                            <i className="fa fa-chevron-right"></i>
                        </div>
                    </div>
                    {
                        this.state.weekList.map(week => (
                            <div className="sc_element" key={week["idx"]}>
                                <div className="date f-meiryo-bold">{week["formatDate"]}</div>
                                <div>
                                    <label className="radio-item">
                                        <span className="wd_30 m_wd_20">
                                            <input type="radio" name={"free" + week["idx"]} value="7"
                                                onChange={this.onHandleChange} checked={this.state["free" + week["idx"]] == 7 ? true : false} disabled={week["time"] < now ? true : false} />
                                            <span className="radiobtn"></span>
                                        </span>
                                        <span className="txt">空きなし</span>
                                    </label>
                                    <label className="radio-item">
                                        <span className="wd_30 m_wd_20">
                                            <input type="radio" name={"free" + week["idx"]} value="3" onChange={this.onHandleChange} checked={this.state["free" + week["idx"]] == 3 ? true : false} disabled={week["time"] < now ? true : false} />
                                            <span className="radiobtn"></span></span>
                                        <span className="txt">終日</span>
                                    </label>
                                    <label className="radio-item">
                                        <span className="wd_30 m_wd_20">
                                            <input type="radio" name={"free" + week["idx"]} value="4" onChange={this.onHandleChange} checked={this.state["free" + week["idx"]] == 4 ? true : false} disabled={week["time"] < now ? true : false} />
                                            <span className="radiobtn"></span></span>
                                        <span className="txt">午前</span>
                                    </label>
                                    <label className="radio-item">
                                        <span className="wd_30 m_wd_20">
                                            <input type="radio" name={"free" + week["idx"]} value="5" onChange={this.onHandleChange} checked={this.state["free" + week["idx"]] == 5 ? true : false} disabled={week["time"] < now ? true : false} />
                                            <span className="radiobtn"></span></span>
                                        <span className="txt">午後</span>
                                    </label>
                                    <label className="radio-item">
                                        <span className="wd_30 m_wd_20">
                                            <input type="radio" name={"free" + week["idx"]} value="2" onChange={this.onHandleChange} checked={this.state["free" + week["idx"]] == 2 ? true : false} disabled={week["time"] < now ? true : false} />
                                            <span className="radiobtn"></span>
                                        </span>
                                        <span className="txt">夜間</span>
                                    </label>
                                    <label className="control-checkbox pd_s mr_25">
                                        <input type="checkbox" name={"is_night_work" + week["idx"]} value="1" disabled={(week["time"] < now || (this.state["free" + week["idx"]] != 3 && this.state["free" + week["idx"]] != 4 && this.state["free" + week["idx"]] != 5)) ? true : false} checked={this.state["is_night_work" + week["idx"]] == 1 ? true : false} onChange={this.onHandleChange} />
                                        <span className="control__indicator"></span>
                                        夜間可
                                    </label>
                                    <label className="control-checkbox pd_s mr_25">
                                        <input type="checkbox" name={"is_not_sure" + week["idx"]} value="1" disabled={(week["time"] < now) ? true : false} checked={this.state["is_not_sure" + week["idx"]] == 1 ? true : false} onChange={this.onHandleCancel} />
                                        <span className="control__indicator"></span>
                                        未確定
                                    </label>
                                    <label className="control-checkbox pd_0">
                                        <input type="text" name={"ef_note" + week["idx"]} value={this.state[`ef_note` + week["idx"]] ? this.state[`ef_note` + week["idx"]] : ''} onChange={this.onHandleCancel} />
                                    </label>
                                </div>
                            </div>
                        ))
                    }
                    <button className="grey-button yellow large_300 rb mt_40"
                        disabled={(this.state.weekList.length == 0 || this.state.weekList[this.state.weekList.length - 1]["time"] < now) ? true : false} onClick={this.onUpdate}>
                        <div className="inner" >
                            <i className="fa fa-pencil"></i>
                            カレンダーに登録
                        </div>
                    </button>
                </div>
            </div>
        );
    }
}
export default MyScheduleEditCom;

