import React, {Component} from 'react';
import '../scss/job.scss';
import '../scss/message.scss';
import {Link} from 'react-router-dom';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as asyncactions from '../../modules/chat/async-actions';
import { ChatActions } from '../../modules/chat/types';
import { IRootState } from '../../modules';
import * as URLS from '../../modules/urls';
import * as Global from '../../modules/global';
type MsgTemplateProps = {
    to:number,
}
export type MsgTemplateShowState = {
    isShownTemplate?: boolean,
    loading:boolean,
    total:number,
    items:any,
};

const mapStateToProps = ({ message }: IRootState, msgTemplateProps:MsgTemplateProps ) => {
    const {to} = msgTemplateProps;
    const {text} = message;
    return { text, to };
  }
  const mapDispatcherToProps = (dispatch: Dispatch<ChatActions>) => {
    return {
      writeMessage:(text:string ) => asyncactions.writeMessageAsync( dispatch, text ),
    }
  }  

class MsgTemplateShowCom extends Component<any, MsgTemplateShowState >{
    state = {
        isShownTemplate: false,
        loading:false,
        total:0,
        items:[],
    }
    showTemplate = () =>{
        this.setState(state => ({
            isShownTemplate: !state.isShownTemplate
          }));
    }

    setValue = (e:any) => {
        const value = e.currentTarget.dataset.value;
        this.props.writeMessage( value );
    }
    componentDidMount() {
        this.fetch( URLS.READ_MSG_TEMPLATE );
    } 
    fetch( url:string = "" ){

        let em_id:any = Global.getEmId();        
        if( em_id == "" ){
            return;
        }        
        this.setState({
            loading:true,
        });
        const sort = [{"property":"mgt_created","direction":"desc"}];
        url += "?em_id=" + em_id;
        url += "?page_all=1";
        url += "&sort=" + JSON.stringify(sort);
        const response = fetch( url ).then(async response => {    
            const data = await response.json();
            this.setState({
                total: data.total,
                items:data.items,
                loading:false,
            });
        }).catch(error => {
            this.setState({
                loading:false,
            });
        });
    }    
    public render(){
        return(
            <div>
                <div className = "fs12 ml_20 mt_20" style = {{color:"#B80B03"}}>メッセージを送信するごとに50ポイントが使用されます。</div>                
                <div className="pt_10 pb_10 ml_20 pointer" onClick = {this.showTemplate}>
                    <span className="fs12">テンプレートから入力する</span>
                    <i className="fa fa-chevron-down"></i>
                </div>
                {
                this.state.isShownTemplate ?(
                    <div className="tempate_inner">
                        <div className="template_content">
                            {
                                this.state.items.length == 0?<div className = "notifiy layout_center mt_20">テンプレートがありません。</div>
                                :
                                this.state.items.map( item =>(
                                    <div className="template_element" key = {item['mgt_id']}>
                                        <span className="fs12 mr_40" dangerouslySetInnerHTML = { {__html: item['mgt_note']} }></span>
                                        <button className="grey-button narrow-vv-small" data-value={item['mgt_note']} onClick = {this.setValue}>
                                            <div className="inner f-meiryo-bold">
                                                挿入		
                                            </div>
                                        </button>													
                                    </div>
                                ))
                            }
                        </div>
                    </div>):(
                        <></>
                    )         
                }
                <Link to = {{pathname:"/messagetemplate", state:{to:this.props.to}}}>
                    <button className="grey-button yellow large_300 rb mt_40 mb_30">
                        <div className="inner">
                            <i className="fa fa-pencil"></i>								
                            テンプレートを編集する
                        </div>
                    </button>				
                </Link>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatcherToProps )(MsgTemplateShowCom);