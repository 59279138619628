import React from 'react';
import '../scss/job.scss';
import Header from '../Include/Header'
import JobNavCom from "../Find/JobNavCom";
import ProviderProfileDetailCom from "./ProviderProfileDetailCom";
import Footer from '../Include/Footer';
import {JobDetailComProp} from '../../modules/job/types';
import * as Global from '../../modules/global';
import {Redirect} from 'react-router-dom';
import {MsgBrief} from '../../modules/chat/types';

class ProviderProfileView extends React.Component<any>{
    renderRedirect = () => {
        let role = Global.getRole();
        if( role !== "1" ){
            let url:string = '/choose';
            return <Redirect to = {url}/>
        }else{
            return <></>
        }
    }
    public render(){
        const url = this.props.match.url;
        let id = this.props.match.params.id?this.props.match.params.id:"";
        let isRightMenuShow = this.props.location && this.props.location.state && this.props.location.state.isRightMenuShow?this.props.location.state.isRightMenuShow:"1";
        let jobName = this.props.location.state && this.props.location.state.jobName?this.props.location.state.jobName:"";        
        let jbaId = this.props.location.state && this.props.location.state.jbaId?this.props.location.state.jbaId:"";
        let jobId = this.props.location.state && this.props.location.state.jobId?this.props.location.state.jobId:"";
        let ctrId = this.props.location.state && this.props.location.state.ctrId?this.props.location.state.ctrId:'';
        let docId = ( this.props.location && this.props.location.state && this.props.location.state.docId )?this.props.location.state.docId:'';
        let from =  ( this.props.location && this.props.location.state && this.props.location.state.from )?this.props.location.state.from:'';
        let to =  ( this.props.location && this.props.location.state && this.props.location.state.to )?this.props.location.state.to:'';
        const jobStatus = this.props.location.state && this.props.location.state.jobStatus?this.props.location.state.jobStatus:'';
        const is_from_message_list = this.props.location.state && this.props.location.state.is_from_message_list?this.props.location.state.is_from_message_list:0;
        
        const jba_is_direct = this.props.location.state && this.props.location.state.jba_is_direct?this.props.location.state.jba_is_direct:'';

        let is_not_available =  ( this.props.location && this.props.location.state && this.props.location.state.is_not_available )?this.props.location.state.is_not_available.toString():'0';
        let jobInfo:JobDetailComProp = {
            em_id:id,
            jobId:jobId,
            jobName:jobName,
            jbaId:jbaId,
            ctrId:ctrId,
        }
        let msgBrief:MsgBrief = {
            docId:docId,
            from:from,
            to:to,
            is_not_available:is_not_available,
            jobStatus:jobStatus,
            is_from_message_list:is_from_message_list,
            jba_is_direct:jba_is_direct,
        }
        let className = "job profile";
        let mode = 'narrow';
        if( isRightMenuShow === '0' ){
            className = "job profile message";
            mode = 'wide';
        }
        return (
            <div>
                <Header/>
                {this.renderRedirect()}                
                <div className="content-container" id = "content">
                    <div className="wrap-content">
                        <div className="main-title">職人のプロフィール</div>
                        <div className="job_container mt_40">
                            {
                                isRightMenuShow == '1'?
                                <JobNavCom to = {url} />
                                :<></>
                            }
                            <div className ={className}>
                                <ProviderProfileDetailCom jobInfo = {jobInfo} message = {msgBrief} mode = {mode}/>
                            </div>                            
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    } 
}
export default ProviderProfileView; 