import React from "react";
import "../scss/job.scss";
import "../scss/profile.scss";
import { Link } from "react-router-dom";
import * as URLS from "../../modules/urls";
import * as Global from "../../modules/global";
import Loading from "../Include/Loading";
import { AlertDialog } from "../Modal/Alert";
import * as FirebaseAPI from "../../firebase/api/message";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import * as asyncactions from "../../modules/chat/async-actions";
import { ChatActions } from "../../modules/chat/types";
import { IRootState } from "../../modules";
import { JobDetailComProp } from "../../modules/job/types";
import { MsgBrief } from "../../modules/chat/types";
import { ConfirmDialog } from "../Modal/Confirm";
import { updateMySystemContacts } from "../../firebase/api/message";
import moment from "moment";

const mapStateToProps = ({ message }: IRootState) => {
  const { text, messages } = message;
  return { text, messages };
};

const mapDispatcherToProps = (dispatch: Dispatch<ChatActions>) => {
  return {
    sendSystemMessage: (
      from: number,
      to: number,
      docId: string,
      cmsg: string,
      title: string = "",
      emt_id: number,
      isKuraha: number = 1,
      isFromMe: boolean = true,
      msg = ""
    ) =>
      asyncactions.sendSystemMessageAsync(
        dispatch,
        from,
        to,
        docId,
        cmsg,
        title,
        emt_id,
        isKuraha,
        isFromMe,
        msg
      ),
  };
};
type ReduxType = ReturnType<typeof mapDispatcherToProps> &
  JobDetailComProp &
  MsgBrief;

class ProviderProfileDetailCom extends React.Component<any> {
  alertRef: any;
  confirmRef: any;

  state = {
    isOKURL: true,
    isMore: false,
    loading: false,
    is_not_available: "0",

    em_id: "-1",
    em_photo: "",
    em_photo_s: "",
    em_firstname: "",
    em_lastname: "",
    em_firstname_kana: "",
    em_lastname_kana: "",
    em_birthday: "",
    year: "0",
    month: "0",
    day: "0",
    sex_id: "1",
    em_is_individual: "-1",
    em_company: "",
    sk_s: [],
    sk_label_s: "",
    business1: "0",
    business2: "0",
    business3: "0",
    sk_years_s: [],
    sk_years_1: "",
    sk_years_2: "",
    sk_years_3: "",
    em_skill_keyword: "",
    em_daily_price: "",
    em_phone: "",
    em_post: "",
    st_id: "0",
    st_label: "",
    ar_id: "0",
    ar_label: "",
    em_street_address: "",
    em_is_foreign: "0",
    em_overview: "",
    sp_s: "",
    sp_label_s: "",
    hi_id: "0",
    hi_label: "",
    em_hi_digit: "",
    pi_id: "0",
    ei_id: "0",
    ei_label: "",
    em_ei_digit: "",
    em_family_name: "",
    em_family_relation: "",
    em_family_phone: "",
    em_recent_health: "",
    em_blood_presure_hi: "",
    em_blood_presure_low: "",
    blt_id: "0",
    em_special_health: "",
    sh_label_s: "",
    sh_s: "",
    em_sh_note: "",
    em_qualify_country: "",
    em_qualify_lecture: "",
    em_qualify_lecture_special: "",
    em_stuff: [],
    ctrc_value_w: "0",
    cnt_ctr: "0",
    cnt_ctrc_w: "0",
    cnt_jba: "0",
    cnt_jba_direct: "0",
    has_relation: "0",
    pi_label: "",
    blt_name: "",
  };
  constructor(props: any) {
    super(props);
    this.alertRef = React.createRef();
    this.confirmRef = React.createRef();
  }
  componentDidMount() {
    const me_em_id = Global.getEmId();
    if (!this.props.jobInfo || !this.props.jobInfo.em_id) {
      return;
    }
    let em_id: any = this.props.jobInfo.em_id;
    if (me_em_id === "" || em_id === "") {
      this.setState({
        isOKURL: false,
      });
      return;
    }
    if (this.props.message) {
      let docId =
        this.props.message && this.props.message.docId
          ? this.props.message.docId
          : "";
      let from =
        this.props.message && this.props.message.from
          ? this.props.message.from
          : "";
      let to =
        this.props.message && this.props.message.to
          ? this.props.message.to
          : "";
      let is_not_available =
        this.props.message && this.props.message.is_not_available
          ? this.props.message.is_not_available
          : "0";
      if (docId !== "" && from !== "" && to !== "") {
        FirebaseAPI.setAlreadyViewed(me_em_id!, to, docId);
        //FirebaseAPI.deleteDocument( from, to, docId );
      }
      if (is_not_available === "1") {
        this.setState({
          is_not_available: is_not_available,
        });
        return;
      }
    }

    let keyword = "worker";
    let url =
      URLS.GET_PROFILE +
      "?em_id=" +
      em_id +
      "&keyword=" +
      keyword +
      "&inc_cnt=1" +
      "&sta_em_id=" +
      me_em_id +
      "&inc_em_stuff=1";
    const jobId = this.props.jobInfo.jobId ? this.props.jobInfo.jobId : "";
    if (jobId != "") {
      url += "&sta_jb_id=" + jobId;
    }
    const that = this;
    this.setState({
      loading: true,
    });
    fetch(url, {
      method: "get",
    })
      .then(async function (response) {
        let ret = await response.json();
        let dateList = Global.DateToString(ret.emp.em_birthday);
        let sk_years_s = Global.getSkills(ret.emp.sk_years_s);
        let skill_labels = Global.getSkills(ret.emp.sk_label_s);
        that.setState({
          em_id: ret.emp.em_id,
          em_firstname: ret.emp.em_firstname,
          em_lastname: ret.emp.em_lastname,
          em_firstname_kana: ret.emp.em_firstname_kana,
          em_lastname_kana: ret.emp.em_lastname_kana,
          em_birthday: ret.emp.em_birthday ? ret.emp.em_birthday : "",
          sex_id: ret.emp.sex_id,
          em_phone: ret.emp.em_phone ? ret.emp.em_phone : "",
          em_is_individual: ret.emp.em_is_individual,
          em_company: ret.emp.em_company,
          sk_s: [],
          sk_label_s: ret.emp.sk_label_s,
          business1: skill_labels.length >= 1 ? skill_labels[0] : "",
          business2: skill_labels.length >= 2 ? skill_labels[1] : "",
          business3: skill_labels.length >= 3 ? skill_labels[2] : "",
          sk_years_s: [],
          sk_years_1: sk_years_s.length >= 1 ? sk_years_s[0] : "",
          sk_years_2: sk_years_s.length >= 2 ? sk_years_s[1] : "",
          sk_years_3: sk_years_s.length >= 3 ? sk_years_s[2] : "",
          em_skill_keyword: ret.emp.em_skill_keyword,
          em_daily_price: ret.emp.em_daily_price ? ret.emp.em_daily_price : "",
          em_post: ret.emp.em_post,
          st_id: ret.emp.st_id == null ? "0" : ret.emp.st_id,
          st_label: ret.emp.st_label == null ? "" : ret.emp.st_label,
          ar_id: ret.emp.ar_id == null ? "0" : ret.emp.ar_id,
          ar_label: ret.emp.ar_label == null ? "" : ret.emp.ar_label,
          em_street_address: ret.emp.em_street_address,
          em_is_foreign:
            ret.emp.em_is_foreign == null ? "0" : ret.emp.em_is_foreign,
          em_overview: ret.emp.em_overview,
          sp_s: ret.emp.sp_s,
          sp_label_s: ret.emp.sp_label_s ? ret.emp.sp_label_s : "",
          hi_id: ret.emp.hi_id == null ? "0" : ret.emp.hi_id,
          hi_label: ret.emp.hi_label == null ? "" : ret.emp.hi_label,
          em_hi_digit: ret.emp.em_hi_digit,
          pi_id: ret.emp.pi_id == null ? "0" : ret.emp.pi_id,
          pi_label: ret.emp.pi_label == null ? "" : ret.emp.pi_label,
          ei_id: ret.emp.ei_id == null ? "0" : ret.emp.ei_id,
          ei_label: ret.emp.ei_label == null ? "" : ret.emp.ei_label,
          em_ei_digit: ret.emp.em_ei_digit,
          em_family_name: ret.emp.em_family_name,
          em_family_relation: ret.emp.em_family_relation,
          em_family_phone: ret.emp.em_family_phone,
          em_recent_health: ret.emp.em_recent_health && ret.emp.em_recent_health != '00/00/0000'
            ? moment(ret.emp.em_recent_health, "MM/DD/YYYY").format(
                "YYYY/MM/DD"
              )
            : "",
          em_blood_presure_hi: ret.emp.em_blood_presure_hi
            ? ret.emp.em_blood_presure_hi
            : "",
          em_blood_presure_low: ret.emp.em_blood_presure_low
            ? ret.emp.em_blood_presure_low
            : "",
          blt_id: ret.emp.blt_id == null ? "0" : ret.emp.blt_id,
          blt_name: ret.emp.blt_name == null ? "" : ret.emp.blt_name,
          em_special_health: ret.emp.em_special_health && ret.emp.em_special_health != '00/00/0000'
            ? moment(ret.emp.em_special_health, "MM/DD/YYYY").format(
                "YYYY/MM/DD"
              )
            : "",
          sh_label_s: ret.emp.sh_label_s ? ret.emp.sh_label_s : "",
          sh_s: ret.emp.sh_s,
          em_sh_note: ret.emp.em_sh_note,
          em_qualify_country: ret.emp.em_qualify_country,
          em_qualify_lecture: ret.emp.em_qualify_lecture,
          em_qualify_lecture_special: ret.emp.em_qualify_lecture_special,
          em_stuff: ret.emp.em_stuff ? ret.emp.em_stuff : [],
          ctrc_value_w: ret.emp.ctrc_value_w ? ret.emp.ctrc_value_w : "0",
          cnt_ctr: ret.emp.cnt_ctr ? ret.emp.cnt_ctr : "0",
          cnt_jba: ret.emp.cnt_jba ? ret.emp.cnt_jba : "0",
          cnt_ctrc_w: ret.emp.cnt_ctrc_w ? ret.emp.cnt_ctrc_w : "0",
          cnt_jba_direct: ret.emp.cnt_jba_direct ? ret.emp.cnt_jba_direct : "0",
          em_photo: ret.emp.em_photo ? ret.emp.em_photo : "",
          em_photo_s: ret.emp.em_photo_s ? ret.emp.em_photo_s : "",
          has_relation: ret.emp.has_relation ? ret.emp.has_relation : "0",
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false, errorMessage: error.toString() });
      });
  }
  renderSkill(skillStr: string) {
    const skills = skillStr.split(",");
    let ret = [];
    for (let i = 0; i < skills.length; i++) {
      ret.push(
        <span className="tag-mg fs14" key={i}>
          <span className="tag-inner">{skills[i]}</span>
        </span>
      );
    }
    return ret;
  }
  onDirectGiveImplement = () => {
    const that = this;
    let em_id: string | null = Global.getEmId();
    let jbaId = this.props.jobInfo.jbaId ? this.props.jobInfo.jbaId : "";
    let jobId = this.props.jobInfo.jobId ? this.props.jobInfo.jobId : "";
    let jobName = this.props.jobInfo.jobName ? this.props.jobInfo.jobName : "";
    let w_em_id = this.props.jobInfo.em_id ? this.props.jobInfo.em_id : "";
    let data = {
      jba_id: jbaId,
    };

    if (
      !em_id ||
      em_id === "" ||
      jbaId === "" ||
      jobId === "" ||
      jobName === "" ||
      w_em_id === ""
    ) {
      return;
    }
    const point = Global.getPoint();
    if (point < 50) {
      this.alertRef.current.open("お知らせ", "ポイントが不足しています。");
      return;
    }
    this.setState({
      loading: true,
    });
    fetch(URLS.CONTRACT_JOB, {
      method: "post",
      body: JSON.stringify(data),
    })
      .then(async function (response) {
        let ret = await response.json();
        if (ret.success) {
          let em_boss_company: any =
            localStorage.getItem("em_boss_company") !== undefined
              ? localStorage.getItem("em_boss_company")?.toString()
              : "ニックネーム";
          if (em_boss_company === "") {
            em_boss_company = "ニックネーム";
          }
          const contract = ret.data;
          let workerName: string = "";
          if (contract) {
            workerName = contract.em_lastname + contract.em_firstname + "さん";
          }
          // let msg: string = em_boss_company + "が" + jobName + "にあなたを選定しました。";
          let contactMsg: string =
            `<pre jb_id="${jobId}" type="contract">` +
            jobName +
            `</pre>` +
            "にあなたを選定しました。";
          let myMsg: string =
            `<pre jb_id="${jobId}" type="contract">` +
            jobName +
            `</pre>` +
            "の仕事にあなたを選定しました。";
          const systemMsgTitle: string = `仕事の選定がありました`;
          //                let docId:string = em_id +"_"+ jobId + "_" + jbaId;
          let docId: string = em_id!;
          that.props.sendSystemMessage(
            em_id,
            parseInt(w_em_id),
            docId,
            contactMsg,
            systemMsgTitle,
            parseInt(Global.getRole()!),
            1,
            false,
            myMsg
          );

          const boss_msg: string =
            `<pre jb_id="${jobId}" type="contract">` +
            jobName +
            `</pre>` +
            `の仕事に${workerName}を選定しました。`;
          updateMySystemContacts(
            `contact_${em_id}`,
            w_em_id,
            parseInt(w_em_id),
            parseInt(em_id!),
            "仕事に職人を選定しました",
            boss_msg,
            parseInt(Global.getRole()!),
            true
          );
          that.alertRef.current.open(
            "成功",
            `仕事に${workerName}を選定しました。`,
            true
          );
        } else {
          that.alertRef.current.open(
            "エラー",
            "仕事に職人を選定ことができませんでした。"
          );
        }
        that.setState({
          loading: false,
        });
      })
      .catch((error) => {
        this.alertRef.current.open(
          "エラー",
          "仕事を依頼することができませんでした。"
        );
        this.setState({ loading: false, errorMessage: error.toString() });
      });
  };
  onSelectApplier = () => {
    let em_id: any = Global.getEmId();
    let jbaId = this.props.jobInfo.jbaId ? this.props.jobInfo.jbaId : "";
    let jobId = this.props.jobInfo.jobId ? this.props.jobInfo.jobId : "";
    let jobName = this.props.jobInfo.jobName ? this.props.jobInfo.jobName : "";
    let w_em_id = this.props.jobInfo.em_id ? this.props.jobInfo.em_id : "";
    // let data = {
    //     jba_id: jbaId,
    // }

    if (
      em_id === "" ||
      jbaId === "" ||
      jobId === "" ||
      jobName === "" ||
      w_em_id === ""
    ) {
      return;
    }
    const point = Global.getPoint();
    if (point < 50) {
      this.alertRef.current.open("お知らせ", "ポイントが不足しています。");
      return;
    } else {
      this.confirmRef.current.open(
        "お知らせ",
        "50ポイント消費します。よろしいですか。"
      );
    }
  };
  // showEmployees = () => {
  //     for (let i = 0; i < this.state.em_stuff.length; i++) {
  //         const item: any = this.state.em_stuff[i];
  //         return (
  //             <div key={`employee_${i}`}>
  //                 <div className="d_element pd_b">
  //                     <span className="desc_title bold">従業員 {i + 1}</span>
  //                     <span className="desc_title">特記事項</span>
  //                     <span className="desc">{item.sp_label_s}</span>
  //                 </div>
  //                 {
  //                     this.state.has_relation == '1' ?
  //                         <div className="d_element pd_b">
  //                             <span className="desc_title">健康保険加入状況</span>
  //                             <span className="desc">{item.hi_label}</span>
  //                         </div>
  //                         : <></>
  //                 }
  //                 <div className="d_element pd_b">
  //                     <span className="desc_title">年金保険加入状況</span>
  //                     <span className="desc">{item.pi_label}</span>
  //                 </div>
  //                 {
  //                     this.state.has_relation == '1' ?
  //                         <div className="d_element pd_b">
  //                             <span className="desc_title">雇用保険番号下４桁</span>
  //                             <span className="desc">{item.em_ei_digit}</span>
  //                         </div>
  //                         : <></>
  //                 }
  //                 {
  //                     this.state.has_relation == '1' ?
  //                         <div className="d_element pd_b">
  //                             <span className="desc_title">家族連絡先</span>
  //                             <span className="desc">{item.em_family_name + " " + item.em_family_relation + " " + item.em_family_phone}</span>
  //                         </div>
  //                         : <></>
  //                 }
  //                 {
  //                     this.state.has_relation == '1' ?
  //                         <div className="d_element pd_b">
  //                             <span className="desc_title">血圧, 血液型</span>
  //                             <span className="desc">{item.em_blood_presure_low + (item.em_blood_presure_hi != '' ? (" ~ " + item.em_blood_presure_hi) : '') + (this.state.blt_name != '' ? (", " + this.state.blt_name) : '')}</span>
  //                         </div>
  //                         : <></>
  //                 }
  //                 {
  //                     <div className="d_element pd_b">
  //                         <span className="desc_title">最近の健康診断日</span>
  //                         <span className="desc">{item.em_recent_health_year ? (item.em_recent_health_year + "年" + item.em_recent_health_month + "月") : ''}</span>
  //                     </div>
  //                 }
  //                 {
  //                     this.state.has_relation == '1' ?
  //                         <div className="d_element pd_b">
  //                             <span className="desc_title">特殊健康診断</span>
  //                             <span className="desc">{item.em_special_health_year != '' ? (item.em_special_health_year + "年" + item.em_special_health_month + "月") : ''}</span>
  //                         </div>
  //                         : <></>
  //                 }
  //                 <div className="d_element pd_b">
  //                     <span className="desc_title">保有資格</span>
  //                     <span className="desc">{"国家資格・免許：" + item.em_qualify_country + ", 技能講習：" + item.em_qualify_lecture + ", 特別教育：" + item.em_qualify_lecture_special}</span>
  //                 </div>
  //             </div>
  //         )
  //     }
  // }
  public render() {
    let jobId = this.props.jobInfo.jobId ? this.props.jobInfo.jobId : 0;
    let jbaId = this.props.jobInfo.jbaId ? this.props.jobInfo.jbaId : "";
    let ctrId = this.props.jobInfo.ctrId ? this.props.jobInfo.ctrId : "";
    const jobStatus = this.props.message ? this.props.message.jobStatus : "";
    const is_from_message_list = this.props.message
      ? this.props.message.is_from_message_list
      : 0;
    const jba_is_direct = this.props.message
      ? this.props.message.jba_is_direct
      : "0";
    const cnt_ctr = this.state.cnt_ctr ? this.state.cnt_ctr : "0";
    let cnt_jba = parseInt(this.state.cnt_jba ? this.state.cnt_jba : "0");
    let cnt_jba_direct = parseInt(
      this.state.cnt_jba_direct ? this.state.cnt_jba_direct : "0"
    );
    let contract_comment_count_for_job = this.state.cnt_ctrc_w;
    let ctrc_value_w = this.state.ctrc_value_w ? this.state.ctrc_value_w : "0";
    let em_company =
      this.state.em_is_individual === "1"
        ? "個人事業主"
        : this.state.em_company;
    let em_name = this.state.em_lastname + " " + this.state.em_firstname;
    const em_star = parseInt(ctrc_value_w);
    return (
      <>
        {this.state.is_not_available === "1" ? (
          <></>
        ) : (
          <div className="job_inner">
            {this.state.loading ? <Loading /> : <></>}
            <AlertDialog ref={this.alertRef} returnURL="/myprovider" />
            <ConfirmDialog
              ref={this.confirmRef}
              func={this.onDirectGiveImplement}
            />

            {jobId &&
            parseInt(jobId) > 0 &&
            cnt_jba - cnt_jba_direct > 0 &&
            cnt_ctr === "0" && ( !is_from_message_list || 
              is_from_message_list === 0 )? (
              <div
                className="fs12 ml_20 mb_10"
                style={{ color: "#B80B03", textAlign: "right" }}
              >
                「応募者を選定する」と、相手にメッセージが送信されます。50ポイントが使用されます。
              </div>
            ) : (
              <></>
            )}
            <div className="element both_ways">
              <div className="first">
                <Link
                  to={{
                    pathname: "/feedback/" + this.state.em_id,
                    state: {
                      em_photo_s: this.state.em_photo_s,
                      em_name: em_name,
                      em_company: em_company,
                      ctrc_value_w: ctrc_value_w,
                    },
                  }}
                >
                  <img alt="" src={URLS.IMAGE_URL + this.state.em_photo_s} />
                </Link>
                <Link
                  to={{
                    pathname: "/feedback/" + this.state.em_id,
                    state: {
                      em_photo_s: this.state.em_photo_s,
                      em_name: em_name,
                      em_company: em_company,
                      ctrc_value_w: ctrc_value_w,
                    },
                  }}
                >
                  <div className="star_container mt_5">
                    <span
                      className={em_star > 0 ? "star active" : "star"}
                    ></span>
                    <span
                      className={em_star > 1 ? "star active" : "star"}
                    ></span>
                    <span
                      className={em_star > 2 ? "star active" : "star"}
                    ></span>
                    <span
                      className={em_star > 3 ? "star active" : "star"}
                    ></span>
                    <span
                      className={em_star > 4 ? "star active" : "star"}
                    ></span>
                  </div>
                </Link>
              </div>
              <div
                className="second"
                style={
                  this.props.mode && this.props.mode === "wide"
                    ? { width: "49em" }
                    : {}
                }
              >
                <div className="company_info">
                  <div className="mr_30">
                    <div className="both_ways">
                      <div className="company_name">
                        <div className="fs14">{em_company}</div>
                        <div className="fs20">{em_name}</div>
                      </div>
                      <div>
                        <div className="fs14 map-icon mt_10">
                          {this.state.st_label}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>{this.renderSkill(this.state.sk_label_s)}</div>
                      <div className="line">
                        <span>{Global.getAge(this.state.em_birthday)}歳</span> :
                        <span className="ml_20">
                          {this.state.sex_id == "1" ? "男性" : "女性"}
                        </span>
                      </div>
                      <div className="line">{this.state.em_overview}</div>
                    </div>
                  </div>
                  <div>
                    {
                      //if you come here without jobId
                      (is_from_message_list &&
                        is_from_message_list === 1) ||
                      (cnt_jba_direct > 0 && jobId && parseInt(jobId)>1 )? (
                      <div className="btn-container">
                        <Link to={"/message/" + this.state.em_id}>
                          <div className="grey-button v-small">
                            <div className="inner font_14 message_send">
                              メッセージを送る
                            </div>
                          </div>
                        </Link>
                      </div>
                    ) :  ((!jobId || jobId == "" || parseInt(jobId) < 1) || parseInt(cnt_ctr) > 0) ? (
                        <div className="btn-container">
                          <Link
                            to={{
                              pathname: "/directrequest/" + this.state.em_id,
                              state: {
                                em_photo_s: this.state.em_photo_s,
                                em_name: em_name,
                                em_company: em_company,
                              },
                            }}
                          >
                            <div className="grey-button v-small">
                              <div className="inner font_14 message_send">
                                仕事を直接依頼する
                              </div>
                            </div>
                          </Link>
                        </div>
                      ) : parseInt(cnt_ctr) < 1 &&
                        cnt_jba - cnt_jba_direct > 0 ? (
                        <div className="btn-container">
                          <div
                            className="grey-button v-small"
                            onClick={this.onSelectApplier}
                          >
                            <div className="inner font_14 message_send">
                              応募者を選定する
                            </div>
                          </div>
                        </div>
                      ) : parseInt(cnt_ctr) > 0 ? (
                        <div className="btn-container">
                          <Link
                            to={{
                              pathname: "/directrequest/" + this.state.em_id,
                              state: {
                                em_photo_s: this.state.em_photo_s,
                                em_name: em_name,
                                em_company: em_company,
                              },
                            }}
                          >
                            <div className="grey-button v-small">
                              <div className="inner font_14 message_send">
                                仕事を直接依頼する
                              </div>
                            </div>
                          </Link>
                        </div>
                      ) : (
                        <></>
                      )
                    }
                    {jobId &&
                    parseInt(jobId) > 0 &&
                    jobStatus == "jobapply" &&
                    (jba_is_direct == undefined || jba_is_direct != "1") ? (
                      <div className="btn-container">
                        <Link to={"/jobview"}>
                          <div className="grey-button no_background_blue v-small">
                            <div className="inner fs25 message_send">
                              応募のあった仕事
                            </div>
                          </div>
                        </Link>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="profile_detail">
                  {/* {jobId != "" &&
                  jbaId != "" &&
                  contract_count_for_job == "1" &&
                  contract_comment_count_for_job == "0" ? (
                    <div className="grey-button yellow large_300 rb mt_10">
                      <Link
                        to={{
                          pathname: "/leave_feedback/" + this.state.em_id,
                          state: {
                            em_photo_s: this.state.em_photo_s,
                            ctrId: ctrId,
                            is_boss: 0,
                          },
                        }}
                      >
                        <div className="inner">
                          <i className="fa fa-pencil"></i>
                          評価をつける
                        </div>
                      </Link>
                    </div>
                  ) : (
                    <></>
                  )} */}
                  <div className="grey-button yellow large_300 rb mt_10">
                    <Link
                      to={{
                        pathname: "/job/" + this.state.em_id,
                        state: { em_name: em_name, is_boss: 0 },
                      }}
                    >
                      <div className="inner">
                        <i className="fa fa-pencil"></i>
                        {em_name + "さんとの仕事"}
                      </div>
                    </Link>
                  </div>
                  <div className="grey-button yellow large_300 rb mt_10">
                    <Link
                      to={{
                        pathname: "/feedback/" + this.state.em_id,
                        state: {
                          em_photo_s: this.state.em_photo_s,
                          em_name: em_name,
                          em_company: em_company,
                          ctrc_value_w: ctrc_value_w,
                        },
                      }}
                    >
                      <div className="inner">
                        <i className="fa fa-pencil"></i>
                        評価を見る
                      </div>
                    </Link>
                  </div>

                  <div className="grey-button yellow large_300 rb mt_10">
                    <Link
                      to={{
                        pathname: "/schedule/" + this.state.em_id,
                        state: {
                          em_photo_s: this.state.em_photo_s,
                          em_name: em_name,
                          em_company: em_company,
                          ctrc_value_w: ctrc_value_w,
                        },
                      }}
                    >
                      <div className="inner">
                        <i className="fa fa-calendar-minus-o"></i>
                        空き時間を確認する
                      </div>
                    </Link>
                  </div>
                  {this.state.business1 != "" ? (
                    <div className="d_element pd_b">
                      <span className="desc_title">業種１（経験年数）</span>
                      <span className="desc">
                        {this.state.business1}（
                        {this.state.sk_years_1 != ""
                          ? this.state.sk_years_1 + "年"
                          : ""}
                        ）
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                  {this.state.business2 != "" ? (
                    <div className="d_element pd_b">
                      <span className="desc_title">業種２（経験年数）</span>
                      <span className="desc">
                        {this.state.business2}（
                        {this.state.sk_years_2 != ""
                          ? this.state.sk_years_2 + "年"
                          : ""}
                        ）
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                  {this.state.business3 != "" ? (
                    <div className="d_element pd_b">
                      <span className="desc_title">業種３（経験年数）</span>
                      <span className="desc">
                        {this.state.business3}（
                        {this.state.sk_years_3 != ""
                          ? this.state.sk_years_3 + "年"
                          : ""}
                        ）
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className="d_element pd_b">
                    <span className="desc_title">検索用業種フリーワード</span>
                    <span className="desc">{this.state.em_skill_keyword}</span>
                  </div>
                  <div className="d_element pd_b">
                    <span className="desc_title">最低金額</span>
                    <span className="desc">
                      {this.state.em_daily_price !== ""
                        ? Global.moneyFormat(this.state.em_daily_price) +
                          "円/人工"
                        : ""}
                    </span>
                  </div>
                  <div className="d_element pd_b mt_10">
                    <span className="desc_title">現住所</span>
                    <span className="desc">
                      {this.state.has_relation == "1" ? (
                        `〒${this.state.em_post} `
                      ) : (
                        <></>
                      )}
                      {this.state.st_label + this.state.ar_label}
                      {this.state.has_relation == "1" ? (
                        `${this.state.em_street_address} `
                      ) : (
                        <></>
                      )}
                    </span>
                  </div>
                  {/* {
                                            this.state.has_relation == '1' ?
                                                <div className="d_element pd_b mt_10">
                                                    <span className="desc_title">電話番号</span>
                                                    <span className="desc">{this.state.em_phone}</span>
                                                </div>
                                                : <></>
                                        } */}
                  <div className="d_element pd_b">
                    <span className="desc_title">国籍</span>
                    <span className="desc">
                      {this.state.em_is_foreign == "0" ? "日本" : "外国"}
                    </span>
                  </div>
                  {this.state.has_relation == "1" ? (
                    <div className="d_element pd_b">
                      <span className="desc_title">特記事項</span>
                      <span className="desc">{this.state.sp_label_s}</span>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="d_element pd_b">
                    <span className="desc_title">健康保険加入状況</span>
                    <span className="desc">
                      {this.state.hi_label}
                      {this.state.has_relation == "1"
                        ? ` ${this.state.em_hi_digit}`
                        : ` `}
                    </span>
                  </div>
                  <div className="d_element pd_b">
                    <span className="desc_title">年金保険加入状況</span>
                    <span className="desc">{this.state.pi_label}</span>
                  </div>
                  <div className="d_element pd_b">
                    <span className="desc_title">雇用保険加入状況</span>
                    <span className="desc">
                      {this.state.ei_label}
                      {this.state.has_relation == "1"
                        ? ` ${this.state.em_ei_digit}`
                        : ` `}
                    </span>
                  </div>
                  {this.state.has_relation == "1" ? (
                    <div className="d_element pd_b">
                      <span className="desc_title">家族連絡先</span>
                      {/* <span className="desc">{this.state.em_family_name + " " + this.state.em_family_relation + " " + this.state.em_family_phone}</span> */}
                      <span className="desc">
                        {this.state.em_family_name +
                          " " +
                          this.state.em_family_relation}
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                  {this.state.has_relation == "1" ? (
                    <div className="d_element pd_b">
                      <span className="desc_title">血圧, 血液型</span>
                      <span className="desc">
                        {this.state.em_blood_presure_low +
                          (this.state.em_blood_presure_hi != ""
                            ? " ~ " + this.state.em_blood_presure_hi
                            : "") +
                          (this.state.blt_name != ""
                            ? ", " + this.state.blt_name
                            : "")}
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                  {this.state.has_relation == "1" ? (
                    <div className="d_element pd_b">
                      <span className="desc_title">最近の健康診断日</span>
                      <span className="desc">
                        {this.state.em_recent_health
                          ? this.state.em_recent_health
                          : ""}
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                  {this.state.has_relation == "1" ? (
                    <div className="d_element pd_b">
                      <div className="desc_title">特殊健康診断</div>
                      <div className="desc">
                        {this.state.em_special_health != ""
                          ? this.state.em_special_health
                          : ""}
                      </div>
                      <div className="desc">
                        {this.state.sh_label_s != ""
                          ? this.state.sh_label_s
                          : ""}
                        {this.state.em_sh_note != ""
                          ? ` ` + this.state.em_sh_note
                          : ""}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="d_element pd_b">
                    <span className="desc_title">保有資格</span>
                    <div className="desc">
                      {"国家資格・免許：" + this.state.em_qualify_country}{" "}
                    </div>
                    <div className="desc">
                      {"技能講習：" + this.state.em_qualify_lecture}{" "}
                    </div>
                    <div className="desc">
                      {"特別教育：" + this.state.em_qualify_lecture_special}{" "}
                    </div>
                  </div>
                  {
                    //this.showEmployees()
                  }
                </div>
              </div>
            </div>
            {jobId &&
            parseInt(jobId) > 0 &&
            cnt_jba - cnt_jba_direct > 0 &&
            cnt_ctr === "0" && ( !is_from_message_list || 
              is_from_message_list === 0 ) ? (
              <div
                className="fs12 ml_20  mb_10"
                style={{ color: "#B80B03", textAlign: "right" }}
              >
                「応募者を選定する」と、相手にメッセージが送信されます。50ポイントが使用されます。
              </div>
            ) : (
              <></>
            )}
            <div className="button-container">
              {
                //if you come here without jobId
                is_from_message_list && is_from_message_list === 1 ? (
                  <div className="grey-button large mt_20">
                    <Link
                      to={{
                        pathname: "/directrequest/" + this.state.em_id,
                        state: {
                          em_photo_s: this.state.em_photo_s,
                          em_name: em_name,
                          em_company: em_company,
                        },
                      }}
                    >
                      <div className="inner font_14 message_send">
                        仕事を直接依頼する
                      </div>
                    </Link>
                  </div>
                ) : (!jobId || jobId == "" || parseInt(jobId) < 1) || parseInt(cnt_ctr) > 0 ? (
                  <div className="grey-button large mt_20">
                    <Link
                      to={{
                        pathname: "/directrequest/" + this.state.em_id,
                        state: {
                          em_photo_s: this.state.em_photo_s,
                          em_name: em_name,
                          em_company: em_company,
                        },
                      }}
                    >
                      <div className="inner font_14 message_send">
                        仕事を直接依頼する
                      </div>
                    </Link>
                  </div>
                ) : parseInt(cnt_ctr) < 1 && cnt_jba - cnt_jba_direct > 0 ? (
                  <div className="grey-button large">
                    <div className="inner" onClick={this.onSelectApplier}>
                      応募者を選定する
                      <i className="fa fa-angle-right"></i>
                    </div>
                  </div>
                ) : (
                  <div className="grey-button large mt_20">
                    <Link
                      to={{
                        pathname: "/directrequest/" + this.state.em_id,
                        state: {
                          em_photo_s: this.state.em_photo_s,
                          em_name: em_name,
                          em_company: em_company,
                        },
                      }}
                    >
                      <div className="inner font_14 message_send">
                        仕事を直接依頼する
                      </div>
                    </Link>
                  </div>
                )
              }
              <div className="grey-button large mt_20">
                <Link to={"/message/" + this.state.em_id}>
                  <div className="inner">
                    メッセージを送る
                    <i className="fa fa-angle-right"></i>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatcherToProps
)(ProviderProfileDetailCom);
