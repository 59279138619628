import React, { Component } from 'react';
import '../scss/job.scss';
import '../scss/editprofile.scss';
import { VALIDATORS, VALIDATION_MESSAGES } from '../../modules/global';
import SimpleReactValidator from 'simple-react-validator';
import { BasicDropDown } from '../Include/dropdown/BasicDropDown';
import BasicCheckBox from '../Include/checkbox/BasicCheckBox';
import { Link } from 'react-router-dom';
import * as URLS from '../../modules/urls';
import { connect } from 'react-redux';
import { IRootState } from '../../modules';
import { Dispatch } from 'redux';
import * as asyncactions from '../../modules/createinfo/async-actions';
import { CreateInfo, CreateInfoActions } from '../../modules/createinfo';
import { Redirect } from 'react-router-dom';
import { getLabel } from '../../modules/basic/types';

const mapStateToProps = ({ create }: IRootState) => {
    const { isUserExist, page, createInfo } = create;
    return { isUserExist, page, createInfo };
}
const mapDispatcherToProps = (dispatch: Dispatch<CreateInfoActions>) => {
    return {
        create: (createInfo: CreateInfo, page: number) => asyncactions.createAsync(dispatch, createInfo, page),
    }
}
type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;

class CreateAccountCom extends Component<ReduxType>{
    validator: SimpleReactValidator;
    state = {
        loading: false,
        errorMessage: '',
        page: -1,
        isUserExist: false,
        //isPhoneExist:true,
        isPhoneExist: false,
        emt_id: 1,
        em_name: '',
        em_passwd: '',
        st_id: "0",
        ar_id: "0",
        em_phone: '',
        sk_s: new Array<string>(),
    };
    areaRef: any;

    constructor(props: any) {
        super(props);
        this.validator = new SimpleReactValidator({
            messages: VALIDATION_MESSAGES,
            autoForceUpdate: this,
            className: 'error',
            validators: VALIDATORS
        });
        this.areaRef = React.createRef();
    }
    onHandleChange = (e: any) => {
        let value:string = e.target.value;
        let name: string = e.target.name;
        this.setState({ [name]: value });
        if (name === "em_name") {
            this.setState({ isUserExist: false });
        } else if (name === "emt_id") {
            this.setState({ sk_s: [] });
            //this.state.sk_s = [];
        } else if (name === "sk_s[]") {
            const target = e.target;
            const checked = target.type === "checkbox" ? target.checked : '';
            if (!checked) {
                const index = this.state.sk_s.indexOf(value);
                if (index > -1) {
                    let business: Array<any> = this.state.sk_s.slice(0, index);
                    business = business.concat(this.state.sk_s.slice(index + 1, this.state.sk_s.length));
                    this.setState({
                        sk_s: business,
                    });
                }
            } else {
                const business = this.state.sk_s.concat(value);
                this.setState({
                    sk_s: business,
                });
            }
            this.validator.showMessageFor(name);
        } else if (name === "st_id") {
            this.setState({
                ar_id: "",
            });
            if (this.areaRef) {
                const areaURL: string = this.getAreaURL(value);
                this.areaRef.current.fetch(areaURL);
            }
        }
    }
    onHandleBlur = (e: any) => {
        let name: string = e.target.name;
        if (name === 'em_name') {
            const value: string = e.target.value;
            if (value.trim() === "") {
                this.validator.showMessageFor(name);
            } else {
                this.OncheckUnique( value );
            }
        } else if (name === 'em_phone') {
            const value: string = e.target.value;
            if (value.trim() === "") {
                this.validator.showMessageFor(name);
            } else {
                //this.OncheckUniquePhone( value );
            }
        } else {
            this.validator.showMessageFor(name);
        }
    }
    OncheckUnique = async (value: string) => {
        fetch(URLS.CHECK_USER_UNIQUE + "?em_name=" + this.state.em_name).then(async response => {
            const data = await response.json();
            // check for error response
            if (!response.ok) {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                this.setState({ isUserExist: true });
                this.validator.showMessageFor("em_name");
                return Promise.reject(error);
            } else {
                if (!data.is_valid) {
                    this.setState({ isUserExist: true });
                    this.validator.showMessageFor("em_name");
                } else {
                    this.setState({ isUserExist: false });
                }
            }
        })
            .catch(error => {
                this.setState({ isUserExist: true, errorMessage: error.toString() });
                console.error('There was an error!', error);
            });
    }
    OncheckUniquePhone = async (value: string) => {
        fetch(URLS.CHECK_PHONE_UNIQUE + "?em_phone=" + this.state.em_phone).then(async response => {
            const data = await response.json();
            // check for error response
            if (!response.ok) {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                this.setState({ isPhoneExist: true });
                this.validator.showMessageFor("em_phone");
                return Promise.reject(error);
            } else {
                if (!data.is_valid) {
                    this.setState({ isPhoneExist: true });
                    this.validator.showMessageFor("em_phone");
                } else {
                    this.setState({ isPhoneExist: false });
                }
            }
        })
            .catch(error => {
                this.setState({ isPhoneExist: true, errorMessage: error.toString() });
                console.error('There was an error!', error);
            });
    }
    onClickHandler = (e: any) => {
        let flag = this.validator.allValid();
        if (flag) {
            const createInfo: CreateInfo = {
                emt_id: this.state.emt_id,
                em_name: this.state.em_name,
                em_passwd: this.state.em_passwd,
                st_id: this.state.st_id,
                ar_id: this.state.ar_id,
                em_phone: this.state.em_phone,
                sk_s: this.state.sk_s,
            }
            this.props.create(createInfo, 1);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    componentDidMount() {
        this.setState({
            emt_id: this.props.createInfo.emt_id,
            em_name: this.props.createInfo.em_name,
            em_passwd: this.props.createInfo.em_passwd,
            st_id: this.props.createInfo.st_id,
            ar_id: this.props.createInfo.ar_id,
            em_phone: this.props.createInfo.em_phone,
            sk_s: this.props.createInfo.sk_s,
        });
        if (this.areaRef) {
            const areaURL: string = this.getAreaURL(this.props.createInfo.st_id);
            this.areaRef.current.fetch(areaURL);
        }
    }
    renderRedirect = () => {
        if (this.props.page === 1) {
            let url = '/createconfirm';
            return <Redirect to={url} />
        }
        return <></>
    }
    getAreaURL = (_prefecture: string) => {
        const prefectureList = getLabel(_prefecture);
        let prefectureKey = "-1";
        if (prefectureList != null) {
            prefectureKey = prefectureList.key;
        }
        const areaURL: string = URLS.GET_AREA_URL + "&st_id=" + prefectureKey;
        return areaURL;
    }
    public render() {
        const areaURL: string = this.getAreaURL("0");
        //this.validator.purgeFields();
        return (
            <>
                {this.renderRedirect()}
                <div className="edit_profile_inner">
                    <div className="element border_none pb_0">
                        <div className="second_column">
                            <div className="wd_100 mr_40 mt_10 text_right"><span className="tag-mg fs12"><span className="tag-inner">ID</span></span></div>
                            <div className="wd_450">
                                <input className="input-txt border full_wide" type="text" name="em_name" value={this.state.em_name} placeholder="IDを入力してください" onChange={this.onHandleChange} onBlur={this.onHandleBlur} />
                                {this.validator.message('em_name', this.state.em_name, 'required')}
                                {this.validator.message('em_name', this.state.isUserExist, 'unique' )}
                            </div>
                        </div>
                    </div>
                    <div className="element">
                        <div className="second_column">
                            <div className="wd_100 mt_10 mr_40 text_right"><span className="tag-mg fs12"><span className="tag-inner">パスワード</span></span></div>
                            <div className="wd_450 mr_5 inline_block">
                                <input className="input-txt border full_wide" type="password" name="em_passwd" value={this.state.em_passwd} placeholder="パスワードを入力してください" onChange={this.onHandleChange} onBlur={this.onHandleBlur} />
                                <span className="fs12">パスワードは8文字以上必要です。</span>
                                {this.validator.message('password', this.state.em_passwd, 'required|min:8,string')}
                            </div>
                        </div>
                    </div>
                    <div className="element">
                        <div className="second_column">
                            <div className="wd_100 mr_40 text_right"><span className="tag-mg fs12"><span className="tag-inner">登録形態</span></span></div>
                            <div className="mr_20">
                                <label className="radio-item">
                                    <span className="wd_30 m_wd_20"><input type="radio" name="emt_id" value="1" checked={parseInt(this.state.emt_id.toString()) === 1 ? true : false} onChange={this.onHandleChange} /><span className="radiobtn"></span></span>
                                    <span className="txt">親方/会社</span>
                                </label>
                            </div>
                            <div className="mr_20">
                                <label className="radio-item">
                                    <span className="wd_30 m_wd_20"><input type="radio" name="emt_id" value="2" checked={parseInt(this.state.emt_id.toString()) === 2 ? true : false} onChange={this.onHandleChange} /><span className="radiobtn"></span></span>
                                    <span className="txt">職人</span>
                                </label>
                            </div>
                            <div className="mr_20">
                                <label className="radio-item">
                                    <span className="wd_30 m_wd_20"><input type="radio" name="emt_id" value="3" checked={parseInt(this.state.emt_id.toString()) === 3 ? true : false} onChange={this.onHandleChange} /><span className="radiobtn"></span></span>
                                    <span className="txt">親方/会社・職人</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="element">
                        <div className="second_column">
                            <div className="wd_100 mr_40 text_right"><span className="tag-mg fs12"><span className="tag-inner">業種</span></span></div>
                            <div className="flex_1">
                                <div>
                                    <span className="fs14 text-right mr_20 inline_block">業種は3つまで追加できます</span>
                                    <Link to="/job_type" target="_blank"><img alt='' src={URLS.BASE_ABSOLUTE_URL + "/images/icon_help.jpg"} className="mt_8" />
                                        <span className="fs14 text-right mr_5 inline_block">業種説明</span></Link>
                                </div>
                                <div>
                                    <BasicCheckBox
                                        className="full_wide" name="sk_s[]" idKey={"sk_id"} valueKey={"sk_label"} url={URLS.GET_DETAIL_SKILL} checkedValues={this.state.sk_s}
                                        onHandleChange={this.onHandleChange} onHandleBlur={this.onHandleBlur} emtType={this.state.emt_id} />
                                </div>
                                {this.validator.message('sk_s[]', this.state.sk_s, 'minselect:1|maxselect:3')}
                            </div>
                        </div>
                    </div>
                    <div className="element">
                        <div className="second_column">
                            <div className="wd_100 mr_40 text_right"><span className="tag-mg fs12"><span className="tag-inner">現住所</span></span></div>
                            <div className="wd_125 inline_block mr_5">
                                <BasicDropDown
                                    className="full_wide" name="st_id" idKey={"st_id"} valueKey={"st_label"} url={URLS.GET_PREFECTURE_URL} checkedValue={this.state.st_id} defaultName="都道府県"
                                    onHandleChange={this.onHandleChange} onHandleBlur={this.onHandleBlur} />
                                {this.validator.message('st_id', this.state.st_id, 'select')}
                            </div>
                            <div className="wd_125 inline_block">
                                <BasicDropDown ref={this.areaRef}
                                    className="full_wide" name="ar_id" idKey={"ar_id"} valueKey={"ar_label"} url={areaURL} checkedValue={this.state.ar_id} defaultName="エリア"
                                    onHandleChange={this.onHandleChange} onHandleBlur={this.onHandleBlur} />
                                {this.validator.message('ar_id', this.state.ar_id, 'select')}
                            </div>
                        </div>
                    </div>
                    <div className="element">
                        <div className="second_column">
                            <div className="wd_100 mr_40 text_right"><span className="tag-mg fs12"><span className="tag-inner">携帯電話番号</span></span></div>
                            <div className="wd_180 inline_block">
                                <input type="text" name="em_phone" placeholder="09000000000" value={this.state.em_phone} className="full_wide" onChange={this.onHandleChange} onBlur={this.onHandleBlur} />
                                {this.validator.message('em_phone', this.state.em_phone, 'required|phone')}
                                {/* {this.validator.message('em_phone', this.state.isPhoneExist, 'unique_phone' )} */}
                            </div>
                        </div>
                    </div>
                    <div className="button-container">
                        <div className="grey-button large mt_50">
                            <div className="inner" onClick={this.onClickHandler}>
                                登録する
                                <i className="fa fa-angle-right"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default connect(mapStateToProps, mapDispatcherToProps)(CreateAccountCom);
