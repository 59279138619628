import { ActionType } from 'typesafe-actions';
import * as actions from './actions';

export type BasicState = {
    total:number,
    items:[],
    error?:number,
    errorMessage?:string,
}
export enum actionTypes {
    SKILL_SET = "skill/set",
    STATE_SET = "state/set",
    AREA_SET = "area/set",
}
export type BasicActions = ActionType<typeof actions>;

export function getLabel( item:string ){
    if( item != null ){
        let itemList = item.split( "_" );
        if( itemList.length == 2 ){
            return {
                key:itemList[0],
                label:itemList[1],
            }
        }
        return null;
    }
    return null;
}
export function getKey( item:any ){
    let key;
    if( typeof( item ) == "string" ){
        let list = getLabel(item);
        key = "";
        if( list != null ){
            key = list.key;
        }
    }else if( typeof( item ) == "object" ){
        let list = getSelectedLabel(item);
        key = [];
        if( list != null ){
            for( let i = 0 ; i < list.length; i ++ ){
                key.push( list[i].key );
            }
        }
    }
    return key;
}
export function getSelectedLabel( items:Array<any> ){
    let ret = [];    
    for( let j = 0; j < items.length; j ++ ){
        const item = items[j];
        const list = item.split( "_" );
        ret.push({
            key:list[0],
            label:list[1],
        });
    }
    return ret;
}
export function getSelectedSkillsLabel( items:Array<any>, keys:Array<any> ){
    let ret = [];
console.log( "-----" );
console.log( items  );
console.log( keys );
console.log( "-----" );
    for( let i = 0; i < keys.length; i ++ ){
        let key = keys[i];
        for( let j=0; j < items.length; j ++ ){
            if( items[j].sk_id == key ){
                ret.push({
                    sk_id:key,
                    sk_label:items[j].sk_label
                });
                break;
            }
        }
//         let item = items.find( x => x.sk_id === key );
// console.log( item + " IS" );
//         if( item != null ){
//             ret.push({
//                 sk_id:key,
//                 sk_label:item.sk_label
//             });
//         }
//this.props.items.find( x => x.sk_id === b ).sk_label        
    }
    return ret;
}